import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import "../../../../css/ui/popUps/myTables.css";
import close_1 from '../../../../assets/images/table/close_1.svg';
import { withTranslation } from 'react-i18next'

export const MyAnimation = (props) => {
  // const [btnState, setBtnState] = useState(true);
  // const [tableId, setTableId] = useState(undefined);
  // const [tableType, setTableType] = useState(undefined);
  // const [Mytables, setMytables] = useState(false);




  return (
    <React.Fragment>
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.setAction("hideMyAnimation") }}> </div>
      <div className="popup_1">
        <div className="popup_1_in">
          <div className="head">My Animation
            <button className="close_1" onClick={(e) => { e.preventDefault(); props.setAction("hideMyAnimation") }}> <img src={close_1} alt="" /> </button>
          </div>
          <div className="fd p_10">
            <h2 style={{textAlign:"center"}}>Are u Sure To change The Animation</h2>
            <div style={{display:"flex",justifyContent:"end"}}>
            <button style={{borderRadius:"5px",backgroundColor:"white",margin:"10px",padding:"10px"}} onClick={()=>{props.animation();setTimeout(()=>{props.setAction("hideMyAnimation")},500)}}>Yes</button>
            <button style={{borderRadius:"5px",backgroundColor:"white",margin:"10px",padding:"10px"}} onClick={()=>{props.setAction("hideMyAnimation")}}>NO</button>
            </div>
          </div>
          {/* <div className="fd p_5">
            <button className="btn_1 wid_auto float_right" disabled={btnState} onClick={(e) => {
              e.preventDefault();
              if (tableId !== undefined) {
                props.network.send("<OpenTable id='" + tableId + "' type='" + tableType + "'/>");
              }
              setTableId(undefined);
              setTableType(undefined);
              setBtnState(true);
              props.setAction("hideMyTables")
            }}>Join Table</button>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(MyAnimation);
