import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import "../../../../css/ui/popUps/myBuddies.css";
import closeIcon from '../../../../assets/images/lobby/close_icon.svg';
import close_1 from '../../../../assets/images/table/close_1.svg';

import { withTranslation } from 'react-i18next'; 

const MyBuddies = (props) => {
  const [btnState, setBtnState] = useState(true);
  const [buddyName, setBuddyName] = useState(undefined);

  const columns = useMemo(() => {
    return [
      {
        Header: props.t("Nickname"),
        accessor: "name",
      },
      {
        Header: props.t("Country"),
        accessor: "id",
      },
    ];
  }, []);

  const data = useMemo(() => props.data, [props.data]);

  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, rows, prepareRow } = tableInstance;
  const [prevRow, setPrevRow] = useState();

  // function onRowClick(row) {
  //   console.log(row);
  // 	setBtnState(false);
  // 	console.log(data[row.index]);

  //   if (prevRow !== undefined) {
  //     prevRow.style.backgroundColor = null;
  //   }

  //   row.style.backgroundColor = "#0e5c63";
  // 			setPrevRow(row);
  // }

  useEffect(() => {
    let r = document.getElementsByTagName("tr");
    // console.log(r);
    for (var i = 0; i < r.length; i++) {
      let row = r[i];
      row.onclick = () => {
        if (prevRow !== undefined) {
          prevRow.style.backgroundColor = null;
        }
        row.style.backgroundColor = "#0e5c63";
        setPrevRow(row);
        if (row.dataset.name !== undefined) {
          setBtnState(false);
          setBuddyName(row.dataset.name);
        }
      };
    }
  });

  return (
    <React.Fragment>
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.setAction("hideBuddies"); }}> </div>
      <div className="popup_1">
        <div className="popup_1_in"> 
          <div className="head fd"> {props.t("My Buddies")}
            <button className="close_1" onClick={(e) => { e.preventDefault(); props.setAction("hideBuddies"); }}> <img src={close_1} alt=""/> </button>
          </div>
          <div className="fd p_10">
            <table className="table_1" {...getTableProps()} id="table">
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr data-name={row.original.name} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Header")}:<span style={{ color: "#fff" }}>{cell.render("Cell")}</span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <footer className="fd p_5">
            <button className="btn_2 fd" style={{height:"10px",width:"120px"}} disabled={btnState} onClick={(e) => {
              e.preventDefault();
              props.network.send(`<RemoveBuddy player="${buddyName}" network="rp1"/>`);
              setBtnState(true);
            }}>
              {props.t("Remove Selected")}
            </button> 

          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(MyBuddies);
