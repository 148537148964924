import close_1 from './../../../../assets/images/table/close_1.svg';
import "../../../../css/ui/popUps/alert.css";
import Countdown, { zeroPad } from "react-countdown";
import React, { useEffect, useState } from "react";

export default function BreakAlert(props) {
    console.log(props.data.timeTwo)
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return ''
        } else {
            return <span>
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        }
    }

    useEffect(() => {
        const updateScale = () => {
            const element = document.querySelector('.popup_1_in');
            if (element) {
                // Get the screen height
                const screenHeight = window.innerHeight;

                // Define minimum and maximum heights for scaling
                const minHeight = 375; // Minimum screen height for scaling
                const maxHeight = 812; // Maximum screen height for scaling

                // Define minimum and maximum scale factors
                const minScale = 0.75; // Minimum scale factor
                const maxScale = 1; // Maximum scale factor

                // Calculate scale factor based on screen height
                let scale = (screenHeight - minHeight) / (maxHeight - minHeight) * (maxScale - minScale) + minScale;

                // Clamp the scale to be within the minScale and maxScale bounds
                scale = Math.max(minScale, Math.min(maxScale, scale));

                // Apply the scale transformation
                element.style.transform = `scale(${scale})`;
                // element.style.transformOrigin = 'top left';
                // Adjust origin if needed
            }
        };

        // Initial call to set scale on mount
        updateScale();

        // Set up event listener for screen size changes
        window.addEventListener('resize', updateScale);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', updateScale);
    }, []);


    return (
        <>
            <div className="popCover_1" onClick={(e) => {
                e.preventDefault();
                props.setAction("hideAlert");
            }} > </div>
            <div className="popup_1">
                <div className="popup_1_in">
                    <div className="head"> Tournament Start
                        {/* <button className="close_1" onClick={(e) => {
                            e.preventDefault();
                            props.setAction("hideAlert");
                        }}> <img src={close_1} alt=''/> </button> */}
                    </div>
                    <div className="fd p_10 text_center">
                        <div style={{ height: '40px' }}>{props.data.lineOne}</div>
                        {/* <Countdown date={Date.now() + 5000} renderer={renderer} /> */}
                        <Countdown date={props.data.timeOne + props.data.timeTwo} renderer={renderer} />
                    </div>
                </div>
            </div>
        </>
    )
}