
import React, { useMemo, useEffect, useState } from "react";
import { useTable } from "react-table";
import wallet from './../../../../assets/images/lobby/profile/wallet.svg'
import spin from './../../../../assets/images/lobby/profile/spin.svg'
import tourno from './../../../../assets/images/lobby/profile/tourn.svg'
import background from './../../../../assets/images/lobby/profile/backgroundimage.svg'
// import star from './../../../../assets/images/lobby/profile/backgroundimage.svg'
import star from "./../../../../assets/images/lobby/star.png"
// import star from "./../../../../assets/images/lobby/star.png"
import { withTranslation } from 'react-i18next';
import UM from "../../../utils/utilityMethods";

export const Profile = (props) => {
    const [levepercetage, setLevepercetage] = useState(null)

    useEffect(() => {
        for (let i = 0; i < props.data.data.length - 1; i++) {
            if (props.t(props.data.level) == props.t("Iron")) {
                setLevepercetage(props.balance.VIPpoints / (props.data.data[0].earn))

            } else if (props.data.data[i].level == props.t(props.data.level)) {
                setLevepercetage(props.balance.VIPpoints / (props.data.data[i + 1].earn - props.data.data[i].earn))
            }
        }
    }, [props.data.data.length != 0])
    const data = useMemo(() => props.data.data, [props.data.data]);
    const columns = useMemo(() => {
        return [
            {
                Header: props.t("Level"),
                accessor: "level",
            },
            {
                Header: props.t("Need to Earn"),
                accessor: "earn",
                Cell: row => <div style={{ textAlign: "right" }}>{Number(row.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
            },
            {
                Header: props.t("By the Date"),
                accessor: "date",
                Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>
            },
        ];
    }, []);

    const tableInstance = useTable({
        columns,
        data,
    });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    const UserLevel = () => {

        return (
            <div className="userlevel">

                <div className="stars"
                // onClick={() => { props.setAction("PLI") }}
                >
                    <div className="singleStar0" hidden={props.stars.levelOne}>
                        <img src={star} alt="" /></div>
                    <div className="singleStar0" hidden={props.stars.levalZero}>
                        <img src={star} alt="" /></div>

                    <div className="singleStar1" hidden={props.stars.levelTwo}>
                        <img src={star} alt="" />
                    </div>

                    <div className="singleStar2" hidden={props.stars.levelThree}>
                        <img src={star} alt="" />
                    </div>

                    <div className="singleStar3" hidden={props.stars.levelFour}>
                        <img src={star} alt="" />
                    </div>
                    <div className="singleStar4" hidden={props.stars.levelFive}>
                        <img src={star} alt="" />
                    </div>
                    <div className="singleStar5" hidden={props.stars.levelSix}>
                        <img src={star} alt="" />
                    </div>
                    <div className="singleStar6" hidden={props.stars.levelSeven} >
                        <img src={star} alt="" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="popCover_1" style={{ top: "129px", background: "#1F2126" }}>
                <div className="p_20">
                    <div className="fd row">
                        <div className="col-6" >
                            <div className="row bg_6 rds_7">

                                <img className="m_10" style={{ height: "35px" }} src={props.avtar} />
                                <div className="userProfile ">
                                    <span className="name_U bold" style={{ color: '#fff' }}> {props.user} </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row bg_6 rds_7">
                                <img className="m_10" style={{ height: "35px" }} src={wallet} />
                                <div className="userProfile ">
                                    <span className="name_U bold" style={{ color: '#fff' }}> Balance </span>
                                    <span className="name_U bold" style={{ color: '#41B856' }}> {UM.numberWithCommas(props.balance.available) + " " + "₾"} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fd row m_t_15">

                        <div className="col-6">
                            <div className="row bg_6 rds_7">
                                <img className="m_10" style={{ height: "35px" }} src={tourno} />
                                <div className="userProfile ">
                                    <span className="name_U bold" style={{ color: '#fff' }}> PLAYMONEY </span>
                                    <span className="name_U bold" style={{ color: '#BE3177' }}> {UM.numberWithCommas(props.balance.PLAYMONEY) + " " + "₾"} </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row bg_6 rds_7">
                                <img className="m_10" style={{ height: "35px" }} src={spin} />
                                <div className="userProfile ">
                                    <span className="name_U bold" style={{ color: '#fff' }}> COMPPOINTS </span>
                                    <span className="name_U bold" style={{ color: '#FFCA2C' }}> {UM.numberWithCommas(props.balance.VIPpoints)} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fd row m_t_15">
                        <div className="col-12 bg_6 rds_7" >

                            {/* <div className="df_jc_ac p_5">
                                <span className="col-6 d_flex jcs">Bonus</span><span className="col-6 d_flex jcc"></span>
                            </div> */}
                            {/* <div className="df_jc_ac p_5">
                                <span className="col-6 d_flex jcs">Balance</span><span className="col-6 d_flex jce">{UM.numberWithCommas(props.balance.available) + " " + "GEL"}</span>
                            </div>
                            <div style={{ border: "1px solid #3D3E43", margin: "0px 10px 0px 10px" }}></div>
                            <div className="df_jc_ac p_5">
                                <span className="col-6 d_flex jcs">In Play</span><span className="col-6 d_flex jce">{UM.numberWithCommas(props.balance.cashInPlay) + " " + "GEL"}</span>
                            </div> */}
                            <div style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat" }} className="rds_9 ">
                                <div className="df_jc_ac p_5 m_t_15">
                                    <span className="col-6 d_flex jcs m_t_15">Level</span><span className="col-6 d_flex jcc m_t_15"><UserLevel></UserLevel></span>

                                </div>
                                <div className="d_flex jcc m_t_10">
                                    <div className="fd rds_7" style={{ border: "0.5 solid #707070", background: "#0000003d", width: "90%", height: "8px" }} >
                                        <div className="fd m_3" style={{ background: "linear-gradient(90deg, rgba(69,188,90,1) 0%, rgba(33,152,54,1) 100%)", height: "7px", padding: "3px", borderRadius: "10px", margin: "0px", width: `${levepercetage}%` }}></div>

                                    </div>
                                </div>
                                <div className="df_jc_ac p_5">
                                    {/* <span className="col-6 d_flex jcs m_t_5 m_b_10">Period ends:</span><span className="col-6 d_flex jcs m_t_5"> 24 Feb</span> */}
                                    {/* <UserLevel></UserLevel> */}

                                </div>
                            </div>
                            <div className="fd m_b_20 m_t_20">
                                <div className="fd p_5">
                                    <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                                        <span className="clr_ff"> {props.t("My Vip level")} : </span> <span className="clr_5">{props.data.level}</span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <div>
                                            <span className="clr_ff"> {props.t("This Month")} : </span> <span className="clr_5">{Number(props.data.collection1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                        </div>
                                        <div>
                                            <span className="clr_ff">  {props.t("This Year")} : </span> <span className="clr_5">{Number(props.data.collection2).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="fd">
                                    {data.length != 0 && <table className="table_1" cellPadding={0} cellSpacing={0} {...getTableProps()} id="table" style={{ fontSize: "10px" }}>
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {rows.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>}
                                </div>

                            </div>
                            {/* <div className="df_jc_ac p_5 m_t_15">
                                <span className="col-6 d_flex jcs">All</span><span className="col-6 d_flex jce" style={{ color: "#41B856" }}>200 GEL</span>
                            </div> */}
                            {/* <div style={{ border: "0.2px solid #3D3E43", margin: "0px 10px 0px 10px" }}></div> */}
                            {/* <div className="df_js_ac p_5 row m_b_10 fd">
                                <div className="col-2">
                                    <img src={star} />
                                </div>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                    <span>Cashback status</span>
                                    <span>8.86 GEL</span>
                                </div>

                            </div> */}
                            {/* <div className="d_flex jcc m_t_15">
                                <div className="fd rds_7" style={{ border: "0.5 solid #707070", background: "#0000003d", width: "90%", height: "8px" }} >
                                    <div className="fd m_3" style={{ background: "linear-gradient(90deg, rgba(69,188,90,1) 0%, rgba(33,152,54,1) 100%)", height: "7px", padding: "3px", borderRadius: "10px", margin: "0px", width: `${75}%` }}></div>

                                </div>
                            </div> */}

                            {/* <div className="df_jc_ac p_5 m_t_15 d_flex jcc m_b_15">
                                <button className="col-8 p_10 rds_4" style={{ background: "linear-gradient(rgb(226, 196, 87) 35%, rgb(255, 184, 0) 100%)" }}>Cash out</button>
                            </div> */}

                        </div>


                    </div>




                </div>


            </div>

        </React.Fragment>
    );
};
export default withTranslation()(Profile);
