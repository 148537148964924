import fileName from './jsconfig.js'
import Screen from './components/utils/screen.js';
var sid;
const interval = setInterval(() => {
    if (sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== undefined || sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== null) {
        try {
            var ws = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid;
            sid = (ws).split(".")[1]
        } catch (e) { console.log(e) }
    } else {
        sid = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid;
    }
    if (localStorage.getItem('showPop')) {
        setTimeout(() => {
            clearInterval(interval)
        }, 500);
    }
}, 500);

console.log(sid)
export default class Config {
    URL_Environment = {
        webSocket: {

            // vendibet-sl.undertesting.com - vesmaster
            // -sl.undertesting.com - lapoker
            // pokerperu-sl.undertesting.com - penmaster
            // prode-sl.undertesting.com - prode
            // fullbet-sl.undertesting.com - fullbet
            // clpmaster.undertesting.com - clpmaster



            // River socketUrls: -
            // 'wss://www.rivho21.com/WebSocket'; old_url
            // `wss://www.riverpoker.com/WebSocket`; live_url
            // `wss://riv3.czargaming.com/WebSocket`; staging_url

            // Lapoker socketUrls: -
            // 'wss://lapoker.ws/WebSocket'; live_url
            // `wss://demo.rapoker.club/WebSocket`; staging_url
            socketUrl: (
                // fileName.name === "Riverpoker" ? (fileName.buildForLive ? `wss://www.riverpoker.com/WebSocket` : fileName.optimizeUrl ? `wss:${window.location.host}/WebSocket` : `wss://riv3.czargaming.com/WebSocket`) :
                fileName.name === "Riverpoker" ? (fileName.buildForLive ? `wss://www.riverpoker.com/WebSocket` : fileName.optimizeUrl ? `wss:${window.location.host}/WebSocket` : `wss://riv7.czargaming.com/WebSocket`) :
                    // fileName.name === "Riverpoker" ? `wss://riverpoker-dev.czargaming.com/WebSocket` :
                    fileName.name === 'Lapoker' ? (fileName.buildForLive ? `wss://demo.latsoft.org/WebSocket` : `wss://demo.latsoft.org/WebSocket`) :
                        // fileName.name === "Leader_bet" ? `wss://lbet.undertesting.com/WebSocket` :
                        // fileName.name === "Leader_bet" ? `wss://demo.latsoft.org/WebSocket` :
                        fileName.name === "Leader_bet" ? `wss://riv3.czargaming.com/WebSocket` :
                            fileName.name === "vendibet" ? `wss://vendibet-sl.undertesting.com/WebSocket` :
                                fileName.name === "lapoker" ? `wss://lapoker-sl.undertesting.com/WebSocket` :
                                    fileName.name === "pokerperu" ? `wss://pokerperu-sl.undertesting.com/WebSocket` :
                                        fileName.name === "prode" ? `wss://prode-sl.undertesting.com/WebSocket` :
                                            fileName.name === "fullbet" ? `wss://fullbet-sl.undertesting.com/WebSocket` :
                                                `wss://clpmaster.undertesting.com/WebSocket`
            )
        },
        proxy: {
            // River baseUrls: - 
            // 'www.rivho21.com', old_url
            // https://www.riverpoker.com/ live_url
            // https://riv3.czargaming.com/ staging_url


            // Lapoker baseUrls: - 
            // https://lapoker.ws/ live_url
            // https://demo.rapoker.club/ staging_url
            baseUrl: (
                // fileName.name === "Riverpoker" ? (fileName.buildForLive ? `https://www.riverpoker.com` : fileName.optimizeUrl ? window.location.origin : `https://riv3.czargaming.com`) :
                fileName.name === "Riverpoker" ? (fileName.buildForLive ? `https://www.riverpoker.com` : fileName.optimizeUrl ? window.location.origin : `https://riv7.czargaming.com`) :
                    // fileName.name === "Riverpoker" ? `http://riverpoker-dev.czargaming.com` :
                    // http://riverpoker-dev.czargaming.com/api/history/pokergameRoundData
                    fileName.name === 'Lapoker' ? (fileName.buildForLive ? `https://demo.latsoft.org` : `https://demo.latsoft.org`) :
                        // fileName.name === 'Leader_bet' ? `https://lbet.undertesting.com` :
                        // fileName.name === 'Leader_bet' ? `https://demo.latsoft.org` :
                        fileName.name === 'Leader_bet' ? `https://riv3.czargaming.com` :
                            fileName.name === 'vendibet' ? `https://vendibet-sl.undertesting.com` :
                                fileName.name === 'lapoker' ? `https://lapoker-sl.undertesting.com` :
                                    fileName.name === 'pokerperu' ? `https://pokerperu-sl.undertesting.com` :
                                        fileName.name === 'prode' ? `https://prode-sl.undertesting.com` :
                                            fileName.name === 'fullbet' ? `https://fullbet-sl.undertesting.com` :
                                                `https://clpmaster-sl.undertesting.com`
            )
        },
        apiPath: {
            login_Api: "/api/player/login",
            generateCaptcha_Api: "/api/player/generateCaptchaText",
            resetPassword_Api: "/api/player/resetPassword",
            getAvatarList_Api: "/api/player/getAvatarList",
            setAvatar_Api: "/api/player/setAvatar",
            getAvtr_Api: "/api/player/getAvatar",
            balcnce_Api: "/api/cashier/balance",
            deposit_Api: "/api/cashier/buy",
            withdraw_Api: "/api/cashier/cashout",
            transaction_Api: "/api/history/transaction",
            invteFrnd_Api: "/api/cashier/inviteFriend",
            handHistory_Api: "/api/history/gameRoundData",
            // handHistory_Api: "/api/history/pokergameRoundData",
            handDataNew_Api: "/api/history/handDataNew",
            handplayNew_Api: "/api/history/handplay",
            logout_Api: "/api/player/logout",
        },
        url: {
            gotoSlots: `/client-redirect?LANG=en&sid=${sid}&to=slots`,
            gotoSports: `/client-redirect?LANG=en&sid=${sid}&to=sports`,
            gotoHorses: `/client-redirect?LANG=en&sid=${sid}&to=horseracing`,
            gotoDeposit: `/client-redirect?LANG=en&sid=${sid}&to=deposit`,
            gotoExchangeCurrency: `/client-redirect?LANG=en&sid=${sid}&to=exchangeCurrency`,
            gotoLivecasino: `/client-redirect?LANG=en&sid=${sid}&to=liveDealer_2`,
            gotoCrash: `/client-redirect?LANG=en&sid=${sid}&to=crash`,
            gotoSportsm: `/client-redirect?LANG=en&sid=${sid}&to=Sportsm`
        },
        sitIds: {
            sitid: (
                fileName.name === "Riverpoker" ? 'rp1' :
                    fileName.name === "Lapoker" ? 'prode' :
                        // fileName.name === "Leader_bet" ? 'lbet' :
                        // fileName.name === "Leader_bet" ? 'prode' :
                        fileName.name === "Leader_bet" ? 'rp1' :
                            fileName.name === "vendibet" ? 'vesmaster' :
                                fileName.name === "lapoker" ? 'prode' :
                                    fileName.name === "pokerperu" ? 'penmaster' :
                                        fileName.name === "prode" ? 'prode' :
                                            fileName.name === "fullbet" ? 'fullbet' :
                                                'clpmaster'
            )
        },

        TableFeatures: {
            selected: (
                fileName.name === "Riverpoker" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F2" :
                    fileName.name === "Lapoker" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                        fileName.name === "Leader_bet" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F2" :
                            fileName.name === "vendibet" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                                fileName.name === "lapoker" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                                    fileName.name === "pokerperu" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                                        fileName.name === "prode" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                                            fileName.name === "fullbet" ? Screen.getDeviceType().name === "Mobile" ? "F1" : "F1" :
                                                Screen.getDeviceType().name === "Mobile" ? "F1" : "F1"
            )

        }

    }
}