import { useMemo, useEffect, useState } from "react";
import { useTable } from "react-table";
import close_1 from '../../../../assets/images/table/close_1.svg';

import { withTranslation } from 'react-i18next';
import "../../../../css/ui/popUps/tourneyInfo.css";

function TournamentInfo(props) {
  console.log(props)
  var breakCount = 0;
  const columns = useMemo(() => {
    return [
      {
        Header: props.t("Level"),
        accessor: "level",
      },
      {
        Header: props.t("Stakes"),
        accessor: "stakes",
      },
      {
        Header: props.t("Ante"),
        accessor: "ante",
        Cell: row => <>{Number(row.value) ? Number(row.value).toLocaleString("en-US") : row.value}</>
      },
      {
        Header: props.t("Period"),
        accessor: "period",
      },
    ];
  }, []);
  const data = useMemo(() => props.data.info, [props.data.info]);
  const table = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;

  const [previousRow, setPreviousRow] = useState(undefined);

  useEffect(() => {
    if (props.data.currentLevel !== undefined) {
      const present = Number(props.data.currentLevel) + Number(breakCount);
      // const present = 12;
      if (previousRow !== undefined) {
        // previousRow.style.backgroundColor = null;
      }
      let t = document.getElementById("info_table");
      let r = t.rows
      let selectedRow = r[present];
      if (selectedRow?.cells[0].innerText === "Break") {
        breakCount = breakCount + 1;
        selectedRow = r[Number(present) + Number(breakCount)];
      }
      // selectedRow.style.backgroundColor = "#ff0000";
      setPreviousRow(selectedRow);
    }
    // 
  }, [props.data.currentLevel])
  // [props.data.currentLevel]
  return (
    <div className="fd">
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.close("INFO") }}></div>
      <div className="popup_1 maxHt_90" style={{ maxWidth: props.info ? "350px" : "600px" }}>
        <div className="popup_1_in" style={{ maxHeight: 'none', overflow: 'initial' }}>
          {/* <div className="head"> Tournament Schedule */}
          <div className="head"> {props.t("Tournament Schedule")}
            <button className="close_1" onClick={(e) => { e.preventDefault(); props.close("INFO") }}> <img src={close_1} alt="" /> </button>
          </div>


          <div className="fd bg_3 rds_5 m_t_5 p_lr_10 txt_clr_3">
            <div style={{ textAlign: "center" }}>
              <div>{props.data.data.text1}</div>
              <div>{props.data.data.text2}</div>
              <div>{props.data.data.text3}</div>
            </div>
          </div>
          <table className="playersCount fd">
            <tbody>
              <tr><td>{props.t("Min Players")}:</td><td> {props.data.data.minPlayers}</td></tr>
              <tr><td>{props.t("Max Players")}:</td><td> {props.data.data.maxPlayers}</td></tr>
              {props.data.data.rebuys &&
                <tr><td>{props.t("Rebuy")}:</td><td> {props.data.data.rebuys}</td></tr>
              }
              {props.data.data.addons &&
                <tr><td>{props.t("Addon")}:</td><td> {props.data.data.addons}</td></tr>
              }
            </tbody>
          </table>



          <div className="fd p_10" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ borderBottom: '1px solid #dfb276', color: '#dfb276' }}>{props.t("Blinds Structure")} :<span>&nbsp; {props.data.data.blinds}</span></div>
            <div >{props.t("Starting Chips")} :<span style={{ color: '#dfb276' }}> &nbsp; {Number(props.data.data.startingChips).toLocaleString("en-US")}</span></div>
          </div>
          <div className="fd p_lr_10">

            <table id="info_table" className="table_1 m_b_15" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={row.original.id} data-id={row.original.id} data-type={row.original.type} {...row.getRowProps()} style={{ background: i == props.data.currentLevel ? "#ff0000" : "" }}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;

                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(TournamentInfo)
