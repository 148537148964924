import refresh from "../../../../../assets/images/lobby/refresh.svg";
import "../../../../../css/ui/lobby/filterCheckBoxOne.css";
import { withTranslation } from 'react-i18next';
import fileName from "../../../../../jsconfig";

import Screen from "../../../../utils/screen";
function FilterCheckBox(props) {

  const style = {

  }
  return (
    <div className="fd d-flex">
      {Screen.getDeviceType().name == "Desktop" && (<form action="/action_page.php" method="get" className="d-flex" style={{ alignItems: fileName.name === "Riverpoker" ? 'center' : '', justifyContent: fileName.name === "Riverpoker" ? 'center' : '' }}>
        <label className="switch">
          <input type="checkbox" name="Full" id="Full" value="FULL" onClick={(event) => { props.action("Check1", event.target.value, event.target.checked) }} />
          <span className="slider round"></span>
        </label>
        <span style={{ marginLeft: '3px' }}>{props.t('Full')}</span>
        <label className="switch" style={{ marginLeft: '3px' }}>
          <input type="checkbox" name="Empty" id="Empty" value="EMPTY" onClick={(event) => { props.action("Check1", event.target.value, event.target.checked) }} />
          <span className="slider round"></span>
        </label>
        <span style={{ marginLeft: '3px' }}>{props.t('Empty')}</span>
      </form>)}


      {Screen.getDeviceType().name == "Mobile" && (<form action="/action_page.php" method="get" className="d-flex">
        <label htmlFor="Full" className="m_l_10 df_ac font_12">
          <input className="checkbox-round" type="checkbox" name="Full" id="Full" value="FULL" onClick={(event) => { props.action("Check1", event.target.value, event.target.checked) }} />
          <span className="checkmark"></span>
          {props.t('Full')}
        </label>
        <label htmlFor="Empty" className="m_l_10 df_ac font_12">
          <input className="checkbox-round" type="checkbox" name="Empty" id="Empty" value="EMPTY" onClick={(event) => { props.action("Check1", event.target.value, event.target.checked) }} />
          {props.t('Empty')}
        </label>
      </form>)}
    </div>
  );
}
export default withTranslation()(FilterCheckBox);