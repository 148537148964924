import { useEffect } from "react";
import Settings from "../../../../assets/images/table/HeadIcons/settings1.svg";
import table_menu_lb from "../../../../assets/images/table/Leader-bet-icons/table-menu_lb.png";
import fileName from "../../../../jsconfig";
export const RightMenu = (props) => {
    useEffect(() => {
        // if(window.innerWidth>=330&&window.innerWidth<=764){props.SelectDevice("mobile")} else if(window.innerWidth>=768&&window.innerWidth<=1024){props.SelectDevice("tablet")}else{props.SelectDevice("desktop")}
    }, [])
    function aspect_ratio(val, lim) {

        var lower = [0, 1];
        var upper = [1, 0];

        while (true) {
            var mediant = [lower[0] + upper[0], lower[1] + upper[1]];

            if (val * mediant[1] > mediant[0]) {
                if (lim < mediant[1]) {
                    return upper;
                }
                lower = mediant;
            } else if (val * mediant[1] == mediant[0]) {
                if (lim >= mediant[1]) {
                    return mediant;
                }
                if (lower[1] < upper[1]) {
                    return lower;
                }
                return upper;
            } else {
                if (lim < mediant[1]) {
                    return lower;
                }
                upper = mediant;
            }
        }
    }
    window.onresize = (e) => {
        console.log(e.target.innerWidth)
        console.log(e.target.innerHeight)
        if (e.target.innerWidth >= 330 && e.target.innerWidth <= 764) { props.SelectDevice("mobile") } else if (e.target.innerWidth >= 768 && e.target.innerWidth <= 1024) { props.SelectDevice("tablet") } else { props.SelectDevice("desktop") }
        console.log(aspect_ratio(e.target.innerWidth / e.target.innerHeight, 50))
    }

    const style_lb = {
        position: "fixed",
        left: "0px",
        top: '0px',
        width: '45px',
        height: '45px',
        margin: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '23px',
        zIndex: '999',
    }
    return (
        <>
            {fileName.name !== "Leader_bet" ?
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        zIndex: "1100",
                        right: (window.innerWidth >= 330 && window.innerWidth <= 764 ? "9%" : fileName.name === "Riverpoker" ? "16px" : "0px"),
                        width: "45px",
                        height: "60px",
                        top: "0px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        className="statsMenu"
                        onClick={(e) => {
                            e.preventDefault();
                            props.action("showCheckBox");
                        }}
                    >
                        {" "}
                        <img src={Settings} alt="" />{" "}
                    </div>
                </div>
                :
                <div style={style_lb} onClick={(e) => {
                    e.preventDefault();
                    props.action("showCheckBox");
                }} >
                    <img style={{ width: '45px', height: '45px', marginTop: '2px' }} src={table_menu_lb} alt="" />
                </div>
            }
        </>
    );
};
