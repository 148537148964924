import LobbyBase from "./lobbyBase";
import LobbyMenu from "../ui/lobby/lobbyMenu";
import LobbyHeader from "../ui/lobby/lobbyHeader";
import { MainGrid } from "../ui/lobby/Grid/mainGrid";
import { MainGridTourney } from "../ui/lobby/Grid/mainGridTourney";
import { MainGridSitGo } from "../ui/lobby/Grid/mainGridSitGo";
import SitGoDetails from "../ui/lobby/tableDetails/sitGoDetails";
import TourneyDetails from "../ui/lobby/tableDetails/tourneyDetails";
import FilterTabs from "../ui/lobby/filterTabs/filterTabs";
import TourneyRegistration from "../ui/popUps/tourneyRegistration";
import Alert from "../ui/popUps/alert";
import NewsBox from "../ui/popUps/newsBox";
import DpSettings from "../ui/popUps/dpSettings";
import PlayerlevelInfo from "../ui/popUps/playerLevelInfo";
import MyTables from "../ui/popUps/myTables";
import MyTournaments from "../ui/popUps/myTournaments";
import Findplayer from "../ui/popUps/findPlayer";
import Options from "../ui/popUps/settings/Options";
import AvatarPopup from "../ui/popUps/AvatarPopup";
import Cashier from "../ui/popUps/cashier";
import MyBuddies from "../ui/popUps/MyBuddies";
import PokerhandHistory from "../ui/popUps/pokerhandHistory";
import UM from "../../utils/utilityMethods";
import LobbyLinks from "../ui/lobby/lobbyLinks";
import Config from "../../../config";
import bottom_mp4 from '../../../assets/images/lobby/VideoMP4/BNR_LOBBY_ES.mp4';
// import river_bottom_banner from '../../../assets/images/lobby/VideoMP4/river-bottom-banner.mp4';
import river_bottom_banner from '../../../assets/images/lobby/VideoMP4/river-bottom-banner.png';
import proban from '../../../assets/images/lobby/VideoMP4/Lb_Banner.png';
import right_mp4 from '../../../assets/images/lobby/VideoMP4/lobby_lateral.mp4';
import { withTranslation } from 'react-i18next';
import fileName from "../../../jsconfig";
import { TableDetails } from "../ui/lobby/tableDetails/tableDetailsPage";
import carpet from '../../../assets/images/tableandcarpet/carpet_1.png';
import defaultAvtar from "../../../assets/images/lobby/avtar/default.svg";

import Screen from "../../utils/screen";
import TourneyDetailspage from "../ui/lobby/tableDetails/tourneyDetailspage";
import MyActiveTourCashTables from "../ui/popUps/myactivetourcashtables";
import rightbanner_png from '../../../assets/images/lobby/VideoMP4/rightbanner.png'
import FilterCheckBoxOne from "../ui/lobby/filterTabs/filterCheckBoxOne";
import FilterCheckBoxTwo from "../ui/lobby/filterTabs/filterCheckBoxTwo";
import FilterCheckBox from "../ui/lobby/filterTabs/filterCheckBoxOne";
import DateUtils from "../../utils/dateUtils";
import React from "react";
import Profile from "../ui/popUps/profile";
import playbtn from '../../../assets/images/lobby/profile/playbtn.svg'
import cancelbtn from '../../../assets/images/lobby/profile/cancelbtn.svg'
import annoncedbtn from '../../../assets/images/lobby/profile/padlock.svg'
import timerbtn from '../../../assets/images/lobby/profile/timerbtn.svg'
import watchbtn from '../../../assets/images/lobby/profile/viewbtn.svg'
import myTournaments from "../ui/popUps/myTournaments";
import UnRegisterpopup from "../ui/popUps/UnRegisterpopup";
import MyAnimation from "../ui/popUps/MyAnimation";
import { Emitter } from "../../utils/eventEmitter";


class LobbyMain extends LobbyBase {
    constructor(props) {
        super(props);
        console.log("lobby Main props are");
        this.state = {
            showCheckOne: true,
            BgState: {
                opacity: 1,
                // backgroundImage: `url(${carpet})`
            },
            data: [],
            gameHistoryErrorMsg: "",
            gametableName: "",
            gametourneyName: "",
            frameCountWidth: "",
            user: this.props.data.user,
            sid: this.props.data.sid,
            tLobbyName: "",
            tTourneyId: "",
            showTopmost: true,
            showTopsecond: true,
            showTourneyLobby: false,
            showActiveTables: false,
            profileshow: false,
            UnRegisterpopup: false,
            showTourneyDetails: false,
            showSitnGoDetails: false,
            activeTableButton: false,
            tourneyTablesTab: false,
            cashTablesTab: false,
            lobbyButton: false,
            iframeTableLength: 0,
            frameContainerWidth: 0,
            dataTourney: [],
            avatarList: {},
            myLevel: "",
            dataSitGo: [],
            tableData: {
                name: "No Table Selected",
                id: "",
                type: "",
                hands_per_hour: "",
                average_pot: "",
                data: [],
                seats: [],
            },
            tourneyData: {
                name: "",
                date: "",
                buyIn: "",
                type: "",
                typeText: "",
                status: "",
                players: "",
                prize: "",
                description: "",
                id: "",
                Tournament_start: "",
                Tournament_cancelled: "",
                status1: "Register"
            },
            sitGoData: {
                name: "",
                date: "",
                buyIn: "",
                type: "",
                typeText: "",
                status: "",
                players: "",
                prize: "",
                description: "",
                id: "",
                Tournament_start: "",
                Tournament_cancelled: "",
                status1: "Register"
            },
            userLogged: "",
            userBalance: "",
            balanceInGame: "",
            playerData: {
                available: "-",
                bonus: "-",
                cashInPlay: "-",
                bonuInPlay: "-",
                tickets: "-",
                tMoney: "-",
                total: "-",
                VIPpoints: "-",
                PLAYMONEY: "-"
            },
            serverInfo: {
                players: "",
                tables: "",
                tourneys: "",
            },
            bbj: "",
            showMainGridOne: true,
            showMainGridTwo: false,
            showMainGridThree: false,
            showRegistration: false,
            showTDbtn_joinMe: true,
            showTDbtn_seatMe: true,
            showTObtn: false,
            showTTbtn: false,
            showAlert: false,
            alert: {
                lineOne: "",
                lineTwo: "",
            },
            regData: {
                types: [],
                status: "-",
                statusAction: "",
                name: "-",
                balance: "-",
                type: "-",
                id: "",
                balance: "",
                tableName: "",
                tableType: ""
            },
            showNewsBox: false,
            news: [],
            showDpSettings: false,
            dpSettings: {
                available: "-",
                left: "-",
                date: "-",
            },
            showLevelInfo: false,
            levelData: {
                level: "-",
                collection1: "-",
                collection2: "-",
                data: [],
            },
            displayStars: {
                levalZero: true,
                levelOne: true,
                levelTwo: true,
                levelThree: true,
                levelFour: true,
                levelFive: true,
                levelSix: true,
                levelSeven: true,
            },

            showOptions: false,
            showMyTables: false,
            myTables: [],
            historyData: [],
            historyDataForReplay: [],
            historyDataNext: [],
            subHistorydata: [],
            showMyTournaments: false,
            myTournaments: [],
            myTournamentsids: [],
            showFindPlayer: false,
            findPlayerData: [],
            showMyBuddies: false,
            myBuddies: [],
            showAvtar: false,
            showpokerHistory: false,
            // avtar: "-",
            avtar: defaultAvtar,
            showCashier: false,
            showOpenTableAlert: false,
            openTableData: { id: undefined, value: undefined },
            time: "00:00",
            newavatar: [],
            avtarId: [],
            Dparray: [],
            myAvatr: "-",
            showMyAnimation: false,
            showProfile: false,
            reEntryTable: {
                isTourneyRetry: false,
                maxRetryCount: 0,
                noOfRetries: 0
            },
            showRegisterBtn: true
        };
        this.tableListArray = [];
        this.tourneyListArray = [];
        this.cashierTourneyTables = [];
        this.playerInfo = [];
        this.tableWidth = (this.sWidth - 20) * 0.5;
        this.tableHeight = (this.sHeight - 20) * 0.6;
        this.config = new Config();
        this.DateUtils = new DateUtils();
        // this._lobbyNetwork.send("<GetMyTournaments/>");


        function updateAnimationTiming() {
            const letters = document.querySelectorAll('.drop-main > div');
            const animationDuration = 2; // Total duration for one complete sequence
            const letterDelay = 0.2; // Delay between each letter's start

            letters.forEach((letter, index) => {
                const delay = index * letterDelay;
                letter.style.animationDelay = `${delay}s`;
            });
        }

        setInterval(() => {
            updateAnimationTiming();
        }, 2500);

    }


    onPlayerInfo(data) {
        this.playerInfo = [];
        if (data.hasOwnProperty("PlayerInfo")) {
            let playerData = this.state.playerData;
            let displayStars = this.state.displayStars;

            if (data.PlayerInfo.hasOwnProperty("attr")) {
                this.setState({ userLogged: data.PlayerInfo.attr.nickname });
                if (data.PlayerInfo.attr.level >= 4) {
                    this.setState({ myLevel: "Platinum" });
                } else if (data.PlayerInfo.attr.level >= 3) {
                    this.setState({ myLevel: "Gold" });
                } else if (data.PlayerInfo.attr.level >= 2) {
                    this.setState({ myLevel: "Silver" });
                } else if (data.PlayerInfo.attr.level >= 1) {
                    this.setState({ myLevel: "Bronze" });
                } else if (data.PlayerInfo.attr.level >= 0) {
                    this.setState({ myLevel: "Iron" });
                }
                if (data.PlayerInfo.attr.avatar) {
                    console.log("from here only")
                    this.setAvtarMy(data.PlayerInfo.attr.avatar)
                }
            }
            if (data.PlayerInfo.hasOwnProperty("Balance")) {
                let i = 0,
                    cnt = data.PlayerInfo.Balance.length;
                var balance = 0;
                this.playerInfo.push(data.PlayerInfo);
                for (i; i < cnt; i++) {
                    if (data.PlayerInfo.Balance[i].attr.wallet === "COMPPOINTS") {
                        playerData.VIPpoints = data.PlayerInfo.Balance[i].attr.total;
                    }
                    if (data.PlayerInfo.Balance[i].attr.wallet === "PLAYMONEY") {
                        playerData.PLAYMONEY = data.PlayerInfo.Balance[i].attr.total;
                    }
                    // if (data.PlayerInfo.Balance[i].attr.wallet === "ARS" || data.PlayerInfo.Balance[i].attr.wallet === "CHP" || data.PlayerInfo.Balance[i].attr.wallet === "GEL" || data.PlayerInfo.Balance[i].attr.wallet === "USD") {
                    if (data.PlayerInfo.Balance[i].attr.wallet === data.PlayerInfo.attr["preferred-wallet"]) {
                        localStorage.setItem("currency_symbols", data.PlayerInfo.attr["preferred-wallet"] === "USD" ? "$ " : "")
                        var playerCash = data.PlayerInfo.Balance[i].attr.cash.split(".")[0];
                        var playerBonus = data.PlayerInfo.Balance[i].attr["bonus"].split(".")[0];
                        playerData.available = Number(playerCash) + Number(playerBonus);
                        playerData.cashInPlay = data.PlayerInfo.Balance[i].attr["cash-in-play"];
                        playerData.tMoney = data.PlayerInfo.Balance[i].attr["tournament-money"];
                        playerData.bonus = data.PlayerInfo.Balance[i].attr.bonus;
                        playerData.bonuInPlay = data.PlayerInfo.Balance[i].attr["bonus-in-play"];
                        playerData.total = Number(playerData.available) + Number(playerData.cashInPlay) + Number(playerData.bonus);

                        if (balance < Number(data.PlayerInfo.Balance[i].attr["cash-in-play"])) {
                            balance = Number(data.PlayerInfo.Balance[i].attr["cash-in-play"])
                        }

                    }
                }
                if (localStorage.getItem('showPop')) {
                    this._lobbyNetwork.send("<GetMyTournaments/>");
                    // this.setState({showMyTournaments:false})
                }
                if (Number(0) === balance) {
                    this.setState({ showActiveTables: false });
                    localStorage.removeItem('showPop');
                } else {
                    this.setState({ showActiveTables: localStorage.getItem('showPop') === "true" ? true : false });
                }

                displayStars.levalZero = !(0 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelOne = !(1 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelTwo = !(2 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelThree = !(3 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelFour = !(4 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelFive = !(5 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelSix = !(6 <= Number(data.PlayerInfo.attr.level))
                displayStars.levelSeven = !(7 <= Number(data.PlayerInfo.attr.level))
                // if (playerData.VIPpoints >= 0 && playerData.VIPpoints < 1000) {
                //     displayStars.levalZero = false
                // }
                // else if (playerData.VIPpoints >= 1000 && playerData.VIPpoints < 3000) {
                //     displayStars.levelOne = false;
                // } else if (playerData.VIPpoints >= 3000 && playerData.VIPpoints < 7000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                // } else if (playerData.VIPpoints >= 7000 && playerData.VIPpoints < 10000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                //     displayStars.levelThree = false;
                // } else if (playerData.VIPpoints >= 10000 && playerData.VIPpoints < 40000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                //     displayStars.levelThree = false;
                //     displayStars.levelFour = false;
                // } else if (playerData.VIPpoints >= 40000 && playerData.VIPpoints < 100000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                //     displayStars.levelThree = false;
                //     displayStars.levelFour = false;
                //     displayStars.levelFive = false;
                // } else if (playerData.VIPpoints >= 100000 && playerData.VIPpoints < 200000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                //     displayStars.levelThree = false;
                //     displayStars.levelFour = false;
                //     displayStars.levelFive = false;
                //     displayStars.levelSix = false;
                // } else if (playerData.VIPpoints >= 200000) {
                //     displayStars.levelOne = false;
                //     displayStars.levelTwo = false;
                //     displayStars.levelThree = false;
                //     displayStars.levelFour = false;
                //     displayStars.levelFive = false;
                //     displayStars.levelSix = false;
                //     displayStars.levelSeven = false;
                // }
            }
            if (data.PlayerInfo.hasOwnProperty("Tickets")) {
                playerData.tickets = data.PlayerInfo.Tickets.attr.count;
            }
            this.setState({ playerData: playerData });
            this.setState({ displayStars: displayStars });
        }
        this._lobbyNetwork.send("<GetMyTables/>");
        this._lobbyNetwork.send("<GetMyTournaments/>");
    }

    onGetavtarList(data) {
        let Avatarid = [];
        if (data.hasOwnProperty("Avatars")) {
            if (Array.isArray(data.Avatars.Avatar)) {
                let con = data.Avatars.Avatar.length;
                for (let i = 0; i < con; i++) {
                    let va = data.Avatars.Avatar[i].attr.id;
                    Avatarid.push(va)
                }
            }
        }
        // this.setState({ Dparray: Avatarid })
        var path = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.getAvatarList_Api;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", path, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("siteid", this.config.URL_Environment.sitIds.sitid);
        try {
            if (`${window.location.hostname}'_wSid'` !== null) {
                xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
            } else if (sessionStorage.getItem(`${window.location.hostname}'_sid'`) !== null) {
                xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid);
            }
        } catch (e) { console.log(e) }
        xhr.addEventListener("load", (e) => {
            this.avtarDataHandlerList(e);
        });
        xhr.send();
        // this.onGetavtar(data);
    }

    onGetavtar(data) {
        var path = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.getAvtr_Api;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", path, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("siteid", (fileName.name === "Riverpoker" ? "rp1" : "prode"));
        xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
        xhr.addEventListener("load", (e) => {
            this.avtrDataHandler(e);
        });
        xhr.send();
    }

    avtarDataHandlerList(data) {
        let newavatar = this.state.newavatar;
        let avtarId = this.state.avtarId;
        try {
            const myAvatar = JSON.parse(data.target.response);
            this.setState({ avatarList: myAvatar });
            for (let i = 0; i < 25; i++) {
                newavatar[i] = myAvatar[i].imageData;
                avtarId[i] = myAvatar[i].id;
            }
        } catch (error) { }
        this.props.LobbyHandler("getAvatarsList", this.state.avatarList);
        this.setState({ newavatar: newavatar });
    }
    avtrDataHandler(data) {
        let response = JSON.parse(data.target.response)
        const myAvatr = `data:image/jpeg;base64,${response.imageData}`;
        this.setState({ avtar: myAvatr });
    }
    /*======================== Set Avatar Initially Start==============================*/
    setAvtarMy(data) {
        const avatarList = this.state.avatarList;
        if (Object.keys(avatarList).length) {
            function getImageData(arr, element) {
                let n = arr.length;
                let image = "";
                for (let i = 0; i < n; i++) {
                    let getLastThreeFromList = (arr[i].id).slice(-3);
                    let getLastThreeFromData = (data).slice(-3);
                    if (getLastThreeFromList === getLastThreeFromData) {
                        image = arr[i].imageData;
                    }
                }
                return `data:image/jpeg;base64,${image}`;
            }
            this.setState({ avtar: getImageData(avatarList, data) });
        }
    }
    /*======================== Set Avatar Initially End  ==============================*/
    onServerInfo(data) {
        let serverInfo = this.state.serverInfo;
        if (data.ServerInfo.hasOwnProperty("attr")) {
            serverInfo.players = data.ServerInfo.attr["players-on-tables"];
            serverInfo.tables = data.ServerInfo.attr["active-tables"];
            serverInfo.tourneys = data.ServerInfo.attr["active-tournaments"];
            this.setState({ serverInfo: serverInfo });
        }
    }
    onServerTime(data) {
        let time = new Date(parseInt(data.ServerTime.attr.time));
        this.setState({ time: `${time.getHours()} : ${time.getMinutes()}` });
    }
    onGetBadBeatJackpot(data) {
        this.setState({ bbj: data.BadBeatJackpot.attr.amount });
    }
    changeAmtlabl = (labelValue) => {
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
                    : Math.abs(Number(labelValue));
    };

    onGetTables(data) {
        let tables;
        let data_arry = [];

        if (data.hasOwnProperty("SingleTables")) {
            this.tableType = "SINGLE_TABLE";
            if (data.SingleTables.hasOwnProperty("Table")) {
                tables = data.SingleTables.Table;
                if (!Array.isArray(tables)) {
                    tables = [tables];
                }
                tables.sort((a, b) => {
                    const stakesA = a.attr.stakes.split("/");
                    const stakesB = b.attr.stakes.split("/");
                    const valueA = parseInt(stakesA[1]);
                    const valueB = parseInt(stakesB[1]);
                    return valueB - valueA;
                });
                if (Screen.getDeviceType().name == "Mobile") {
                    for (let i = 0; i < tables.length; i++) {
                        let activePlayers = tables[i].attr["players-seats"].split("/")[0];
                        let activeSeats = Number(tables[i].attr["players-seats"].split("/")[1]);
                        var object = {};
                        // var object1={}
                        var splittedAveragePot = String(this.roundToTwo(tables[i].attr["average-pot"])).split(".")[0];

                        var splittedStacksOne = tables[i].attr.stakes.split("/")[0];
                        var splittedStacksTwo = tables[i].attr.stakes.split("/")[1];
                        let newStacksFirst = this.changeAmtlabl(splittedStacksOne);
                        let newStacksSecond = this.changeAmtlabl(splittedStacksTwo);
                        // object1.StackName = this.props.t("Stakes");
                        // object1.stakes = `${newStacksFirst} / ${newStacksSecond}`;
                        // object.name = tables[i].attr.name ;
                        object.name = (fileName.name !== "Riverpoker" && fileName.name !== "Leader_bet" ? <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {tables[i].attr.bounty === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/KnockoutBounty_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.runTwice === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/rit.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.thirdBlind === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/thirdBlind_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.timeForced === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/TimeForced_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.rabbit === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/rabbit_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.private === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/private.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.Straddle === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/Straddle_icon.png").default} alt="Not Found" />
                            )}
                            <span style={{ marginLeft: '2px' }}>  {tables[i].attr.name} </span>
                        </label> : tables[i].attr.name);
                        object.stakes = `${newStacksFirst} / ${newStacksSecond}`;
                        object.StackName = this.props.t("Stakes");
                        object.Players = this.props.t("Players");
                        object.Average_Pot = this.props.t("Average Pot");
                        if (fileName.name === "Leader_bet" && Screen.getDeviceType().name == "Mobile") {
                            object.seats = <span style={{
                                color: (tables[i].attr["players-seats"].split("/")[0] > 0 && Number(tables[i].attr["players-seats"].split("/")[0]) < Number(tables[i].attr["players-seats"].split("/")[1])) ? '#3EB553' :
                                    (tables[i].attr["players-seats"].split("/")[0] == tables[i].attr["players-seats"].split("/")[1]) ? '#E1253E' : ''
                            }}> {tables[i].attr["players-seats"]} </span>;
                        } else {
                            object.seats = tables[i].attr["players-seats"];
                        }
                        object.pot = Number(splittedAveragePot).toLocaleString("en-US");;
                        object.wait = tables[i].attr.wait;
                        object.pf = tables[i].attr["players-per-2nd-round"];
                        object.hh = tables[i].attr["hands-per-hour"];
                        object.id = tables[i].attr.id;
                        object.type = this.tableType;
                        object.dollar = <img style={{ height: "26px", width: "26px" }} src={require("../../../assets/images/table/dollar.svg").default} alt="Not Found" />;
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                        // stateImg
                        object.img = <span><img src={require("../../../assets/images/gametypeicons/" + ((fileName.name === "Lapoker" ? 'LA_Table' : (fileName.name === "Riverpoker" ? "R_table" : "LB_table"))) + ".png").default} alt="Not Found" />

                            {activeSeats === 2 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSec activeTable' : 'oneSec'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSec activeTable' : 'twoSec'}></small>
                                </>
                            }
                            {activeSeats === 4 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInFour activeTable' : 'oneSecInFour'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInFour activeTable' : 'twoSecInFour'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInFour activeTable' : 'threeSecInFour'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInFour activeTable' : 'fourSecInFour'}></small>
                                </>
                            }
                            {activeSeats === 5 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInFive activeTable' : 'oneSecInFive'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInFive activeTable' : 'twoSecInFive'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInFive activeTable' : 'threeSecInFive'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInFive activeTable' : 'fourSecInFive'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 5 ? 'fiveSecInFive activeTable' : 'fiveSecInFive'}></small>
                                </>
                            }
                            {activeSeats === 6 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInSix activeTable' : 'oneSecInSix'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInSix activeTable' : 'twoSecInSix'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInSix activeTable' : 'threeSecInSix'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInSix activeTable' : 'fourSecInSix'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 5 ? 'fiveSecInSix activeTable' : 'fiveSecInSix'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 6 ? 'sixSecInSix activeTable' : 'sixSecInSix'}></small>
                                </>
                            }
                            {activeSeats === 7 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInSeven activeTable' : 'oneSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInSeven activeTable' : 'twoSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInSeven activeTable' : 'threeSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInSeven activeTable' : 'fourSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 5 ? 'fiveSecInSeven activeTable' : 'fiveSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 6 ? 'sixSecInSeven activeTable' : 'sixSecInSeven'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 7 ? 'sevenSecInSeven activeTable' : 'sevenSecInSeven'}></small>
                                </>
                            }
                            {activeSeats === 8 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInEight activeTable' : 'oneSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInEight activeTable' : 'twoSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInEight activeTable' : 'threeSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInEight activeTable' : 'fourSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 5 ? 'fiveSecInEight activeTable' : 'fiveSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 6 ? 'sixSecInEight activeTable' : 'sixSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 7 ? 'sevenSecInEight activeTable' : 'sevenSecInEight'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 8 ? 'eightSecInEight activeTable' : 'eightSecInEight'}></small>
                                </>
                            }
                            {activeSeats === 10 &&
                                <>
                                    <small className={activePlayers !== 0 && activePlayers >= 1 ? 'oneSecInten activeTable' : 'oneSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 2 ? 'twoSecInten activeTable' : 'twoSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 3 ? 'threeSecInten activeTable' : 'threeSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 4 ? 'fourSecInten activeTable' : 'fourSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 5 ? 'fiveSecInten activeTable' : 'fiveSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 6 ? 'sixSecInten activeTable' : 'sixSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 7 ? 'sevenSecInten activeTable' : 'sevenSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 8 ? 'eightSecInten activeTable' : 'eightSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 9 ? 'nineSecInten activeTable' : 'nineSecInten'}></small>
                                    <small className={activePlayers !== 0 && activePlayers >= 10 ? 'tenSecInten activeTable' : 'tenSecInten'}></small>
                                </>
                            }
                        </span>;
                        object.game = UM.GameName(tables[i].attr.game);
                        object.password = tables[i].attr.password;
                        object.runtwice = tables[i].attr.runTwice;
                        object.jackpot = tables[i].attr.jackpot;
                        // object.StackName1=object1

                        data_arry.push(object);
                    }
                } else if (Screen.getDeviceType().name == "Desktop") {
                    for (let i = 0; i < tables.length; i++) {
                        var object = new Object();
                        // object.name = tables[i].attr.name;
                        object.name = (fileName.name !== "Riverpoker" ? <label style={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between' }}>
                            <span style={{ marginLeft: '2px' }}>  {tables[i].attr.name} </span>
                            {tables[i].attr.bounty === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/KnockoutBounty_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.runTwice === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/rit.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.thirdBlind === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/thirdBlind_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.timeForced === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/TimeForced_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.rabbit === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/rabbit_icon.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.private === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/private.png").default} alt="Not Found" />
                            )}
                            {tables[i].attr.Straddle === "true" && (
                                <img style={{ marginRight: '2px' }} src={require("../../../assets/images/tableTypeIcons/Straddle_icon.png").default} alt="Not Found" />
                            )}

                        </label> : tables[i].attr.name);
                        // object.game = tables[i].attr.game;

                        var splittedAveragePot = String(this.roundToTwo(tables[i].attr["average-pot"])).split(".")[0];
                        var splittedStacksOne = tables[i].attr.stakes.split("/")[0];
                        var splittedStacksTwo = tables[i].attr.stakes.split("/")[1];
                        let newStacksFirst = this.changeAmtlabl(splittedStacksOne);
                        let newStacksSecond = this.changeAmtlabl(splittedStacksTwo);


                        if (fileName.name == "Leader_bet") {
                            object.stakes = tables[i].attr.stakes + " " + "₾";
                            object.seats = <span style={{ color: Number((tables[i].attr["players-seats"]).split('/')[0]) == 0 ? "#FFFFFF" : Number((tables[i].attr["players-seats"]).split('/')[0]) == Number((tables[i].attr["players-seats"]).split('/')[1]) ? "#E1253E" : "#3EB553" }}>{tables[i].attr["players-seats"]}</span>
                        } else if (fileName.name === "Riverpoker") {
                            object.stakes = `${newStacksFirst} / ${newStacksSecond}`;
                            object.seats = tables[i].attr["players-seats"];
                        } else {
                            // object.stakes = tables[i].attr.stakes;
                            object.stakes = `${Number(splittedStacksOne).toLocaleString("en-Us")} / ${Number(splittedStacksTwo).toLocaleString("en-Us")}`;
                            object.seats = tables[i].attr["players-seats"];
                        }

                        // object.pot = String(this.roundToTwo(tables[i].attr["average-pot"]));
                        object.pot = Number(splittedAveragePot).toLocaleString("en-US");;
                        object.wait = tables[i].attr.wait;
                        object.pf = tables[i].attr["players-per-2nd-round"];

                        object.hh = tables[i].attr["hands-per-hour"];
                        object.id = tables[i].attr.id;
                        object.type = this.tableType;
                        object.game = this.getGameType(tables[i].attr.game);

                        object.password = tables[i].attr.password;
                        object.runtwice = tables[i].attr.runTwice;
                        object.jackpot = tables[i].attr.jackpot;

                        data_arry.push(object);
                    }
                }
            } else {
                var object = {};
                object.name = "No Table Matching Your Search Criteria";
                object.game = "No Table Matching Your Search Criteria";
                object.stakes = "";
                object.seats = "";
                object.Pot = "";
                object.wait = "";
                object.pf = "";
                object.hh = "";
                object.id = "";
                object.type = "";

                data_arry.push(object);
            }
        }
        this.setState({ data: data_arry });
    }
    onGetTournaments(data) {
        this.cashierTourneyTables = [];
        if (data.Tournaments.hasOwnProperty("Table")) {
            let tables = data.Tournaments.Table;
            if (!Array.isArray(tables)) {
                tables = [tables];
            }
            this.cashierTourneyTables.push(tables);
            if (tables[0].attr.type === "SCHEDULED_TOURNAMENT") {
                this.updateTournaments(tables);
            }
            if (tables[0].attr.type === "SITANDGO_TOURNAMENT") {
                this.updateSitandGo(tables);
            }
        } else {
            this.updateSitandGo([]);
            this.updateTournaments([]);
            this.cashierTourneyTables = [];
        }
    }
    updateTournaments(tables) {
        let data_arry = [];

        tables.sort((a, b) => {
            const stakesA = a.attr.buyIn;
            const stakesB = b.attr.buyIn;
            return Number(stakesB) - Number(stakesA);
        });
        for (var i = 0; i < tables.length; i++) {
            var object = new Object();
            object.name = tables[i].attr.name;
            // console.log(tables[i])
            // console.log(tables[i].attr.name)
            // object.buyIn = `${this.props.t("Buy In")}   :${tables[i].attr.buyIn}`;
            if (fileName.name == "Leader_bet") {
                // console.log(this.DateUtils.formatDateFromNumber(tables[i].attr.tournamentStart,"DD MMM HH:MM"))
                object.date = this.DateUtils.formatDateFromNumber(tables[i].attr.tournamentStart, "DD MMM HH:MM");
                object.buyIn = (tables[i].attr.buyIn === 'FREEROLL' ? tables[i].attr.buyIn : Number(tables[i].attr.buyIn).toLocaleString("en")) + " " + "₾";
                object.players = <span style={{ color: tables[i].attr.players === "0" ? "#FFFFFF" : "#3EB553" }}>{tables[i].attr.players}</span>;

                if (Screen.getDeviceType().name == "Mobile") {
                    switch (tables[i].attr.status) {
                        case "CANCELED_BEFORE_START":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/CancledIcon.png").default} alt="Not Found" />;
                            break;
                        case "CANCELED_AFTER_START":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/CancledIcon.png").default} alt="Not Found" />;
                            break;
                        case "CANCELLING":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/CancledIcon.png").default} alt="Not Found" />;
                            break;
                        case "COMPLETED":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/TimeOutIcon.png").default} alt="Not Found" />;
                            break;
                        case "SEATING":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                            break;
                        case "REGISTERING":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                            break;
                        case "ANNOUNCED":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/LockedIcon.png").default} alt="Not Found" />;
                            break;
                        case "LATE_REG":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                            break;
                        case "RUNNING":
                            object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/watchIcon.png").default} alt="Not Found" />;
                            break;
                        default:
                            console.log("status  >>  ", tables[i].attr.status)
                            break;
                    }
                }
                // object.seats = <span style={{ color: Number((tables[i].attr["players-seats"]).split('/')[0]) == 0 ? "#FFFFFF" : Number((tables[i].attr["players-seats"]).split('/')[0]) == Number((tables[i].attr["players-seats"]).split('/')[1]) ? "#E1253E" : "#3EB553" }}>{tables[i].attr["players-seats"]}</span>
            } else {

                object.date = new Date(parseInt(tables[i].attr.tournamentStart)).toLocaleDateString();
                object.buyIn = (tables[i].attr.buyIn === 'FREEROLL' ? tables[i].attr.buyIn : Number(tables[i].attr.buyIn).toLocaleString("en"));
                object.players = tables[i].attr.players;
            }
            // object.buyIn = tables[i].attr.buyIn;
            object.id = tables[i].attr.id;
            object.type = tables[i].attr.type;

            object.game = UM.GameName(tables[i].attr.game);
            if (fileName.name == "Leader_bet") {
                if (Screen.getDeviceType().name == "Desktop") {
                    switch (tables[i].attr.status) {
                        case "CANCELED_BEFORE_START":
                            // object.status = this.props.t("Cancelled");
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={cancelbtn} /><span>{this.props.t("Cancelled")}</span></div>;
                            break;
                        case "CANCELED_AFTER_START":
                            // object.status = this.props.t("Cancelled");
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={cancelbtn} /><span>{this.props.t("Cancelled")}</span></div>;
                            break;
                        case "COMPLETED":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={timerbtn} /><span>{this.props.t("Completed")}</span></div>;
                            // object.status = this.props.t("Completed");
                            break;
                        case "SEATING":
                            // object.status = this.props.t("Seating");
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={watchbtn} /><span>{this.props.t("Seating")}</span></div>;
                            break;
                        case "REGISTERING":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={playbtn} /><span>{this.props.t("Register")}</span></div>;
                            let t = tables[i].attr.tournamentStart;
                            let t2 = Math.abs(Number(Date.now()) - Number(t)) / 1000;
                            let sec = Math.trunc(t2 % 60);
                            let min = Math.trunc(t2 / 60);
                            let breakTime = `${min} min ${sec} sec`;

                            object.breakTime = breakTime
                            break;
                        case "ANNOUNCED":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={annoncedbtn} /><span>{this.props.t("Announced")}</span></div>;
                            // object.status = this.props.t("Announced");
                            break;
                        case "LATE_REG":
                            // object.status = this.props.t("Late Registration");
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={playbtn} /><span>{this.props.t("Late Registration")}</span></div>;
                            break;
                        case "RUNNING":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={watchbtn} /><span>{this.props.t("Running")}</span></div>;
                            break;
                        default:
                            object.status = tables[i].attr.status;
                            break;
                    }

                    data_arry.push(object);
                }
                else {
                    switch (tables[i].attr.status) {
                        case "CANCELED_BEFORE_START":
                            object.status = this.props.t("Cancelled");
                            break;
                        case "CANCELED_AFTER_START":
                            object.status = this.props.t("Cancelled");
                            break;
                        case "COMPLETED":
                            object.status = this.props.t("Completed");
                            break;
                        case "SEATING":
                            object.status = this.props.t("Seating");
                            break;
                        case "REGISTERING":
                            object.status = this.props.t("Register");
                            let t = tables[i].attr.tournamentStart;
                            let t2 = Math.abs(Number(Date.now()) - Number(t)) / 1000;
                            let sec = Math.trunc(t2 % 60);
                            let min = Math.trunc(t2 / 60);
                            let breakTime = `${min} min ${sec} sec`;
                            object.breakTime = breakTime
                            break;
                        case "ANNOUNCED":
                            object.status = this.props.t("Announced");
                            break;
                        case "LATE_REG":
                            object.status = this.props.t("Late Registration");
                            break;
                        default:
                            object.status = tables[i].attr.status;
                            break;
                    }
                    data_arry.push(object);
                }

            }
            else {
                switch (tables[i].attr.status) {
                    case "CANCELED_BEFORE_START":
                        object.status = this.props.t("Cancelled");
                        break;
                    case "CANCELED_AFTER_START":
                        object.status = this.props.t("Cancelled");
                        break;
                    case "COMPLETED":
                        object.status = this.props.t("Completed");
                        break;
                    case "SEATING":
                        object.status = this.props.t("Seating");
                        break;
                    case "REGISTERING":
                        object.status = this.props.t("Register");
                        let t = tables[i].attr.tournamentStart;
                        let t2 = Math.abs(Number(Date.now()) - Number(t)) / 1000;
                        let sec = Math.trunc(t2 % 60);
                        let min = Math.trunc(t2 / 60);
                        let breakTime = `${min} min ${sec} sec`;
                        object.breakTime = breakTime
                        break;
                    case "ANNOUNCED":
                        object.status = this.props.t("Announced");
                        break;
                    case "LATE_REG":
                        object.status = this.props.t("Late Registration");
                        break;
                    default:
                        object.status = tables[i].attr.status;
                        break;
                }
                data_arry.push(object);
            }
        }
        this.setState({ dataTourney: data_arry });
    }
    updateSitandGo(tables) {
        let data_arry = [];

        tables.sort((a, b) => {
            const stakesA = a.attr.buyIn;
            const stakesB = b.attr.buyIn;
            return Number(stakesB) - Number(stakesA);
        });
        for (var i = 0; i < tables.length; i++) {
            var object = new Object();

            object.name = tables[i].attr.name;
            // object.buyIn = tables[i].attr.buyIn;
            // object.buyIn = `${this.props.t("Buy In")}   :${tables[i].attr.buyIn}`;
            if (fileName.name == "Leader_bet") {

                object.buyIn = (tables[i].attr.buyIn === 'FREEROLL' ? tables[i].attr.buyIn : Number(tables[i].attr.buyIn).toLocaleString("en")) + " " + "₾";
                object.players = <span style={{ color: Number((tables[i].attr.players).split('/')[0]) == 0 ? "#FFFFFF" : Number((tables[i].attr.players).split('/')[0]) == Number((tables[i].attr.players).split('/')[1]) ? "#E1253E" : "#3EB553" }}>{tables[i].attr.players}</span>

            } else {

                object.buyIn = (tables[i].attr.buyIn === 'FREEROLL' ? tables[i].attr.buyIn : Number(tables[i].attr.buyIn).toLocaleString("en"));
                object.players = tables[i].attr.players;
            }
            object.id = tables[i].attr.id;
            object.type = tables[i].attr.type;
            object.game = UM.GameName(tables[i].attr.game);
            if (Screen.getDeviceType().name == "Mobile") {
                switch (tables[i].attr.status) {
                    case "CANCELED_BEFORE_START":
                        object.status = this.props.t("Cancelled");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/CancledIcon.png").default} alt="Not Found" />;
                        break;
                    case "CANCELED_AFTER_START":
                        object.status = this.props.t("Cancelled");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/CancledIcon.png").default} alt="Not Found" />;
                        break;
                    case "COMPLETED":
                        object.status = this.props.t("Completed");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/TimeOutIcon.png").default} alt="Not Found" />;
                        break;
                    case "SEATING":
                        object.status = this.props.t("Seating");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                        break;
                    case "REGISTERING":
                        object.status = this.props.t("Register");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                        break;
                    case "ANNOUNCED":
                        object.status = this.props.t("Announced");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/LockedIcon.png").default} alt="Not Found" />;
                        break;
                    case "LATE_REG":
                        object.status = this.props.t("Late Registration");
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/readytoPlay_lb.png").default} alt="Not Found" />;
                        break;
                    case "RUNNING":
                        object.status = 'Running';
                        object.stateImg = <img style={{ marginTop: "6px", width: "fit-content" }} src={require("../../../assets/images/lobby/watchIcon.png").default} alt="Not Found" />;
                        break;
                    default:
                        object.status = tables[i].attr.status;
                        break;
                }
            }
            if (fileName.name == "Leader_bet") {
                if (Screen.getDeviceType().name == "Desktop") {
                    switch (tables[i].attr.status) {
                        case "CANCELED_BEFORE_START":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={cancelbtn} /><span>{this.props.t("Cancelled")}</span></div>;
                            break;
                        case "CANCELED_AFTER_START":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={cancelbtn} /><span>{this.props.t("Cancelled")}</span></div>;
                            break;
                        case "COMPLETED":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={timerbtn} /><span>{this.props.t("Completed")}</span></div>;

                            break;
                        case "SEATING":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={watchbtn} /><span>{this.props.t("Seating")}</span></div>;
                            break;
                        case "REGISTERING":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={playbtn} /><span>{this.props.t("Register")}</span></div>;
                            let t = tables[i].attr.tournamentStart;

                            break;
                        case "ANNOUNCED":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={annoncedbtn} /><span>{this.props.t("Announced")}</span></div>;

                            break;
                        case "LATE_REG":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={playbtn} /><span>{this.props.t("Late Registration")}</span></div>;
                            break;
                        case "RUNNING":
                            object.status = <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={watchbtn} /><span>{this.props.t("Running")}</span></div>;
                            break;
                        default:
                            object.status = tables[i].attr.status;
                            break;
                    }

                    data_arry.push(object);
                }
                else {
                    switch (tables[i].attr.status) {
                        case "CANCELED_BEFORE_START":
                            object.status = this.props.t("Cancelled");
                            break;
                        case "CANCELED_AFTER_START":
                            object.status = this.props.t("Cancelled");
                            break;
                        case "COMPLETED":
                            object.status = this.props.t("Completed");
                            break;
                        case "SEATING":
                            object.status = this.props.t("Seating");
                            break;
                        case "REGISTERING":
                            object.status = this.props.t("Register");
                            break;
                        case "ANNOUNCED":
                            object.status = this.props.t("Announced");
                            break;
                        case "LATE_REG":
                            object.status = this.props.t("Late Registration");
                            break;
                        default:
                            object.status = tables[i].attr.status;
                            break;
                    }
                    data_arry.push(object);
                }

            }
            else {
                switch (tables[i].attr.status) {
                    case "CANCELED_BEFORE_START":
                        object.status = this.props.t("Cancelled");
                        break;
                    case "CANCELED_AFTER_START":
                        object.status = this.props.t("Cancelled");
                        break;
                    case "COMPLETED":
                        object.status = this.props.t("Completed");
                        break;
                    case "SEATING":
                        object.status = this.props.t("Seating");
                        break;
                    case "REGISTERING":
                        object.status = this.props.t("Register");

                        break;
                    case "ANNOUNCED":
                        object.status = this.props.t("Announced");
                        break;
                    case "LATE_REG":
                        object.status = this.props.t("Late Registration");
                        break;
                    default:
                        object.status = tables[i].attr.status;
                        break;
                }
                data_arry.push(object);
            }
            // data_arry.push(object);
        }
        this.setState({ dataSitGo: data_arry });
    }
    onGetTableDetails(data) {
        if (data.TableDetails.hasOwnProperty("ScheduledTournament")) {
            this.updateTourneyDetails(data);
        } else if (data.TableDetails.hasOwnProperty("SitAndGoTournament")) {
            this.updateSitandGoDetails(data);
        } else {
            this.updateSingleTableDetails(data);
        }
        if (data.TableDetails.hasOwnProperty("attr")) {
            if (data.TableDetails.attr.hasOwnProperty("tourneyRetry")) {
                let reEntryTable = this.state.reEntryTable;
                reEntryTable.noOfRetries = 0;
                reEntryTable.maxRetryCount = data.TableDetails.attr?.maxRetryCount;
                let tables = this.cashierTourneyTables[0];
                if (data.TableDetails.attr.tourneyRetry === "true") {
                    for (let i = 0; i < tables.length; i++) {
                        if (data.TableDetails.attr?.id === tables[i].attr.id) {
                            // console.log(data)
                            // console.log(tables[i])
                            // console.log(this.isMe)
                            reEntryTable.noOfRetries = tables[i].attr.noOfRetries;
                            if (Number(data.TableDetails.attr?.maxRetryCount) >= Number(tables[i].attr.noOfRetries)) {
                                this.setState({ showRegisterBtn: true });
                            } else {
                                this.setState({ showRegisterBtn: false });
                            }
                        }
                    }

                    reEntryTable.isTourneyRetry = true;
                } else {
                    this.setState({ showRegisterBtn: false });
                    reEntryTable.isTourneyRetry = false;
                }
                this.setState({ reEntryTable: reEntryTable });
                console.log(this.state.reEntryTable)
            } else {
                this.setState({ showRegisterBtn: false });
            }
            console.log(this.state.showRegisterBtn);
        }
    }

    updateTourneyDetails(data) {
        this.setState({ showTourneyDetails: true });
        let tourneyData = this.state.tourneyData;
        let regData = this.state.regData;
        // this._lobbyNetwork.send(`<GetTournamentPlayerRegistered>`)


        if (data.TableDetails.hasOwnProperty("ScheduledTournament")) {
            tourneyData.id = data.TableDetails.attr.id;
            regData.id = data.TableDetails.attr.id;
            regData.balance = this.state.playerData.available;
            tourneyData.type = data.TableDetails.attr.type;
            regData.tableType = data.TableDetails.attr.type;
            tourneyData.typeText = "Scheduled";
            tourneyData.status1 = "Register";

            // this._regType = "BALANCE";
            tourneyData.date = new Date(parseInt(data.TableDetails.ScheduledTournament.Schedule.attr.tournamentStart)).toLocaleString();
            tourneyData.description = data.TableDetails.ScheduledTournament.Description;
            tourneyData.name = data.TableDetails.attr.name;

            tourneyData.buyIn = data.TableDetails.ScheduledTournament.Parameters.attr.buyIn;
            switch (data.TableDetails.ScheduledTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    tourneyData.status = this.props.t("Cancelled");
                    break;
                case "CANCELED_AFTER_START":
                    tourneyData.status = this.props.t("Cancelled");
                    break;
                case "COMPLETED":
                    tourneyData.status = this.props.t("Completed");
                    break;
                case "SEATING":
                    tourneyData.status = this.props.t("Seating");
                    break;
                case "REGISTERING":
                    tourneyData.status = this.props.t("Register");
                    break;
                case "ANNOUNCED":
                    tourneyData.status = this.props.t("Announced");
                    break;
                case "LATE_REG":
                    tourneyData.status = this.props.t("Late Registration");
                    break;
                default:
                    tourneyData.status = data.TableDetails.ScheduledTournament.Schedule.attr.status;
                    break;
            }
            if (data.TableDetails.ScheduledTournament.hasOwnProperty("RegistrationTypes")) {
                if (data.TableDetails.ScheduledTournament.RegistrationTypes.hasOwnProperty("RegistrationType")) {
                    let types = data.TableDetails.ScheduledTournament.RegistrationTypes.RegistrationType;

                    let playerData = this.state.playerData
                    if (!Array.isArray(types)) {
                        types = [types];
                    }
                    let i = 0,
                        cnt = types.length;
                    regData.types = [];
                    for (i; i < cnt; i++) {
                        if (types[i].hasOwnProperty("attr")) {
                            switch (types[i].attr.type) {
                                case "BALANCE":
                                    regData.types.push({
                                        type: "Balance", text: "BALANCE", amount: UM.numberWithCommas(this.state.playerData.available), buyAmount: (data.TableDetails.ScheduledTournament.Parameters.attr.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(data.TableDetails.ScheduledTournament.Parameters.attr.buyIn).toLocaleString("en-US")),
                                        enable: (data.TableDetails.ScheduledTournament.Parameters.attr.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(this.state.playerData.available) > Number(data.TableDetails.ScheduledTournament.Parameters.attr.buyIn))
                                    });
                                    break;
                                case "TOURNAMENT_MONEY":
                                    regData.types.push({
                                        type: "Tournament Money", text: "TOURNAMENT_MONEY", amount: UM.numberWithCommas(this.state.playerData.tMoney), buyAmount: UM.numberWithCommas(this.state.regData.TM),
                                        enable: Number(this.state.playerData.tMoney) > Number(this.state.regData.TM)
                                    });
                                    break;
                                case "TICKET":
                                    regData.types.push({
                                        type: "Tickets", text: "TICKET", amount: UM.numberWithCommas(this.state.playerData.tickets), buyAmount: "Ticket",
                                        enable: Number(this.state.playerData.tickets) > 0
                                    });
                                    break;
                                // case "COMP_POINTS":
                                //     regData.types.push({
                                //         type: "CompPoints", text: "COMP_POINTS", amount: Number(this.playerCompoints).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }), buyAmount: this.state.regData.compPoints,
                                //         enable: Number(this.playerCompoints) > Number(this.state.regData.compPoints)
                                //     });
                                //     break;
                                case "COMP_POINTS":
                                    regData.types.push({
                                        type: "CompPoints", text: "COMP_POINTS", amount: UM.numberWithCommas(this.state.playerData.VIPpoints), buyAmount: UM.numberWithCommas(this.state.regData.compPoints),
                                        enable: Number(this.state.playerData.VIPpoints) > Number(this.state.regData.compPoints)
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
            regData.status = tourneyData.status
            this.setState({ regData: regData });
            tourneyData.players = data.TableDetails.ScheduledTournament.Participants.attr.total;
            tourneyData.prize = data.TableDetails.ScheduledTournament.PrizeInfo.attr.prizePool;
            if (data.TableDetails.ScheduledTournament.hasOwnProperty("attr")) {
                if (data.TableDetails.ScheduledTournament.attr.hasOwnProperty("limit")) {
                    let type = data.TableDetails.ScheduledTournament.attr.game + "_" + data.TableDetails.ScheduledTournament.attr.limit;
                    tourneyData.tableType = UM.GameName(type);
                    regData.tableName = UM.GameName(type);
                }
            }
            if (data.TableDetails.ScheduledTournament.hasOwnProperty("Schedule")) {
                tourneyData.Tournament_start = data.TableDetails.ScheduledTournament.Schedule.attr.tournamentStart;
                tourneyData.Tournament_cancelled = data.TableDetails.ScheduledTournament.Schedule.attr.tournamentFinish;
            }
        }

        this.setState({ tourneyData: tourneyData });
    }
    updateSitandGoDetails(data) {
        this.setState({ showSitnGoDetails: true });
        let sitGoData = this.state.sitGoData;
        let regData = this.state.regData;
        regData.balance = this.state.playerData.available
        regData.tableType = data.TableDetails.attr.type;
        if (data.TableDetails.hasOwnProperty("SitAndGoTournament")) {
            sitGoData.id = data.TableDetails.attr.id;
            regData.id = data.TableDetails.attr.id;
            sitGoData.type = data.TableDetails.attr.type;
            // sitGoData.typeText = "Sit And Go";
            // data.TableDetails.SitAndGoTournament.RegistrationTypes.RegistrationType.attr.type;
            sitGoData.date = new Date(parseInt(data.TableDetails.SitAndGoTournament.Schedule.attr.registrationStart)).toLocaleString();

            sitGoData.description = data.TableDetails.SitAndGoTournament.Description;
            sitGoData.name = data.TableDetails.attr.name;
            sitGoData.buyIn = data.TableDetails.SitAndGoTournament.Parameters.attr.buyIn;
            switch (data.TableDetails.SitAndGoTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    sitGoData.status = this.props.t("Cancelled");
                    break;
                case "CANCELED_AFTER_START":
                    sitGoData.status = this.props.t("Cancelled");
                    break;
                case "COMPLETED":
                    sitGoData.status = this.props.t("Completed");
                    break;
                case "SEATING":
                    sitGoData.status = this.props.t("Seating");
                    break;
                case "REGISTERING":
                    sitGoData.status = this.props.t("Register");
                    break;
                case "ANNOUNCED":
                    sitGoData.status = this.props.t("Announced");
                    break;
                case "LATE_REG":
                    sitGoData.status = this.props.t("Late Registration");
                    break;
                default:
                    sitGoData.status = data.TableDetails.SitAndGoTournament.Schedule.attr.status;
                    break;
            }
            sitGoData.players = data.TableDetails.SitAndGoTournament.Participants.attr.total;
            sitGoData.prize = data.TableDetails.SitAndGoTournament.PrizeInfo.attr.prizePool;
            if (data.TableDetails.SitAndGoTournament.hasOwnProperty("RegistrationTypes")) {
                if (data.TableDetails.SitAndGoTournament.RegistrationTypes.hasOwnProperty("RegistrationType")) {
                    let types = data.TableDetails.SitAndGoTournament.RegistrationTypes.RegistrationType;
                    if (!Array.isArray(types)) {
                        types = [types];
                    }
                    let i = 0,
                        cnt = types.length;
                    regData.types = [];
                    for (i; i < cnt; i++) {
                        if (types[i].hasOwnProperty("attr")) {
                            switch (types[i].attr.type) {
                                case "BALANCE":
                                    regData.types.push({
                                        type: "Balance", text: "BALANCE", amount: Number(this.state.playerData.available).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }), buyAmount: (data.TableDetails.SitAndGoTournament.Parameters.attr.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(data.TableDetails.SitAndGoTournament.Parameters.attr.buyIn).toLocaleString("en-US")),
                                        enable: (data.TableDetails.SitAndGoTournament.Parameters.attr.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(this.state.playerData.available) > Number(data.TableDetails.SitAndGoTournament.Parameters.attr.buyIn))
                                    });
                                    break;
                                case "TOURNAMENT_MONEY":
                                    regData.types.push({
                                        type: "Tournament Money", text: "TOURNAMENT_MONEY", amount: (Number(this.state.playerData.tMoney)).toLocaleString("en-US"), buyAmount: this.state.regData.TM,
                                        enable: Number(this.state.playerData.tMoney) > Number(this.state.regData.TM)
                                    });
                                    break;
                                case "TICKET":
                                    regData.types.push({
                                        type: "Tickets", text: "TICKET", amount: (Number(this.state.playerData.tickets)).toLocaleString("en-US"),
                                        enable: Number(this.state.playerData.tickets) > 0
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                }
            }
            regData.status = sitGoData.status
            if (data.TableDetails.SitAndGoTournament.hasOwnProperty("attr")) {
                if (data.TableDetails.SitAndGoTournament.attr.hasOwnProperty("limit")) {
                    let type = data.TableDetails.SitAndGoTournament.attr.game + "_" + data.TableDetails.SitAndGoTournament.attr.limit;
                    sitGoData.typeText = UM.GameName(type);
                    regData.tableName = UM.GameName(type);
                }
            }
            if (data.TableDetails.SitAndGoTournament.hasOwnProperty("Schedule")) {
                sitGoData.Tournament_start = data.TableDetails.SitAndGoTournament.Schedule.attr.tournamentStart;
                sitGoData.Tournament_cancelled = data.TableDetails.SitAndGoTournament.Schedule.attr.tournamentFinish;

            }
        }
        this.setState({ sitGoData: sitGoData });
    }
    updateSingleTableDetails(data) {
        console.log("SIngleTable", data);

        this.isMe = false;
        let tablesItem = [];
        let previewItem = [];
        let tabel_data = {
            name: "",
            id: "",
            type: "",
            hands_per_hour: "",
            average_pot: "",
            data: [],
            seats: [],
        };
        if (data.hasOwnProperty("TableDetails")) {
            if (data.TableDetails.hasOwnProperty("attr")) {
                if (data.TableDetails.attr.hasOwnProperty("name")) {
                    tabel_data.name = data.TableDetails.attr.name;
                }
                if (data.TableDetails.attr.hasOwnProperty("id")) {
                    tabel_data.id = data.TableDetails.attr.id;
                }
                if (data.TableDetails.attr.hasOwnProperty("type")) {
                    tabel_data.type = data.TableDetails.attr.type;
                }
                if (data.TableDetails.hasOwnProperty("SingleTable")) {
                    if (data.TableDetails.SingleTable.hasOwnProperty("Statistics")) {
                        if (data.TableDetails.SingleTable.Statistics.attr.hasOwnProperty("average-pot")) {
                            tabel_data.average_pot = data.TableDetails.SingleTable.Statistics.attr["average-pot"];
                        }
                        if (data.TableDetails.SingleTable.Statistics.attr.hasOwnProperty("hands-per-hour")) {
                            tabel_data.hands_per_hour = data.TableDetails.SingleTable.Statistics.attr["hands-per-hour"];
                        }
                    }
                }
            }
            if (data.TableDetails.hasOwnProperty("SingleTable")) {
                if (data.TableDetails.SingleTable.hasOwnProperty("Seats")) {
                    // tabel_data.seats = data.TableDetails.SingleTable.Seats;

                    if (data.TableDetails.SingleTable.Seats.hasOwnProperty("Seat")) {
                        let tables = data.TableDetails.SingleTable.Seats.Seat;
                        let ismeA = false;
                        for (let i = 0; i < tables.length; i++) {
                            if (tables[i].hasOwnProperty("PlayerInfo")) {
                                ismeA = tables[i].PlayerInfo.attr.nickname === this.state.userLogged ? true : false;
                            }
                        }
                        for (let i = 0; i < tables.length; i++) {

                            if (tables[i].hasOwnProperty("PlayerInfo")) {
                                // console.log("PlayerInfo",tables[i].PlayerInfo);

                                this.isMe = tables[i].PlayerInfo.attr.nickname === this.state.userLogged ? true : false;
                                let obj = {
                                    player: `${tables[i].PlayerInfo.attr.nickname}`,
                                    /*============================ Avatar on Update Start =====================================*/
                                    avatarId: tables[i].PlayerInfo.attr.avatar ? `${tables[i].PlayerInfo.attr.avatar}` : "",
                                    /*============================ Avatar on Update end =======================================*/
                                    chips: `${tables[i].PlayerInfo.Chips.attr["stack-size"]}`,
                                    taken: 1,
                                };
                                tablesItem.push(obj);
                                previewItem.push(obj);
                            } else {
                                if (ismeA) {
                                    previewItem.push({ player: "Open Seat", chips: "", taken: 0 });
                                } else {
                                    previewItem.push({ player: "Take Seat", chips: "", taken: 0 });
                                }
                            }
                        }
                        tabel_data.data = tablesItem;
                        tabel_data.seats = previewItem;
                    }
                }
            }
            this.setState({ tableData: tabel_data, showTDbtn_seatMe: this.isMe, showTDbtn_joinMe: false });
        }
    }
    getGameType(game) {
        let gameType = "";
        switch (game) {
            case "TEXAS_HOLDEM_LIMIT":
                gameType = "FLHE";
                break;
            case "TEXAS_HOLDEM_NO_LIMIT":
                gameType = "NLHE";
                break;
            case "TEXAS_HOLDEM_POT_LIMIT":
                gameType = "PLHE";
                break;
            case "OMAHA_LIMIT":
                gameType = "FLO";
                break;
            case "OMAHA_NO_LIMIT":
                gameType = "NLO";
                break;
            case "OMAHA_POT_LIMIT":
                gameType = "PLO";
                break;
            case "OMAHA_FIVE_CARDS_LIMIT":
                gameType = "FLO5";
                break;
            case "OMAHA_FIVE_CARDS_NO_LIMIT":
                gameType = "NLO5";
                break;
            case "OMAHA_FIVE_CARDS_POT_LIMIT":
                gameType = "PLO5";
                break;
            case "OMAHA_SIX_CARDS_LIMIT":
                gameType = "FLO6";
                break;
            case "OMAHA_SIX_CARDS_NO_LIMIT":
                gameType = "NLO6";
                break;
            case "OMAHA_SIX_CARDS_POT_LIMIT":
                gameType = "PLO6";
                break;
            case "OMAHA_HIGH_LOW_NO_LIMIT":
                gameType = "NL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_POT_LIMIT":
                gameType = "PL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_LIMIT":
                gameType = "FL Omaha Hi-Lo";
                break;
            case "TEXAS_HOLDEM_LIMIT":
                gameType = "FLHE";
                break;
            default:
                gameType = "Not In List";
                break;
        }
        return gameType;
    }
    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    onGetError(data) {
        alert.lineOne = " ";
        alert.lineTwo = " ";

        if (data.Error === 401) {
            alert.lineOne = "you have logged in from other device";
            alert.lineTwo = "logging out from here..!!!";
            this.setState({ showAlert: true });
            setTimeout(() => {
                this.logOutHandler();
            }, 1500);
        }
        if (data.Error === "ConnectionReplaced") {
            alert.lineOne = "you have active session in other location";
            alert.lineTwo = "logging out from here..!!!";
            this.setState({ alert: alert });
            this.setState({ showAlert: true });
            setTimeout(() => {
                this.logOutHandler();
            }, 3000);
        }
        if (data.Error.hasOwnProperty("attr")) {

            let alert = this.state.alert;
            let regData = this.state.regData;
            switch (data.Error.attr.code) {
                case "011":
                    regData.status = "Un Registered";
                    regData.statusAction = "Register";

                    this.setState({ regAlert: "Invalid Action! Since You Are NOT Registered..Please Register Now" });
                    this.setState({ regData: regData });
                    break;
                case "003":
                    // alert.lineOne = "Please Chose a Table/Torunament to Open";
                    alert.lineOne = "Please choose a table/tournament to open";
                    this.setState({ alert: alert });
                    this.setState({ showAlert: true });
                    break;
                case "008":
                    regData.status = "Registered";
                    regData.statusAction = "Un Register";

                    this.setState({ regAlert: "You Are Already Registered" });
                    this.setState({ regData: regData });
                    break;
                case "040":
                    console.log("hand shake error");
                    alert.lineOne = data.Error.attr.description;
                    window.location.reload();
                    this.setState({ alert: alert });
                    this.setState({ showAlert: true });
                    break;
                case "002":
                    console.log("playersession expired");
                    alert.lineOne = "player session expired";
                    alert.lineTwo = "logging out.......!";

                    this.setState({ alert: alert });
                    this.setState({ showAlert: true });
                    this._lobbyNetwork.close();
                    sessionStorage.clear();
                    window.location.reload();
                    break;
                default:
                    alert.lineOne = data.Error.attr.description;
                    this.setState({ alert: alert });
                    this.setState({ showAlert: true });

                    break;
            }
        }
    }
    onGetNews(data) {
        let newsdata = [...this.state.news];
        if (data.NewsList.hasOwnProperty("News")) {
            let news = data.NewsList.News;
            if (Array.isArray(news)) {
                let i = 0,
                    cnt = news.length;
                for (i; i < cnt; i++) {
                    // let object = new Object();
                    newsdata.push({
                        title: new Date(parseInt(data.NewsList.News[i].attr.time)).toLocaleTimeString(),
                        content: data.NewsList.News[i].ShortText,
                        id: data.NewsList.News[i].attr.id,
                    });
                }
            } else {
            }

            this.setState({ news: newsdata, BgState: { pointerEvents: "none" }, showNewsBox: true });
        }
    }
    onGetDPsettings(data) {
        let dpSettings = this.state.dpSettings;
        if (data.DpSetting.hasOwnProperty("attr")) {
            dpSettings.available = data.DpSetting.attr.protectionsLeft;
            dpSettings.left = data.DpSetting.attr.resetsLeft;
            dpSettings.date = new Date(parseInt(data.DpSetting.attr.renewDate)).toLocaleString();

            this.setState({ dpSettings: dpSettings });
            this.setState({ BgState: { pointerEvents: "none" } });

            this.setState({ showDpSettings: true });
        }
    }
    onGetPlayerLevelInfo(data) {

        console.log(data)
        let levelData = this.state.levelData;

        // if (data.hasOwnProperty("PlayerlevelInfo")) {
        levelData.level = data.PlayerLevelInfo.PlayerLevel.attr.name;
        levelData.collection1 = data.PlayerLevelInfo.CompPointsEarned.attr.month;
        levelData.collection2 = data.PlayerLevelInfo.CompPointsEarned.attr.year;
        if (Array.isArray(data.PlayerLevelInfo.PlayerLevelStructure.PlayerLevel)) {
            let level = data.PlayerLevelInfo.PlayerLevelStructure.PlayerLevel;
            let i = 0,
                cnt = level.length;
            levelData.data = [];
            for (i; i < cnt; i++) {
                levelData.data.push({
                    level: this.props.t(data.PlayerLevelInfo.PlayerLevelStructure.PlayerLevel[i].attr.name),
                    earn: data.PlayerLevelInfo.PlayerLevelStructure.PlayerLevel[i].Obtain.attr.value,
                    date: new Date(parseInt(data.PlayerLevelInfo.PlayerLevelStructure.PlayerLevel[i].Obtain.attr.date)).toLocaleDateString(),
                });
            }

        }
        this.setState({ levelData: levelData });
        if (!this.state.profileshow) {
            this.setState({ BgState: { pointerEvents: "none" } });
        }
        // if (!this.state.showCashier) {
        this.setState({ showLevelInfo: true });
        // }
    }
    changeAmtlabl = (labelValue) => {
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
                    : Math.abs(Number(labelValue));
    };

    onGetMyTable(data) {
        let myTables = this.state.myTables;
        if (data.MyTables.hasOwnProperty("Table")) {
            let tables = data.MyTables.Table;
            if (!Array.isArray(tables)) {
                tables = [tables];
            }

            let i = 0,
                cnt = tables.length;
            myTables = [];
            for (i; i < cnt; i++) {
                let splitStackone = tables[i].attr.stakes.split("/")[0];
                let splitStacktwo = tables[i].attr.stakes.split("/")[1]
                myTables.push({
                    name: tables[i].attr.name,
                    game: UM.GameName(tables[i].attr.game),
                    // stakes: `${Number(splitStackone).toLocaleString("en")}/${Number(splitStacktwo).toLocaleString("en")}`,
                    stakes: `${this.changeAmtlabl(splitStackone)}/${this.changeAmtlabl(splitStacktwo)}`,
                    seats: tables[i].attr["players-seats"],
                    wait: tables[i].attr.wait,
                    pf: tables[i].attr["players-per-2nd-round"],
                    id: tables[i].attr.id,
                    type: tables[i].attr.type,
                });
            }

            this.setState({ myTables: myTables });
            // this.setState({ BgState: { opacity: 0.2, pointerEvents: "none" } });

            // this.setState({ showMyTables: true });
            this.props.LobbyHandler("showFooterToggle", myTables);
        } else {
            myTables = [];
            if (this.state.myTournaments.length === 0) {
                // console.log("tourney tables are not found");
                setTimeout(() => {
                    if (this.state.myTournaments.length !== 0 || this.state.myTables.length !== 0) {
                        // console.log("there is not cash tables and tourney tables");
                        this.setState({ showActiveTables: localStorage.getItem('showPop') === "true" ? true : false });
                    } else {
                        localStorage.removeItem('showPop');
                        this.setState({ showActiveTables: false });
                    }
                }, 1000);
                this.props.LobbyHandler("showFooterToggle", []);
            }
            // myTables.push({ name: this.props.t("No Tables") });
            // this.setState({ myTables: myTables });
            // this.setState({ BgState: { opacity: 0.2, pointerEvents: "none" } });
        }
    }
    onGetMyTournaments(data) {
        let myTournaments = this.state.myTournaments;
        let myTournamentsids = this.state.myTournamentsids;
        if (data.MyTournaments.hasOwnProperty("Table")) {
            let tables = data.MyTournaments.Table;
            if (!Array.isArray(tables)) {
                tables = [tables];
            }

            let i = 0,
                cnt = tables.length;
            myTournaments = [];
            myTournamentsids = [];
            for (i; i < cnt; i++) {
                myTournaments.push({
                    name: tables[i].attr.name,
                    game: UM.GameName(tables[i].attr.game),
                    status: tables[i].attr.status,
                    seats: tables[i].attr.players,
                    id: tables[i].attr.id,
                    type: tables[i].attr.type,
                });
                myTournamentsids.push(tables[i].attr.id)
            }

            this.setState({ myTournaments: myTournaments, myTournamentsids: myTournamentsids });
            // this.setState({ BgState: { opacity: 0.2, pointerEvents: "none" } });
            this.props.LobbyHandler("showFooterToggle", myTournaments);
        } else {
            myTournamentsids = [];
            myTournaments = [];
            if (this.state.myTables.length === 0) {
                // console.log("cash tables are not found");
                setTimeout(() => {
                    if (this.state.myTournaments.length !== 0 || this.state.myTables.length !== 0) {
                        // console.log("there is not cash tables and tourney tables");
                        this.setState({ showActiveTables: localStorage.getItem('showPop') === "true" ? true : false });
                    } else {
                        localStorage.removeItem('showPop');
                        this.setState({ showActiveTables: false });
                    }
                }, 1000);
                this.props.LobbyHandler("showFooterToggle", []);
            }
            this.setState({ myTournamentsids: myTournamentsids });
        }
        if (!this.state.showActiveTables) {

            // this.setState({ showMyTournaments: true });
        }
    }
    onGetPlayerSearchResult(data) {
        console.log("FIND player", data);
        let playerTables = this.state.findPlayerData;
        if (data.PlayerSearchResult.hasOwnProperty("Result")) {
            let tables = data.PlayerSearchResult.Result;
            if (!Array.isArray(tables)) {
                tables = [tables];
            }

            let i = 0,
                cnt = tables.length;
            playerTables = [];
            for (i; i < cnt; i++) {
                if (tables[i].Table.attr.type === "SITANDGO_TOURNAMENT" || tables[i].Table.attr.type === "SCHEDULED_TOURNAMENT") {
                    playerTables.push({
                        name: tables[i].Table.attr.name,
                        game: UM.GameName(tables[i].Table.attr.game),
                        stakes: "NA",
                        seats: tables[i].Table.attr.players,
                        wait: "NA",
                        pf: "NA",
                        id: tables[i].Table.attr.id,
                        type: tables[i].Table.attr.type,
                    });
                }
                else if (tables[i].Table.attr.type === "SINGLE_TABLE") {
                    playerTables.push({
                        name: tables[i].Table.attr.name,
                        game: UM.GameName(tables[i].Table.attr.game),
                        stakes: tables[i].Table.attr.stakes,
                        seats: tables[i].Table.attr["players-seats"],
                        wait: tables[i].Table.attr.wait,
                        pf: tables[i].Table.attr["players-per-2nd-round"],
                        id: tables[i].Table.attr.id,
                        type: tables[i].Table.attr.type,
                    });
                }
            }
            console.log("Play table", playerTables);
            this.setState({ findPlayerData: playerTables });
            this.setState({ BgState: { pointerEvents: "none" } });

            this.setState({ showFindPlayer: true });
        } else {
            // playerTables.push({name:"No Tables To Show"});
            this.setState({ findPlayerData: [{ name: "Player Not Found" }] });
            this.setState({ BgState: { pointerEvents: "none" } });
            this.setState({ showFindPlayer: true });
        }
    }
    onTournamentPlayerRegistered(data) {
        let regData = this.state.regData;
        let tourneyData = this.state.tourneyData;

        regData.status = "Registered";
        regData.statusAction = "Un Register";
        tourneyData.status1 = "Un Register";
        this.setState({ regAlert: "You Are Succesfully registered👍" });
        this.setState({ regData: regData, tourneyData: tourneyData });
    }
    onTournamentPlayerUnregistered(data) {
        this.setState({ UnRegisterpopup: true })
        let regData = this.state.regData;
        let tourneyData = this.state.tourneyData;
        regData.status = "Un Registered";
        regData.statusAction = "Register";
        tourneyData.status1 = "Register";
        this.setState({ regAlert: "You Are Succesfully Unregistered", tourneyData: tourneyData });
    }
    onGetBuddies(data) {
        let myBuddies = this.state.myBuddies;
        if (data.Buddies.hasOwnProperty("PlayerInfo")) {
            let players = data.Buddies.PlayerInfo;
            if (!Array.isArray(players)) {
                players = [players];
            }
            let i = 0,
                cnt = players.length;
            for (i; i < cnt; i++) {
                myBuddies.push({ id: players[i].Location.attr.countryId, name: players[i].attr.nickname });
            }
            this.setState({ myBuddies: myBuddies });
        } else {
            myBuddies = ["no buddies to show"];
            this.setState({ myBuddies: myBuddies });
        }
    }
    onBuddyRemoved(data) {
        if (data.BuddyRemoved.hasOwnProperty("PlayerInfo")) {
            this._lobbyNetwork.send(`<GetBuddies/>`);
        }
    }
    changeGrid(game) {
        console.log("====> from change grid", game);
        if (game === this.props.t('TOURNEYS')) {
            this.setState({ showMainGridThree: false });
            this.setState({ showMainGridOne: false });
            this.setState({ showMainGridTwo: true });
            this.setState({ sitGoData: { name: "", date: "", buyIn: "", type: "", typeText: "", status: "", players: "", prize: "", description: "", id: "", Tournament_start: "", Tournament_cancelled: "", status1: "Register" } })
            this.setState({ tableData: { name: "No Table Selected", id: "", type: "", hands_per_hour: "", average_pot: "", data: [], seats: [], } })

        } else if (game === this.props.t('SIT & GO')) {
            this.setState({ showMainGridTwo: false });
            this.setState({ showMainGridOne: false });
            this.setState({ showMainGridThree: true });
            this.setState({ tourneyData: { name: "", date: "", buyIn: "", type: "", typeText: "", status: "", players: "", prize: "", description: "", id: "", Tournament_start: "", Tournament_cancelled: "", status1: "Register" } })
            this.setState({ tableData: { name: "No Table Selected", id: "", type: "", hands_per_hour: "", average_pot: "", data: [], seats: [], } })
        } else {
            this.setState({ showMainGridTwo: false });
            this.setState({ showMainGridThree: false });
            this.setState({ showMainGridOne: true });

            this.setState({ tourneyData: { name: "", date: "", buyIn: "", type: "", typeText: "", status: "", players: "", prize: "", description: "", id: "", Tournament_start: "", Tournament_cancelled: "", status1: "Register" } })
            this.setState({ sitGoData: { name: "", date: "", buyIn: "", type: "", typeText: "", status: "", players: "", prize: "", description: "", id: "", Tournament_start: "", Tournament_cancelled: "", status1: "Register" } })
        }
    }
    changeLobbyButtonState(group) {
        switch (group) {
            case "TDpageJoinTable":
                this.setState({ showTDbtn_joinMe: true, showTDbtn_seatMe: true });
                this.isSeatMe = false;
                break;
            case "TDpageSeatMe":
                this.setState({ showTDbtn_joinMe: true, showTDbtn_seatMe: true });
                this.isSeatMe = true;
                break;
            case "mainGridDblClick":
                this.isSeatMe = false;
                setTimeout(() => {
                    this.setState({ showTDbtn_joinMe: true, showTDbtn_seatMe: true });
                }, 1000);
            default:
                break;
        }
    }
    setPopUpActionsOpen(action) {
        this.setState({ BgState: { pointerEvents: "none" } });

        switch (action) {
            case "REG":

                this.setState({ regAlert: "", showRegistration: true });
                break;
            case "INFO":
                this.setState({ showInfo: false });
                break;
            default:
                break;
        }
    }
    setPopUpActionsClose(action) {
        this.setState({ BgState: { opacity: 1 } });

        switch (action) {
            case "REG":
                this.setState({ showRegistration: false });
                break;
            case "hideAlert":
                this.setState({ showAlert: false });
                break;
            case "hideNews":
                this.setState({ showNewsBox: false });
                break;
            case "hideDp":
                this.setState({ showDpSettings: false });
                break;
            case "hidePLI":
                this.setState({ showLevelInfo: false });
                break;
            case "hideMyTables":
                this.setState({ showMyTables: false });
                break;
            case "hideMyTourneys":
                this.setState({ showMyTournaments: false });
                break;
            case "hideFindPlayer":
                this.setState({ showFindPlayer: false });
                break;
            case "hideThemes":
                this.setState({ showOptions: false });
                break;
            case "hideAvtar":
                this.setState({ showAvtar: false });
                break;
            case "Cashier":
                Emitter.emit("ShowCashTable")
                this.setState({ showCashier: false });
                break;
            case "hideBuddies":
                this.setState({ showMyBuddies: false });
                break;
            case "hideHistory":
                this.state.historyData = [];
                this.state.historyDataForReplay = [];
                this.setState({ showpokerHistory: false });
                break;
            case "hideTourneyDet":
                this.setState({ showTourneyDetails: false });
                break;
            case "hideSitnGoDet":
                this.setState({ showSitnGoDetails: false });
                break;
            case "hideMyActiveTourCashTables":
                localStorage.removeItem('showPop');
                this.setState({ showActiveTables: localStorage.getItem('showPop') === "true" ? true : false });
                break;
            case "Unregisterpopup":
                this.setState({ UnRegisterpopup: false })
                break;
            case "hideMyAnimation":
                this.setState({ showMyAnimation: false })
                break;
            case "getPlayerInfo":
                this._lobbyNetwork.send("<GetPlayerInfo/>");
                break;
            case "logoutPlayer":
                this.logOutHandlerOne();
                break;
            default:
                break;
        }
    }
    lobbyMenuHandler(action) {
        switch (action) {
            case "News":
                this._lobbyNetwork.send("<GetNewsList/>");
                break;
            case "DP":
                this._lobbyNetwork.send("<GetDpSetting/>");
                break;
            case "Cashier":
                //   this._lobbyNetwork.send("<GetDpSetting/>");
                // alert("action not defined");
                // this.setState({ showCashier: true });
                // this.setState({ BgState: { opacity: 0.2, pointerEvents: "none" } });
                // let ws = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid;
                if (sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== null) {
                    var ws = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid;
                    var sid = (ws).split(".")[1]
                } else if (sessionStorage.getItem(`${window.location.hostname}'_sid'`) !== null) {
                    var sid = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid;
                }
                console.log(ws)
                window.open(`https://${window.location.host}/client-redirect?LANG=en&sid=${sid}&to=deposit`)
                // window.open(`https://${window.location.host}/deposit?wsession=${ws}`);

                break;
            case "Options":
                //   this._lobbyNetwork.send("<GetDpSetting/>");
                // alert("action not defined");
                this.setState({ showOptions: true });
                this.setState({ BgState: { pointerEvents: "none" } });

                break;
            case "MyTables":
                this.state.myTables = [];
                this._lobbyNetwork.send("<GetMyTables/>");
                this.setState({ showMyTables: true });
                this.setState({ BgState: { pointerEvents: "none" } });
                // alert("action not defined");
                break;
            case "MyTourney":
                this.state.myTournaments = [];
                this._lobbyNetwork.send("<GetMyTournaments/>");
                this.setState({ BgState: { pointerEvents: "none" } });
                this.setState({ showMyTournaments: true });
                break;
            case "MyBuddies":
                this._lobbyNetwork.send("<GetBuddies/>");
                this.setState({ BgState: { pointerEvents: "none" } });
                this.setState({ showMyBuddies: true });
                break;
            case "FindPlayer":
                this.setState({ findPlayerData: [] });
                this.setState({ showFindPlayer: true });
                this.setState({ BgState: { pointerEvents: "none" } });
                break;
            case "PLI":
                this._lobbyNetwork.send(`<GetPlayerLevelInfo/>`);
                this.setState({ BgState: { pointerEvents: "none" } });
                // alert("action not defined");
                // this.setState({showLevelInfo:true});
                break;
            case "Avtar":
                //   this._lobbyNetwork.send("<GetDpSetting/>");
                // alert("action not defined");
                this.setState({ showAvtar: true });
                this.setState({ BgState: { pointerEvents: "none" } });
                break;
            case "History":
                this.setState({ showpokerHistory: true });
                this.setState({ BgState: { pointerEvents: "none" } });
                break;
            case "MyAnimation":
                this.setState({ showMyAnimation: true });
                break;
            case "showCashierPopUp":
                // this._lobbyNetwork.send("<GetPlayerLevelInfo/>");
                this.setState({ showCashier: true });
                break;
            default:
                break;
        }
    }
    setThemes(group, data) {
        console.log("group", group)
        switch (group) {
            case "Table":
                this.props.setThemesL("Table", data);
                break;
            case "Cards":
                break;
            default:
                console.log("there is no Table and cards themes from option component")
                break;
        }
        console.log("data", data)
    }
    setAvtar(data) {
        // console.log(this.state.Dparray[data])
        this._lobbyNetwork.send(`<SetAvatar id="2-5e3"/>`);
        // this._lobbyNetwork.send(`<GetAvatars/>`)
        var xhr = new XMLHttpRequest();
        xhr.open("GET", this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.getAvtr_Api, true);
        xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.setRequestHeader("siteid", this.config.URL_Environment.sitIds.sitid);
        if (sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== null) {
            xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
        } else if (sessionStorage.getItem(`${window.location.hostname}'_sid'`) !== null) {
            xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid);
        }
        xhr.addEventListener("load", (e) => {
            this.avtrDataHandler(e);
        });
        xhr.send();
    }
    logOutHandler() {
        let alert = this.state.alert;
        alert.lineOne = "Are you sure want to";
        alert.lineTwo = "log out...!";
        this.setState({ alert: alert, showAlert: true });
    }

    logOutHandlerOne() {
        let alert = this.state.alert;
        alert.lineOne = "";
        alert.lineTwo = "";
        this.setState({ alert: alert, showAlert: false });

        var session = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`));

        if (session && session.wSid) {
            fetch("/api/player/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    siteid: this.config.URL_Environment.sitIds.sitid,
                    wsession: session.wSid,
                },
                body: JSON.stringify({}),
            })
                .then((resp) => resp.json())
                .then((resp) => {
                    console.log("logout resp: ", resp);
                    sessionStorage.clear();
                })
                .catch((err) => {
                    console.log("logout err: ", err);
                    sessionStorage.clear();
                });
        } else {
        }

        this.props.data.logOutHandler();
    }



    readXMl(event) {
        let data1 = event.target.response;
        let data = JSON.parse(data1);
        console.log("logout data");
        if (data) {
            if (data.success === true) {
                console.log("successfully logged out");
                sessionStorage.clear();
                // window.location.reload();
            } else {
                console.log("could not log out");
            }
        }
    }

    handleErrorEvent(e) {
        console.log("error fired");
        console.log(e);
        sessionStorage.clear();
        window.location.reload();
    }
    getHandHistory(startDate, endDate) {
        this.setState({ gameHistoryErrorMsg: "" });
        this.state.historyData = [];
        this.state.historyDataForReplay = [];
        if (startDate !== null && endDate !== null) {
            var body = {
                currency: (fileName.name === "Riverpoker" ? "CHP" : "ARS"),
                startDate: startDate,
                endDate: endDate,
                types: ["POKER_HOLDEMNOLIMIT_CHP,HOLDEM,NOLIMIT", "POKER_HOLDEMLIMIT_CHP,HOLDEM,LIMIT", "POKER_OMAHANOLIMIT_CHP,OMAHA,NOLIMIT", "POKER_OMAHALIMIT_CHP,OMAHA,LIMIT"],
                limit: "100",
                index: "0",
            }; //cashout
            // var body = {
            //     currency: (fileName.name === "Riverpoker" ? "CHP" : "ARS"),
            //     startDate: startDate,
            //     endDate: endDate,
            //     "types": [
            //         "POKER_HOLDEMNOLIMIT_CHP,HOLDEM,NOLIMIT",
            //         "POKER_HOLDEMLIMIT_CHP,HOLDEM,LIMIT",
            //         "POKER_OMAHANOLIMIT_CHP,OMAHA,NOLIMIT",
            //         "POKER_OMAHALIMIT_CHP,OMAHA,LIMIT",
            //         "POKER_OMAHAPOTLIMIT_CHP,OMAHA,LIMIT",
            //         "POKER_OMAHAPOTLIMIT_CHP,OMAHA,POTLIMIT",
            //         "POKER_OMAHAFIVECARDSNOLIMIT_CHP,OMAHA,NOLIMIT",
            //         "POKER_OMAHAFIVECARDSPOTLIMIT_CHP,OMAHA,POTLIMIT"
            //     ],
            //     "limit": 100,
            //     "index": 0
            // }; //cashout

            var pathW = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.handHistory_Api;

            var xhr = new XMLHttpRequest();
            xhr.open("POST", pathW, true);
            xhr.setRequestHeader("Content-Type", "application/json");
            xhr.setRequestHeader("siteid", this.config.URL_Environment.sitIds.sitid);
            if (sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== null) {
                xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
            } else if (sessionStorage.getItem(`${window.location.hostname}'_sid'`) !== null) {
                xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid);
            }
            xhr.addEventListener("load", (e) => {
                this.historydata(e);
            });

            if (body) {
                console.log("sending with body");
                xhr.send(JSON.stringify(body));
            } else {
                console.log("sending without body");
                xhr.send();
            }
        } else {
            // alert("please enter valid dates");
            this.setState({ gameHistoryErrorMsg: "please enter valid dates" });
        }
    }
    historydata(data) {
        console.log("the history response is");
        let tableDataH = JSON.parse(data.target.response);

        let hData = this.state.historyData;
        let hrData = this.state.historyDataForReplay;

        if (tableDataH !== null) {
            console.log(tableDataH)
            // if (tableDataH.success) {
            //     let tData = tableDataH.playSession;
            //     let i = 0,
            //         cnt = tData.length;
            //     hrData = [];
            //     for (i; i < cnt; i++) {
            //         hrData.push({
            //             startDate: tData[i].startDate,
            //             finishDate: tData[i].finishDate,
            //             tableName: tData[i].tableName,
            //             roundsCount: tData[i].roundsCount,
            //             payouts: tData[i].payouts,
            //             compPoints: tData[i].compPoints,
            //             bonusBuyIn: tData[i].bonusBuyIn,
            //             bets: tData[i].bets,
            //             buyIn: tData[i].buyIn,
            //             playSessionId: tData[i].playSessionId
            //         });
            //     }
            //     this.setState({ historyDataForReplay: hrData });
            // } else {
            //     this.setState({ gameHistoryErrorMsg: "No records found" });
            //     setTimeout(() => {
            //         this.setState({ gameHistoryErrorMsg: "" });
            //     }, 8000);
            // }
            let tables = tableDataH.values;
            if (tables !== undefined) {
                let i = 0,
                    cnt = tables.length;
                hData = [];
                for (i; i < cnt; i++) {
                    hData.push({
                        time: tables[i].tableInfo.startDate,
                        tableName: tables[i].tableInfo.name,
                        name: tables[i].tableInfo.name,
                        hands: tables[i].sessionInfo.roundsCount,
                        // result: tables[i].tableInfo.casinoRevenue 
                        result: UM.numberWithCommas(tables[i].tableInfo.casinoRevenue)
                    });
                }

                this.setState({ historyData: hData });
                // this.setState({ historyDataForReplay: hrData });
            } else {
                // alert("no_records_found");
                this.setState({ gameHistoryErrorMsg: "No records found" });
                setTimeout(() => {
                    this.setState({ gameHistoryErrorMsg: "" });
                }, 8000);
            }
        } else {
            hData.push({ time: "No Tables To Show" });
            this.setState({ historyData: hData });
            this.setState({ historyDataForReplay: hrData });
        }
        this.setState({ subHistorydata: tableDataH });
    }
    openMenu() {
        if (fileName.name === "Leader_bet") {
            var menuSide = document.getElementById("menuSideOne");
        } else {
            var menuSide = document.getElementById("menuSide");
        }
        menuSide = menuSide.classList.add("active");
        var menuCover = document.getElementById("menuCover");
        menuCover = menuCover.classList.add("active");
    }
    closeMenu() {
        if (fileName.name === "Leader_bet") {
            var menuSide = document.getElementById("menuSideOne");
        } else {
            var menuSide = document.getElementById("menuSide");
        }
        menuSide = menuSide.classList.remove("active");
        var menuCover = document.getElementById("menuCover");
        menuCover = menuCover.classList.remove("active");
    }

    onOpenTable(data) {
        console.log("====open table: ", data);
        this.props.LobbyHandler("openCashTable", { ...data, isSeatMe: this.isSeatMe });
        this._lobbyNetwork.send("<GetMyTables/>");
    }
    onOpenTournamentLobby(data) {
        console.log("open t lobby: ", data);
        this.props.LobbyHandler("openTournamentLobby", data);
        this._lobbyNetwork.send("<GetMyTournaments/>");
    }
    handleshowCheckOne(data) {
        this.setState({ showCheckOne: data })

    }
    FilterCheck(data) {
        this.FilterCheck(data)
    }
    ProfilePop(data) {
        this.setState({ showLevelInfo: false })
        this.setState({ profileshow: data, showProfile: true })
        this.setAvtar()
    }

    render() {
        return (
            <div className="fd ">
                <div className="fd" style={{ display: `${this.state.showTopmost ? "block" : "none"}` }}>
                    <div className="row" style={this.state.BgState}>
                        <div className="fd">
                            {(fileName.name == "Leader_bet" || fileName.name == "Riverpoker" ? Screen.getDeviceType().name == "Mobile" ? true : false : true) && <LobbyHeader ProfilePop={this.ProfilePop.bind(this)} activeTables={this.state.myTables.concat(this.state.myTournaments)} network={this._lobbyNetwork} openMenu={this.openMenu.bind(this)} avtar={this.state.avtar} user={this.state.userLogged} balance={this.state.playerData} stars={this.state.displayStars} setAction={this.lobbyMenuHandler.bind(this)} datas={this.state.serverInfo} logOutHandler={this.logOutHandler.bind(this)} ></LobbyHeader>}
                            {(fileName.name !== "Riverpoker") &&
                                <LobbyLinks></LobbyLinks>
                            }
                            {this.lobbyNetworkReady && <FilterTabs handleshowCheckOne={this.handleshowCheckOne.bind(this)} action={this.changeGrid.bind(this)} network={this._lobbyNetwork} updateRequest={this.updateGetTableRequest.bind(this)} data={this.state.bbj} datas={this.state.serverInfo}
                                openMenu={this.openMenu.bind(this)} avtar={this.state.avtar} user={this.state.userLogged} balance={this.state.playerData} stars={this.state.displayStars} setAction={this.lobbyMenuHandler.bind(this)} logOutHandler={this.logOutHandler.bind(this)}
                                FilterCheck={this.FilterCheck}
                            ></FilterTabs>}
                        </div>


                        <div className="col-md-8 bg_new_1 m_b_10" style={{ paddingTop: (Screen.getDeviceType().name == "Mobile" && fileName.name === "Leader_bet" ? "" : "10px"), height: Screen.getDeviceType().name == "Mobile" ? fileName.name === "Leader_bet" ? "100%" : "65vh" : "75vh" }}>
                            {/* <div className="col-md-12 col-lg-8 bg_new_1 m_b_10" style={{ paddingTop: (Screen.getDeviceType().name == "Mobile" && fileName.name === "Leader_bet" ? "" : "10px"), height: Screen.getDeviceType().name == "Mobile" ? fileName.name === "Leader_bet" ? "100%" : "65vh" : !(window.innerWidth > 769 && window.innerWidth < 992) ? "100%" : "100%", margin: Screen.getDeviceType().name == "Mobile" ? fileName.name === "Leader_bet" ? "100%" : "65vh" : !(window.innerWidth > 769 && window.innerWidth < 992) ? "0px 0px -15px 0px" : "" }}> */}
                            {this.state.showMainGridOne && <MainGrid data={this.state.data} mytables={this.state.myTables} network={this._lobbyNetwork} setAction={this.changeLobbyButtonState.bind(this)}></MainGrid>}
                            {this.state.showMainGridTwo && <MainGridTourney data={this.state.dataTourney} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)} ></MainGridTourney>}
                            {this.state.showMainGridThree && <MainGridSitGo data={this.state.dataSitGo} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></MainGridSitGo>}
                        </div>
                        {/* {Screen.getDeviceType().name === "Desktop" && (

                            <img className="col-md-4 bdrgold"  src={rightbanner_png} />



                        )} */}
                        <div className="col-md-4 bg_new_1" style={{ border: window.innerWidth > 810 ? "1px solid" : '', marginTop: window.innerWidth > 810 ? "5px" : "" }}>
                            {/* {this.state.showTourneyDetails && <TourneyDetails data={this.state.tourneyData} network={this._lobbyNetwork} open={this.setPopUpActionsOpen.bind(this)} setAction={this.setPopUpActionsClose.bind(this)}></TourneyDetails>} */}
                            <LobbyMenu closeMenu={this.closeMenu.bind(this)} action={this.lobbyMenuHandler.bind(this)} network={this._lobbyNetwork} logOutHandler={this.logOutHandler.bind(this)}></LobbyMenu>
                            {/* {!(window.innerWidth > 769 && window.innerWidth < 992) && */}
                            {((Screen.getDeviceType().name == "Desktop" && !(window.innerWidth > 769 && window.innerWidth < 992)) && this.state.showMainGridOne) && <TableDetails data={this.state.tableData} avatarList={this.state.avatarList} network={this._lobbyNetwork} btn={this.state.showTDbtn_joinMe} btn2={this.state.showTDbtn_seatMe} setAction={this.changeLobbyButtonState.bind(this)}></TableDetails>}
                            {((Screen.getDeviceType().name == "Desktop" && !(window.innerWidth > 769 && window.innerWidth < 992)) && this.state.showMainGridTwo) && <TourneyDetailspage data={this.state.tourneyData} reEntryTable={this.state.reEntryTable} showRegisterBtn={this.state.showRegisterBtn} network={this._lobbyNetwork} open={this.setPopUpActionsOpen.bind(this)} setAction={this.setPopUpActionsClose.bind(this)} myTournamentsids={this.state.myTournamentsids} ></TourneyDetailspage>}
                            {((Screen.getDeviceType().name == "Desktop" && !(window.innerWidth > 769 && window.innerWidth < 992)) && this.state.showMainGridThree) && <SitGoDetails data={this.state.sitGoData} network={this._lobbyNetwork} open={this.setPopUpActionsOpen.bind(this)} setAction={this.setPopUpActionsClose.bind(this)} myTournamentsids={this.state.myTournamentsids}></SitGoDetails>}
                            {/* } */}
                        </div>

                        <div className="fd" >
                            {/* <div className="col-md-8"> */}
                            {(window.innerWidth > 992 && fileName.name === "Lapoker") ?
                                <video autoPlay loop className="col-md-8" style={{ position: "absolute", bottom: "0", padding: "0" }}>
                                    <source src={bottom_mp4} type="video/mp4" />
                                </video>
                                : fileName.name === "Leader_bet" ?
                                    <div className="col-md-8" style={{ position: "absolute", bottom: "0", padding: "0", height: "50px", background: "#25272D", }}></div>
                                    : window.innerWidth > 992 &&
                                    <div className="bottom-banner">
                                        {/* <video autoPlay loop className="col-md-8" style={{ position: "absolute", bottom: "0", padding: "0" }}>
                                            <source src={river_bottom_banner} type="video/mp4" />
                                        </video> */}



                                        <div className="col-md-8" style={{ position: "absolute", bottom: "0", padding: "0", }}>

                                            <svg className="animation" viewBox="0 0 1320 300" >
                                                <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                                                    TEXAS HOLDEM TOURNAMENT
                                                </text>
                                            </svg>


                                            {/* <div class="wrapper one">
                                                <div class="drop-main">
                                                    <div class="d">T</div>
                                                    <div class="r">E</div>
                                                    <div class="o">X</div>
                                                    <div class="p">A</div>
                                                    <div class="s">S</div>
                                                </div>
                                            </div> */}


                                            <img className="fd" src={river_bottom_banner} alt="" />
                                        </div>

                                    </div>
                            }
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                <div className="fd" style={{ display: `${this.state.showTopsecond ? "block" : "none"}` }}>
                    {this.state.showAlert && <Alert data={this.state.alert} setAction={this.setPopUpActionsClose.bind(this)}></Alert>}
                    {this.state.showNewsBox && <NewsBox data={this.state.news} setAction={this.setPopUpActionsClose.bind(this)}></NewsBox>}
                    {this.state.showRegistration && <TourneyRegistration data={this.state.regData} error={this.state.regAlert} close={this.setPopUpActionsClose.bind(this)} network={this._lobbyNetwork}></TourneyRegistration>}
                    {this.state.showDpSettings && <DpSettings data={this.state.dpSettings} setAction={this.setPopUpActionsClose.bind(this)} network={this._lobbyNetwork}></DpSettings>}
                    {this.state.showMyTables && <MyTables data={this.state.myTables} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></MyTables>}
                    {this.state.showMyTournaments && <MyTournaments data={this.state.myTournaments} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></MyTournaments>}
                    {this.state.showFindPlayer && <Findplayer data={this.state.findPlayerData} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></Findplayer>}
                    {this.state.showOptions && <Options setAction={this.setPopUpActionsClose.bind(this)} setThemes={this.setThemes.bind(this)}></Options>}
                    {this.state.showAvtar && <AvatarPopup setAction={this.setPopUpActionsClose.bind(this)} setAvtar={this.setAvtar.bind(this)} newavatar={this.state.newavatar} avtarId={this.state.avtarId}></AvatarPopup>}
                    {/* {this.state.showCashier && <Cashier balance={this.state.playerData} setAction={this.setPopUpActionsClose.bind(this)}></Cashier>} */}
                    {this.state.showCashier && <Cashier data={this.state.levelData} myLevel={this.state.myLevel} lobbyMenuHandler={this.lobbyMenuHandler.bind(this)} cashierTourneyTables={this.cashierTourneyTables} stars={this.state.displayStars} playerInfo={this.playerInfo} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></Cashier>}
                    {this.state.showMyBuddies && <MyBuddies data={this.state.myBuddies} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)}></MyBuddies>}
                    {this.state.showpokerHistory && <PokerhandHistory user={this.state.userLogged} errorMsg={this.state.gameHistoryErrorMsg}
                        // data={this.state.historyDataForReplay}
                        data={this.state.historyData}
                        setAction={this.setPopUpActionsClose.bind(this)} gethandHistory={this.getHandHistory.bind(this)} secondLevel={this.state.subHistorydata}></PokerhandHistory>}
                    {this.state.showLevelInfo && <PlayerlevelInfo data={this.state.levelData} showCashier={this.state.showCashier} setAction={this.setPopUpActionsClose.bind(this)}></PlayerlevelInfo>}
                    {this.state.showActiveTables && <MyActiveTourCashTables data={this.state.myTables.concat(this.state.myTournaments)} network={this._lobbyNetwork} setAction={this.setPopUpActionsClose.bind(this)} ></MyActiveTourCashTables>}
                    {this.state.profileshow && <Profile data={this.state.levelData} stars={this.state.displayStars} setAction={this.lobbyMenuHandler.bind(this)} user={this.state.userLogged} balance={this.state.playerData} avtar={this.state.avtar}></Profile>}
                    {this.state.UnRegisterpopup && <UnRegisterpopup regAlert={this.state.regAlert} setAction={this.setPopUpActionsClose.bind(this)} network={this._lobbyNetwork}></UnRegisterpopup>}
                    {this.state.showMyAnimation && <MyAnimation setAction={this.setPopUpActionsClose.bind(this)} animation={this.props.animation.bind(this)}></MyAnimation>}
                </div>
            </div>
        );
    }
}
export default withTranslation()(LobbyMain)