import "../../../../css/ui/table/leftMenu.css";
import { useState } from "react";
// import menubar from "../../../../assets/images/table/menubar.svg";
import menubar from "../../../../assets/images/table/HeadIcons/menubar.svg";
import profile from "../../../../assets/images/table/HeadIcons/profile.svg";
import exit_lb from "../../../../assets/images/table/Leader-bet-icons/exit-table_lb.png";
import exit from "../../../../assets/images/table/HeadIcons/exit.svg";
import leaveTableIcon from "../../../../assets/images/table/HeadIcons/arrow-right-from-breaket.svg"
import fileName from "../../../../jsconfig";
import Screen from "../../../utils/screen";

export const LeftMenu = (props) => {
    const [show, setShow] = useState(true);

    return (
        <div>
            <div
                className="bgcover"
                onClick={() => {
                    setShow(true);
                }}
                hidden={show}
            ></div>
            {/*</div><div className="closeTbl">*/}
                <div className="popupbtn" hidden={show}>
                    <div className="profile">
                        <button>
                            <img src={profile} alt="Image not found" />
                            Profile
                        </button>
                    </div>
                    <div>
                        <hr />
                    </div>
                    <div className="profile">
                        <button

                            onClick={(e) => {
                                e.preventDefault();
                                props.setAction("openExitAlert");
                                setShow(true);
                            }}
                        >
                            <img src={exit} alt="Image not found" />
                            Exit
                        </button>
                    </div>
                </div>
                {/* <div className="tabsCloseBtn">
                    <img
                        src={menubar}
                        onClick={() => {
                            setShow(!show);
                        }}
                        alt="Image not found"
                    />
                </div> */}
                {fileName.name !== "Leader_bet" ?
                    <div className="tabsCloseBtn" style={{ right: (fileName.name === "Riverpoker" && Screen.getDeviceType().name !== "Mobile") ? '25px' : '' }}>
                        {/* <img src={menubar} onClick={() => { setShow(!show) }} alt="Image not found" /> */}
                        {/* <button style={{ background: 'none', cursor: props.timerforcetable === true ? 'no-drop' : 'pointer' }} */}
                        <button style={{ background: 'none', cursor: props.leaveTablebtn === true ? 'no-drop' : 'pointer' }}
                            // disabled={props.timerforcetable}
                            disabled={props.leaveTablebtn}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setAction("openExitAlert");

                            }}>
                            <img src={leaveTableIcon} alt="not found" style={{ color: 'gray' }} />
                        </button>
                    </div> :
                    <div className="exit-btn_lb">
                        {/* <button style={{ background: 'none', cursor: props.timerforcetable === true ? 'no-drop' : 'pointer' }} */}
                        <button style={{ background: 'none', cursor: props.leaveTablebtn === true ? 'no-drop' : 'pointer' }}
                            disabled={props.leaveTablebtn}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setAction("openExitAlert");

                            }}>
                            <img style={{ width: '45px', height: '45px' }} src={exit_lb} alt="" />
                        </button>
                    </div>
                }
            {/*</div>*/}
        </div>
    );
};
