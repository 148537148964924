import React, { useMemo, useState, useEffect } from "react";
import "../../../../../css/ui/lobby/mainGrid.css";
import Columns from "./mainGridColumnsSitGo";
import { useTable, useRowSelect, useSortBy } from "react-table";
import Screen from "../../../../utils/screen";
import { t } from "i18next";
import fileName from "../../../../../jsconfig";
import background_td from '../../../../../assets/images/lobby/Path1.svg'
import background_td1 from '../../../../../assets/images/lobby/Path2.svg'
import background_td2 from '../../../../../assets/images/lobby/path3.svg'
import background_td3 from '../../../../../assets/images/lobby/path4.svg'
import background_td4 from '../../../../../assets/images/lobby/path5.png'
export const MainGridSitGo = (props) => {
  // const columns = useMemo(() => Columns, []);
  const columns = useMemo(() => {
    if (Screen.getDeviceType().name === "Mobile") {
      return fileName.name === "Leader_bet" ? Columns.Columns_lb : Columns.Columns;
    } else {
      return Columns.Columns;
    }
  }, []);


  const data = useMemo(() => props.data, [props.data]);


  // const table = useTable({ columns, data }, useSortBy, useRowSelect);
  const table = useTable(
    {
      columns,
      data,
      initialState: {
        // sortBy: [
        //   {
        //     id: "buyIn",
        //     desc: true,
        //   },
        // ],
      },
    },
    useSortBy,
    useRowSelect
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  const [prevRow, setPrevRow] = useState();


  useEffect(() => {
    if (Screen.getDeviceType().name === "Mobile") {
      let r = document.getElementsByTagName("tr");
      for (var i = 0; i < r.length; i++) {
        let row = r[i];
        if (row.dataset.type === "SITANDGO_TOURNAMENT") {
          row.onclick = () => {
            if (prevRow !== undefined) {
              prevRow.style.boxShadow = null;

            }
            setPrevRow(row);
            if (row !== undefined && row.dataset.id !== undefined) {
              props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
              props.network.send(`<OpenTable id="${row.dataset.id}" type="${row.dataset.type}"/>`);
              row.style.boxShadow = (fileName.name === "Leader_bet" ? "#3ba14c 0px 0px 8px" : "rgb(60 162 200) 0px 0px 8px");
            }
          }
          // row.ondblclick = () => {
          //   console.log("am double clicked")
          // }
        }
      }
    }
    else if (Screen.getDeviceType().name === "Desktop") {
      let r = document.getElementsByTagName("tr");
      for (var i = 1; i < r.length; i++) {
        let row = r[i];
        if (fileName.name === "Leaderbet") {
          for (var j = 0; j < row.childNodes.length; j++) {
            if (j == 4 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("REGISTER") || row.childNodes[j].innerText == t("Late Registration")) {
              row.childNodes[j].style.backgroundImage = `url(${background_td})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 4 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Cancelled")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td1})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 4 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Announced")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td2})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 4 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Completed")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td3})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 4 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Running") || row.childNodes[j].innerText == t("Seating")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td4})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            // else if (j == 4 && row.childNodes[j] != undefined ) {
            //   row.childNodes[j].style.textAlign = "center";
            //   row.childNodes[j].style.backgroundImage = "none";
            //   row.childNodes[j].style.backgroundRepeat = "";
            //   row.childNodes[j].style.backgroundPosition = "";
            //   row.childNodes[j].style.backgroundSize = "";
            // }
            else {
              row.childNodes[j].style.backgroundImage = "none";
              row.childNodes[j].style.backgroundRepeat = "";
              row.childNodes[j].style.backgroundPosition = "";
              row.childNodes[j].style.backgroundSize = "";
              row.childNodes[j].style.textAlign = "";
            }
          }
        }

        row.onclick = () => {
          if (prevRow !== undefined) {
            prevRow.style.backgroundColor = null;
            prevRow.style.backgroundImage = null;
            prevRow.style.boxShadow = null;
          }
          // row.style.backgroundImage = "linear-gradient(to right, rgb(60 162 200), rgb(3 5 12 / 46%), rgb(60 162 200))";
          // row.style.boxShadow = "rgb(60 162 200) 0px 0px 8px";
          if (fileName.name === "Riverpoker") {
            row.style.backgroundImage = "linear-gradient(to right, rgb(0 83 192), rgba(3, 5, 12, 0.46), rgb(0, 83, 192))";
            row.style.boxShadow = "rgb(0, 83, 139) 0px 0px 8px";
            row.style.padding = "4px 0px";
          } else {
            row.style.backgroundImage = "linear-gradient(to right, rgb(201 55 68), rgb(3 5 12 / 46%), rgb(201 55 68))";
            row.style.boxShadow = "rgb(201 55 68) 0px 0px 8px";
          }
          setPrevRow(row);

          if (row.dataset.id !== undefined) {
            props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
            props.network.send("<GetMyTournaments/>")
            props.setAction("hideMyTourneys")

          }
        }
        row.ondblclick = () => {
          console.log("am double clicked")

        }
      }
    }


  });


  return (
    <div className={fileName.name === "Leader_bet" ? 'mainGrid_lb mainGridOverflow' : 'mainGrid mainGridOverflow'} >
      {/* <table id="mainGridTourney_table" {...getTableProps()}> */}
      <table id={(fileName.name === "Leader_bet" && Screen.getDeviceType().name === "Mobile") ? "mainGridTourney_table_lb" : (fileName.name === "Riverpoker" && Screen.getDeviceType().name !== "Mobile") ? 'mainGrid_table_rv' : "mainGridTourney_table"}  {...getTableProps()}>
        {Screen.getDeviceType().name === "Desktop" && (
          <thead className="maingridheader ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="bdrgold bg_3">
                {headerGroup.headers.map((column, i) => (
                  <th className={Screen.getDeviceType().name === "Desktop" ? i === 0 ? "col-4" : "col-3" : ""} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {t(column.render("Header"))}
                    {column.isSorted ? ((column.isSortedDesc ? "  ▲" : "  ▼")) : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>)}
        <tbody {...getTableBodyProps()} style={{ background: fileName.name !== "Riverpoker" ? "#000" : "" }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr data-id={row.original.id} data-type={row.original.type} {...row.getRowProps()} className="p_5">
                {row.cells.map((cell, i) => {
                  return <td className={Screen.getDeviceType().name === "Desktop" ? i === 0 ? "col-4" : "col-3" : ""} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div >
  );
};
