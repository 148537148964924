import React from "react";
import CardDefault from "../../../../../../assets/images/table/cards/cards2.png";
import { Group, Sprite } from "react-konva";
import cardSound from "../../../../../../assets/audio/Card.mp3";
import Konva from "konva";
Konva.showWarnings = false;

export class Card extends React.Component {
    constructor(props) {
        super(props);
        this.width = window.innerWidth * 0.5;
        this.state = {
            image: null,
            x: this.width - 26,
            y: 50,
        };
        this.cardStyle = CardDefault;
        this.cardSound = new Audio(cardSound);
    }

    componentDidMount() {
        this.loadImage(CardDefault);
    }
    componentDidUpdate(prevProps) {
        if (this.cardStyle !== this.props.cardStyle) {
            this.loadImage(this.props.cardStyle);
            this.cardStyle = this.props.cardStyle;
        }
        if (this.props.x != prevProps.x) {
            this.changeCardPosition();
        }

    }
    changeCardPosition() {
        this.imageNode.to({ x: this.props.x, y: this.props.y, duration: 0.5, ease: "Linear" });

    }
    pullDown() {
        this.imageNode.to({ y: this.props.y + 50, x: this.props.x, opacity: 0.35, duration: 0.5, ease: "Linear", visible: false });
    }
    pullUpCards() {
        this.imageNode.to({ y: this.props.y, x: this.props.x, opacity: 0.5, duration: 0.5, ease: "Linear", visible: true });
    }
    pullUp() {
        this.imageNode.to({ y: this.props.y - 10, opacity: 1, duration: 0.5, ease: "Linear" });
    }
    animateCard() {
        this.cardSound.play();
        // setTimeout(() => {
        // this.setState({ x: this.props.x, y: this.props.y });

        // gsap.to(this.imageNode, { x: this.props.x, y: this.props.y, duration: 0.5, ease: "Linear" });
        this.imageNode.to({ visible: true, duration: 0.05 });
        this.imageNode.to({ x: this.props.x, y: this.props.y, scaleX: this.props.scale, scaleY: this.props.scale, rotation: 0, duration: 0.5, ease: "EaseInOut" });
        this.imageNode.to({ rotation: this.props.angled });
        // }, this.props.delay);
    }
    componentWillUnmount() {
        this.image.removeEventListener("load", this.handleLoad);
    }
    loadImage(img) {
        this.image = new window.Image();
        this.image.src = img;
        this.image.addEventListener("load", this.handleLoad);
    }
    handleLoad = () => {
        this.setState({
            image: this.image,
        });
    };

    render() {
        return (
            <Group>
                <Sprite
                    // x={this.width - 26}
                    x={(this.width/2)+50}
                    y={50}
                    // x={this.props.x}
                    // y={this.props.y}
                    ref={(node) => {
                        this.imageNode = node;
                    }}
                    image={this.state.image}
                    // scaleX={this.props.scale}
                    // scaleY={this.props.scale}
                    scaleX={0.15}
                    scaleY={0.15}
                
                    animations={{
                        cardFrame: this.props.frame,
                    }}
                    animation={"cardFrame"}
                    frameRate={1}
                    frameIndex={0}
                    opacity={this.props.alpha}
                    visible={false}
                    // visible={this.props.show}
                    // rotation={this.props.angled}
                    rotation={0}
                ></Sprite>
            </Group>
        );
    }
}
