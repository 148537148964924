import "../../../../../css/ui/table/waitListTable.css";

export default function WaitListTable(props) {

	return (
		<table className="table_3" cellPadding="0" cellSpacing="0">
			<tbody>
				<tr>
					<th>Position</th>
					<th>Player</th>
				</tr>
				{(() => {
					let i = 0,
						cnt = props.data.length,
						rows = [];
					for (i; i < cnt; i++) {
						rows.push(
							<tr key={i}>
								<td>{props.data[i].id}</td>
								<td>{props.data[i].name}</td>
							</tr>
						);
					}

					return rows;
				})()}
			</tbody>
		</table>
	);
}
