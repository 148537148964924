import React, { useMemo, useState, useEffect } from "react";
import "../../../../../css/ui/lobby/mainGrid.css";
import Columns from "./mainGridColumnsTourney";
import { useTable, useRowSelect, useSortBy } from "react-table";
import Screen from "../../../../utils/screen";
import fileName from "../../../../../jsconfig";
import { t } from "i18next";
import background_td from '../../../../../assets/images/lobby/Path1.svg'
import background_td1 from '../../../../../assets/images/lobby/Path2.svg'
import background_td2 from '../../../../../assets/images/lobby/path3.svg'
import background_td3 from '../../../../../assets/images/lobby/path4.svg'
import background_td4 from '../../../../../assets/images/lobby/path5.png'
export const MainGridTourney = (props) => {
  // const columns = useMemo(() => Columns, []);
  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(() => {
    if (Screen.getDeviceType().name === "Mobile") {
      return fileName.name === "Leader_bet" ? Columns.Columns_lb : Columns.Columns;
    } else if (Screen.getDeviceType().name === "Desktop") {
      if (fileName.name == "Leader_bet") {
        return Columns.Columns2;
      } else {
        return Columns.Columns1;
      }
    }
  }, []);


  // const table = useTable({ columns, data }, useSortBy, useRowSelect);
  // const table = useTable({
  //   columns, data, initialState: {
  //     sortBy: columns.map((one) => {
  //       return {
  //         desc: true,
  //         id: one.accessor.status ? one.accessor : "status",
  //       };
  //     }),
  //   }
  // }, useSortBy, useRowSelect);


  const table = useTable(
    {
      columns,
      data,
      initialState: {
        // sortBy: [
        //   ...columns.map((column) => ({
        //     id: column.accessor || column.id,
        //     desc: column.accessor && column.accessor.status ? false : true,
        //   })),
        // ],
      },
    },
    useSortBy,
    useRowSelect
  );
  // console.log(table)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  const [prevRow, setPrevRow] = useState();


  useEffect(() => {
    if (Screen.getDeviceType().name === "Mobile") {
      let r = document.getElementsByTagName("tr");
      for (var i = 0; i < r.length; i++) {
        let row = r[i];
        if (row.dataset.type === "SCHEDULED_TOURNAMENT") {
          row.onclick = () => {
            if (prevRow !== undefined) {
              // prevRow.style.backgroundColor = null;
              prevRow.style.boxShadow = null;
            }

            setPrevRow(row);
            // if (row.dataset.id !== undefined && prevRow !== undefined) {
            if (row.dataset.id !== undefined && row !== undefined) {
              props.network.send(`<OpenTable id="${row.dataset.id}" type="${row.dataset.type}"/>`);
              props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
              row.style.boxShadow = (fileName.name === "Leader_bet" ? "#3ba14c 0px 0px 8px" : "rgb(60 162 200) 0px 0px 8px");
            }
          }
          // row.ondblclick = () => {
          //   console.log("am double clicked")
          // }
        }
      }
    }
    else if (Screen.getDeviceType().name === "Desktop") {


      let r = document.getElementsByTagName("tr");
      // console.log(r);
      for (var i = 1; i < r.length; i++) {
        let row = r[i];
        if (fileName.name === "Leaderbet") {
          for (var j = 0; j < row.childNodes.length; j++) {
            if (j == 5 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("REGISTER") || row.childNodes[j].innerText == t("Late Registration")) {
              row.childNodes[j].style.backgroundImage = `url(${background_td})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 5 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Cancelled")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td1})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 5 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Announced")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td2})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 5 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Completed")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td3})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            else if (j == 5 && row.childNodes[j] != undefined && row.childNodes[j].innerText == t("Running") || row.childNodes[j].innerText == t("Seating")) {

              row.childNodes[j].style.backgroundImage = `url(${background_td4})`;
              row.childNodes[j].style.backgroundRepeat = "no-repeat";
              row.childNodes[j].style.backgroundPosition = "right";
              row.childNodes[j].style.backgroundSize = "100% 200%";
              row.childNodes[j].style.textAlign = "center";
            }
            // else if (j == 5 && row.childNodes[j] != undefined ) {
            //   row.childNodes[j].style.textAlign = "center";
            //   row.childNodes[j].style.backgroundImage = "none";
            //   row.childNodes[j].style.backgroundRepeat = "";
            //   row.childNodes[j].style.backgroundPosition = "";
            //   row.childNodes[j].style.backgroundSize = "";
            // }
            else {
              row.childNodes[j].style.backgroundImage = "none";
              row.childNodes[j].style.backgroundRepeat = "";
              row.childNodes[j].style.backgroundPosition = "";
              row.childNodes[j].style.backgroundSize = "";
              row.childNodes[j].style.textAlign = "";
            }
          }
        }

        row.onclick = () => {
          if (prevRow !== undefined) {
            prevRow.style.backgroundColor = null;
            prevRow.style.backgroundImage = null;
            prevRow.style.boxShadow = null;
          }
          // console.log(row.dataset.id +",,..,,"+row.dataset.type);
          // console.log(row.innerText);
          // row.style.backgroundImage = "linear-gradient(to right, rgb(60 162 200), rgb(3 5 12 / 46%), rgb(60 162 200))";
          // row.style.boxShadow = "rgb(60 162 200) 0px 0px 8px";

          if (fileName.name === "Riverpoker") {
            row.style.backgroundImage = "linear-gradient(to right, rgb(0 83 192), rgba(3, 5, 12, 0.46), rgb(0, 83, 192))";
            row.style.boxShadow = "rgb(0, 83, 139) 0px 0px 8px";
            row.style.padding = "4px 0px";
          } else {
            row.style.backgroundImage = "linear-gradient(to right, rgb(201 55 68), rgb(3 5 12 / 46%), rgb(201 55 68))";
            row.style.boxShadow = "rgb(201 55 68) 0px 0px 8px";
          }
          setPrevRow(row);

          if (row.dataset.id !== undefined) {
            console.log("open table with id: " + row.dataset.id)
            props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
            props.network.send("<GetMyTournaments/>")
            // props.network.send("<GetPlayerInfo/>");
            props.setAction("hideMyTourneys")
          }
        }
        row.ondblclick = () => {
          console.log("am double clicked")
          // console.log(row.innerText);

        }
      }
    }


  });


  return (
    <div className={fileName.name === "Leader_bet" ? 'mainGrid_lb mainGridOverflow' : 'mainGrid mainGridOverflow'} >
      {/* <table id="mainGridTourney_table" {...getTableProps()}> */}
      <table id={(fileName.name === "Leader_bet" && Screen.getDeviceType().name === "Mobile") ? "mainGridTourney_table_lb" : (fileName.name === "Riverpoker" && Screen.getDeviceType().name !== "Mobile") ? 'mainGrid_table_rv' : "mainGridTourney_table"}  {...getTableProps()}>
        {Screen.getDeviceType().name === "Desktop" && (
          <thead className="maingridheader ">
            {headerGroups.map((headerGroup) => (
              <tr  {...headerGroup.getHeaderGroupProps()} className="bdrgold bg_3">
                {headerGroup.headers.map((column) => (
                  <th className={Screen.getDeviceType().name === "Desktop" ? "col-2" : ""} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {t(column.render("Header"))}
                    {column.isSorted ? ((column.isSortedDesc ? "  ▲" : "  ▼")) : ""}
                  </th>
                ))}
              </tr>
            ))}
          </thead>)}
        <tbody {...getTableBodyProps()} style={{ background: fileName.name !== "Riverpoker" ? "#000" : "" }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr data-id={row.original.id} data-type={row.original.type} {...row.getRowProps()} className=" p_5">
                {row.cells.map((cell) => {
                  return <td className={Screen.getDeviceType().name === "Desktop" ? "col-2" : ""} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })
          }
        </tbody>
      </table>
    </div>
  );
};

