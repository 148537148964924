
import React from "react";
import Background from "../../../assets/images/lobby/latambackground.png";
import "../../../css/login/login.css";
import Modal from "react-modal";
import logo_svg from "../../../assets/images/lobby/RiverLogo.png";
import eye from "../../../assets/images/lobby/eye.svg";
import LanguageSelector from "../../LanguageSelector";
import { withTranslation } from 'react-i18next';
import Config from "../../../config";
import fileName from "../../../jsconfig.js";
import i18n from "i18next";
import Instructions from "../ui/popUps/Instructions.js";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginResp1: "",
            loginResp2: "",
            error: false,
            username: "",
            password: "",
            genCaptcha: "",
            nickname: "",
            isOpen: false,
            loader: false,
            isChecked: true,
            active: true,
            loginBtn: false,
            spinStart: false,
            showErrorMsg: false,
            errorMessage: "",
            isPWAInstalled: false,
            isInPWA: false,
            promptInstall: null,
            showInstructions: false,
            isIOS: false
        };
        this.config = new Config();
    }
    componentDidMount() {
        console.log("React version : ", React.version)
        if (fileName.name === "Riverpoker") {
            localStorage.removeItem("locale")
            localStorage.setItem("locale", "English");
            i18n.changeLanguage("English");
        } else if (fileName.name === "Leader_bet") {
            localStorage.removeItem("locale")
            localStorage.setItem("locale", "Georgian");
            i18n.changeLanguage("Georgian");
        } else {
            // localStorage.removeItem("locale")
            // localStorage.setItem("locale", "Spanish");
            i18n.changeLanguage(localStorage.getItem("locale"));
        }
        let newsession = sessionStorage.getItem("webSessionId");
        if (JSON.parse(newsession) !== null) {
            if ((JSON.parse(newsession)).success) {
                let id = (JSON.parse(newsession)).sessionId.split(".");
                sessionStorage.setItem(`${window.location.hostname}'_sid'`, JSON.stringify({ sid: id[1] }));
                // sessionStorage.setItem(`${window.location.hostname}'_wSid'`, JSON.stringify({ wSid: newsession.sessionId }));
                this.props.handler({ status: true, sid: id[1] });
                return;
            }
        }
        let session = this.checkSession();
        if (session.status) {
            let id = session.wSid.split(".");
            sessionStorage.setItem(`${window.location.hostname}'_sid'`, JSON.stringify({ sid: id[1] }));
            // sessionStorage.setItem(`${window.location.hostname}'_wSid'`, JSON.stringify({ wSid: session.wSid }));
            this.props.handler({ status: true, sid: id[1] });
            return;
        } else {
            var username = document.getElementById("username").value;
            var password = document.getElementById("password").value;
            var captcha = document.getElementById("captcha").value;
            if (username === "" && password === "" && captcha === "") {
                this.setState({ loginBtn: true });
                if (localStorage.getItem("username") !== "" && localStorage.getItem("password") !== "") {
                    if (localStorage.getItem("username") !== null && localStorage.getItem("password") !== null) {
                        this.setState({ username: localStorage.getItem("username"), password: localStorage.getItem("password") })
                        if (fileName.name !== "Riverpoker") {
                            this.setState({ loginBtn: false });
                        }
                        this.setState({ showErrorMsg: true });
                    }
                }
            }
            if (fileName.name === "Riverpoker") {
                this.generateCaptcha();
            }
        }
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            if (window.navigator.standalone) {
                this.setState({ isInPWA: true });
            }
            this.setState({ isIOS: true })
        } else {
            this.setState({ isIOS: false })
            window.addEventListener("beforeinstallprompt", (e) => {
                e.preventDefault();
                this.setState({ promptInstall: e })
            });
            window.addEventListener('appinstalled', () => {
                this.setState({ isPWAInstalled: true, promptInstall: null });
            });
            const isInPWA = window.matchMedia('(display-mode: standalone)').matches;
            if (isInPWA) {
                this.setState({ isInPWA, isPWAInstalled: true, promptInstall: null });
            }
        }

    }
    componentWillUnmount() {
        clearTimeout(this.clearlogin);
        this.setState({ loginResp1: "", loginResp2: "", error: false });
    }

    checkSession() {
        let session = sessionStorage.getItem(`${window.location.hostname}'_wSid'`);
        session = session ? JSON.parse(session) : null;
        return session?.wSid ? { status: true, wSid: session.wSid } : { status: false, wSid: null };
    }

    handleUsernameChange(e) {
        e.preventDefault();
        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        var captcha = document.getElementById("captcha").value;
        if (fileName.name === "Riverpoker") {
            if (username !== "" && password !== "" && captcha !== "") {
                this.setState({ loginBtn: false });
            } else {
                this.setState({ loginBtn: true });
            }
            this.setState({ username: e.target.value });
        } else {
            if (username !== "" && password !== "") {
                this.setState({ loginBtn: false });
                this.setState({ showErrorMsg: true });
            } else {
                this.setState({ loginBtn: true });
            }
            this.setState({ username: e.target.value });
        }
    }

    handlePasswordChange(e) {
        e.preventDefault();
        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        var captcha = document.getElementById("captcha").value;
        if (fileName.name === "Riverpoker") {
            if (username !== "" && password !== "" && captcha !== "") {
                this.setState({ loginBtn: false });
            } else {
                this.setState({ loginBtn: true });
            }
            this.setState({ password: e.target.value });
        } else {
            if (username !== "" && password !== "") {
                this.setState({ loginBtn: false });
                this.setState({ showErrorMsg: true });
            } else {
                this.setState({ loginBtn: true });
            }
            this.setState({ password: e.target.value });
        }
    }
    handleCaptcha(e) {
        this.setState()
        e.preventDefault();
        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        var captcha = document.getElementById("captcha").value;
        if (fileName.name === "Riverpoker") {
            if (username !== "" && password !== "" && captcha !== "") {
                this.setState({ loginBtn: false });
            } else {
                this.setState({ loginBtn: true });
            }

            if (e.target.value === this.state.genCaptcha) {
                this.setState({ showErrorMsg: true });
            } else {
                this.setState({ showErrorMsg: false });
            }
            this.setState({ captcha: e.target.value });
        }
    }


    onClickSignIn(e) {
        this.setState({ loader: true });
        this.clearlogin = setTimeout(() => {
            this.setState({ loader: false });
        }, 4000);
        e.preventDefault();
        this.props.onSubmit();
        this.setState({ loginResp1: "Please Wait...  Loggin In", loginResp2: "", error: false });
        if (this.state.showErrorMsg) {
            let value = {
                login: this.state.username,
                password: this.state.password,
                captcha: this.state.captcha
            };

            fetch(this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.login_Api, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    siteid: this.config.URL_Environment.sitIds.sitid,
                },
                body: JSON.stringify(value),
            })
                .then((resp) => resp.json())
                .then((resp) => {
                    if (resp.success && resp.hasOwnProperty("sessionId")) {
                        let id = resp.sessionId.split(".");
                        localStorage.setItem('showPop', true)
                        this.setState({ loader: false });

                        sessionStorage.setItem(`${window.location.hostname}'_sid'`, JSON.stringify({ sid: id[1] }));
                        sessionStorage.setItem(`${window.location.hostname}'_wSid'`, JSON.stringify({ wSid: resp.sessionId }));
                        this.props.handler({ status: true, sid: id[1] });
                        if (this.state.isChecked) {
                            localStorage.setItem("username", this.state.username)
                            localStorage.setItem("password", this.state.password)
                        }

                    } else {
                        this.props.handler({ status: false, sid: null, description: resp.description });
                        this.setState({ loginResp1: "Oops!", loginResp2: resp.description, error: true });
                    }
                })
                .catch((err) => {
                    console.log("login err: ", err);
                });
        } else {
            this.setState({ errorMessage: 'Invalid Captcha' });
            this.generateCaptcha();
            this.clearlogin = setTimeout(() => {
                this.setState({ errorMessage: '' });
            }, 3000);
        }
    }

    onChangeCheckbox = (event) => {
        console.log(event.target.checked)
        // as of now no action implemented for this feature
        console.log(this.state.username)
        if (event.target.checked) {
            if (this.state.username !== null && this.state.password !== null) {
                localStorage.setItem("username", this.state.username)
                localStorage.setItem("password", this.state.password)
                // this.setState({
                //     isChecked: event.target.checked,
                // });
            }
        } else {
            localStorage.removeItem("username")
            localStorage.removeItem("password")
        }


        this.setState({
            isChecked: event.target.checked,
        });
    };



    generateCaptcha() {
        var xhr = new XMLHttpRequest();
        var path = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.generateCaptcha_Api;
        xhr.open("POST", path, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("siteid", this.config.URL_Environment.sitIds.sitid);
        xhr.addEventListener("load", this.readCaptcha.bind(this));
        xhr.addEventListener("error", this.handleEvent.bind(this));
        xhr.addEventListener("abort", this.handleEvent.bind(this));
        let value = {};
        xhr.send(JSON.stringify(value));
    }

    handleEvent(e) {
        console.log(`${e.type}: ${e.loaded} bytes transferred\n`);
    }
    onclickRemember(e) {
        this.setState({ modalShow: true });
        e.preventDefault();
    }
    // flow clear till here, yet to check the forgot password
    readCaptcha(data) {
        if (data.target.response !== null || data.target.response !== undefined) {
            this.setState({ genCaptcha: data.target.response })
            this.setState({ spinStart: false })
        }
    }


    RpHandler(data) {
        console.log("the remember response is");
        console.info(data.target.response);
        document.getElementById("userfp").value = " ";
        document.getElementById("emailfp").value = " ";
    }

    readXMl(event) {
        let sessionId = sessionStorage.getItem('webSessionId')
        var apiResponse
        if (sessionId !== null) {
            apiResponse = JSON.parse(event)
        } else {
            apiResponse = JSON.parse(event.target.response)
        }
        if (apiResponse.success === false) {
            this.setState({ apiMessage: apiResponse.description, showError: true })
        } else {
            this.setState({ apiMessage: apiResponse.description })
        }
        this.clearlogin = setTimeout(() => {
            this.setState({ apiMessage: "" })
        }, 4000);
        let API_loginData;
        if (window.location.search.split("=")[1] === undefined) {
        } else {
            API_loginData = window.location.search.split("=")[1];
            let id = API_loginData.split(".");
            this.setState({ sid: id[1] });
            sessionStorage.setItem(`${window.location.hostname}'_sid'`, JSON.stringify({ sid: id[1] }));
            sessionStorage.setItem(`${window.location.hostname}'_wSid'`, JSON.stringify({ wSid: API_loginData }));
            this.props.action({ status: "yes", user: this.state.nickname, sid: id[1] });
            return;
        }
        try {
            API_loginData = JSON.parse(event);
        } catch (e) {
            API_loginData = JSON.parse(event.target.response);
        }
        this.setState({ nickname: sessionStorage.getItem('profile') });
        if (API_loginData.hasOwnProperty("success")) {
            if (API_loginData.success === true) {
                this.setState({ isLoggedIn: "yes" });
                if (API_loginData.hasOwnProperty("sessionId")) {
                    let id = API_loginData.sessionId.split(".");
                    this.setState({ sid: id[1] });
                    sessionStorage.setItem(`${window.location.hostname}'_sid'`, JSON.stringify({ sid: id[1] }));
                    sessionStorage.setItem(`${window.location.hostname}'_wSid'`, JSON.stringify({ wSid: API_loginData.sessionId }));
                    this.props.action({ status: "yes", user: this.state.nickname, sid: id[1] });
                }
            } else {
                this.setState({ isLoggedIn: "no" });
                this.props.action({ status: this.state.isLoggedIn, user: this.state.nickname, sid: this.state.sid });
            }
        }
    }

    toggleModal() {
        this.setState({ isOpen: true });
    }

    toggleModalclose() {
        this.setState({ isOpen: false });
    }

    submitForgotten() {
        var body = { login: document.getElementById("userfp").value, email: document.getElementById("emailfp").value };

        document.getElementById("message").style.visibility = "visible";
        document.getElementById("message").style.color = "green";

        var path = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.resetPassword_Api;

        var xhr = new XMLHttpRequest();
        xhr.open("POST", path, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("siteid", this.config.URL_Environment.sitIds.sitid);
        xhr.addEventListener("load", (e) => {
            this.RpHandler(e);
        });
        if (body) {
            console.log("sending with body");
            xhr.send(JSON.stringify(body));
        } else {
            console.log("sending without body");
            xhr.send();
        }
    }

    handleClick() {
        this.setState({
            active: !this.state.active,
        });
        if (this.state.active) {
            document.getElementById("password").type = "text";
        } else {
            document.getElementById("password").type = "password";
        }
    }

    handleUrl(e) {
        console.log(e.target.value);
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        window.location.reload();
        localStorage.setItem("url", e.target.value)
    }

    handlePWA() {
        if (this.state.promptInstall) {
            this.state.promptInstall.prompt().then((res) => {
                if (res.outcome === 'accepted') {
                    this.setState({ isInPWA: true, isPWAInstalled: true, promptInstall: null })
                }

            });
        }
    }
    isTelegramInAppBrowser() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /Telegram/i.test(userAgent);
    }

    isRedirectedFromTelegram() {
        const referrer = document.referrer;
        return /t\.me|telegram\.me/i.test(referrer);
    }


    handleIOS() {
        this.setState({ showInstructions: true })
    }

    closeInstructions() {
        this.setState({ showInstructions: false })
    }

    render() {
        return (
            <div className="login_Main" style={{ position: 'fixed', margin: 'auto', left: '0', right: '0', top: '0', bottom: '0', borderRadius: '9px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', inset: "20px" }}>

                <div className="fd ">
                    <div className="fd versionDiv" style={{ marginTop: "10px" }}>
                        <div className="errordiv">
                            <span className="clr_ff m_5">{this.state.loginResp1} </span>
                            <span className="clr_ff m_5" style={{ color: this.state.error ? "#FF0000" : "#FFFFF" }}>{this.state.loginResp2} </span>
                        </div>
                        <span className="versionTxt m_5 ">{this.props.t('Version')} : 24.09.24b </span>
                        {fileName.name !== "Riverpoker" &&
                            <LanguageSelector />
                        }
                    </div>
                    {/* <div className="fd">
                        <select onChange={(e) => { this.handleUrl(e) }} defaultValue={localStorage.getItem("url") != undefined ? localStorage.getItem("url") : fileName.name}>
                            <option value="Riverpoker">River</option>
                            <option value="Lapoker">Demo</option>
                            <option value="Leader_bet">Leaderbet </option>
                            <option value="vendibet">vendibet </option>
                            <option value="lapoker">lapoker </option>
                            <option value="pokerperu">pokerperu </option>
                            <option value="prode">prode </option>
                            <option value="fullbet">fullbet </option>
                            <option value="clpmaster">clpmaster </option>
                        </select>
                    </div> */}

                    <form className="fd">
                        <div className="fd text_center">
                            <img className="logoSvg" src={logo_svg} alt="" />
                        </div>
                        <h3 className="fd m_b_0"></h3>
                        <div className="fd">
                            <input type="text" id="username" name="username" onChange={this.handleUsernameChange.bind(this)} value={this.state.username} placeholder={this.props.t('USERNAME')} />
                            <p className="errMessageTxt clr_ff">*{this.props.t('Enter your registered username')}</p>
                        </div>
                        <div className="fd">
                            <input type="password" id="password" name="password" onChange={this.handlePasswordChange.bind(this)} value={this.state.password} autoComplete="on" placeholder={this.props.t('PASSWORD')} />
                            <div className="eyeToggleDiv" onClick={this.handleClick.bind(this)}>
                                <img src={eye} id="eyes" className={this.state.active ? "eyeToggleHide" : "eyeToggleShow"} alt="Not Found" />
                            </div>
                            <p className="errMessageTxt clr_ff">*{this.props.t('Enter your password')}</p>
                        </div>

                        <div className="fd" style={{ display: fileName.name === "Riverpoker" ? 'block' : 'none' }}>
                            <div className="fd">
                                <div className="captchaImg">
                                    <div className="captchaNum"> <div className="captchaCover"></div>{this.state.genCaptcha}</div>
                                    <input type="number" id="captcha" name="captcha" onChange={this.handleCaptcha.bind(this)} autoComplete="on" placeholder="Enter captcha" style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} />
                                    <div className="eyeToggleDiv" onClick={e => this.generateCaptcha()}>
                                        <i className={this.state.spinStart ? 'fa fa-refresh fa-spin' : 'fa fa-refresh'} style={{ fontSize: "28px", margin: fileName.name === "Riverpoker" ? "" : '4px 0px 0px 0px' }}  ></i>
                                    </div>
                                </div>
                            </div>
                            <p className="errMessageTxt" style={{ display: this.state.errorMessage === 'Invalid Captcha' ? 'block' : 'none', color: 'red', fontWeight: '800' }}>{this.state.errorMessage}</p>
                        </div>


                        <div className="fd m_t_5 rememberMeDiv">
                            <input id="rememberMe" type="checkbox" onChange={this.onChangeCheckbox} checked={this.state.isChecked} name="checkbox" value="checked" /> <label htmlFor="rememberMe" className="font_14">{this.props.t('Remember Password')}</label>
                        </div>
                        <div className="fd versionDiv">
                            {/* <button type="submit" className="btn_2 m_t_10 fd" name="loginButton" disabled={this.props.loginBtnState} */}
                            {/* <button type="submit" className="btn_2 m_t_10 fd" name="loginButton" disabled={this.state.loginBtn} onClick={this.onClickSignIn.bind(this)}>
                                {this.props.t('Login')}
                                {this.state.loader && <span className="m_l_5 loader_3"></span>}
                            </button> */}
                            <button type="submit" className="btn_2 m_t_10 fd" name="loginButton" disabled={this.state.loginBtn}
                                onClick={this.onClickSignIn.bind(this)} >
                                {this.props.t('Login')}
                                {this.state.loader && <span className="m_l_5 loader_3"></span>}
                            </button>
                            {/* {(this.state.isIOS || (!this.state.isPWAInstalled && this.state.promptInstall)) && !this.state.isInPWA && <button type="button" className="btn_2 m_t_10 fd" name="installBtn" id="installBtn"
                                onClick={this.state.isIOS ? this.handleIOS.bind(this) : this.handlePWA.bind(this)} >
                                Install PWA
                            </button>} */}
                            {/*s{(!this.isRedirectedFromTelegram() && !this.isTelegramInAppBrowser()) && (this.state.isIOS || (!this.state.isPWAInstalled && this.state.promptInstall)) && !this.state.isInPWA && <button type="button" className="btn_2 m_t_10 fd" name="installBtn" id="installBtn"
                                onClick={this.state.isIOS ? this.handleIOS.bind(this) : this.handlePWA.bind(this)} >
                                Install PWA
                            </button>}*/}
                        </div>
                    </form>
                    {/* <div className="fd versionDiv">
                        <span className="forgetPassword" onClick={this.toggleModal.bind(this)}>
                            {this.props.t('Forget Password?')}{" "}
                        </span>
                    </div> */}
                </div>

                <div className="fd footerTxt">
                    <span className="versionTxt">Copyright &#169; 2024, {(fileName.name === "Riverpoker" ? 'Riverpoker' : (fileName.name === "Lapoker" ? 'Lapoker' : 'Leaderbet'))} &#174;</span>
                    <span className="versionTxt"> All Rights Reserved </span>
                </div>

                <Modal
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            height: "100vh",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: Background,
                            // background: "linear-gradient(#040610, #131D50 )",
                            //     width: 100 %;
                            //     height: 100vh;
                            //     background- repeat: no-repeat;
                            //     background-size: 100% 100%;
                            //    background-image: url(../assets/images/lobby/latambackground.png);
                            //    background-repeat: no-repeat;
                        },
                        content: {
                            background: "#0a3135",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            all: "unset",
                        },
                    }}
                    isOpen={this.state.isOpen}
                    onRequestClose={this.toggleModal.bind(this)}
                    contentLabel="My dialog"
                >
                    <div className="login_Main" style={{ maxWidth: '90vw', position: 'fixed', margin: 'auto', left: '0', right: '0', top: '0', bottom: '0', borderRadius: '9px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <div className="login_Main"> */}
                        <img className="fd"
                            src={Background}
                            alt=""
                            style={{ width: "-webkit-fill-available", position: "fixed", top: "0", left: "0" }}
                        />
                        <div className="fd p_15" style={{ marginTop: "25px" }}>
                            <form className="fd ">
                                <div className="fd text_center">
                                    <img className="logoSvg" src={logo_svg} alt="" />
                                </div>
                                <h3 className="clr_5 fd text_center m_t_10 m_b_0">
                                    {" "}
                                    <i> {this.props.t("Forgot Password")} </i>{" "}
                                </h3>
                                <div className="fd m_t_15">
                                    <div className="fd m_t_5">
                                        <input type="email" id="emailfp" name="fname" placeholder={this.props.t("Email")} />
                                    </div>
                                </div>
                                <div className="fd m_t_15">
                                    <input type="text" id="userfp" name="lname" placeholder={this.props.t("Username")} />
                                </div>
                                <p id="message" className="errMessage m_t_10">
                                    <em>{this.props.t("Check your mail for the temporary password")}</em>
                                </p>
                            </form>
                            <div className="fd forgetBtns">
                                <button className="closeBtn m_t_15" onClick={this.toggleModalclose.bind(this)}>
                                    {this.props.t("Close")}
                                </button>
                                <button className="btn_2 m_t_15" onClick={this.submitForgotten.bind(this)}>
                                    {this.props.t("Submit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.state.showInstructions && <Instructions close={this.closeInstructions.bind(this)}></Instructions>}
            </div >
        );
    }
}
export default withTranslation()(Login);