const Columns = [
  {
    Header: "Name",
    accessor: "name",
  },

  {
    Header: "Game",
    accessor: "game",
  },

  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "Players",
    accessor: "players",
  },
  {
    Header: "Status",
    accessor: "status",
  }
]
const Columns_lb = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "breakTime",
    accessor: "breakTime"
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Game",
    accessor: "game",
  },
  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "state",
    accessor: "stateImg",
  }
]
export default { Columns, Columns_lb };