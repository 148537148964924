import fileName from "../../../jsconfig";

function desktopproperties() {
    let cw_min = 1000 / 20;
    let ch_min = 650 / 30;
    let details = {
        name: "Desktop",
        width: 1000,
        height: 650,
        style: {
            gameBox: "gameBox1",
            image: "backPixTable1",
            buttonGroup: { width: "100%", float: "right", padding: fileName.name === "Riverpoker" ? '5px 0px 15px 0px' : '5px' },
            checkboxGroup: (fileName.name === "Riverpoker" ? "checkboxGroup" : "checkboxGroup1")
        },
        boardCardPositions: {
            top: 200,
            left: 511,
            width: '280px',
            height: '83px',
            cardScale: 0.96,
            halfwidth: 0.35,
        },
        boardCardXSpace: 52,//33,//60
        ritBoardCardPositions: {
            width: '280px',
            height: '83px',
            top: '300px',
            cardScale: 0.96,
        },
        seatProperties: {
            width: 73,
            height: 12.5,
            profileRadius: 28,
            panelWidth: 100,
            panelHeight: 61,
            padddings: { x1: 13, y1: 18, x2: 19, y2: 6, x3: 12, y3: 6, x4: 16, y4: 18 },
            cardScale: 0.9,
            cardY: 53,
            cardXpadding: 10,
            dealerAdjustment: 10,
            delaerSize: 18,
            blindsTextOne: "SB",
            blindsTextTwo: "BB",
        },
        cardStyle: {
            frontCardStyle: "CardDefault",
            backCardStyle: [689, 0, 53, 73],
        },
        seatLayout: {
            2: [{ x: cw_min * 16.5, y: ch_min * 13.01 }, { x: cw_min * 1.38, y: ch_min * 13.01 }],

            4: [{ x: cw_min * 14.01, y: ch_min * 5.51 }, { x: cw_min * 14, y: ch_min * 20 },
            { x: cw_min * 4.51, y: ch_min * 20.01 }, { x: cw_min * 4.5, y: ch_min * 5.5 }],

            5: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 16.5, y: ch_min * 13.01 },
            { x: cw_min * 12, y: ch_min * 20.501 }, { x: cw_min * 6, y: ch_min * 20.501 },
            { x: cw_min * 6, y: ch_min * 4.801 }],

            6: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 16.5, y: ch_min * 13.01 },
            { x: cw_min * 12, y: ch_min * 20.501 }, { x: cw_min * 6, y: ch_min * 20.501 },
            { x: cw_min * 1.38, y: ch_min * 13.01 }, { x: cw_min * 6, y: ch_min * 4.8 }],

            7: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 15.8, y: ch_min * 9 },
            { x: cw_min * 16, y: ch_min * 17 }, { x: cw_min * 12, y: ch_min * 20.501 },
            { x: cw_min * 6, y: ch_min * 20.501 }, { x: cw_min * 1.38, y: ch_min * 13.01 },
            { x: cw_min * 6, y: ch_min * 4.8 }],

            8: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 16.01, y: ch_min * 9.01 },
            { x: cw_min * 16, y: ch_min * 17 }, { x: cw_min * 12, y: ch_min * 20.501 },
            { x: cw_min * 6, y: ch_min * 20.501 }, { x: cw_min * 2, y: ch_min * 17.01 },
            { x: cw_min * 2.01, y: ch_min * 9 }, { x: cw_min * 6, y: ch_min * 4.801 }],

            9: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 15, y: ch_min * 7 },
            { x: cw_min * 16.5, y: ch_min * 13.01 }, { x: cw_min * 15, y: ch_min * 19 },
            { x: cw_min * 12, y: ch_min * 20.501 }, { x: cw_min * 6, y: ch_min * 20.501 },
            { x: cw_min * 2, y: ch_min * 17 }, { x: cw_min * 2, y: ch_min * 9 },
            { x: cw_min * 6, y: ch_min * 4.8 }],

            10: [{ x: cw_min * 12, y: ch_min * 4.801 }, { x: cw_min * 15, y: ch_min * 7 },
            { x: cw_min * 16.5, y: ch_min * 13.01 }, { x: cw_min * 15, y: ch_min * 19 },
            { x: cw_min * 12, y: ch_min * 20.501 }, { x: cw_min * 6, y: ch_min * 20.501 },
            { x: cw_min * 3.01, y: ch_min * 19 }, { x: cw_min * 1.38, y: ch_min * 13.01 },
            { x: cw_min * 3, y: ch_min * 7 }, { x: cw_min * 6, y: ch_min * 4.8 }],
        },
        seatPotLayout: {
            2: [{ x: cw_min * 15, y: ch_min * 10.5 }, { x: cw_min * 4, y: ch_min * 10.5 }],

            4: [{ x: cw_min * 14.5, y: ch_min * 7.2 }, { x: cw_min * 14.5, y: ch_min * 14.2 },
            { x: cw_min * 5.5, y: ch_min * 14.2 }, { x: cw_min * 5.5, y: ch_min * 7.2 }],

            5: [{ x: cw_min * 14.5, y: ch_min * 6.5 }, { x: cw_min * 15, y: ch_min * 10.5 },
            { x: cw_min * 14.5, y: ch_min * 15.7 }, { x: cw_min * 5.5, y: ch_min * 15.2 },
            { x: cw_min * 5.5, y: ch_min * 6.5 }],

            6: [{ x: cw_min * 14.5, y: ch_min * 6.5 }, { x: cw_min * 15, y: ch_min * 10.5 },
            { x: cw_min * 14.5, y: ch_min * 15.7 }, { x: cw_min * 5.5, y: ch_min * 15.2 },
            { x: cw_min * 4, y: ch_min * 10.5 }, { x: cw_min * 5.5, y: ch_min * 6.5 }],

            7: [{ x: cw_min * 12, y: ch_min * 6.4 }, { x: cw_min * 15.8, y: ch_min * 10.5 },
            { x: cw_min * 14.5, y: ch_min * 15 }, { x: cw_min * 12, y: ch_min * 15 },
            { x: cw_min * 6.5, y: ch_min * 15 }, { x: cw_min * 4, y: ch_min * 12 },
            { x: cw_min * 6.5, y: ch_min * 6.4 }],

            8: [{ x: cw_min * 12, y: ch_min * 6.4 }, { x: cw_min * 15.8, y: ch_min * 10.5 },
            { x: cw_min * 14.5, y: ch_min * 15 }, { x: cw_min * 12, y: ch_min * 15 },
            { x: cw_min * 6.5, y: ch_min * 15 }, { x: cw_min * 4.5, y: ch_min * 15 },
            { x: cw_min * 3.5, y: ch_min * 10.6 }, { x: cw_min * 6.5, y: ch_min * 6.4 }],

            9: [{ x: cw_min * 12, y: ch_min * 6.4 }, { x: cw_min * 14.8, y: ch_min * 8.4 },
            { x: cw_min * 15.2, y: ch_min * 10.8 }, { x: cw_min * 14.8, y: ch_min * 13.6 },
            { x: cw_min * 12, y: ch_min * 15 }, { x: cw_min * 6.5, y: ch_min * 15 },
            { x: cw_min * 4.4, y: ch_min * 13 }, { x: cw_min * 4.4, y: ch_min * 8.6 },
            { x: cw_min * 6.5, y: ch_min * 6.4 },],

            10: [{ x: cw_min * 12, y: ch_min * 6.4 }, { x: cw_min * 14.8, y: ch_min * 8.4 },
            { x: cw_min * 15.2, y: ch_min * 10.8 }, { x: cw_min * 14.8, y: ch_min * 13.6 },
            { x: cw_min * 12, y: ch_min * 15 }, { x: cw_min * 6.5, y: ch_min * 15 },
            { x: cw_min * 4.4, y: ch_min * 13 }, { x: cw_min * 3.8, y: ch_min * 10.8 },
            { x: cw_min * 4.4, y: ch_min * 8.7 }, { x: cw_min * 6.5, y: ch_min * 6.4 }],

        },
        tablePotLayout: [
            { x: cw_min * 9.9, y: ch_min * 5 }, { x: cw_min * 5.8, y: ch_min * 10.78 },
            { x: cw_min * 13.4, y: ch_min * 10.4 }, { x: cw_min * 13.4, y: ch_min * 12.4 },
            { x: cw_min * 5.8, y: ch_min * 12.72 }, { x: cw_min * 12.8, y: ch_min * 8.8 },
            { x: cw_min * 6.5, y: ch_min * 9.3 }, { x: cw_min * 12.8, y: ch_min * 14.2 },
            { x: cw_min * 6.5, y: ch_min * 14.28 }, { x: cw_min * 6, y: ch_min * 10.5 }],

        boardCardsPosition: {
            x: 1100,
            y: 180,
            halfwidth: 0.35,
        },
        RitboardCardsPosition: {
            x: 1135,
            y: 245,
            halfwidth: 0.35,
        },
        handstrength: {
            position: {
                y: fileName.name === "Riverpoker" ? 60 : 40
            }
        }
    }
    return details;
}
export default desktopproperties;