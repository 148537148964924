import React, { Component } from "react";
import "./css/app.css";
import Loader from "./components/utils/loader";

import Login from "./components/game/login/login";
import Game from "./components/game/game";

export default class Appcopy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: true,
            showLogin: false,
            loginResp1: "",
            loginResp2: "",
            error: true,
            isSessionActive: false,
            sid: "",
            BgOpacity: 1,
            loginBtnState: false,
        };
    }
    componentDidMount() {
        this.onAppMount();
        // document.documentElement.style.setProperty('--dynamic-width', window.innerWidth);
        // document.documentElement.style.setProperty('--dynamic-height', window.innerHeight);

    }
    onFormSubmit() {
        this.setState({ loginResp1: "Please Wait...  Loggin In", loginResp2: "", BgOpacity: 0.5, loginBtnState: true, error: true });
    }
    logOutHandler() {
        sessionStorage.clear();
        this.setState({ showLogin: true, showLoading: false, isSessionActive: false, sid: null, BgOpacity: 1 });
        window.location.reload();
    }

    AppHandler(action) {
        console.log(action)
        if (action) {
            if (action.status) {
                this.setState({ showLogin: false, showLoading: false, isSessionActive: true, sid: action.sid, loginResp1: "", loginResp2: "", loginBtnState: false, error: true });
            } else {
                this.setState({ showLogin: true, showLoading: false, loginResp1: "Oops!", loginResp2: action.description, loginBtnState: false, BgOpacity: 1, isSessionActive: false, error: false });
            }
        }
    }

    onAppMount() {
        var sessionData = JSON.parse(sessionStorage.getItem(`${window.location.hostname}_sid`));

        if (sessionData) {
            this.setState({ sid: sessionData.sid, showLogin: false, showLoading: false });
        } else {
            this.setState({ showLogin: true, showLoading: false });
        }
    }
    render() {
        return (
            <div className="App fd ">
                {this.state.showLoading && <Loader />}
                {!this.state.showLoading && this.state.showLogin && (
                    <>
                        {/* <div className="errordiv">
                            <h3 style={{ textAlign: "center", marginBottom: "0px" }}>{this.state.loginResp1}</h3>
                            <h3 style={{ color: this.state.error ? "white" : "#FF0000", textAlign: "center", marginTop: "0px" }}>{this.state.loginResp2}</h3>
                        </div> */}
                        <Login handler={this.AppHandler.bind(this)} onSubmit={this.onFormSubmit.bind(this)} loginBtnState={this.state.loginBtnState}></Login>

                    </>
                )}
                {!this.state.showLoading && !this.state.showLogin && <Game sid={this.state.sid} logOutHandler={this.logOutHandler.bind(this)}></Game>}
            </div>
        );
    }
}
