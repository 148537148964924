
// import { useState,useEffect, Component } from "react";
// import Countdown from "react-countdown";
// export const Breaktime  = (props) => {
//   console.log("breaktime4   :"+props.breaktime1)

//     const [timer, setTimer] = useState(Number(props.breaktime1)); //in seconds

//     const timerToString = (timer) => {
//       console.log("breaktime500:  "+timer)
//       // alert("arey nti ra")
//       let hours = ('0' + Math.floor(timer/3600)).slice(-2);
//       let minutes = ('0' + Math.floor(timer/60)).slice(-2);
//       let seconds = ('0' + timer%60).slice(-2);
//       return /*hours + ":" +*/ minutes + ":" + seconds;
//     }

//     useEffect(()=>{
//       if(timer > 0){
//         // alert("ggg")
//         setTimeout(()=>{
//           setTimer(timer-1);
//           // setCount(props.breaktime1)
//         }, 1000)
//       }
//     }, [timer]);

//     console.log("breaktime0   :"+timer)
//     // console.log("breaktime09   "+count)
//     console.log("breaktime5   :"+timerToString())

//     return (
//       <div> {timerToString()}


// <div className="fd p_10 text_center">  
//                         <div style={{ height: '40px' }}>{props.data.lineOne}</div>
//                         <Countdown date={props.data.timeOne + props.data.timeTwo} />
//                     </div> 

//       </div>

//     )
// }
// import { useState,useEffect } from "react";

// export  function Breaktime(props) {

//   const [time, setTime] = useState(Number(props.breaktime1)); 
//   useEffect(() => {
//     let timer = setInterval(() => {
//       setTime((time) => {
//         if (time === 0) {
//           clearInterval(timer);
//           return 0;
//         } else return time - 1;
//       });
//     }, 1000);
//   }, []);

//   return (
//     <div >
//       <p style={{border:"2px solid forestgreen",width:"100px",height:"100px",background:"red",color:"black",position:"absolute",top:"100px"}}>
//         Time left: {`${Math.floor(time / 60)}`.padStart(2, 0)}:
//         {`${time % 60}`.padStart(2, 0)}
//       </p>
//     </div>
//   );
// }

import React, { useImperativeHandle, useState, useEffect } from "react";
export const Breaktime = React.forwardRef((props, ref) => {
  const [time, setTime] = useState(0);
  useImperativeHandle(ref, () => ({
    childMethod(e) {
      childMethod(e)
      // setTime(Number(e))
    }
  }))

  function childMethod(e) {
    // alert("call me-->>>>"+e)


    setTime(Number(e))
  }
  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);

    // console.log(`Tournament is on break.Next hand will start in: ${`${Math.floor(time / 60)}`.padStart(2, 0)} minutes:
    // ${`${time % 60}`.padStart(2, 0)} seconds`)
    return () => { clearInterval(timer); }
  }, []);

  // return (<View><Text> I am a child</Text></View>)
  return (
    <div >
      {/* <h1>hellow world</h1> */}

      {!props.feature && <p style={{
        width: "50vw", color: "rgb(219 219 219 / 64%)", position: "absolute", top: props.setCondition === false ? "38vh" : "54vh", left: "26vw", right: "25vw",
        zIndex: "9",

      }}>
        Tournament is on break. Next hand will start in: {`${Math.floor(time / 60)}`.padStart(2, 0)} minutes:
        {`${time % 60}`.padStart(2, 0)} seconds
      </p>}
      {props.feature && <p
        style={{
          margin: "auto",
          border: "1px solid #323232",
          background: "#0E1E33",
          borderRadius: "10px",
          color: "rgb(1, 191, 1)",
          padding: "5px"
        }}
      >
        Tournament is on break. Next hand will start in: {`${Math.floor(time / 60)}`.padStart(2, 0)} minutes:
        {`${time % 60}`.padStart(2, 0)} seconds
      </p>}
    </div>
  )
})