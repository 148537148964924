import React from "react";
import "../../../../css/ui/popUps/alert.css";
import close_1 from '../../../../assets/images/table/close_1.svg';
import share from '../../../../assets/Instructions/shareButton.jpg'
import ATH from '../../../../assets/Instructions/AddToHome.jpg'
import logo from "../../../../assets/Instructions/logo192.png"
import { withTranslation } from 'react-i18next'

 function Instructions(props) {
  return (
    <React.Fragment>
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.close(); }} > </div>
      <div className="popup_1">
        <div className="popup_1_in">  
        <div className="head"> Instructions For Safari browser
        <button className="close_1" onClick={(e) => { e.preventDefault(); props.close();}} > <img src={close_1} alt=""/> </button>
        </div>
        <div className="fd clr_ff p_15 text_center">
            <div className="fd" style={{height:"25px",display:"flex",alignItems:"center",margin:"1px"}}>1. Tap on<img src={share}  style={{height:"25px",width:"25px",margin:"0px 2px"}}></img>  in the Tab bar.</div>
            <div className="fd" style={{height:"40px",display:"flex",alignItems:"center",margin:"1px"}}>2. Scroll and Select <img src={ATH}  style={{height:"40px",width:"200px",margin:"0px 5px"}}></img></div>
            <div className="fd" style={{height:"40px",display:"flex",alignItems:"center",margin:"1px"}}>3. Give a name to your PWA And save at home screem.</div>
            <div className="fd" style={{height:"40px",display:"flex",alignItems:"center",margin:"1px"}}>4. Look for <img src={logo} style={{height:"25px",width:"25px",margin:"0px 5px"}}></img> on the home screen and click to open.</div>
        </div>
        </div>
    </div>
    </React.Fragment>
  );
}
export default withTranslation()(Instructions);
