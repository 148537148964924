import React from "react";
import "../../../../css/ui/popUps/alert.css";
import close_1 from '../../../../assets/images/table/close_1.svg';
import { withTranslation } from 'react-i18next'
function ExitAlert(props) {
    function exit(){
        props.network.send("<LeaveTable/>");
        props.setAction("hideExitAlert")
    }

    
  return (
    <React.Fragment>
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.setAction("hideExitAlert"); }} > </div>
      <div className="popup_1">
        <div className="popup_1_in">  
        <div className="head"> {props.t("Alert")}
        <button className="close_1" onClick={(e) => { e.preventDefault(); props.setAction("hideExitAlert"); }} > <img src={close_1}  alt=""/> </button>
        </div>
        <div className="fd clr_ff p_15 text_center">
            <div className="fd">{props.t("Are you sure")}, <br></br>{props.t("you want to leave the table")}</div>
        </div>
        <div className="fd">
          <ul className="footUl">
            <li>  
              <button className="btn_1" onClick={()=>{exit()}}>{props.t("Yes")}</button>
            </li>
            <li>
              <button className="btn_1"onClick={(e) => { e.preventDefault(); props.setAction("hideExitAlert"); }}>{props.t("No")}</button>
            </li>
          </ul>
        </div>
        </div>
        
    </div>
    </React.Fragment>
  );
}
export default withTranslation()(ExitAlert)