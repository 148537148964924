import "../../../../../css/ui/lobby/filterCheckBoxOne.css";
import { withTranslation } from 'react-i18next'
import Screen from "../../../../utils/screen";
import fileName from "../../../../../jsconfig";
function FilterCheckBoxTwo(props) {
  const style = {
    position: "absolute",
    left: "50vw",
    top: '10vh'
  }

  return (
    <div className="fd">
      {Screen.getDeviceType().name === "Desktop" && (
        <form action="/action_page.php" method="get" style={{ width: "max-content", alignItems: fileName.name === "Riverpoker" ? 'center' : '', justifyContent: fileName.name === "Riverpoker" ? 'center' : '', display: fileName.name === "Riverpoker" ? 'flex' : '' }}>
            <input type="checkbox" id="UpComingOnly" name="UpComingOnly" value="UpComingOnly" onClick={(event) => { props.action("Check2", event.target.value, event.target.checked) }} />
          <label className="switch" htmlFor="UpComingOnly">
            <span className="slider round"></span>
          <span style={{ marginLeft: '3px' }}>{props.t("Show Upcoming Only")}</span>
          </label>
        </form>)}
      {Screen.getDeviceType().name === "Mobile" && (
        <form action="/action_page.php" method="get" className="m_l_10 pointer df_ac font_12">
            <input className='checkbox-round' type="checkbox" id="UpComingOnly" name="UpComingOnly" value="UpComingOnly" onClick={(event) => { props.action("Check2", event.target.value, event.target.checked) }} />
          <label htmlFor="UpComingOnly">
            {props.t("Show Upcoming Only")}</label>
        </form>)}
    </div>
  );
}
export default withTranslation()(FilterCheckBoxTwo);