import React, { useMemo, useState, useEffect } from "react";
import "../../../../../css/ui/lobby/mainGrid.css";
import Columns from "./mainGridColumns";
import { useTable, useRowSelect, useSortBy } from "react-table";
import { withTranslation } from 'react-i18next'
import fileName from "../../../../../jsconfig";
import Screen from "../../../../utils/screen";
import { t } from "i18next";
export const MainGrid = (props) => {
    // const [Mytables, setMytables] = useState(true);
    const columns = useMemo(() => {
        if (Screen.getDeviceType().name === "Mobile") {
            return fileName.name === "Leader_bet" ? Columns.Columns_lb : Columns.Columns;
            // return Columns.Columns;
        } else if (Screen.getDeviceType().name === "Desktop") {
            return Columns.Columns1;
        }
    }, []);
    const data = useMemo(() => props.data, [props.data]);
    const table = useTable(
        {
            columns,
            data,
            // initialState: {
            //     sortBy: [
            //         {
            //             id: "stakes",
            //             desc: true,
            //         },
            //     ],
            // },
        },
        useSortBy,
        useRowSelect
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = table;

    const [prevRow, setPrevRow] = useState();

    useEffect(() => {
        if (Screen.getDeviceType().name === "Mobile" || (window.innerWidth >= 764 && window.innerWidth < 996)) {
            let r = document.getElementsByTagName("tr");
            for (var i = 0; i < r.length; i++) {
                let row = r[i];
                if (row.innerText.includes("No Table Matching Your Search Criteria")) {
                    // setMytables(false);
                } else {
                    // setMytables(true);
                }
                for (var j = 0; j < props.mytables.length; j++) {
                    if (row.innerText.includes(`${props.mytables[j].name}`)) {
                        row.classList.add("seated");
                    } else {
                        row.classList.remove("seated");
                    }
                }

                row.onclick = () => {
                    if (row.innerText.includes("Seats")) {
                        return;
                    }

                    if (prevRow !== undefined) {
                        // prevRow.style.backgroundColor = null;
                        prevRow.style.boxShadow = null;
                    }

                    setPrevRow(row);

                    if (row.dataset.id !== undefined) {
                        props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`);
                        props.network.send(`<OpenTable id="${row.dataset.id}" type="${row.dataset.type}"/>`);
                        row.style.boxShadow = (fileName.name === "Leader_bet" ? "#3ba14c 0px 0px 8px" : "rgb(60 162 200) 0px 0px 8px");
                        props.setAction("mainGridDblClick");
                        // props.setAction("TDpageJoinTable")
                    }
                };
            }
        }
        else if (Screen.getDeviceType().name === "Desktop") {
            // if((window.innerWidth > 810 && window.innerWidth < 1124)){

            // }else{
            let r = document.getElementsByTagName("tr");
            for (var i = 1; i < r.length; i++) {
                let row = r[i];
                for (var j = 0; j < props.mytables.length; j++) {
                    if (row.innerText.includes(`${props.mytables[j].name}`)) {
                        row.classList.add('seated')
                    }
                    else {
                        row.classList.remove('seated')
                    }
                }


                row.onclick = () => {
                    // window.scrollBy(0,550);
                    if (prevRow !== undefined) {
                        prevRow.style.backgroundColor = null;
                        prevRow.style.backgroundImage = null;
                        prevRow.style.boxShadow = null;
                        // row.style.fontWeight = "normal"
                    }
                    // row.style.backgroundImage = "linear-gradient(to right, rgb(60 162 200), rgb(3 5 12 / 46%), rgb(60 162 200))";
                    // row.style.boxShadow = "rgb(60 162 200) 0px 0px 8px";

                    if (fileName.name === "Riverpoker") {
                        row.style.backgroundImage = "linear-gradient(to right, rgb(0 83 192), rgba(3, 5, 12, 0.46), rgb(0, 83, 192))";
                        row.style.boxShadow = "rgb(0, 83, 139) 0px 0px 8px";
                        row.style.padding = "4px 0px";
                    } else {
                        row.style.backgroundImage = "linear-gradient(to right, rgb(201 55 68), rgb(3 5 12 / 46%), rgb(201 55 68))";
                        row.style.boxShadow = "rgb(201 55 68) 0px 0px 8px";
                    }

                    setPrevRow(row);

                    if (row.dataset.id !== undefined) {
                        props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
                    }

                    console.log("am single clicked")
                    row.ondblclick = () => {
                        if (row.dataset.id !== undefined) {
                            props.network.send(`<OpenTable id="${row.dataset.id}" type="${row.dataset.type}"/>`);
                            props.setAction("mainGridDblClick");
                        }
                    }
                }

                // }
            }
        }

    });

    return (
        <div className={(fileName.name === "Leader_bet" && Screen.getDeviceType().name === "Mobile") ? 'mainGrid_lb mainGridOverflow' : 'mainGrid mainGridOverflow'} >
            <table id={(fileName.name === "Leader_bet" && Screen.getDeviceType().name === "Mobile") ? 'mainGrid_table_lb' : (fileName.name === "Riverpoker" && Screen.getDeviceType().name !== "Mobile") ? 'mainGrid_table_rv' : 'mainGrid_table'} {...getTableProps()}  >
                {Screen.getDeviceType().name === "Desktop" && (<thead className="maingridheader">
                    {headerGroups.map((headerGroup) => (
                        <tr className='p_0 bdrgold' {...headerGroup.getHeaderGroupProps()} style={{ border: fileName.name === "Riverpoker" ? "1px solid #346ab0" : "" }}>
                            {headerGroup.headers.map((column, i) => (
                                <th index={i} className={Screen.getDeviceType().name === "Desktop" ? i === 0 ? "col-3" : "col-2" : ""} {...column.getHeaderProps()} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {t(column.render("Header"))}
                                    {column.isSorted ? ((column.isSortedDesc ? "  ▼ " : "  ▲")) : ""}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>)}
                <tbody {...getTableBodyProps()} style={{ background: fileName.name === "Riverpoker" ? '' : "#000" }} key={"tbody"}>
                    {rows.map((row) => {
                        prepareRow(row);
                        if (row.values.name !== "No Table Matching Your Search Criteria") {
                            return (
                                <tr key={row.original.id} data-id={row.original.id} data-type={row.original.type} data-password={row.original.password} data-runtwice={row.original.runtwice} data-jackpot={row.original.jackpot} {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <td index={i} className={Screen.getDeviceType().name === "Desktop" ? i === 0 ? "col-3" : "col-2" : ""} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            </React.Fragment>
                                        );
                                    })}
                                </tr>
                            );
                        }
                        else {
                            return (
                                <tr style={{ display: "contents" }} key={"NOtables"}>
                                    {Screen.getDeviceType().name === "Desktop" && (<td key={"NOtables"} style={{ rotate: "0deg", display: "flex", justifyContent: "center", color: "#d6ba6d" }}>
                                        <div className="NOtables" key={"NOtables"} style={{ padding: fileName.name === "Riverpoker" ? '0px' : '', boxShadow: fileName.name === "Riverpoker" ? 'none' : '', height: fileName.name === "Riverpoker" ? 'fit-content' : '' }}>
                                            {t("No Table Matching Your Search Criteria")}
                                        </div>
                                    </td>)}
                                    {Screen.getDeviceType().name === "Mobile" && (<td key={"NOtables"} style={{ borderRadius: "26px", rotate: "0deg", }}>
                                        <div className="NOtables" key={"NOtables"} >
                                            {t("No Table Matching Your Search Criteria")}
                                        </div>
                                    </td>)}

                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
            {/* <div className="NOtables" style={{ display: `${Mytables ? "block" : "none"}` }}>
                No Table Matching Your Search Criteria
            </div> */}
        </div>
    );
};
export default withTranslation()
