const Columns = [
  {
    Header: "Game",
    accessor: "game",
  },

  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },

  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "Players",
    accessor: "players",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "breakTime",
    accessor: "breakTime"
  }
]

const Columns_lb = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "breakTime",
    accessor: "breakTime"
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Game",
    accessor: "game",
  },
  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "state",
    accessor: "stateImg",
  }
]


const Columns1 = [
  {
    Header: "Game",
    accessor: "game",
  },

  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Date",
    accessor: "date",
  },

  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "Players",
    accessor: "players",
  },
  {
    Header: "Status",
    accessor: "status",
  },

]
const Columns2 = [
  {
    Header: "Date",
    accessor: "date",
  },


  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Game",
    accessor: "game",
  },

  {
    Header: "BuyIn",
    accessor: "buyIn",
  },
  {
    Header: "Players",
    accessor: "players",
  },
  {
    Header: "Status",
    accessor: "status",
  },

]
export default { Columns, Columns1, Columns2, Columns_lb };