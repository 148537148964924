

function GameName(type) {
    switch (type) {
        case "TEXAS_HOLDEM_LIMIT":
            return "FL Hold'em";
        case "TEXAS_HOLDEM_NO_LIMIT":
            return "NL Hold'em";
        case "TEXAS_HOLDEM_POT_LIMIT":
            return "PL Hold'em";
        case "OMAHA_LIMIT":
            return "FL Omaha";
        case "OMAHA_NO_LIMIT":
            return "NL Omaha";
        case "OMAHA_POT_LIMIT":
            return "PL Omaha";
        case "OMAHA_FIVE_CARDS_LIMIT":
            return "FL Omaha 5card";
        case "OMAHA_FIVE_CARDS_NO_LIMIT":
            return "NL Omaha 5card";
        case "OMAHA_FIVE_CARDS_POT_LIMIT":
            return "PL Omaha 5card";
        case "OMAHA_SIX_CARDS_LIMIT":
            return "FL Omaha 6card";
        case "OMAHA_SIX_CARDS_NO_LIMIT":
            return "NL Omaha 6card";
        case "OMAHA_SIX_CARDS_POT_LIMIT":
            return "PL Omaha 6card";
        case "OMAHA_HIGH_LOW_POT_LIMIT":
            return "PL Omaha Hi-Lo";
        default:
            return type;
    }
}
function cardsLength(type) {
    switch (type) {
        case "TEXAS_HOLDEM_LIMIT":
            return 2;
        case "TEXAS_HOLDEM_NO_LIMIT":
            return 2;
        case "TEXAS_HOLDEM_POT_LIMIT":
            return 2;
        case "OMAHA_LIMIT":
            return 4;
        case "OMAHA_NO_LIMIT":
            return 4;
        case "OMAHA_POT_LIMIT":
            return 4;
        case "OMAHA_FIVE_CARDS_LIMIT":
            return 5;
        case "OMAHA_FIVE_CARDS_NO_LIMIT":
            return 5;
        case "OMAHA_FIVE_CARDS_POT_LIMIT":
            return 5;
        case "OMAHA_SIX_CARDS_LIMIT":
            return 6;
        case "OMAHA_SIX_CARDS_NO_LIMIT":
            return 6;
        case "OMAHA_SIX_CARDS_POT_LIMIT":
            return 6;
        case "OMAHA_HIGH_LOW_POT_LIMIT":
            return 4;
        // default:
        //     return type;
    }
}

const numberWithCommas = (x) => {
    const number = parseFloat(x);
    if (isNaN(number)) return x;

    const hasDecimalPoint = x.toString().includes(".");
    const formattedNumber = number.toFixed(hasDecimalPoint ? 2 : 0);

    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const UM = { GameName, cardsLength, numberWithCommas }

export default UM;