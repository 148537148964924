import XMLParser from "../utils/xmlParser";
import Config from "../../config";
let ex = ['<GetPlayerInfo/>', '<GetMyTables/>', '<GetServerTime/>', '<GetBadBeatJackpot/>', '<GetPlayers/>', '<Ping/>', '<GetServerInfo/>']
export default class Network {
    constructor(props) {

        this.socketName = "";
        this.count = 0;
        this.state_firstConnection = true;
        this.queue = [];
        this.count_reconnect = 0;
        this.reConnect = true;
    }
    initNetwork(socketName, callBack) {
        let config = new Config();
        this.callBack = callBack;
        this.socketName = socketName;
        this.url = config.URL_Environment.webSocket.socketUrl;
        this._xmlParser = new XMLParser();
        this.connection();
    }
    connection() {
        this.count_reconnect++;
        var connectRandom = Math.floor(Math.random() * (200 - 1 + 1) + 1);
        if (this.count_reconnect < 6) {
            setTimeout(() => {
                if (this._connection) {
                    this.cleanupListeners(this.connection);
                }
                console.log("===========connecting to socket============" + this.count_reconnect);
                this._connection = new WebSocket(this.url);
                this._connection.addEventListener("open", this.connectEventHandler.bind(this));
                this._connection.addEventListener("close", this.closeEventHandler.bind(this));
                this._connection.addEventListener("error", this.errorEventHandler.bind(this));
                this._connection.addEventListener("message", this.dataEventHandler.bind(this));
            }, 1000 * this.count_reconnect * this.count_reconnect + connectRandom);
        } else {
            alert.lineOne = "Unable to connect";
            alert.lineTwo = "Please relogin 🤔";
            setTimeout(() => {
                console.log("clearing storage");
                sessionStorage.clear();
                console.log("Reloading the window");
                window.location.reload();
            }, 3000);
        }
    }

    close(reConnect) {
        this.reConnect = reConnect;
        this.cleanupListeners(this._connection);
    }

    cleanupListeners(connectionObj) {
        try {
            if (connectionObj) {
                connectionObj.removeEventListener("open", this.connectEventHandler.bind(this));
                connectionObj.removeEventListener("close", this.closeEventHandler.bind(this));
                connectionObj.removeEventListener("error", this.errorEventHandler.bind(this));
                connectionObj.removeEventListener("message", this.dataEventHandler.bind(this));
                this._connection.close();
            }
        } catch (e) { console.log(e) }
    }
    connectEventHandler(event) {
        console.log("cb", this.callBack);
        if (this.state_firstConnection) {
            this.state_firstConnection = false;
            this.callBack["open"](event);
        } else {
            this.callBack["reConnect"](event);

        }
        // window.onblur = function () {
        //     // output.innerHTML += "browser tab is changed </br>";
        //     console.log("browser is inactive")
        //  };

        //  // If users come back to the current tab again, the below function will invoke
        //  window.onfocus = function () {
        //     // output.innerHTML += "Browser tab is again active! </br>";
        //     console.log("browser is active")
        //  };
    }
    closeEventHandler(event) {
        console.log("===========Connection Closed===========");
        this.callBack["close"](event, this.reConnect);
        if (!this.reConnect) return;

        // which is better, reconnect from here of triggered by the user class 
        setTimeout(() => {
            this.connection();
        }, 2000);
    }
    errorEventHandler(event) {
        if (this._connection.OPEN === 1) {
        } else {
            console.log("initiate connection");
            this.callBack["error"](event);
        }
    }
    // reConnect() {
    //     this.connection();
    // }
    dataEventHandler(event) {
        this.count_reconnect = 0;
        this.queue.push(event);
        setTimeout(() => {
            let first = this.queue.shift();
            this.parseIntoJSON(first);
        }, 100);
    }
    parseIntoJSON(event) {
        if (event.data) {
            let jsonData = this._xmlParser.parseXML(event.data);

            this.callBack["message"](jsonData);
        }
    }

    send(request) {
        if (this._connection !== undefined) {
            if (this._connection.readyState === 1) {
                // if(!ex.includes(request.toString())) {
                //     console.log(`========== sending on ${this.socketName} socket ==========`,request.toString() );
                // }
                // console.log(`========== sending on ${this.socketName} socket ==========`,request.toString() );
                this._connection.send(request.toString());
            } else {
                console.log(`========== ${this.socketName} socket dead ==========`);
            }
        }
    }
}
