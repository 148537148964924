import React from "react";
import { Circle, Group, Image, Layer, Stage } from 'react-konva';

export class PlayerAvtar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      x: 75,
      y: 75
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.avtar !== this.props.avtar) {
      this.loadImage();
    }
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.removeEventListener('load', this.handleLoad);
    }
  }

  getPlayersAvatars(playerAvatar) {
    if (playerAvatar) {
      if (playerAvatar.indexOf("data:image/png;base64,") !== -1) {
        let base64Data = playerAvatar.split("data:image/png;base64,")[1];
        return base64Data;
      } else {
        const lastThreeChars = playerAvatar.slice(-3);
        const totalAvatars = this.props.setAvatars;

        for (const avatar of totalAvatars) {
          if (avatar.id.endsWith(lastThreeChars)) {
            return avatar.imageData;
          }
        }
      }
    }
    return null;
  }


  loadImage() {
    if (this.image) {
      this.image.removeEventListener('load', this.handleLoad);
    }

    this.image = new window.Image();
    const avatarSrc = `data:image/jpeg;base64,${this.getPlayersAvatars(this.props.avtar)}`;
    if (avatarSrc) {
      this.image.src = avatarSrc;
    } else {
      console.error("Avatar image source not found");
      return;
    }

    this.image.addEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    this.setState({ image: this.image });
  };

  render() {
    const { x, y, width, height } = this.props;
    const radius=Math.min(width, height) / 2
    return (
      //   <Image
      //   cornerRadius={Math.min(width, height) / 2}
      //   x={x}
      //   style={{borderRadius:"50%"}}
      //   y={y}
      //   width={width}
      //   height={height}
      //   image={this.state.image}
      //   ref={node => { this.imageNode = node; }}
      // />
      /*================================= Circle Avatar Start ==========================================*/
      <Circle
      x={x + radius}
      y={y + radius}
      radius={radius}
      fillPatternImage={this.state.image}
      stroke="#475069bf"
      strokeWidth="5"
      fillPatternOffset={{ x: x+3, y: y-7 }}
      />
      /*================================= Circle Avatar End ==========================================*/
    );
  }
}
