import "../../../../../css/ui/table/cDtimer.css";
import gameTB from "../../../../../assets/images/table/game_timebank.png"
import timerIcon from "../../../../../assets/images/table/timerIcon.svg"
import fileName from "../../../../../jsconfig";
import Screen from "../../../../utils/screen";
export const CountDownTimer = (props) => {
  // const style = {
  //   width: '42px',
  //   height: '42px',
  //   fill: 'none',
  //   strokeWidth: '21px',
  //   stroke: '#000000',
  //   strokeDasharray: '63 63',
  //   strokeDashoffset: `${63 * props.per}`,
  // }

  return (
    <div className={(fileName.name !== "Leader_bet" && Screen.getDeviceType().name == "Mobile") ? "countDownTimer" : "countDownTimer_lb"} style={{ marginBottom: (fileName.name === "Riverpoker" && Screen.getDeviceType().name != "Mobile") ? '38px' : '' }}>
      <div id="countdown">
        <img id="countdown-number" className="timerIcon" src={timerIcon} alt="Image not found" />
        <div id="countdown-number">{props.time}</div>
      </div>
    </div>
  );

}




