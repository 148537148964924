import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import "../../../../css/ui/popUps/findPlayer.css";
import closeIcon from '../../../../assets/images/lobby/close_icon.svg';
import close_1 from '../../../../assets/images/table/close_1.svg';

import { withTranslation } from 'react-i18next';

export const Findplayer = (props) => {
  const [btnState, setBtnState] = useState(true);
  const [tableId, setTableId] = useState(undefined);
  const [tableType, setTableType] = useState(undefined);
  const [msg, setMsg] = useState("");
  const columns = useMemo(() => {
    return [
      {
        Header: props.t("Name"),
        accessor: "name",
      },
      {
        Header: props.t("Game"),
        accessor: "game",
      },
      {
        Header: props.t("Stakes"),
        accessor: "stakes",
        Cell: row => <span style={{ textAlign: "right", visibility: row.value !== undefined ? 'visible' : 'hidden' }}>{Number(row.value) ? Number(row.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : row.value}</span>
      },
      {
        Header: props.t("Seats"),
        accessor: "seats",
      },
      {
        Header: props.t("Wait"),
        accessor: "wait",
      },
      {
        Header: props.t("P/F"),
        accessor: "pf",
      },
    ];
  }, []);
  const data = useMemo(() => props.data, [props.data]);


  const tableInstance = useTable({
    columns,
    data,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const [prevRow, setPrevRow] = useState();

  useEffect(() => {
    let r = document.getElementsByTagName("tr");
    // console.log(r);
    for (var i = 0; i < r.length; i++) {
      let row = r[i];
      row.onclick = () => {
        if (prevRow !== undefined) {
          // prevRow.style.backgroundColor = null;
          prevRow.style.boxShadow = null;
        }
        // console.log(row.dataset.id +",,..,,"+row.dataset.type);
        // console.log(row.innerText);
        row.style.backgroundColor = "#0e5c63";
        setPrevRow(row);

        if (row.dataset.id !== undefined) {
          // console.log("open table with id: " + row.dataset.id)
          //   props.network.send(`<GetTableDetails id="${row.dataset.id}" type="${row.dataset.type}"/>`)
          setBtnState(false);
          setTableId(row.dataset.id);
          setTableType(row.dataset.type);
        }
      };
      row.ondblclick = () => {
        console.log("am double clicked");
        // console.log(row.innerText);
      };
    }
  });
  return (
    <React.Fragment>
      <div className="popCover_1" onClick={(e) => { e.preventDefault(); props.setAction("hideFindPlayer"); }}> </div>
      <div className="popup_1">
        <div className="popup_1_in">
          <div className="head"> {props.t("Find Player")}
            <button className="close_1" onClick={(e) => { e.preventDefault(); props.setAction("hideFindPlayer"); }}> <img src={close_1} alt="" /> </button>
          </div>
          <div className="fd inpElm p_15 extra1">
            <div className="findPlr fd ">
              <input type="text" id="findPlayerNickName" name="search" placeholder={props.t("User nickname")} style={{ width: '80%' }} />
              <button
                className="btn_1 rds_10"
                onClick={(e) => {
                  e.preventDefault();
                  setMsg("");
                  let element = document.getElementById('findPlayerNickName');
                  let name = element.value.toUpperCase();
                  if (name) {
                    props.network.send(`<PlayerSearch nickname="${name}"/>`);
                  } else {
                    // console.log("Please enter Player NickName");
                    setMsg("Please Enter Player Name To Search");
                  }
                }}
              >
                {props.t("Find")}
              </button>
            </div>
          </div>
          <div className="findPlayerError fd">{msg}</div>
          <div className="fd">
            <table className="table_1" {...getTableProps()} id="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th className="col-2" key={column.render("Header")}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  if (row.values.name !== "Player Not Found") {
                    return (
                      <tr data-id={row.original.id} data-type={row.original.type} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  } else {
                    return (<tr>
                      <td colSpan={6} style={{ textAlign: "center" }}>{row.values.name}</td>
                    </tr>)
                  }
                })}
              </tbody>
            </table>
          </div>
          <footer className="fd p_5">
            <button
              className="btn_1 wid_auto float_right"
              disabled={btnState}
              onClick={(e) => {
                e.preventDefault();
                if (tableId !== undefined) {
                  props.network.send("<OpenTable id='" + tableId + "' type='" + tableType + "'/>");
                }
                setTableId(undefined);
                setTableType(undefined);
                setBtnState(true);
              }}
            >
              {props.t("Join Table")}
            </button>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(Findplayer);
