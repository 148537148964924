import React from "react";
import { Rect, Text, Group, Image, Circle, Line, Star } from "react-konva";
import { Timer } from "./timer";
import { PlayerAvtar } from "./playerAvtar";
import Cards from "./cards/cards";
import timerTicker from "../../../../../assets/audio/TimeWarningFlash.wav";
import clockTick from "../../../../../assets/audio/MetronomeFlash.wav";
// import claps from "../../../../../assets/audio/clap.mp3";
import flodCar from "../../../../../assets/audio/FoldFlash.wav";
import collectChips from "../../../../../assets/audio/Chips.wav";
import defaultAvtarO from "../../../../../assets/images/lobby/avtar/avtar_icon8.png";
import profileAvtar from "../../../../../assets/images/table/profileImg.svg";
// import profilePanel from "../../../../../assets/images/table/Rectangle.svg";
import profilePanelTwo from "../../../../../assets/images/table/Rectangle.svg";
import profilePanelThree from "../../../../../assets/images/table/Rectanglethree.svg";
import lbplayerSeat from "../../../../../assets/images/table/lb_playerSeat.png";
import lb_winAnim from "../../../../../assets/images/table/lb_winAnim.png";
import chat_btn from '../../../../../assets/images/table/chat_btn.png';
import Config from "../../../../../config";
import fileName from "../../../../../jsconfig";
import Screen from '../../../../utils/screen'
import { t } from "i18next";
// import Spritegif from "../animation/spriteGif";
import Spritegif from "../animation/spritegif";
import { duration } from "moment";
import UM from "../../../../utils/utilityMethods";

const apiPath = {
    getAvtr: "/api/player/getAvatar",
};
export default class Seat extends React.Component {
    constructor(props) {
        super(props);
        // console.log(this.props.avatarsList)
        this.x = 200;
        this.y = 20;
        this.onNewPlayer = this.onNewPlayer.bind(this);
        this.state = {
            calltimetable: { Time: 0, hide_show: false },
            showKnockOutBounty: false,
            toggleseat: true,
            ratio: 1,
            knockoutBounty: 0,
            hidecardRatio: 0,
            name: "Take Seat",
            enableTwoX: null,
            chips: "",
            proImage: null,
            chat: null,
            proLable: null,
            proLable1: null,
            noofBB: '',
            action: "",
            handStrength: "",
            color: "#167a2f",
            ritcolor: "#ffe111",
            alpha: 1,
            showTimer: false,
            timerValue: 0,
            avtar: profileAvtar,
            mySeatId: '',
            showSeatMenu: false,
            showChat: false,
            showAddbuddy: true,
            showAction: false,
            chatText: "",
            showAvtar: false,
            seatGread: "#0005",
            seatMenuColorOne: "#000000",
            seatMenuColorTwo: "#000000",
            seatMenuColorThree: "#000000",
            animateScale: 1,
            showWinAnima: false,
            toggleBB: false,
            activeSeats: 0,
            playersAvatars: "",
            level: -1,
            winpercent: ""
        };
        this.config = new Config();

        this.timerTicker = new Audio(timerTicker);
        this.timerTicker.volume = 0.05;

        this.clockTick = new Audio(clockTick);
        this.clockTick.volume = 0.05;
        this.clockTick.loop = true;

        // this.claps = new Audio(claps);
        this.flodCar = new Audio(flodCar);
        this.collectChips = new Audio(collectChips);
        this.cardsRef = React.createRef();
    }
    state = {
        color: "green",
    };
    handleClick = (id) => {
        if (this.state.mySeatId !== "") {
            if (Number(this.state.mySeatId) === Number(id)) {
                if (this.state.action === "Fold") {
                    this.cardsRef.current.showFoldCards();
                }
            } else {
                this.props.showSeatAlert("Seat is occupied");
            }
        } else {
            this.cardsRef.current.initSounds();
            this.props.initSounds();
            this.timerTicker = new Audio(timerTicker);
            this.timerTicker.volume = 0.05;

            this.clockTick = new Audio(clockTick)
            this.clockTick.volume = 0.05;
            this.clockTick.loop = true;

            // this.claps = new Audio(claps);
            // this.flodCar = new Audio(flodCar);
            this.collectChips = new Audio(collectChips);

            // this.getAvtarforSeat();
            this.props.sendSeat(id);
            if (!this.props.changetourno_sitandgo) {
                this.setState({ showSeatMenu: true })
                setTimeout(() => {
                    this.setState({ showSeatMenu: false });
                }, 2000);
            }
            this.props.network.send(`<TakeSeat seat="${id}"/>`);
            this.setState({ showAddbuddy: false });
            console.log(id)
            setTimeout(() => {
                // fixed position 2  for 6 seater 
                // this.props.RearrangeTakeSeat(2);
            }, 1000);
        }
    };

    // getAvtarforSeat() {

    // var path2 = fetch( this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.getAvtr_Api, {
    //     mode: "no-cors",
    //     method: "GET",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    // });

    // // var path2 = 'https://riv1.czargaming.com/api/player/getAvatar';
    // var xhr = new XMLHttpRequest();
    // xhr.open("GET", path2, true);
    // xhr.setRequestHeader("Content-Type", "application/json");
    // xhr.setRequestHeader("siteid", "riv3");
    // xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
    // xhr.addEventListener("load", (e) => {
    //     this.avtrDataHandler(e);
    // });
    // xhr.send();
    // }
    getAvtarforSeat() {
        var path2 = this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.getAvtr_Api;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", path2, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("siteid", "prode");
        xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
        // xhr.addEventListener("load", (e) => { this.avtrDataHandler(e) });
        xhr.send();
    }
    componentDidMount() {
        // setTimeout(() => {
        this.loadImage();
        // this.getAvtarforSeat();
        // }, 1500);
    }
    componentWillUnmount() {
        clearTimeout(this.clearRunItOnce);
        this.proImage.removeEventListener("load", this.handleLoad);
        this.proLable.removeEventListener("load", this.handleLoad);
        this.proLable1.removeEventListener("load", this.handleLoad);
        this.chat.removeEventListener("load", this.handleLoad);
        // clearInterval(this.seatTimerInterval_CallTimeTable)
        // clearInterval(this.clearRunItOnce)
        // clearInterval(this.seatTimerInterval);

        // this.setState({ timerValue: '', showTimer: false,calltimetable: { Time: 0, hide_show: false } });



    }
    loadImage() {
        this.proImage = new window.Image();
        this.chat = new window.Image();
        this.proImage.src = profileAvtar;
        this.chat.src = chat_btn
        this.proImage.addEventListener("load", this.handleLoad);


        this.proLable = new window.Image();
        this.proLable1 = new window.Image();
        this.winAnim = new window.Image();
        // if (this.state.showKnockOutBounty === true) {
        //     console.log("this seat is KOB")
        //     this.proLable.src = profilePanelThree;
        // } else {
        //     console.log("this seat is not KOB")
        //     this.proLable.src = profilePanelTwo;
        // }

        this.proLable.src = (fileName.name === "Leader_bet" ? lbplayerSeat : profilePanelTwo);
        if (fileName.name === "Leader_bet") {
            this.winAnim.src = lb_winAnim;
        }
        this.proLable1.src = profilePanelThree;
        // this.proLable = new window.Image();
        // this.proLable.src = profilePanel;
        this.proLable.addEventListener("load", this.handleLoad);
        this.proLable1.addEventListener("load", this.handleLoad);
        this.winAnim.addEventListener("load", this.handleLoad);
        this.chat.addEventListener("load", this.handleLoad);
    }
    handleLoad = () => {
        this.setState({
            proImage: this.proImage,
            proLable: this.proLable,
            winAnim: this.winAnim,
            proLable1: this.proLable1,
            chat: this.chat
        });
    };

    avtrDataHandler(data) {
        const myAvatr = `data:image/jpeg;base64,${((data.target.response.split(",")[2]).split(":")[1]).slice(1, -2)}`;
        // console.log(myAvatr)
        // const myAvatr = `data:image/jpeg;base64,${data.target.response}`;
        this.setState({ avtar: myAvatr });
        this.setState({ showAvtar: true });
    }
    handleOver = (e) => {
        this.setState({ showSeatMenu: true });
        setTimeout(() => {
            this.setState({ showSeatMenu: false });
        }, 2000);
    };
    ShowMenu() {
        this.setState({ showSeatMenu: true });
        setTimeout(() => {
            this.setState({ showSeatMenu: false });
        }, 2000);
    }
    handleOut = () => {
        clearTimeout(this.seatMenuTime);
    };

    enableTwoX(data) {
        this.setState({ enableTwoX: data })
    }
    onNewPlayer(data, knockValue) {
        // knockoutBounty
        this.setState({ showKnockOutBounty: knockValue })
        this.setState({ name: data.name, chips: data.chips, knockoutBounty: data.knockoutBounty, action: data.action, showAction: false, noofBB: data.NoofBB, level: data.level });

        if (data.hasOwnProperty("avatar")) {
            // this.setState({ playersAvatars: data.avatar === "" ? profileAvtar : data.avatar });
            this.setState({ playersAvatars: data.avatar === "" ? profileAvtar : data.avatar, showAvtar: true });
        } else {
            this.setState({ playersAvatars: "", showAvtar: false });
        }



        switch (data.status) {
            case "takenActive":
                this.setState({ color: "#167a2f", seatGread: "#0f1b27", enableTwoX: data.enableTwoX, toggleseat: false });
                this.setState({ alpha: 1 });
                this.setState({ avtar: defaultAvtarO, mySeatId: data.me.id });
                console.log("data.chips", "     ", data.chips)
                break;
            case "readyToTake":
                this.setState({ color: "#167a2f", mySeatId: '', hide_show: false, toggleseat: true });
                this.setState({ alpha: 1 });
                this.setState({ calltimetable: { Time: 0, hide_show: false, } })
                clearInterval(this.seatTimerInterval_CallTimeTable)
                break;
            case "takenInactive":
                this.setState({ toggleseat: false });
                this.setState({ color: "#167a2f", mySeatId: '', enableTwoX: null });
                this.setState({ alpha: 0.6 });
                this.setState({ calltimetable: { Time: 0, hide_show: false } })
                clearInterval(this.seatTimerInterval_CallTimeTable)
                break;
            case "onlyMe":
                this.setState({ color: "#167a2f", mySeatId: '', enableTwoX: null });
                this.setState({ calltimetable: { Time: 0, hide_show: false, toggleseat: false } })
                this.setState({ alpha: 0.6 });
                clearInterval(this.seatTimerInterval_CallTimeTable)
                break;
            default:
                this.setState({ calltimetable: { Time: 0, mySeatId: '', hide_show: false, toggleseat: true } })
                // this.setState({ calltimetable: { Time: 0, mySeatId: '', hide_show: false } })
                clearInterval(this.seatTimerInterval_CallTimeTable)
                break;
        }
    }

    onActiveSeats(data) {
        this.setState({ color: "#167a2f" });
        // if(data.onActiveSeats)
    }
    onChipsRebuy(data) {
        this.setState({ chips: this.roundToTwo(Number(this.state.chips) + Number(data.amount)) });
    }
    onPlayerAction(data) {
        this.setState({ ritcolor: "#ffe111" });
        if (data.action === "RunItTwice" || data.action === "RunItOnce") {
            this.clearRunItOnce = setTimeout(() => {
                this.setState({ action: "clear" });
            }, 6000);
        } else {
            this.clearRunItOnce = setTimeout(() => {
                this.setState({ action: "clear" });
            }, 3000);
        }
        switch (data.action) {
            case "SitIn":
                this.setState({ alpha: 1 });
                break;
            case "SitOut":
                this.setState({ alpha: 0.6 });
                break;
            case "PostSmallBlind":
                this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `${this.props.seatProperties.blindsTextOne} ${data.amount}` });
                break;
            case "PostBigBlind":
                this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `${this.props.seatProperties.blindsTextTwo} ${data.amount}` });
                break;
            case "PostThirdBlind":
                this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `TB ${data.amount}` });
                break;
            case "PostAnte":
                this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `` });
                break;
            case "Fold":
                this.setState({ action: "Fold" });
                // this.cardsRef.current.removeCards();
                this.cardsRef.current.foldCards();
                break;
            case "Check":
                this.setState({ action: "Check" });
                break;
            case "Raise":
                if (this.roundToTwo(Number(this.state.chips) - Number(data.amount)) === 0) {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `All-In` });
                } else {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `Raise` });
                }
                break;
            case "Call":
                if (this.roundToTwo(Number(this.state.chips) - Number(data.amount)) === 0) {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `All-In` });
                } else {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `Call` });
                }
                break;
            case "Bet":
                if (this.roundToTwo(Number(this.state.chips) - Number(data.amount)) === 0) {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `All-In` });
                } else {
                    this.setState({ chips: this.roundToTwo(Number(this.state.chips) - Number(data.amount)), action: `Bet` });
                }
                break;
            case "UncalledBet":
                this.setState({ chips: this.roundToTwo(Number(this.state.chips) + Number(data.amount)), action: ` ` });

                break;
            case "RunItOnce":
                this.setState({ action: "RIT once", ritcolor: "#ff0000" });
                break;
            case "RunItTwice":
                this.setState({ action: "RIT twice", ritcolor: "#00ff00" });
                break;
            case "":
                break;
            default:
                break;
        }
        this.setState({ chips: this.state.chips })
    }
    // handStrength(data) {
    //     if (data === undefined) {
    //         this.setState({ showAction: false, handStrength: "" })
    //     } else {
    //         this.setState({ showAction: true, handStrength: `${data}` })
    //     }

    // }
    // handStrengthCombination(data) {
    //     if (data === undefined) {
    //         this.setState({ showAction: false, handStrength: "" })
    //     } else {
    //         this.setState({ showAction: true, handStrength: `${data}` })
    //     }
    // }

    handStrength(data, winpercent) {
        if (data === undefined) {
            this.setState({ showAction: false, handStrength: "", winpercent: "" })
        } else {
            this.setState({ showAction: true, handStrength: `${data}`, winpercent: winpercent != undefined ? `${winpercent}%` : "" })
        }

    }
    handStrengthCombination(data, winpercent) {
        if (data === undefined || data === "") {
            this.setState({ showAction: false, handStrength: "", winpercent: "" })
        } else {
            this.setState({ showAction: true, handStrength: `${data}`, winpercent: winpercent != undefined ? `${winpercent}%` : "" })
        }
    }
    colorchange() {
        if (this.state.action === "Call") {
            return (fileName.name === "Leader_bet" ? "#000000e0" : "#136B05");
        } else if (this.state.action === "Raise") {
            return (fileName.name === "Leader_bet" ? "#000000e0" : "#096B95");
        } else if (this.state.action === "Fold") {
            return (fileName.name === "Leader_bet" ? "#000000e0" : "#980303");
        } else if (this.state.action === "RIT twice") {
            return ((fileName.name === "Leader_bet" ? "#000000e0" : (fileName.name === "Riverpoker" ? "#00ff00" : "#ff0000")));
        } else if (this.state.action === "RIT once") {
            return ((fileName.name === "Leader_bet" ? "#000000e0" : (fileName.name !== "Riverpoker" ? "#00ff00" : "#ff0000")));
        } else if (this.state.action === "clear") {
            return "#c7d0dd00";
        } else if (this.state.action === "Check") {
            return "#000000";
        } else if (this.state.action === "Bet") {
            return (fileName.name === "Leader_bet" ? "#000000e0" : "#ff0066");
        } else if (this.state.action === "All-In") {
            return (fileName.name === "Leader_bet" ? "#000000e0" : "#FF4500");
        }
    }
    messagechange() {
        if (this.state.action === "Call") {
            return this.state.action
        } else if (this.state.action === "Raise") {
            return this.state.action
        } else if (this.state.action === "Fold") {
            return this.state.action
        } else if (this.state.action === 'RIT twice') {
            return this.state.action
        } else if (this.state.action === 'RIT once') {
            return this.state.action
        } else if (this.state.action === 'Check') {
            return this.state.action
        } else if (this.state.action === 'Bet') {
            return this.state.action
        } else if (this.state.action === 'All-In') {
            return this.state.action
        }
    }
    colorchange1() {
        if (this.state.action === "Call") {
            return (fileName.name === "Leader_bet" ? "#47FF86" : "#12F402")
        } else if (this.state.action === "Raise") {
            return "#2A94E4"
        } else if (this.state.action === "Fold") {
            return "#FF0303"
        } else if (this.state.action === 'Check') {
            return "#4d4d4d"
        } else if (this.state.action === 'Bet') {
            return (fileName.name === "Leader_bet" ? "#00CEFF" : "#ff80b3")
        } else if (this.state.action === 'All-In') {
            return "#f97544"
        } else if (this.state.action === 'RIT twice') {
            return (fileName.name !== "Riverpker" ? "#00ff00" : "#ff0000")
        } else if (this.state.action === 'RIT once') {
            return (fileName.name !== "Riverpker" ? "#ff0000" : "#00ff00")
        }
    }
    changeTxtColor() {
        if (this.state.action === "Call") {
            return "#47FF86"
        } else if (this.state.action === "Raise") {
            return "#2A94E4"
        } else if (this.state.action === "Fold") {
            return "#FF0303"
        } else if (this.state.action === 'Check') {
            return "#ffff"
        } else if (this.state.action === 'Bet') {
            return "#00CEFF"
        } else if (this.state.action === 'All-In') {
            return "#f97544"
        } else if (this.state.action === 'RIT twice') {
            return (fileName.name !== "Riverpker" ? "#ff0000" : "#00ff00")
        } else if (this.state.action === 'RIT once') {
            return (fileName.name !== "Riverpker" ? "#00ff00" : "#ff0000")
        }
    }
    onWinner(amount, thisPlayer, id) {
        if (this.seatAnimateInterval !== undefined) {
            clearInterval(this.seatAnimateInterval);
        }
        if (thisPlayer === id) {
            // this.claps.play();
            try {
                this.flodCar.play();
                this.collectChips.play();
            } catch (e) { console.error(e.message) }
        }
        this.setState({ chips: this.roundToTwo(Number(this.state.chips) + Number(amount)) });
        this.winAnimation();
        this.setState({ showWinAnima: true });
    }
    winAnimation() {
        this.seat.to({
            scaleX: 1.15,
            scaleY: 1.15,
            duration: 0.1,
            onFinish: () => {
                this.seat.to({
                    scaleX: 1,
                    scaleY: 1,
                    onFinish: () => {
                        this.seat.to({
                            scaleX: 1.15,
                            scaleY: 1.15,
                            duration: 0.1,
                            onFinish: () => {
                                this.seat.to({
                                    scaleX: 1,
                                    scaleY: 1,
                                });
                                setTimeout(() => {
                                    this.setState({ showWinAnima: false });
                                }, 500);
                            },
                        });
                    },
                });
            },
        });
    }
    onWinnerHighlightCards(cards) {
        let winCards = [];
        let x = this.props.x;
        let y = this.props.y - this.props.seatProperties.cardY;
        let xPadding = this.props.seatProperties.cardY == 90 ? 25 : 18;

        if (!Array.isArray(cards)) {
            cards = [cards];
        }
        for (let i = 0; i < cards.length; i++) {
            try {
                winCards.push({ card: cards[i]["#text"], id: cards[i].attr.id, x: x + i * xPadding, y: y - 15 });
            } catch (e) { }
        }
        this.cardsRef.current.showWinningCombination(winCards);
    }

    startTimer(thisPlayer, id, min, max, duration) {
        // console.log(thisPlayer,"           ",id,"           ",min,"                ",max,"                  ",duration)
        this.setState({ showTimer: true });

        this.seatTimerInterval = setInterval(() => {
            if (min < max) {
                let per = (max - min) / duration;
                // console.log(per)
                this.setState({ timerValue: per });
                min = min + 1000;
            } else {
                this.stopTimer(id);
                if (thisPlayer === id) {
                    this.props.network.send("<TimedOut/>");
                } else {
                }
            }
        }, 1000);
    }
    CallTimeSeat(duration, isTimeForcedPaused, timeLeft, timedOut) {
        console.log("duration:", duration, "        isTimeForcedPaused:", isTimeForcedPaused, "              timeLeft:", timeLeft, "               timedOut", timedOut)
        if (duration === timeLeft) {
            clearInterval(this.seatTimerInterval_CallTimeTable)
            this.setState({ calltimetable: { Time: (timeLeft / 1000).toFixed(0), hide_show: true } })
        } else {
            if (timeLeft > 0 && isTimeForcedPaused === "false") {
                clearInterval(this.seatTimerInterval_CallTimeTable)
                this.seatTimerInterval_CallTimeTable = setInterval(() => {
                    let sec = (timeLeft / 1000).toFixed(0);
                    // console.log(sec)//here setState
                    if (sec > 0) {

                        this.setState({ calltimetable: { Time: sec, hide_show: true } })
                        timeLeft = timeLeft - 1000
                    } else {
                        this.setState({ calltimetable: { Time: sec, hide_show: true } })
                        timeLeft = 0
                    }
                }, 1000)

            } else if (timeLeft > 0 && isTimeForcedPaused === "true") {
                clearInterval(this.seatTimerInterval_CallTimeTable)
                this.setState({ calltimetable: { Time: (timeLeft / 1000).toFixed(0), hide_show: true } })
            } else if (timeLeft === '0' && isTimeForcedPaused === "false") {
                this.setState({ calltimetable: { hide_show: false } })
                clearInterval(this.seatTimerInterval_CallTimeTable)
            } else if (timeLeft === '-1' && isTimeForcedPaused === "false") {
                this.setState({ calltimetable: { hide_show: false } })
                clearInterval(this.seatTimerInterval_CallTimeTable)
            }
        }



    }
    clearTheTableSeats() {
        clearInterval(this.seatTimerInterval_CallTimeTable)
        clearInterval(this.clearRunItOnce)
        clearInterval(this.seatTimerInterval);

        this.setState({ timerValue: '', showTimer: false, calltimetable: { Time: 0, hide_show: false } });
    }
    initTimerSounds(seat) {
        this.timerTicker.play();
        this.clockTick.play();
    }
    stopTimer(id) {
        this.timerTicker.pause();
        this.clockTick.pause();
        if (this.seatTimerInterval !== undefined) {
            if (id !== undefined) {
                clearInterval(this.seatTimerInterval);
                this.setState({ timerValue: 0 });
                this.setState({ showTimer: false });
            }
        } else {
        }
    }
    onDetermineDealer(data, cnt) {
        this.cardsRef.current.addCards("cardZero", data["#text"], cnt);
    }
    onDealingCards(data, i) {
        let cnt = data.Cards.Card.length;
        // for (let i = 0; i < cnt; i++) {
        console.log("This is called from the seat.js file", data, i);
        var sessionData = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`));
        var encryptDecrypt = new window.EncryptDecrypt(128, 2, "566e4a61fd3220031fa17ebd846c8ec0", "c4c7712d6f62c4f8c40dd2248a029a8a");
        if (data.Cards.Card[i]["#text"].length > 2) {
            this.setState({ ratio: 1, hidecardRatio: 30 });
            var b1 = `${encryptDecrypt.decrypt(`${sessionData.sid}`, data.Cards.Card[i]["#text"])}`;
        } else {
            this.setState({ ratio: 2, hidecardRatio: 0 });
            var b1 = data.Cards.Card[i]["#text"];
        }
        switch (i) {
            case 0:
                this.cardsRef.current.addCards("cardZero", b1, cnt);
                break;
            case 1:
                this.cardsRef.current.addCards("cardOne", b1, cnt);
                break;
            case 2:
                this.cardsRef.current.addCards("cardTwo", b1, cnt);
                break;
            case 3:
                this.cardsRef.current.addCards("cardThree", b1, cnt);
                break;
            case 4:
                this.cardsRef.current.addCards("cardFour", b1, cnt);
                break;
            case 5:
                this.cardsRef.current.addCards("cardFive", b1, cnt);
                break;
            default:
                break;
        }
        // }
    }

    onDealingCardReplay(cnt, data) {
        const cards = data.cards[0].split(" ");
        var cnt = cards.length
        for (let i = 0; i < cnt; i++) {
            var b1 = cards[i];
            switch (i) {
                case 0:
                    this.cardsRef.current.addCards("cardZero", b1, cnt);
                    break;
                case 1:
                    this.cardsRef.current.addCards("cardOne", b1, cnt);
                    break;
                case 2:
                    this.cardsRef.current.addCards("cardTwo", b1, cnt);
                    break;
                case 3:
                    this.cardsRef.current.addCards("cardThree", b1, cnt);
                    break;
                case 4:
                    this.cardsRef.current.addCards("cardFour", b1, cnt);
                    break;
                case 5:
                    this.cardsRef.current.addCards("cardFive", b1, cnt);
                    break;
                default:
                    break;
            }
        }
    }
    onDealingCards_show(data) {
        let x = this.props.x;
        let y = this.props.y - this.props.seatProperties.cardY;
        // let xPadding = (this.props.seatProperties.cardY == 90) ? 25 : 18;
        this.setState({ ratio: 1, hidecardRatio: 30 });

        for (let i = 0; i < data.Cards.Card.length; i++) {
            switch (i) {
                case 0:
                    this.cardsRef.current.addCards_show("cardZero", data.Cards.Card[i]["#text"]);
                    break;
                case 1:
                    this.cardsRef.current.addCards_show("cardOne", data.Cards.Card[i]["#text"]);
                    break;
                case 2:
                    this.cardsRef.current.addCards_show("cardTwo", data.Cards.Card[i]["#text"]);
                    break;
                case 3:
                    this.cardsRef.current.addCards_show("cardThree", data.Cards.Card[i]["#text"]);
                    break;
                case 4:
                    this.cardsRef.current.addCards_show("cardFour", data.Cards.Card[i]["#text"]);
                    break;
                case 5:
                    this.cardsRef.current.addCards_show("cardFive", data.Cards.Card[i]["#text"]);
                    break;
                default:
                    break;
            }
        }
    }
    setCardStyle(style) {
        this.cardsRef.current.setCardStyle(style);
    }
    onEndHand() {
        this.cardsRef.current.removeCards();
        this.setState({ showAction: false, handStrength: "", action: "", winpercent: "" });
    }
    roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }
    onChatMessage(text) {
        this.setState({ showChat: true, chatText: text });
        setTimeout(() => {
            this.setState({ showChat: false, chatText: "" });
        }, 3000);
    }
    dividvalue = (data) => {
        // console.log(this.props.BigBlindValue)
        // return Math.floor(Number(data)/Number(this.props.bigshowhide.bigblindvalue))
        return Math.floor(Number(data) / Number(this.props.BigBlindValue))
    }
    // tostringvalue=(data)=>{
    //     return data.toLocaleString()
    // }

    render() {
        return (
            <Group>
                <Group>
                    <Group container="seat" x={this.props.x} y={this.props.y} width={this.props.seatProperties.panelWidth} height={this.props.seatProperties.panelHeight}>
                        <Group
                            visible={fileName.name === "Leader_bet" ? !this.state.toggleseat : true}
                            opacity={this.state.alpha}
                            onTap={() => {
                                this.handleClick(this.props.id);
                            }}
                            onClick={(e) => {
                                if (e.evt.button === 0) {

                                    this.handleClick(this.props.id);
                                }
                                if (e.evt.button === 2) {
                                    this.handleOver();
                                }
                            }}
                            onMouseOver={() => { }}
                            onMouseOut={() => { }}
                            scaleX={this.state.animateScale}
                            scaleY={this.state.animateScale}
                            ref={(node) => {
                                this.seat = node;
                            }}
                        >

                            {/* {this.state.playersAvatars === "" && <Image x={28} y={-88} width={70} height={70} image={this.state.proImage} />} */}






                            {/* {/ <-------------------------------------------------------------------star start----------------------------------------------------> /} */}



                            {(() => {
                                let i = 0;
                                this.imagescount = [];
                                if (this.state.level > -1) {
                                    for (i; i < 5; i++) {
                                        if (i < this.state.level + 1) {

                                            this.imagescount.push(
                                                <Star
                                                    key={i}
                                                    id={i}
                                                    x={45 + i * 9}
                                                    y={-98 - (i > 2 ? -(i - 2) * 2.5 : (i - 2) * 2.5)}
                                                    numPoints={5}
                                                    innerRadius={2}
                                                    outerRadius={4}
                                                    fill="#FFD700"
                                                    opacity={0.8}
                                                    draggable

                                                    shadowColor="black"
                                                    shadowBlur={10}
                                                    shadowOpacity={0.6}

                                                />


                                            );
                                        } else {
                                            this.imagescount.push(
                                                <Star
                                                    key={i}
                                                    id={i}
                                                    x={45 + i * 9}
                                                    y={-98 - (i > 2 ? -(i - 2) * 2.5 : (i - 2) * 2.5)}
                                                    numPoints={5}
                                                    innerRadius={2}
                                                    outerRadius={4}
                                                    fill="#686868"
                                                    opacity={0.8}
                                                    draggable

                                                    shadowColor="black"
                                                    shadowBlur={10}
                                                    shadowOpacity={0.6}

                                                />


                                            );
                                        }
                                    }
                                }
                                return this.imagescount;
                            })()}
                            {/* {/ <-------------------------------------------------------------------star end----------------------------------------------------> /} */}






                            <Image x={28} y={-88} width={70} height={70} image={this.state.proImage} />

                            {/* {this.state.showAvtar && <PlayerAvtar x={-this.props.seatProperties.profileRadius} y={-this.props.seatProperties.profileRadius} width={this.props.seatProperties.profileRadius * 2} height={this.props.seatProperties.profileRadius * 2} avtar={this.state.avtar}></PlayerAvtar>} */}
                            {/* <div className="test-div"> */}
                            {this.state.playersAvatars !== "" && <PlayerAvtar x={28} y={-88} width={70} height={70} avtar={this.state.playersAvatars} setAvatars={this.props.avatarsList}></PlayerAvtar>}
                            {/* </div> */}
                            {/* <PlayerAvtar x={-60} y={24} width={35} height={35} avtar={this.state.playersAvatars} setAvatars={this.props.avatarsList}></PlayerAvtar> */}
                            {/* {this.state.showAvtar &&<Circle  x={65} y={-53} width={70} height={70} stroke={"#606d84"} cornerRadius={5000} strokeWidth={12} ></Circle>} */}
                        </Group>
                    </Group>
                </Group>
                <Group>
                    {/* <Group>
                   <Spritegif></Spritegif>
                   </Group> */}
                    <Cards index={this.props.index} ActiveSeatCnt={this.props.ActiveSeatCnt} ref={this.cardsRef} x={this.props.x + 15} y={this.props.y - this.props.seatProperties.cardY - 12} xPadding={this.props.seatProperties.cardXpadding} scale={this.props.seatProperties.cardScale} ratioed={this.state.ratio} hidecardPos={this.state.hidecardRatio}></Cards>

                    <Group container="seat" x={this.props.x} y={this.props.y} width={this.props.seatProperties.panelWidth} height={this.props.seatProperties.panelHeight}>

                        {fileName.name === "Leader_bet" &&
                            <Group visible={this.state.toggleseat} x={this.props.seatProperties.padddings.x2 + 50} y={this.props.seatProperties.padddings.y2 - 40} ref={(node) => { this.seatprop = node }}
                                onMouseOver={(e) => {
                                    this.seatprop.children[0].to({ strokeWidth: 0 })
                                    this.seatprop.children[1].to({ fillLinearGradientColorStops: [0, '#069C6B', 1, '#046948'], strokeWidth: 0, opacity: 1 })
                                    this.seatprop.children[2].to({ strokeWidth: 0 })
                                    this.seatprop.children[3].to({ strokeWidth: 0 })
                                    this.seatprop.children[4].to({ fillLinearGradientColorStops: [0, '#191919', 1, '#191919'], })

                                }}
                                onMouseOut={() => {
                                    this.seatprop.children[0].to({ strokeWidth: 2 })
                                    this.seatprop.children[1].to({ fillLinearGradientColorStops: [0, '#2C2C2C', 1, '#2C2C2C'], strokeWidth: 2, opacity: 0.8 })
                                    this.seatprop.children[2].to({ strokeWidth: 2.5 })
                                    this.seatprop.children[3].to({ strokeWidth: 2.5 })
                                    this.seatprop.children[4].to({ fillLinearGradientColorStops: [0, '#069C6B', 1, '#069C6B'], })
                                }}
                                onMouseEnter={(e) => {
                                    // style stage container:
                                    const container = e.target.getStage().container();
                                    container.style.cursor = "pointer";
                                }}
                                onMouseLeave={(e) => {
                                    const container = e.target.getStage().container();
                                    container.style.cursor = "default"
                                }}
                                onTap={(e) => {
                                    this.handleClick(this.props.id);
                                }}
                                onClick={(e) => {
                                    if (e.evt.button === 0) {
                                        this.handleClick(this.props.id);
                                        // alert(this.props.id)
                                    }
                                    if (e.evt.button === 2) {
                                        this.handleOver();
                                    }
                                }}
                            >

                                <Circle
                                    radius={42}
                                    opacity={0.3}
                                    stroke={"#00000"}
                                    strokeBlur={0.5}
                                    strokeWidth={2}

                                    shadowColor={"#069C6B"}
                                    shadowWidth={10}
                                    shadowBlur={10}
                                    shadowOffset={{ x: -1, y: 1 }}
                                    shadowOpacity={1}

                                ></Circle>
                                <Circle
                                    radius={40}
                                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                                    fillLinearGradientColorStops={[0, '#2C2C2C', 1, '#161616']}
                                    opacity={0.8}
                                    stroke={"#3C3C3C"}
                                    strokeWidth={2}

                                ></Circle>
                                <Circle
                                    radius={32}

                                    opacity={0.3}
                                    shadowColor={"#2F2F2F"}
                                    stroke={"#000000"}
                                    strokeBlur={0.5}
                                    strokeWidth={2.5}
                                ></Circle>
                                <Circle
                                    radius={30}

                                    opacity={0.6}
                                    shadowColor={"#2F2F2F"}
                                    stroke={"#535353"}
                                    strokeWidth={2.5}
                                ></Circle>
                                <Line
                                    points={[-17, -10, 17, -10, -0, 10]}
                                    tension={0.1}
                                    closed
                                    fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                                    fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                                    fillLinearGradientColorStops={[0, '#069C6B', 1, '#069C6B']}
                                    shadowColor={"#069C6B"}
                                    shadowWidth={10}
                                    shadowBlur={10}
                                    shadowOffset={{ x: -1, y: 1 }}
                                    shadowOpacity={1}
                                />
                            </Group>
                        }
                        {/* <Group x={this.props.seatProperties.padddings.x2 + 30} y={this.props.seatProperties.padddings.y2 - 20}>
                            <Line
                                points={[0, 0, 100, 0, 90, 40, 10, 40]}
                                tension={0.1}
                                // cornerRadius={[5, 5, 5, 5]}
                                closed
                                fillLinearGradientStartPoint={{ x: 50, y: 0 }}
                                fillLinearGradientEndPoint={{ x: 50, y: 40 }}
                                fillLinearGradientColorStops={[0, '#2C2C2C', 1, '#161616']}
                                // shadowColor={"#069C6B"}
                                // shadowWidth={10}
                                // shadowBlur={5}
                                // shadowOffset={{ x: 0, y: 0 }}
                                // shadowOpacity={1}
                            />
                            <Line points={[5, 19, 95, 19, 96, 21, 6, 21]}
                                closed
                                fillLinearGradientStartPoint={{ x: 0, y: -0.5 }}
                                fillLinearGradientEndPoint={{ x: 90, y: 0.5 }}
                                fillLinearGradientColorStops={[0.20, '#282828', 0.50, '#353535', 0.80, '#353535', 1, '#282828']}
                            />
                        </Group> */}

                        <Group
                            visible={fileName.name == "Leader_bet" ? !this.state.toggleseat : true}
                            opacity={this.state.alpha}
                            onTap={(e) => {
                                this.handleClick(this.props.id);
                            }}
                            onClick={(e) => {
                                if (e.evt.button === 0) {
                                    this.handleClick(this.props.id);
                                    // alert(this.props.id)
                                }
                                if (e.evt.button === 2) {
                                    this.handleOver();
                                }
                            }}
                            onMouseOver={() => { }}
                            onMouseOut={() => { }}
                            scaleX={this.state.animateScale}
                            scaleY={this.state.animateScale}
                            ref={(node) => {
                                this.seat = node;
                            }}
                        >
                            {!this.state.showKnockOutBounty && <Image x={8} y={-30} width={110} height={50} image={this.state.proLable} />}
                            {this.state.showKnockOutBounty && <Image x={8} y={-30} width={110} height={70} image={this.state.proLable1} />}

                            <Image x={-60} y={-160} width={250} height={250} image={this.state.winAnim} visible={this.state.showWinAnima} />

                            {/* <Circle x={this.props.seatProperties.padddings.x2 + 90} stroke={this.state.enableTwoX == null ? '' : this.state.enableTwoX ? "#3CA2C8" : ""} y={this.props.seatProperties.padddings.y2 - 22} radius={12} fill={this.state.enableTwoX == null ? '' : this.state.enableTwoX ? "#161A1D" : ""} /> */}
                            <Text x={this.props.seatProperties.padddings.x2 + 75} y={this.props.seatProperties.padddings.y2 - 5} fontStyle={'bold'} fill={"#FF0000"} fontSize={13} text={this.state.enableTwoX == null ? '' : this.state.enableTwoX ? "2X" : ""} />
                            <Text x={this.props.seatProperties.padddings.x2 + 3} y={this.state.showKnockOutBounty ? -this.props.seatProperties.padddings.y2 - 16 : -this.props.seatProperties.padddings.y2 - 14}
                                width={this.props.seatProperties.width + 8} height={this.props.seatProperties.height} align={"center"}
                                verticalAlign={"middle"} text={this.state.name} fontSize={14} fontFamily={"Roboto"}
                                fill={(fileName.name === "Leader_bet" ? "#9b9b9b" : "#dfb276")} ></Text>
                            <Text x={this.state.calltimetable.hide_show ? this.props.seatProperties.padddings.x1 - 15 : this.props.seatProperties.padddings.x1 + 5} y={(fileName.name === "Leader_bet" ? -this.props.seatProperties.padddings.y1 + 15 : (this.state.showKnockOutBounty ? -this.props.seatProperties.padddings.y1 + 18 : -this.props.seatProperties.padddings.y1 + 22))}
                                width={this.props.seatProperties.width + 10} height={this.props.seatProperties.height} align={"center"}
                                // verticalAlign={"middle"} text={isNaN(this.state.chips) ? "####" :this.props.bigshowhide.show? (Number(this.state.chips)).toLocaleString()!=="0"?`${this.state.noofBB} BB`:"":(this.state.chips).toLocaleString()} fill={"#fff"} fontSize={12}
                                verticalAlign={"middle"}
                                // text={isNaN(this.state.chips) ? "####" : this.props.bigshowhide.show ? (Number(this.state.chips)).toLocaleString() !== "0" ? `${this.dividvalue(this.state.chips)} BB` : "" : UM.numberWithCommas(this.state.chips)}
                                text={isNaN(this.state.chips) ? "####" : this.state.toggleBB ? (Number(this.state.chips)).toLocaleString() !== "0" ? `${this.dividvalue(this.state.chips)} BB` : "" : UM.numberWithCommas(this.state.chips)}
                                fill={"#fff"} fontSize={(fileName.name === "Leader_bet" ? 15 : 12)}
                                fontFamily={"Roboto"} fontStyle={'bold'}></Text>


                            {/* {/ <------------------------------------knockout-bounty-start-----------------------------------------------> /} */}
                            {this.state.showKnockOutBounty &&
                                <Text x={this.props.seatProperties.padddings.x1 + 1} y={-this.props.seatProperties.padddings.y1 + 33.5}
                                    width={this.props.seatProperties.width + 10} height={this.props.seatProperties.height} align={"center"}
                                    verticalAlign={"middle"} text={this.state.knockoutBounty} fill={"#7FCDFF"} fontSize={12}
                                    fontFamily={"Roboto"} fontStyle={'bold'}  ></Text>}
                            {/* {/ <------------------------------------knockout-bounty-end-----------------------------------------------> /} */}

                            {/* {/ <------------------------------------Call-Time-Table-Timer-Start-----------------------------------------------> /} */}
                            {/* {this.state.calltimetable.hide_show && <Rect x={this.props.seatProperties.padddings.x2 + 62} y={-this.props.seatProperties.padddings.y3 + 6} stroke={"#3CA2C8"} shadowColor={"green"} cornerRadius={[5, 5, 5, 5]} width={this.props.seatProperties.width - 45} height={this.props.seatProperties.height + 4} ></Rect>}
                            {this.state.calltimetable.hide_show && <Text x={this.props.seatProperties.padddings.x2 + 60} y={-this.props.seatProperties.padddings.y3 + 8} align={"center"} verticalAlign={"middle"} width={this.props.seatProperties.width - 40} fontSize={12} text={`${Math.trunc((this.state.calltimetable.Time) / 60)}` + ":" + `${Math.trunc((this.state.calltimetable.Time) % 60)}`} fill="#FF0000" ontStyle={"bold"}></Text>} */}
                            {this.state.calltimetable.hide_show && <Rect x={this.props.seatProperties.padddings.x2 + 57} y={-this.props.seatProperties.padddings.y3 + 4} stroke={"#3CA2C8"} shadowColor={"green"} cornerRadius={[5, 5, 5, 5]} width={this.props.seatProperties.width - 35} height={this.props.seatProperties.height + 4} ></Rect>}
                            {this.state.calltimetable.hide_show && <Text x={this.props.seatProperties.padddings.x2 + 60} y={-this.props.seatProperties.padddings.y3 + 8} align={"center"} verticalAlign={"middle"} width={this.props.seatProperties.width - 40} fontSize={12} text={`${(Math.trunc((this.state.calltimetable.Time) / 60)).toString().padStart(2, '0')}` + ":" + `${(Math.trunc((this.state.calltimetable.Time) % 60)).toString().padStart(2, '0')}`} fill="#FF0000" ontStyle={"bold"}></Text>}
                            {/* {/ <------------------------------------Call-Time-Table-Timer-End-----------------------------------------------> /} */}

                            <Rect x={this.props.seatProperties.padddings.x3 - 23} y={this.props.seatProperties.padddings.y3 - 25}
                                width={145} height={this.props.seatProperties.height + 40}
                                // this.state.showAction
                                fill={"#000000"} cornerRadius={[5, 5, 5, 5]} visible={this.state.showAction} wrap={'balance'} align={"center"} />

                            <Text x={this.props.seatProperties.padddings.x3 - 15} y={this.props.seatProperties.padddings.y3 - 25}
                                width={120} wrap="char" height={this.props.seatProperties.height + 40} align={"center"}
                                // verticalAlign={"middle"} text={this.state.handStrength.split(",").join("\n")} fill={"#fff"} fontSize={14} fontFamily={"Roboto"} fontStyle={'bold'} />
                                verticalAlign={"middle"} text={`${this.state.handStrength.split(",").join("\n")}${this.state.winpercent !== "" ? `${"\n" + this.state.winpercent}` : ""}`} fill={"#fff"} fontSize={14} fontFamily={"Roboto"} fontStyle={'bold'} />

                            {/* <Text  x={24} y={-58}
							width={this.props.seatProperties.width + 30} height={this.props.seatProperties.height} align={"center"}
							verticalAlign={"middle"} text={this.state.action}  fill={this.state.ritcolor} fontSize={15} fontFamily={"Roboto"} fontStyle={'bold'}></Text> */}
                            <Rect x={26.7} y={-58} width={75} height={30} zIndex={999} fill={this.colorchange()} fontStyle={"bold"} stroke={this.colorchange1()} fontSize={15} fontFamily={"Roboto"} shadowColor={this.colorchange1()} cornerRadius={[5, 5, 5, 5]} />
                            <Text x={23.8} y={-52} width={80} height={20} align={"center"} verticalAlign={"middle"} text={this.messagechange()} fill={fileName.name === "Leader_bet" ? this.changeTxtColor() : "#fff"} fontSize={15} fontFamily={"Roboto"} fontStyle={"bold"}></Text>

                            {/* <Timer x={this.props.seatProperties.padddings.x2 - 2} y={-this.props.seatProperties.padddings.y3 + 35} width={this.props.seatProperties.width + 20} height={this.props.seatProperties.height - 5} show={this.state.showTimer} value={this.state.timerValue}></Timer> */}
                            <Timer x={this.props.seatProperties.padddings.x2 - 2} y={-this.props.seatProperties.padddings.y3 + ((fileName.name === "Leader_bet" && Screen.getDeviceType().name == "Mobile") ? (this.state.showKnockOutBounty ? 35 : 25) : (fileName.name === "Riverpoker" && Screen.getDeviceType().name == "Mobile") ? (this.state.showKnockOutBounty ? 35 : 28) : 35)} width={this.props.seatProperties.width + 20} height={this.props.seatProperties.height - 5} show={this.state.showTimer} value={this.state.timerValue}></Timer>

                        </Group>
                        <Group x={0} y={0} visible={this.state.showSeatMenu && fileName.name !== "Leader_bet" && this.props.changetourno_sitandgo} onClick={() => { this.setState({ showSeatMenu: false }); this.props.changeSeatHandler(this.props.id) }}
                            onTap={() => {
                                this.setState({ showSeatMenu: false }); this.props.changeSeatHandler(this.props.id)
                            }}>
                            <Rect x={0} y={0} width={90} height={40} fill={"#dbdbdb"}></Rect>
                            <Text x={0} y={10} width={80} height={20} align={"center"} verticalAlign={"middle"}
                                text={"Sit Here"} fill={this.state.seatMenuColorOne} fontSize={16} fontFamily={"Calibri"}
                                onMouseOver={() => { this.setState({ seatMenuColorOne: '#ff0000' }) }}
                                onMouseOut={() => { this.setState({ seatMenuColorOne: '#000000' }) }} fontStyle={'bold'} ></Text>
                        </Group>
                        <Group x={0} y={0} >
                            <Rect x={18} y={-5} width={90} height={20} onClick={(e) => { this.setState({ toggleBB: !this.state.toggleBB }) }} />
                        </Group>
                        <Group
                            x={0}
                            y={0}
                            visible={this.state.showChat}>
                            {/* <Rect x={0} y={-this.props.seatProperties.panelHeight * 0.5} width={120} height={40} fill={"#0084ffed"}
								cornerRadius={[5, 5, 5, 5]} stroke={"#00fff982"}></Rect> */}
                            {/* before here is the  y={-this.props.seatProperties.panelHeight * 0.5 - 100}*/}
                            <Image x={0} y={-this.props.seatProperties.panelHeight * 0.5 - 70} width={120} height={40} image={this.state.chat} />
                            <Text x={0} y={-this.props.seatProperties.panelHeight * 0.5 - 73} width={120} height={40} align={"center"}
                                verticalAlign={"middle"} text={this.state.chatText} fill={this.state.seatMeanuMegTxt} fontSize={14}
                                fontFamily={"Roboto"}
                                onClick={() => { this.setState({ showSeatMenu: false }); this.props.changeSeatHandler(this.props.id) }}
                                onMouseOver={() => { this.setState({ seatMeanuMegTxt: '#80ff00' }) }}
                                onMouseOut={() => { this.setState({ seatMeanuMegTxt: '#ffff' }) }}></Text>
                        </Group>


                        {/* <Group x={0} y={0} visible={true} >
                            <Rect x={17.5} y={0} width={90} height={40} fill={"#dbdbdb"} ></Rect>
                            <Text x={22} y={2}
                                text={"Folded Cards"} fill={"red"}
                                verticalAlign={"middle"} align={"center"}
                                fontSize={14} fontFamily={"Roboto"}>
                            </Text>
                            <span x={0} y={0} id="rCards">
                                <img style={{ width: '15px', height: '21px' }} src={riverscard} alt="" />
                                <img style={{ width: '15px', height: '21px' }} src={riverscard} alt="" />
                            </span>
                            <Cards ref={this.cardsRef} x={this.props.x + 15} y={this.props.y - this.props.seatProperties.cardY - 12} xPadding={this.props.seatProperties.cardXpadding} scale={this.props.seatProperties.cardScale} ratioed={this.state.ratio} hidecardPos={this.state.hidecardRatio}></Cards>
                        </Group> */}
                    </Group>
                </Group>
            </Group>
        );
    }
}