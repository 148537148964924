import ReactDOM from "react-dom";
import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../../../css/ui/popUps/avatarPopup.css";

import batman from "../../../../assets/images/lobby/avtar/batman-icon.png";
import realpro from "../../../../assets/images/lobby/avtar/avtar_icon9.png";
import closeIcon from '../../../../assets/images/lobby/close_icon.svg';
import close_1 from '../../../../assets/images/table/close_1.svg';
import Config from "../../../../config";



// import Postframesdata from '../Postimg/Postframes.json'
let avatarDetails = {
  default: "",
  src: "",
};


class AvatarPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadBtnState: true,
      previewBtnState: true,
      setBtnState: true,
      DefaultImgValue: false,
      avatarUrl: "",
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1,
      },
      newavatar: [],
      avtarId: [],
      setId: "-",
    };
    this.config = new Config();
  }

  disButton() {
    this.setState({ loadBtnState: false, setBtnState: true });
  }
  setAvatar() {
    avatarDetails.default = this.state.DefaultImgValue;
    avatarDetails.src = this.state.avatarUrl;
    console.log("custom avtar base64 string is:");
    // console.log(avatarDetails.src);
    // this.props.setAvtar(avatarDetails.src);
    // this.props.setAvtar(avtarId[setId]);
    console.log(this.props.avtarId)
    console.log(this.props.avtarId[this.state.setId])
    console.log(this.state.setId)
    // this.props.setAvtar(this.props.avtarId[this.state.setId])

    var sessionData = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`));
    var encryptDecrypt = new window.EncryptDecrypt(128, 2, "566e4a61fd3220031fa17ebd846c8ec0", "c4c7712d6f62c4f8c40dd2248a029a8a");
    var b1 = `${encryptDecrypt.decrypt(`${sessionData.sid}`, this.props.avtarId[this.state.setId])}`;
    console.log(b1)
    // this.props.setAvtar(this.state.setId)
    let avtarId = this.props.avtarId;//{
    // "avatar_id": "00000000000000010000000000001179"
    // }
    let setId = this.state.setId;
    var body = { "avatar_id": avtarId[setId] };

    // var pathW = fetch(this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.setAvatar_Api, {
    //   mode: 'no-cors',
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    // })
    // var pathW = 'https://riv1.czargaming.com/api/player/setAvatar';


    var xhr = new XMLHttpRequest();
    xhr.open("POST", this.config.URL_Environment.proxy.baseUrl + this.config.URL_Environment.apiPath.setAvatar_Api, true);
    xhr.setRequestHeader("Content-Type", "text/plain");
    // xhr.setRequestHeader("Access-Control-Allow-Origin", `https:/${window.location.hostname}/`);
    // xhr.setRequestHeader("Access-Control-Allow-Origin", `${this.config.URL_Environment.proxy.baseUrl}`);
    xhr.setRequestHeader("SiteId", this.config.URL_Environment.sitIds.sitid);
    xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
    console.log(JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
    console.log(`https:/${window.location.hostname}/`)
    console.log(`${this.config.URL_Environment.proxy.baseUrl}/`)
    console.log(this.config.URL_Environment.proxy.baseUrl)
    xhr.addEventListener("load", (e) => {
      this.setavtarHandler(e);
    });
    if (body) {
      console.log("sending with body");
      console.log(body)
      xhr.send(JSON.stringify(body));
    } else {
      console.log("sending without body");
      xhr.send();
    }
    setTimeout(() => {
      this.props.setAvtar(this.state.setId)
    }, 500);
  }
  setavtarHandler(data) {
    console.log("the setavtar response is");
    // console.log(data);
    // console.log(data.target.response);
  }
  yourfunc(i) {
    console.log(this.props.newavatar)
    let newavatar = this.props.newavatar;
    // console.log(newavatar[i])
    this.setState({ loadBtnState: true });
    document.getElementById("pre").src = `data:image/jpeg;base64,${newavatar[i]}`;
    // console.log(`data:image/jpeg;base64,${newavatar[i]}`)
    this.setState({ setBtnState: false });
    this.setState({ DefaultImgValue: true });
    this.setState({ avatarUrl: document.getElementById("pre").src });
    document.getElementById("cropping").innerHTML = "";
    this.setState({ setId: i });
  }
  loadfile() {
    document.getElementById("getFile").click();
    // this.setState({ setBtnState: false });
    // this.setState({DefaultImgValue:true});
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;

    console.log("ready to crop");
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
    this.setState({ previewBtnState: false });
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }
  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <React.Fragment>
        <div className="popCover_1" onClick={(e) => { e.preventDefault(); this.props.setAction("hideAvtar"); }} > </div>
        <div className="popup_1">
          <div className="popup_1_in">
            <div className="head fd">
              Add/Change Avatar
              <button className="close_1" onClick={(e) => { e.preventDefault(); this.props.setAction("hideAvtar"); }}> <img src={close_1} alt="" /> </button>
            </div>
            <div className="p_10 font_14 fd">Select Avatar</div>
            <div className="responsive">
              <div className="fd">
                <img src={batman} onClick={this.disButton.bind(this)} alt="" />
                {(() => {
                  let newavatar = this.props.newavatar;
                  let i = 0,
                    optionsArray = [];
                  if (newavatar !== undefined) {
                    for (i = 0; i < 25; i++) {
                      optionsArray.push(<img key={i} src={`data:image/jpeg;base64,${newavatar[i]}`} onClick={this.yourfunc.bind(this, i)} alt="" />);
                    }
                  }

                  return optionsArray;
                })()}
              </div>
            </div>
            <div className="fd">
              <div className="row">
                <div className="col-8">
                  <div className="fd d_flex p_tb_5">
                    <p className="m_tb_0 float_left clr_ff">Custom Avatar</p>
                    <div className="loadFileAvt">
                      <button className="Lfile" type="file" onClick={this.loadfile.bind(this)} id="togglel" disabled={this.state.loadBtnState}>
                        Load File
                      </button>
                      <input type="file" accept="image/*" id="getFile" className="image" onChange={this.onSelectFile} />
                    </div>
                  </div>
                  <div className="cropping" id="cropping">
                    {src && <ReactCrop src={src} crop={crop} ruleOfThirds onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />}
                  </div>

                </div>
                <div className="col-4">
                  <p className="Pheader">Preview</p>
                  <div className="preview" id="preview">
                    <img src={realpro} id="pre" height="70px" width="70px" alt="" style={{ borderRadius: 50, position: "relative", left: "12px", top: "10px" }} />
                  </div>
                </div>
                <div className="col-12 p_b_5 p_t_25">
                  <button
                    type="button"
                    className="btn_1 wid_auto"
                    disabled={this.state.previewBtnState}
                    onClick={() => {
                      document.getElementById("pre").src = croppedImageUrl;
                      var imgSrc = this.getBase64FromUrl(croppedImageUrl);
                      imgSrc.then((x) => {
                        this.setState({ avatarUrl: x });
                      });
                      this.setState({ DefaultImgValue: false });
                      this.setState({ setBtnState: false });
                    }}
                  >
                    Preview
                  </button>

                  <button type="button" className="btn_1 wid_auto float_right m_r_5" id="togglee"
                    disabled={this.state.setBtnState}
                    onClick={this.setAvatar.bind(this)}>
                    Set Avatar
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AvatarPopup;
ReactDOM.render(<AvatarPopup />, document.getElementById("root"));
