import React from "react";
import TourneyLobbyBase from "./tourneyLobbyBase";
import { TourneyLobbyLogo } from "../ui/lobby/lobbyLogo";
import TourneyLobbyMenu from "../ui/tLobby/menu";
import TourneyButtons from "../ui/tLobby/tourneyButtons";
import Participants from "../ui/tLobby/participants";
import TableData from "../ui/tLobby/tableData";
import OpenTables from "../ui/tLobby/openTables";
import OpenTables1 from "../ui/tLobby/openTables1";
import PrizeInfo from "../ui/tLobby/prizeInfo";
import TourneyInfo from "../ui/tLobby/info";
// import TourneyDetails from "../ui/tLobby/details";
import TourneyDetails from "../ui/lobby/tableDetails/tourneyDetails";
// import TourneyDetails from "../ui/lobby/tableDetails/tourneyDetails";
import proImg from "../../../assets/images/table/playBtn.svg";
import TourneyRegistration from "../ui/popUps/tourneyRegistration";
import TournamentInfo from "../ui/popUps/tourneyInfo";
import Spinner from "../../utils/spinner";
import { t } from "i18next";
import DateUtils from '../../utils/dateUtils'

import Screen from "../../utils/screen";
import Config from "../../../config";
import { waitFor } from "@testing-library/react";
import { Emitter } from "../../utils/eventEmitter.js";

import CountdownTimer1 from "../../utils/CountdownTimer.js";
import UM from "../../utils/utilityMethods.js";

export default class TourneyLobbyMain extends TourneyLobbyBase {
    constructor(props) {
        super(props);
        // console.log("tourney lobby Main props are");

        this.tMoney = "";
        this.tickets = "";
        this.balance = "";
        this.cPoints = "";
        this.addonpresent = false;


        this.state = {
            reEntryTable: {
                isTourneyRetry: false,
                maxRetryCount: 0,
                noOfRetries: ""
            },
            gameTableid: this.props.idTables,
            opentabledetails: { id: "", type: "" },
            tableLoded: false,
            showOpenTableData: false,
            BgOpacity: 1,
            playerData: {
                balance: "",
                tickets: "",
                tMoney: "",
                cPoints: "",
                playMoney: "",
            },
            participants: [],
            tableData: [],
            openTables: [], //must include id and type of tables
            details: {
                buyIn: "",
                field1: "-",
                field2: "-",
                field3: "-",
                field4: "-",
                field5: "-",
                description: "",
                type: "",
                status: ""
            },
            info: {
                name: "-",
                date: "-",
                min: "-",
                max: "-",
                players: "-",
                Registerend: "",
                tournmentstart: ""
            },
            prizeInfo: {
                prizePool: "0",
                placesPaid: "0",
                entries: "0",
                rebuys: "0",
                totalRebuys: "0",
                addons: "0",
                totalAddons: "0",
                data: [],
                payment: ''
            },
            regData: {
                types: [],
                status: "-",
                statusAction: "",
                name: "-",
                balance: "-",
                type: "-",
                id: "",
                tableIdNo: "",
                tableType: "",
                tableName: "",
                compPoints: "-",
                TM: "-",
                myTableId: '',
                joinBtnStatus: ''
            },
            showRegistration: false,
            shiowRegistredbtn: false,
            showwatchorjoin: false,
            shiowRegistredtext: "",
            watchorjoin: "",
            showUnRegistration: false,
            showInfo: false,
            showrestOf: true,
            regAlert: "",
            infoData: {
                data: {
                    startingChips: "-",
                    blinds: "-",
                    rebuys: "",
                    addons: "",
                    text1: "",
                    text2: "",
                    text3: "",
                    minPlayers: 0,
                    maxPlayers: 0,
                    TouroStatus: ""

                },
                info: [],
                currentLevel: -1,
            },
            tourneyData: {
                name: "",
                date: "",
                buyIn: "",
                type: "",
                typeText: "",
                status: "",
                players: "",
                prize: "",
                description: "",
                id: "",
            },
            isRanked: false,
            tournmentstartLeftTime: { min: "", sec: "", enable: false },
        };
        this.playerCompoints = "";
        this.config = new Config();
        Emitter.on("updateTLobbyDetailsAfterTourney", this.UpdateTLobbyDetailsAfterTourney.bind(this))
        Emitter.on("updateTLobbyDetails", this.UpdateTLobbyDetails.bind(this))
    }
    openTablesRequest(res, status) {
        let regData = this.state.regData;
        regData.joinBtnStatus = 'enableJoinBtn';
        this.setState({ regData: regData });
        if (status === 'enableJoinBtn') {
            regData.joinBtnStatus = '';
            this.setState({ regData: regData });
        }
        switch (res) {
            case "openTableRequrest":
                this.setState({ tableLoded: status });
                break
            default:
                console.log("openTablesRequest  >> ", res)
                break;
        }
        console.log("This is RegData", regData)
    }


    initTourneyLobby(data) {
        console.log("initTourneyLobby >>  ", data)
        // this.setState({
        //     // tableLoded: false,
        //     showOpenTableData: false,
        //     BgOpacity: 1,
        //     playerData: {
        //         balance: "",
        //         tickets: "",
        //         tMoney: "",
        //         cPoints: "",
        //         playMoney: "",
        //     },
        //     participants: [],
        //     tableData: [],
        //     openTables: [], //must include id and type of tables
        //     details: {
        //         buyIn: "",
        //         field1: "-",
        //         field2: "-",
        //         field3: "-",
        //         field4: "-",
        //         field5: "-",
        //         description: "",
        //         type: "",
        //         status: ""
        //     },
        //     info: {
        //         name: "-",
        //         date: "-",
        //         min: "-",
        //         max: "-",
        //         players: "-",
        //         Registerend: "",
        //         tournmentstart: ""
        //     },
        //     prizeInfo: {
        //         prizePool: "-",
        //         placesPaid: "-",
        //         entries: "-",
        //         rebuys: "-",
        //         addons: "-",
        //         data: [],
        //     },
        //     regData: {
        //         types: [],
        //         status: "-",
        //         statusAction: "",
        //         name: "-",
        //         balance: "-",
        //         type: "-",
        //         id: "",
        //         tableIdNo: "",
        //         tableType: "",
        //         tableName: "",
        //         compPoints: "-",
        //         TM: "-",
        //         myTableId: '',
        //         joinBtnStatus: ''
        //     },
        //     showRegistration: false,
        //     showUnRegistration: false,
        //     showInfo: false,
        //     showrestOf: true,
        //     regAlert: "",
        //     infoData: {
        //         data: {
        //             startingChips: "-",
        //             blinds: "-",
        //             rebuys: "",
        //             addons: "",
        //             text1: "name",
        //             text2: "inko name",
        //             text3: "inga chaalu",
        //             minPlayers: 0,
        //             maxPlayers: 0,
        //             TouroStatus: ""

        //         },
        //         info: [],
        //         currentLevel: undefined,
        //     },
        //     tourneyData: {
        //         name: "",
        //         date: "",
        //         buyIn: "",
        //         type: "",
        //         typeText: "",
        //         status: "",
        //         players: "",
        //         prize: "",
        //         description: "",
        //         id: "",
        //     },
        //     shiowRegistredbtn: false,


        // })
        console.log(data)
        if (data.TournamentDetails.hasOwnProperty("SitAndGoTournament")) {
            this.onGetSitGodata(data);
        } else if (data.TournamentDetails.hasOwnProperty("ScheduledTournament")) {
            this.onGetScheduledData(data);
        }
        if (data.TournamentDetails.hasOwnProperty("attr")) {
            if (data.TournamentDetails.attr.hasOwnProperty("tourneyRetry")) {
                let reEntryTable = this.state.reEntryTable;
                reEntryTable.maxRetryCount = data.TournamentDetails.attr?.maxRetryCount;
                if (data.TournamentDetails.attr.tourneyRetry === "true") {
                    reEntryTable.isTourneyRetry = true;
                } else {
                    reEntryTable.isTourneyRetry = false;
                }
                this.setState({ reEntryTable: reEntryTable })
            }
        }
    }
    async onPlayerInfo(data) {
        console.log("The on Player info is called", data);
        let regData = this.state.regData;
        let playerData = this.state.playerData;
        if (data.hasOwnProperty("PlayerInfo")) {
            if (data.PlayerInfo.hasOwnProperty("attr")) {
                // this.setState({ userLogged: data.PlayerInfo.attr.nickname });
                if (data.PlayerInfo.attr.hasOwnProperty("noOfRetries")) {
                    this.setState({ reEntryTable: { noOfRetries: data.PlayerInfo.attr.noOfRetries } });
                }
                if (data.PlayerInfo.attr.registered === "false") {
                    regData.status = "Unregistered";
                    regData.statusAction = "Register";
                    this.setState({ shiowRegistredtext: "Register", watchorjoin: "Watch Table" });
                } else {
                    regData.status = "Registered";
                    regData.statusAction = "Unregister";
                    if (!this.state.reEntryTable.isTourneyRetry && !this.state.isRanked) {
                        this.setState({ shiowRegistredtext: "Unregister", watchorjoin: "Join Table" })
                    }
                }
                // regData.status = data.PlayerInfo.attr.registered;
                regData.name = data.PlayerInfo.attr.nickname;
            }

            if (data.PlayerInfo.hasOwnProperty("Tickets")) {
                playerData.tickets = data.PlayerInfo.Tickets.attr.count;
            }
            if (data.PlayerInfo.hasOwnProperty("Balance")) {
                if (data.PlayerInfo.Balance.hasOwnProperty("attr")) {
                    this.balance = await data.PlayerInfo.Balance.attr.cash;
                    playerData.balance = data.PlayerInfo.Balance.attr.cash;
                    regData.balance = data.PlayerInfo.Balance.attr.cash;
                    this.tMoney = data.PlayerInfo.Balance.attr["tournament-money"];
                    playerData.tMoney = data.PlayerInfo.Balance.attr["tournament-money"];
                } else {
                    for (let i = 0; i < data.PlayerInfo.Balance.length; i++) {

                        if (data.PlayerInfo.Balance[i].attr.wallet === 'COMPPOINTS') {
                            playerData.cPoints = data.PlayerInfo.Balance[i].attr.total;
                            this.playerCompoints = await data.PlayerInfo.Balance[i].attr.total;
                        }
                        if (data.PlayerInfo.Balance[i].attr.wallet === 'PLAYMONEY') {
                            playerData.playMoney = data.PlayerInfo.Balance[i].attr.total
                        }
                        if (data.PlayerInfo.Balance[i].attr.wallet === 'CHP') {
                            if (data.PlayerInfo.Balance[i].attr.hasOwnProperty('total')) {
                                this.cPoints = data.PlayerInfo.Balance[i].attr.total;
                            }
                            if (data.PlayerInfo.Balance[i].attr.hasOwnProperty('cash')) {
                                playerData.balance = data.PlayerInfo.Balance[i].attr.cash;
                                regData.balance = data.PlayerInfo.Balance[i].attr.cash;
                                this.balance = data.PlayerInfo.Balance[i].attr.cash;
                            }

                        }
                        try {
                            if (data.PlayerInfo.Balance[i].attr["tournament-money"]) {
                                this.tMoney = data.PlayerInfo.Balance[i].attr["tournament-money"];
                                playerData.tMoney = data.PlayerInfo.Balance[i].attr["tournament-money"];
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                }
            }
            this.setState({ playerData: playerData });
            this.setState({ regData: regData });
        }
    }

    onGetSitGodata(data) {
        let info = this.state.info;
        let details = this.state.details;
        let prizeInfo = this.state.prizeInfo;
        let regData = this.state.regData;
        let infoData = this.state.infoData;

        if (data.TournamentDetails.hasOwnProperty("attr")) {
            if (data.TournamentDetails.attr.hasOwnProperty("name")) {
                info.name = data.TournamentDetails.attr.name;
                details.field1 = data.TournamentDetails.attr.name;
                infoData.data.text1 = data.TournamentDetails.attr.name;
                regData.id = data.TournamentDetails.attr.id;
                details.type = data.TournamentDetails.attr.type
                regData.tableType = data.TournamentDetails.SitAndGoTournament.attr.game;
                regData.tableName = data.TournamentDetails.attr.name;

            }
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("Description")) {
            details.description = data.TournamentDetails.SitAndGoTournament.Description;
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("Schedule")) {
            switch (data.TournamentDetails.SitAndGoTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    details.status = "Cancelled";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "CANCELED_AFTER_START":
                    details.status = "Cancelled";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "COMPLETED":
                    details.status = "Completed";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "SEATING":
                    details.status = "Seating";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "REGISTERING":
                    details.status = "Registering";
                    this.setState({ shiowRegistredbtn: true, showwatchorjoin: false })
                    break;
                case "ANNOUNCED":
                    details.status = "Announced";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "LATE_REG":
                    details.status = "Late Registration";
                    this.setState({ shiowRegistredbtn: true, showwatchorjoin: true })
                    break;
                case "RUNNING":
                    details.status = "Running";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: true })
                    break;
                default:
                    details.status = data.TournamentDetails.ScheduledTournament.Schedule.attr.status;
                    break;
            }

        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("attr")) {
            let limit, game;
            if (data.TournamentDetails.SitAndGoTournament.attr.hasOwnProperty("limit")) {
                limit = data.TournamentDetails.SitAndGoTournament.attr.limit;
            }
            if (data.TournamentDetails.SitAndGoTournament.attr.hasOwnProperty("game")) {
                game = data.TournamentDetails.SitAndGoTournament.attr.game;
            }
            // details.field2 = `${limit} ${game}`;//
            details.field4 = this.GETlIMITEXT(`${limit}`) + this.getGameType(`${game}_${limit}`);
            details.field2 = this.getGameType(`${game}_${limit}`);
            infoData.data.text3 = this.getGameType(`${game}_${limit}`);
        }

        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("Parameters")) {
            let buyIn, chips;
            if (data.TournamentDetails.SitAndGoTournament.Parameters.hasOwnProperty("attr")) {
                buyIn = data.TournamentDetails.SitAndGoTournament.Parameters.attr.buyIn;
                chips = data.TournamentDetails.SitAndGoTournament.Parameters.attr.chips;
                regData.compPoints = data.TournamentDetails.SitAndGoTournament.Parameters.attr.compPoints
                regData.TM = Number(data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee) + Number(data.TournamentDetails.SitAndGoTournament.Parameters.attr.prizeFee)
                infoData.data.startingChips = data.TournamentDetails.SitAndGoTournament.Parameters.attr.chips;
                // details.field5 = `${t("Buy In")}: ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee} + ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.prizeFee}`
                details.field5 = `${t("Buy In")}: ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.buyIn === "FREEROLL" ? "FREEROLL" : data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee + " + " + data.TournamentDetails.SitAndGoTournament.Parameters.attr.prizeFee}`
                details.buyIn = data.TournamentDetails.SitAndGoTournament.Parameters.attr.buyIn
                details.field3 = `${t("Buy In")}: ${UM.numberWithCommas(buyIn)} ${t("Chips")}: ${UM.numberWithCommas(chips)}`;
                infoData.data.text2 = `${t("Buy In")}: ${UM.numberWithCommas(buyIn)} ${t("Chips")}: ${UM.numberWithCommas(chips)}`;
            }
            if (data.TournamentDetails.SitAndGoTournament.Parameters.hasOwnProperty("Addon")) {
                prizeInfo.totalAddons = `${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.cost)} + ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.chips)} chips (${data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.count})`;
                // infoData.data.addons = `${Number(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.chips).toLocaleString("en-US")} for ${Number(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.cost).toLocaleString("en-US")}`;
                infoData.data.addons = `${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.cost)} + ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.chips)} chips (${data.TournamentDetails.SitAndGoTournament.Parameters.Addon.attr.count})`;
                this.addonpresent = true;
            }
            if (data.TournamentDetails.SitAndGoTournament.Parameters.hasOwnProperty("Rebuy")) {
                prizeInfo.totalRebuys = `${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.cost)} + ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.chips)} chips (${data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.count})`;
                // infoData.data.rebuys = `${Number(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.chips).toLocaleString("en-US")} for ${Number(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.cost).toLocaleString("en-US")}`;
                infoData.data.rebuys = `${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.cost)} + ${data.TournamentDetails.SitAndGoTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.chips)} chips (${data.TournamentDetails.SitAndGoTournament.Parameters.Rebuy.attr.count})`;
            }
            // if (data.TournamentDetails.SitAndGoTournament.Parameters.hasOwnProperty("Entries")) {
            //     prizeInfo.entries = data.TournamentDetails.SitAndGoTournament.Parameters.Entries.attr.count;
            // }
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("PrizeInfo")) {
            let playerRebuys = data.TournamentDetails.SitAndGoTournament.PrizeInfo.attr?.rebuys;
            let playerAddons = data.TournamentDetails.SitAndGoTournament.PrizeInfo.attr?.addons;
            prizeInfo.rebuys = playerRebuys === undefined ? "0" : playerRebuys;
            prizeInfo.addons = playerAddons === undefined ? "0" : playerAddons;
            prizeInfo.prizePool = data.TournamentDetails.SitAndGoTournament.PrizeInfo.attr.prizePool;
            prizeInfo.placesPaid = data.TournamentDetails.SitAndGoTournament.PrizeInfo.attr.placesPaid;
            if (data.TournamentDetails.SitAndGoTournament.PrizeInfo.hasOwnProperty("Payment")) {
                if (Array.isArray(data.TournamentDetails.SitAndGoTournament.PrizeInfo.Payment)) {
                    prizeInfo.payment = data.TournamentDetails.SitAndGoTournament.PrizeInfo.Payment.map(payment => {
                        return `<div class="df_al_jsb"><span>${payment.attr.place}. </span> <span> ${UM.numberWithCommas(payment.attr.amount)}</span></div>`;
                    }).join('');
                } else if (data.TournamentDetails.SitAndGoTournament.PrizeInfo.Payment) {
                    const payment = data.TournamentDetails.SitAndGoTournament.PrizeInfo.Payment;
                    prizeInfo.payment = `<div class="df_al_jsb"><span>${payment.attr.place}. </span> <span>${UM.numberWithCommas(payment.attr.amount)}</span></div>`;
                }
            }
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("Participants")) {
            infoData.data.minPlayers = Number(data.TournamentDetails.SitAndGoTournament.Participants.attr.minimum).toLocaleString("en-US");
            infoData.data.maxPlayers = Number(data.TournamentDetails.SitAndGoTournament.Participants.attr.maximum).toLocaleString("en-US");
            prizeInfo.entries = data.TournamentDetails.SitAndGoTournament.Participants.attr.total;
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("Participants")) {
            if (data.TournamentDetails.SitAndGoTournament.Participants.hasOwnProperty("attr")) {
                info.min = data.TournamentDetails.SitAndGoTournament.Participants.attr.minimum;
                info.max = data.TournamentDetails.SitAndGoTournament.Participants.attr.maximum;
                info.players = data.TournamentDetails.SitAndGoTournament.Participants.attr.total;
                info.date = `When ${data.TournamentDetails.SitAndGoTournament.Participants.attr.minimum} are Registered`;
            }
        }
        if (data.TournamentDetails.SitAndGoTournament.hasOwnProperty("RegistrationTypes")) {
            if (data.TournamentDetails.SitAndGoTournament.RegistrationTypes.hasOwnProperty("RegistrationType")) {
                let types = data.TournamentDetails.SitAndGoTournament.RegistrationTypes.RegistrationType;

                if (!Array.isArray(types)) {
                    types = [types];
                }
                let i = 0,
                    cnt = types.length;
                regData.types = [];
                for (i; i < cnt; i++) {
                    if (types[i].hasOwnProperty("attr")) {
                        switch (types[i].attr.type) {
                            case "BALANCE":
                                // regData.types.push({ type: "Balance", text: "BALANCE", amount: Number(this.state.regData.balance).toLocaleString("en-US"), buyAmount: Number(this.state.details.buyIn).toLocaleString("en-US"), enable: (this.state.details.buyIn === "FREEROLL" ? "FREEROLL" : Number(this.state.regData.balance) > Number(this.state.details.buyIn)) });
                                regData.types.push({
                                    type: "Balance", text: "BALANCE", amount: UM.numberWithCommas(this.balance), buyAmount: (this.state.details.buyIn === "FREEROLL" ? 'FREEROLL' : UM.numberWithCommas(this.state.details.buyIn)), enable: (this.state.details.buyIn === "FREEROLL" ? "FREEROLL" : Number(this.balance) > Number(this.state.details.buyIn))
                                });
                                // regData.types.push({ type: "Balance", text: "BALANCE", amount: this.numberWithCommas(this.state.regData.balance), buyAmount: (this.state.details.buyIn === "FREEROLL" ? 'FREEROLL' : Number(this.state.details.buyIn).toLocaleString("en-US")), enable: (this.state.details.buyIn === "FREEROLL" ? "FREEROLL" : Number(this.state.regData.balance) > Number(this.state.details.buyIn)) });
                                // regData.types.push({ type: "Balance", text: "BALANCE", amount: Number(this.state.details.buyIn).toLocaleString("en-US") });
                                break;
                            case "TOURNAMENT_MONEY":
                                regData.types.push({ type: "Tournament Money", text: "TOURNAMENT_MONEY", amount: UM.numberWithCommas(this.state.playerData.tMoney), buyAmount: UM.numberWithCommas(this.state.regData.TM), enable: Number(this.state.playerData.tMoney) > Number(this.state.regData.TM) });
                                break;
                            case "TICKET":
                                regData.types.push({ type: "Tickets", text: "TICKET", amount: UM.numberWithCommas(this.state.playerData.tickets), buyAmount: "Ticket", enable: Number(this.state.playerData.tickets) > 0 });
                                break;
                            case "COMP_POINTS":
                                // regData.types.push({ type: "CompPoints", text: "COMP_POINTS", amount: this.cPoints });
                                // regData.types.push({ type: "CompPoints", text: "COMP_POINTS", amount: this.numberWithCommas(this.state.playerData.cPoints), buyAmount: this.state.regData.compPoints, enable: Number(this.state.playerData.cPoints) > Number(this.state.regData.compPoints) });
                                regData.types.push({ type: "CompPoints", text: "COMP_POINTS", amount: UM.numberWithCommas(this.playerCompoints), buyAmount: UM.numberWithCommas(this.state.regData.compPoints), enable: Number(this.playerCompoints) > Number(this.state.regData.compPoints) });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        this.setState({ regData: regData });
        this.setState({ info: info });
        this.setState({ infoData: infoData, tableLoded: true });
        // this.setState({ showOpenTableData: true })
    }
    onGetScheduledData(data) {
        console.log(data)
        let info = this.state.info;
        let details = this.state.details;
        let prizeInfo = this.state.prizeInfo;
        let regData = this.state.regData;
        let infoData = this.state.infoData;


        if (data.TournamentDetails.hasOwnProperty("attr")) {
            if (data.TournamentDetails.attr.hasOwnProperty("name")) {
                info.name = data.TournamentDetails.attr.name;
                details.field1 = data.TournamentDetails.attr.name;
                infoData.data.text1 = data.TournamentDetails.attr.name;
                regData.id = data.TournamentDetails.attr.id;
                regData.tableType = data.TournamentDetails.ScheduledTournament.attr.game;
                regData.tableName = data.TournamentDetails.attr.name;
                details.type = data.TournamentDetails.attr.type
            }
            // this.props.TourneyHandler("hideMiniTableTab", true);
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Description")) {
            details.description = data.TournamentDetails.ScheduledTournament.Description;
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("attr")) {
            let limit, game;
            if (data.TournamentDetails.ScheduledTournament.attr.hasOwnProperty("limit")) {
                limit = data.TournamentDetails.ScheduledTournament.attr.limit;
            }
            if (data.TournamentDetails.ScheduledTournament.attr.hasOwnProperty("game")) {
                game = data.TournamentDetails.ScheduledTournament.attr.game;
            }
            details.field4 = this.GETlIMITEXT(`${limit}`) + this.getGameType(`${game}_${limit}`);
            details.field2 = this.getGameType(`${game}_${limit}`);
            infoData.data.text3 = this.getGameType(`${game}_${limit}`);
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Parameters")) {
            let buyIn, chips;
            if (data.TournamentDetails.ScheduledTournament.Parameters.hasOwnProperty("attr")) {
                buyIn = data.TournamentDetails.ScheduledTournament.Parameters.attr.buyIn;
                chips = data.TournamentDetails.ScheduledTournament.Parameters.attr.chips;
                regData.compPoints = data.TournamentDetails.ScheduledTournament.Parameters.attr.compPoints
                regData.TM = Number(data.TournamentDetails.ScheduledTournament.Parameters.attr.fee) + Number(data.TournamentDetails.ScheduledTournament.Parameters.attr.prizeFee)
                details.field5 = `${t("Buy In")}: ${data.TournamentDetails.ScheduledTournament.Parameters.attr.buyIn === "FREEROLL" ? "FREEROLL" : data.TournamentDetails.ScheduledTournament.Parameters.attr.fee + " + " + data.TournamentDetails.ScheduledTournament.Parameters.attr.prizeFee}`
                infoData.data.startingChips = data.TournamentDetails.ScheduledTournament.Parameters.attr.chips;
                details.buyIn = data.TournamentDetails.ScheduledTournament.Parameters.attr.buyIn;
                details.field3 = `${t("Buy In")}: ${buyIn} ${t("Chips")}: ${chips}`;
                // infoData.data.text2 = `${t("Buy In")}: ${buyIn} ${t("Chips")}: ${chips}`;
                infoData.data.text2 = `${t("Buy In")}: ${UM.numberWithCommas(buyIn)} ${t("Chips")}: ${UM.numberWithCommas(chips)}`;
            }

            if (data.TournamentDetails.ScheduledTournament.Parameters.hasOwnProperty("Addon")) {
                prizeInfo.totalAddons = `${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.cost)} + ${data.TournamentDetails.ScheduledTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.chips)} chips (${data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.count})`;
                // infoData.data.addons = `${Number(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.chips).toLocaleString("en-US")} for ${Number(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.cost).toLocaleString("en-US")}`;
                infoData.data.addons = `${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.cost)} + ${data.TournamentDetails.ScheduledTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.chips)} chips (${data.TournamentDetails.ScheduledTournament.Parameters.Addon.attr.count})`;
                this.addonpresent = true;
            }
            if (data.TournamentDetails.ScheduledTournament.Parameters.hasOwnProperty("Rebuy")) {
                prizeInfo.totalRebuys = `${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.cost)} + ${data.TournamentDetails.ScheduledTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.chips)} chips (${data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.count})`;
                // infoData.data.rebuys = `${Number(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.chips).toLocaleString("en-US")} for ${Number(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.cost).toLocaleString("en-US")}`;
                infoData.data.rebuys = `${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.cost)} + ${data.TournamentDetails.ScheduledTournament.Parameters.attr.fee} for ${UM.numberWithCommas(data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.chips)} chips (${data.TournamentDetails.ScheduledTournament.Parameters.Rebuy.attr.count})`;
            }
            // if (data.TournamentDetails.ScheduledTournament.Parameters.hasOwnProperty("Entries")) {
            //     prizeInfo.entries = data.TournamentDetails.ScheduledTournament.Parameters.Entries.attr.count;
            // }
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("PrizeInfo")) {
            console.log(data.TournamentDetails.ScheduledTournament)
            let playerRebuys = data.TournamentDetails.ScheduledTournament.PrizeInfo.attr?.rebuys;
            let playerAddons = data.TournamentDetails.ScheduledTournament.PrizeInfo.attr?.addons;
            prizeInfo.rebuys = playerRebuys === undefined ? "0" : playerRebuys;
            prizeInfo.addons = playerAddons === undefined ? "0" : playerAddons;
            prizeInfo.prizePool = data.TournamentDetails.ScheduledTournament.PrizeInfo.attr.prizePool;
            prizeInfo.placesPaid = data.TournamentDetails.ScheduledTournament.PrizeInfo.attr.placesPaid;
            if (data.TournamentDetails.ScheduledTournament.PrizeInfo.hasOwnProperty("Payment")) {
                if (data.TournamentDetails.ScheduledTournament.PrizeInfo.hasOwnProperty("Payment")) {
                    if (Array.isArray(data.TournamentDetails.ScheduledTournament.PrizeInfo.Payment)) {
                        prizeInfo.payment = data.TournamentDetails.ScheduledTournament.PrizeInfo.Payment.map(payment => {
                            return `<div class="df_al_jsb"><span>${payment.attr.place}. </span> <span> ${UM.numberWithCommas(payment.attr.amount)}</span></div>`;
                        }).join('');
                    } else if (data.TournamentDetails.ScheduledTournament.PrizeInfo.Payment) {
                        const payment = data.TournamentDetails.ScheduledTournament.PrizeInfo.Payment;
                        prizeInfo.payment = `<div class="df_al_jsb"> <span>${payment.attr.place}. </span> <span>${UM.numberWithCommas(payment.attr.amount)}</span></div>`;
                    }
                }
            }
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Participants")) {

            infoData.data.minPlayers = Number(data.TournamentDetails.ScheduledTournament.Participants.attr.minimum).toLocaleString("en-US");
            infoData.data.maxPlayers = Number(data.TournamentDetails.ScheduledTournament.Participants.attr.maximum).toLocaleString("en-US");
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Participants")) {
            if (data.TournamentDetails.ScheduledTournament.Participants.hasOwnProperty("attr")) {
                info.min = data.TournamentDetails.ScheduledTournament.Participants.attr.minimum;
                info.max = data.TournamentDetails.ScheduledTournament.Participants.attr.maximum;
                info.players = data.TournamentDetails.ScheduledTournament.Participants.attr.total;
                prizeInfo.entries = data.TournamentDetails.ScheduledTournament.Participants.attr.total;
            }
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Schedule")) {
            let t2 = (Math.abs(Number(Date.now()) - Number(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart)) / 1000).toFixed(2);
            let sec = Math.trunc(t2 % 60);
            let min = Math.trunc(t2 / 60);
            this.setState({ tournmentstartLeftTime: { min: min, sec: sec, enable: t2 > 0 } });


            info.date = new Date(parseInt(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart)).toLocaleString();
            let registerendTiem = new DateUtils().getMinutesLeft(data.TournamentDetails.ScheduledTournament.Schedule.attr.registrationFinish);
            // console.log(registerendTiem)
            info.Registerend = `${new DateUtils().formatDateFromNumber(data.TournamentDetails.ScheduledTournament.Schedule.attr.registrationFinish, "MMM DD YYYY HH:MM")} (in ${registerendTiem == "0" ? "<1" : registerendTiem}m)`
            // info.tournmentstart = `${new DateUtils().formatDateFromNumber(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart, "MMM DD YYYY HH:MM")} (in ${new DateUtils().getMinutesLeft(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart)}m)`
            info.tournmentstart = `${new DateUtils().formatDateFromNumber(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart, "MMM DD YYYY HH:MM")}`
            // details.status = data.TournamentDetails.ScheduledTournament.Schedule.attr.status
            switch (data.TournamentDetails.ScheduledTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    details.status = "Cancelled";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "CANCELED_AFTER_START":
                    details.status = "Cancelled";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "COMPLETED":
                    details.status = "Completed";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "SEATING":
                    details.status = "Seating";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "REGISTERING":
                    details.status = "Registering";
                    this.setState({ shiowRegistredbtn: true, showwatchorjoin: false })
                    break;
                case "ANNOUNCED":
                    details.status = "Announced";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: false })
                    break;
                case "LATE_REG":
                    details.status = "Late Registration";
                    this.setState({ shiowRegistredbtn: this.state.regData.status === "Registered" ? false : true, showwatchorjoin: true })
                    break;
                case "RUNNING":
                    details.status = "Running";
                    this.setState({ shiowRegistredbtn: false, showwatchorjoin: true })
                    break;
                default:
                    details.status = data.TournamentDetails.ScheduledTournament.Schedule.attr.status;
                    break;
            }
        }
        if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("RegistrationTypes")) {
            if (data.TournamentDetails.ScheduledTournament.RegistrationTypes.hasOwnProperty("RegistrationType")) {
                let types = data.TournamentDetails.ScheduledTournament.RegistrationTypes.RegistrationType;
                if (!Array.isArray(types)) {
                    types = [types];
                }
                let i = 0,
                    cnt = types.length;
                regData.types = [];
                for (i; i < cnt; i++) {
                    if (types[i].hasOwnProperty("attr")) {
                        switch (types[i].attr.type) {
                            case "BALANCE":
                                regData.types.push({
                                    // type: "Balance", text: "BALANCE", amount: this.numberWithCommas(this.state.regData.balance), buyAmount: (this.state.details.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(this.state.details.buyIn).toLocaleString("en-US")),
                                    type: "Balance", text: "BALANCE", amount: UM.numberWithCommas(this.balance), buyAmount: (this.state.details.buyIn === 'FREEROLL' ? 'FREEROLL' : UM.numberWithCommas(this.state.details.buyIn)),
                                    enable: (this.state.details.buyIn === 'FREEROLL' ? 'FREEROLL' : Number(this.balance) > Number(this.state.details.buyIn))
                                });
                                // regData.types.push({ type: "Balance", text: "BALANCE", amount: Number(this.state.details.buyIn).toLocaleString("en-US") });
                                break;
                            case "TOURNAMENT_MONEY":
                                regData.types.push({
                                    type: "Tournament Money", text: "TOURNAMENT_MONEY", amount: UM.numberWithCommas(this.state.playerData.tMoney), buyAmount: UM.numberWithCommas(this.state.regData.TM),
                                    enable: Number(this.state.playerData.tMoney) > Number(this.state.regData.TM)
                                });
                                break;
                            case "TICKET":
                                regData.types.push({
                                    type: "Tickets", text: "TICKET", amount: UM.numberWithCommas(this.state.playerData.tickets), buyAmount: "Ticket",
                                    enable: Number(this.state.playerData.tickets) > 0
                                });
                                break;
                            case "COMP_POINTS":
                                // regData.types.push({ type: "CompPoints", text: "COMP_POINTS", amount: this.cPoints });
                                regData.types.push({
                                    // type: "CompPoints", text: "COMP_POINTS", amount: this.numberWithCommas(this.state.playerData.cPoints), buyAmount: this.state.regData.compPoints,
                                    type: "CompPoints", text: "COMP_POINTS", amount: UM.numberWithCommas(this.playerCompoints), buyAmount: UM.numberWithCommas(this.state.regData.compPoints),
                                    enable: Number(this.playerCompoints) > Number(this.state.regData.compPoints)
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        this.setState({ regData: regData });
        this.setState({ info: info });
        // this.setState({
        //     // infoData: infoData, 
        //     tableLoded: true
        // });
        this.setState({ infoData: infoData, tableLoded: true });
        // this.setState({ showOpenTableData: true })

    }
    onGetPlayers(data) {
        let participants = this.state.participants;
        participants = [];
        let info = this.state.info;
        let playerArray;
        if (data.hasOwnProperty("Players")) {
            if (data.Players.attr.hasOwnProperty("total")) {
                info.players = data.Players.attr.total;
            }
            if (data.Players.hasOwnProperty("Player")) {
                playerArray = data.Players.Player;

                if (!Array.isArray(playerArray)) {
                    playerArray = [playerArray];
                }
                let i = 0,
                    cnt = playerArray.length;
                for (i; i < cnt; i++) {
                    let object = new Object();
                    object.nickName = playerArray[i].attr.nickname;
                    if (playerArray[i].attr.hasOwnProperty("stack")) {
                        object.chips = playerArray[i].attr.prizeAmount;
                    } else {
                        object.chips = "-";
                    }
                    if (playerArray[i].attr.hasOwnProperty("placeTo")) {
                        object.place = playerArray[i].attr.placeTo;
                    } else {
                        object.place = "-";
                    }
                    if (playerArray[i].attr.hasOwnProperty("knockoutPrize")) {
                        // object.knockoutFee = Number(playerArray[i].attr.knockoutFee) + Number(playerArray[i].attr.knockoutAmount);
                        object.knockoutFee = Number(playerArray[i].attr.knockoutPrize);
                    } else {
                        object.knockoutFee = "-";
                    }
                    if (playerArray[i].attr.nickname == this.state.regData.name) {
                        if (playerArray[i].attr.hasOwnProperty("placeTo")) {
                            this.setState({ isRanked: true, watchorjoin: "Watch Table" });
                            if (this.state.reEntryTable.isTourneyRetry && Number(this.state.reEntryTable.noOfRetries) <= Number(this.state.reEntryTable.maxRetryCount) && this.state.details.status === "Late Registration") {
                                this.setState({ shiowRegistredbtn: true, shiowRegistredtext: "Register" });
                            }
                        } else {
                            this.setState({ isRanked: false, watchorjoin: "Join Table" })
                        }
                    }
                    participants.push(object);
                }
            }
            if (data.Players.attr.total === 0) {
                participants = [{ nickName: "", chips: "No Players Registered Currently", place: "" }];
            } else {
                let tourneyData = this.state.tourneyData;
                tourneyData.players = data.Players.attr.total;
                this.setState({ tourneyData: tourneyData });
            }
            this.setState({ participants: participants, info: info });

        }
    }

    onGetTables(data) {
        let openTables = this.state.openTables;
        let tabledata = [];
        openTables = [];
        if (data.hasOwnProperty("Tables")) {
            if (data.Tables.hasOwnProperty("Table")) {
                if (Array.isArray(data.Tables.Table)) {
                    tabledata = data.Tables.Table;
                } else {
                    tabledata = [data.Tables.Table];
                }
                let i = 0,
                    cnt = tabledata.length;

                for (i; i < cnt; i++) {
                    let object = {};
                    object.table = i + 1;
                    object.players = tabledata[i].attr.players;
                    object.largest = Number(tabledata[i].attr.highestStack).toLocaleString("en-US");
                    object.smallest = Number(tabledata[i].attr.lowestStack).toLocaleString("en-US");
                    object.id = tabledata[i].attr.id;
                    object.type = "TOURNAMENT_TABLE";
                    openTables.push(object);
                }
            }
            if (data.Tables.attr.total === 0) {
                openTables = [{ players: "There are no Tables", largest: "", smallest: "" }];
            }

            this.setState({
                openTables: openTables,
                // tableLoded:true 
            });
        }
        this.TourneylobbyNetworkReady = true;
    }
    onGetTournyDetails(data) {
        console.log("======================onGetTournyDetails================================")
        this.setState({ tableLoded: false })
        let tourneyData = this.state.tourneyData;
        if (data.TournamentDetails.hasOwnProperty("ScheduledTournament")) {
            tourneyData.id = data.TournamentDetails.attr.id;
            tourneyData.type = data.TournamentDetails.attr.type;
            tourneyData.typeText = "Scheduled";
            tourneyData.date = new Date(parseInt(data.TournamentDetails.ScheduledTournament.Schedule.attr.tournamentStart)).toLocaleString("en");
            tourneyData.description = data.TournamentDetails.ScheduledTournament.Description;
            tourneyData.name = data.TournamentDetails.attr.name;
            tourneyData.buyIn = data.TournamentDetails.ScheduledTournament.Parameters.attr.buyIn;
            tourneyData.players = data.TournamentDetails.ScheduledTournament.Participants.attr.total;
            tourneyData.prize = data.TournamentDetails.ScheduledTournament.PrizeInfo.attr.prizePool;

            switch (data.TournamentDetails.ScheduledTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    tourneyData.status = "Cancelled";
                    break;
                case "CANCELED_AFTER_START":
                    tourneyData.status = "Cancelled";
                    break;
                case "COMPLETED":
                    tourneyData.status = "Completed";
                    break;
                case "SEATING":
                    tourneyData.status = "Seating";
                    break;
                case "REGISTERING":
                    tourneyData.status = "Registering";
                    break;
                case "ANNOUNCED":
                    tourneyData.status = "Announced";
                    break;
                case "LATE_REG":
                    tourneyData.status = "Late Registration";
                    break;
                default:
                    tourneyData.status = data.TournamentDetails.ScheduledTournament.Schedule.attr.status;
                    break;
            }
            this.setState({ tourneyData: tourneyData });
            if (data.TournamentDetails.ScheduledTournament.hasOwnProperty("Schedule")) {
                // "REGISTERING" "SEATING"
                if (data.TournamentDetails.ScheduledTournament.Schedule.attr.status === "CANCELLING" || data.TournamentDetails.ScheduledTournament.Schedule.attr.status === "CANCELED_BEFORE_START" || data.TournamentDetails.ScheduledTournament.Schedule.attr.status === "CANCELED_AFTER_START" || data.TournamentDetails.ScheduledTournament.Schedule.attr.status === "COMPLETED") {
                    this.setState({ showOpenTableData: true })
                }
            }

        }
        if (data.TournamentDetails.hasOwnProperty("SitAndGoTournament")) {
            tourneyData.id = data.TournamentDetails.attr.id;
            tourneyData.type = data.TournamentDetails.attr.type;
            tourneyData.typeText = "Scheduled";
            tourneyData.date = new Date(parseInt(data.TournamentDetails.SitAndGoTournament.Schedule.attr.tournamentStart)).toLocaleString("en");
            tourneyData.description = data.TournamentDetails.SitAndGoTournament.Description;
            tourneyData.name = data.TournamentDetails.attr.name;
            tourneyData.buyIn = data.TournamentDetails.SitAndGoTournament.Parameters.attr.buyIn;
            tourneyData.players = data.TournamentDetails.SitAndGoTournament.Participants.attr.total;
            tourneyData.prize = data.TournamentDetails.SitAndGoTournament.PrizeInfo.attr.prizePool;
            switch (data.TournamentDetails.SitAndGoTournament.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    tourneyData.status = "Cancelled";
                    break;
                case "CANCELED_AFTER_START":
                    tourneyData.status = "Cancelled";
                    break;
                case "COMPLETED":
                    tourneyData.status = "Completed";
                    break;
                case "SEATING":
                    tourneyData.status = "Seating";
                    break;
                case "REGISTERING":
                    tourneyData.status = "Registering";
                    break;
                case "ANNOUNCED":
                    tourneyData.status = "Announced";
                    break;
                case "LATE_REG":
                    tourneyData.status = "Late Registration";
                    break;
                default:
                    tourneyData.status = data.TournamentDetails.SitAndGoTournament.Schedule.attr.status;
                    break;
            }
            this.setState({ tourneyData: tourneyData });
        }
        // this.setState({ tableLoded: true })
    }

    onGetTableDetails(data) {
        console.log("get Player Info is called", data);
        let tableData = this.state.tableData;
        if (data.hasOwnProperty("TableDetails")) {
            this.setState({ opentabledetails: { id: data.TableDetails.attr.id, type: data.TableDetails.attr.type } })
        }

        if (data.TableDetails.hasOwnProperty("TournamentTable")) {
            if (data.TableDetails.TournamentTable.hasOwnProperty("Seats")) {
                if (data.TableDetails.TournamentTable.Seats.hasOwnProperty("Seat")) {
                    let tables = data.TableDetails.TournamentTable.Seats.Seat;
                    let i = 0,
                        cnt = tables.length;
                    tableData = [];
                    for (i; i < cnt; i++) {
                        if (tables[i].hasOwnProperty("PlayerInfo")) {
                            tableData.push({ players: tables[i].PlayerInfo.attr.nickname, chips: Number(tables[i].PlayerInfo.Chips.attr["stack-size"]).toLocaleString("en-US") });
                            console.log(this.state.regData.name);
                            if (this.state.regData.name === tables[i].PlayerInfo.attr.nickname) {
                                this.state.regData.tableIdNo = tables[i].PlayerInfo.Table.attr.id;
                                this.setState({ shiowRegistredbtn: false });
                                // if (this.state.reEntryTable.isTourneyRetry && Number(this.state.reEntryTable.noOfRetries) <= Number(this.state.reEntryTable.maxRetryCount)) {
                                //     this.setState({ shiowRegistredtext: "Register" });
                                // }
                            }
                        }
                    }
                    console.log("Table DAta", tableData);
                    this.setState({ tableData: tableData });
                }
            }
        }
    }
    onTournamentPlayerRegistered(data) {
        let regData = this.state.regData;
        regData.status = "Registered";
        regData.statusAction = "Unregister";
        this.setState({ shiowRegistredtext: "Unregister" });
        this.setState({ regAlert: "You Are Succesfully registered👍" });
        this.setState({ regData: regData });
    }
    onTournamentPlayerUnregistered(data) {
        let regData = this.state.regData;
        regData.status = "Unregistered";
        regData.statusAction = "Register";
        this.setState({ shiowRegistredtext: "Register" });
        this.setState({ regAlert: "You Are Succesfully Unregistered" });
        this.setState({ regData: regData });
    }
    onGetSchedule(data) {
        let infoData = this.state.infoData;
        let details = this.state.details;
        if (data.Schedule.hasOwnProperty("attr")) {
            if (data.Schedule.attr.status === "CANCELLING" || data.Schedule.attr.status.status === "CANCELED_BEFORE_START" || data.Schedule.attr.status.status === "CANCELED_AFTER_START" || data.Schedule.attr.status.status === "COMPLETED") {
                this.setState({ showOpenTableData: true })
            }
            infoData.data.TouroStatus = data.Schedule.attr.status
            // details.status = data.Schedule.attr.status
            switch (data.Schedule.attr.status) {
                case "CANCELED_BEFORE_START":
                    details.status = "Cancelled";
                    break;
                case "CANCELED_AFTER_START":
                    details.status = "Cancelled";
                    break;
                case "COMPLETED":
                    details.status = "Completed";
                    break;
                case "SEATING":
                    details.status = "Seating";
                    break;
                case "REGISTERING":
                    details.status = "Registering";
                    break;
                case "ANNOUNCED":
                    details.status = "Announced";
                    break;
                case "LATE_REG":
                    details.status = "Late Registration";
                    break;
                default:
                    details.status = data.Schedule.attr.status;
                    break;
            }
        }
        if (data.Schedule.hasOwnProperty("Item")) {
            let i = 0,
                cnt = data.Schedule.Item.length;
            infoData.info = [];
            let boolean = this.addonpresent;

            // console.log(data)

            for (i; i < cnt; i++) {

                if (data.Schedule.attr.hasOwnProperty("currentLevel")) {
                    if (data.Schedule.attr.hasOwnProperty("onBreak") && data.Schedule.attr?.onBreak === "true") {
                        if (data.Schedule.attr.currentLevel === data.Schedule.Item[i - 1]?.attr?.number) {
                            infoData.currentLevel = i;
                            Emitter.emit("tableOnBreak", this.state.openTables);
                        }
                    } else {
                        if (data.Schedule.attr.currentLevel === data.Schedule.Item[i].attr.number) {
                            infoData.currentLevel = i;
                        }
                    }
                }

                if (data.Schedule.Item[i].attr.type === "LEVEL") {
                    let time = (Number(data.Schedule.Item[i].attr.periodTo) - Number(data.Schedule.Item[i].attr.periodFrom)) / 1000 / 60;
                    infoData.info.push(
                        {
                            level: data.Schedule.Item[i].attr.number,
                            stakes: `${Number(data.Schedule.Item[i].attr.lowStake).toLocaleString("en-US")}/${Number(data.Schedule.Item[i].attr.highStake).toLocaleString("en-US")}`,
                            ante: data.Schedule.Item[i].attr.hasOwnProperty("ante") ? data.Schedule.Item[i].attr.ante : "-",
                            period: `${time}m`
                        }
                    )
                } else {
                    let time = (Number(data.Schedule.Item[i].attr.periodTo) - Number(data.Schedule.Item[i].attr.periodFrom)) / 1000 / 60;
                    infoData.info.push({
                        level: boolean ? "Add on Break" : t("Break"),
                        stakes: "-",
                        ante: "-",
                        period: `${time}m`,
                    });
                    boolean = false;
                }
            }
        }
        this.setState({ details: details, infoData: infoData })
    }

    onGetError(data) {
        if (data.Error.hasOwnProperty("attr")) {
            if (data.Error.attr.hasOwnProperty("code")) {
                switch (data.Error.attr.code) {
                    case "010":
                        this.setState({ regAlert: "You don't have enough money to register" });
                        break;
                    case "005":
                        this.setState({ regAlert: "The tournament is not in registration status now" });
                        break;
                    default:
                        break;
                }
            }
        }
    }

    getGameType(game) {
        let gameType = "";
        switch (game) {
            case "TEXAS_HOLDEM_LIMIT":
                gameType = "FL Hold'em";
                break;
            case "TEXAS_HOLDEM_NO_LIMIT":
                gameType = "NL Hold'em";
                break;
            case "TEXAS_HOLDEM_POT_LIMIT":
                gameType = "PL Hold'em";
                break;
            case "OMAHA_LIMIT":
                gameType = "FL Omaha";
                break;
            case "OMAHA_NO_LIMIT":
                gameType = "NL Omaha";
                break;
            case "OMAHA_POT_LIMIT":
                gameType = "PL Omaha";
                break;
            case "OMAHA_FIVE_CARDS_LIMIT":
                gameType = "FL Omaha 5card";
                break;
            case "OMAHA_FIVE_CARDS_NO_LIMIT":
                gameType = "NL Omaha 5card";
                break;
            case "OMAHA_FIVE_CARDS_POT_LIMIT":
                gameType = "PL Omaha 5card";
                break;
            case "OMAHA_SIX_CARDS_LIMIT":
                gameType = "FL Omaha 6card";
                break;
            case "OMAHA_SIX_CARDS_NO_LIMIT":
                gameType = "NL Omaha 6card";
                break;
            case "OMAHA_SIX_CARDS_POT_LIMIT":
                gameType = "PL Omaha 6card";
                break;
            case "OMAHA_HIGH_LOW_NO_LIMIT":
                gameType = "NL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_POT_LIMIT":
                gameType = "PL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_LIMIT":
                gameType = "FL Omaha Hi-Lo";
                break;
            // case "TEXAS_HOLDEM_LIMIT":
            // 	break;
            default:
                gameType = "Not In List";
                console.log("game type is:.. " + game);
                break;
        }
        return gameType;
    }
    GETlIMITEXT(game) {
        let gameType = "";
        switch (game) {
            case "NO_LIMIT":
                gameType = "No Limit ";
                break;
            case "POT_LIMIT":
                gameType = "Pot Limit ";
                break;
            case "LIMIT":
                gameType = "Limit ";
                break;
            case "OMAHA_LIMIT":
                gameType = "FL Omaha";
                break;
            case "OMAHA_NO_LIMIT":
                gameType = "NL Omaha";
                break;
            case "OMAHA_POT_LIMIT":
                gameType = "PL Omaha";
                break;
            case "OMAHA_FIVE_CARDS_LIMIT":
                gameType = "FL Omaha 5card";
                break;
            case "OMAHA_FIVE_CARDS_NO_LIMIT":
                gameType = "NL Omaha 5card";
                break;
            case "OMAHA_FIVE_CARDS_POT_LIMIT":
                gameType = "PL Omaha 5card";
                break;
            case "OMAHA_SIX_CARDS_LIMIT":
                gameType = "FL Omaha 6card";
                break;
            case "OMAHA_SIX_CARDS_NO_LIMIT":
                gameType = "NL Omaha 6card";
                break;
            case "OMAHA_SIX_CARDS_POT_LIMIT":
                gameType = "PL Omaha 6card";
                break;
            case "OMAHA_HIGH_LOW_NO_LIMIT":
                gameType = "NL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_POT_LIMIT":
                gameType = "PL Omaha Hi-Lo";
                break;
            case "OMAHA_HIGH_LOW_LIMIT":
                gameType = "FL Omaha Hi-Lo";
                break;
            // case "TEXAS_HOLDEM_LIMIT":
            // 	break;
            default:
                gameType = "Not In List";
                console.log("game type is:.. " + game);
                break;
        }
        return gameType;
    }

    setPopUpActionsOpen(action) {
        switch (action) {
            case "REG":
                this._tourneyNetwork.send("<GetTournamentDetails/>");
                this.setState({ showRegistration: true, BgOpacity: 0.2, regAlert: "" });
                break;
            case "INFO":
                this.setState({ showInfo: true, BgOpacity: 0.2 });
                this.setState({ tableLoded: true })
                break;
            case "UNREG":

                this.setState({ showUnRegistration: true });
                setTimeout(() => {
                    this.setState({ showUnRegistration: false });
                    this.setState({ BgOpacity: 1 });
                }, 4000);
                break;
            case "closeTourneyLobby":
                // this.closeTheLobby();
                this._tourneyNetwork.send("<CloseTournamentLobby/>");
                this._tourneyNetwork.close(false);
                this.first = true;
                this.setState({ tableLoded: false });
                break;
            default:
                break;
        }
    }
    setPopUpActionsClose(action) {
        this.setState({ BgOpacity: 1 });
        switch (action) {
            case "REG":
                this._tourneyNetwork.send("<GetPlayerInfo/>");
                this.setState({ showRegistration: false, regAlert: "" });
                break;
            case "INFO":
                this._tourneyNetwork.send("<GetPlayerInfo/>");
                this.setState({ showInfo: false });
                break;
            case "UNREG":
                this._tourneyNetwork.send("<GetPlayerInfo/>");
                this.setState({ showUnRegistration: false });
                break;
            case "Update":
                this._tourneyNetwork.send("<GetTournamentDetails/>");
                break;
            default:
                break;
        }
    }
    preLoader(data) {
        switch (data) {
            case "show":
                this.setState({ tableLoded: false })
                break;
            case "hide":
                break;
            default:
                break;
        }
    }
    onGetOpenTournamentTable(data) {
        // let value = { sid: this.props.data.sid, tourneyId: this.props.data.tourneyId };

        // var tableWindow = window.open(`${window.location}`, `_self`);
        // tableWindow.name = `tableid0=${data.OpenTournamentTable.attr.id}`;

        // tableWindow.sessionStorage.setItem(data.OpenTournamentTable.attr.id, JSON.stringify(value));
        // tourney id, t table id are not the same
        if (this.config.URL_Environment.TableFeatures.selected == "F1") {
            this.props.TourneyHandler("hideMiniTableTab", false);
        }

        if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            this.props.TourneyHandler("hideMiniTableTab", true);
        }

        console.log("====>>>> t lobby id: ", this.tourneyData.tourneyId);
        console.log("====open the tourney table: ", data);
        // let a = {
        //     OpenTournamentTable: {
        //         attr: {
        //             id: "1291-35c7",
        //         },
        //     },
        // };
        setTimeout(() => {
            this.props.TourneyHandler("openTourneyTable", { tourney_table_id: data?.OpenTournamentTable?.attr?.id ?? null, id: this.tourneyData.tourneyId });
            this.setState({ showRegistration: false, showInfo: false, BgOpacity: 1, tableLoded: true });
        }, 2000)
    }

    // closeTheLobby() {
    clearTourneyLobby() {
        // this.onConnectionClosed();
        // this._tourneyNetwork.close(false);

        this.setState({
            tableLoded: false,
            showOpenTableData: false,
            BgOpacity: 1,
            tournmentstartLeftTime: { min: "", sec: "", enable: false },
            // playerData: {
            //     balance: "",
            //     tickets: "",
            //     tMoney: "",
            //     cPoints: "",
            //     playMoney: "",
            // },
            participants: [],
            tableData: [],
            openTables: [], //must include id and type of tables
            details: {
                buyIn: "",
                field1: "-",
                field2: "-",
                field3: "-",
                description: "",
                type: "",
                status: ""
            },
            info: {
                name: "-",
                date: "-",
                min: "-",
                max: "-",
                players: "-",
            },
            prizeInfo: {
                prizePool: "-",
                placesPaid: "-",
                entries: "-",
                rebuys: "-",
                addons: "-",
                data: [],
            },
            // regData: {
            //     types: [],
            //     status: "-",
            //     name: "-",
            //     balance: "-",
            //     type: "-",
            //     id: "",
            //     tableType: "",
            //     tableName: "",
            //     compPoints: "-",
            //     TM: "-"

            // },
            showRegistration: false,
            showUnRegistration: false,
            showInfo: false,
            showrestOf: true,
            infoData: {
                data: {
                    startingChips: "-",
                    blinds: "-",
                    rebuys: "",
                    addons: "",
                    text1: "",
                    text2: " ",
                    text3: " ",
                    minPlayers: 0,
                    maxPlayers: 0,
                    TouroStatus: ""

                },
                info: [],
                currentLevel: -1,
            },
            tourneyData: {
                name: "",
                date: "",
                buyIn: "",
                type: "",
                typeText: "",
                status: "",
                players: "",
                prize: "",
                description: "",
                id: "",
            },
        })
        console.log("clearing tourneylobby ")

        setTimeout(() => {
            console.log("========> close ", this.tourneyData);
            this.props.TourneyHandler("closeTourneyLobby", { id: this.tourneyData.tourneyId });
            this.tourneyData = {};
            this.setState({ tableLoded: false })
        }, 1500);
    }

    UpadateTableId() {
        console.log(this.props.idTables)
        this.setState({ gameTableid: this.props.idTables })
        // alert("updategame tables ids")
    }

    UpdateTLobbyDetails = () => {
        let startInterval = setInterval(() => {
            if (this._tourneyNetwork) {
                this._tourneyNetwork.send("<GetTournamentDetails/>");
                clearInterval(startInterval);
            }
        }, 1000);
    }
    UpdateTLobbyDetailsAfterTourney = () => {
        let startInterval = setInterval(() => {
            if (this._tourneyNetwork) {
                this.setState({ opentabledetails: { id: "", type: "" } });
                this._tourneyNetwork.send("<GetTournamentDetails/>");
                this._tourneyNetwork.send("<GetPlayerInfo/>");
                this._tourneyNetwork.send("<GetPlayers/>");
                this._tourneyNetwork.send("<GetTables/>");
                this._tourneyNetwork.send("<GetSchedule/>");
                this._tourneyNetwork.send("<GetTableDetails/>");
                clearInterval(startInterval);
            }
        }, 1000);
    }

    render() {
        return (
            <div className="fd">
                <div className="popCover_1" style={{ display: this.state.showUnRegistration ? 'block' : 'none' }}> </div>
                <div className="popup_1" style={{ display: this.state.showUnRegistration ? 'block' : 'none' }}>
                    <div className="popup_1_in">
                        <div className="head"> {t("Alert")}</div>
                        <div className="fd clr_ff p_15 text_center">
                            <div className="fd">{t("You Are Succesfully Unregistered")}</div>
                        </div>
                        <div className="fd">
                            <ul className="footUl">
                                <li>
                                    <button className="btn_1" onClick={() => this.setPopUpActionsClose("UNREG")}>{t("Ok")}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.showrestOf && Screen.getDeviceType().name == "Mobile" &&
                    <div>
                        <div className="fd" style={{ opacity: this.state.BgOpacity }}>
                            <TourneyButtons name={this.state.regData.statusAction} open={this.setPopUpActionsOpen.bind(this)}></TourneyButtons>
                            <TourneyDetails data={this.state.details} data1={this.state.info} network={this._tourneyNetwork}></TourneyDetails>
                            {this.state.tableData.length !== 0 && (
                                <TableData data={this.state.tableData}></TableData>
                            )}
                            {(!this.state.showOpenTableData && this.state.details.status !== "Cancelled" && this.TourneylobbyNetworkReady) &&
                                <OpenTables watchorjoin={this.state.watchorjoin} reEntryTable={this.state.reEntryTable} isRanked={this.state.isRanked} participants={this.state.participants} tableData={this.state.tableData} gameTableid={this.props.idTables} showCashTableTabs={this.props.showCashTableTabs} toggleCashTourneyTables={this.props.toggleCashTourneyTables} network={this._tourneyNetwork} status={this.state.details.status} data={this.state.openTables} joinTableId={this.state.regData} callMethod={this.openTablesRequest.bind(this)} open={this.setPopUpActionsOpen.bind(this)} name={this.state.regData.statusAction}></OpenTables>
                            }
                            {this.state.participants.length !== 0 && (
                                <Participants featear={false} data={this.state.participants} network={this._tourneyNetwork}></Participants>
                            )}
                            <PrizeInfo data={this.state.prizeInfo}></PrizeInfo>
                        </div>
                        <div className="fd">
                            {this.state.showRegistration &&
                                <TourneyRegistration data={this.state.regData} error={this.state.regAlert} close={this.setPopUpActionsClose.bind(this)} network={this._tourneyNetwork}></TourneyRegistration>
                            }
                            {this.state.showInfo && <TournamentInfo info={true} data={this.state.infoData} close={this.setPopUpActionsClose.bind(this)}></TournamentInfo>}
                        </div>
                    </div>}
                {this.state.showrestOf && Screen.getDeviceType().name == "Desktop" &&
                    <div className="fd desktop brd"
                    // style={{ opacity: this.state.BgOpacity }}
                    >
                        {/* <TourneyButtons name={this.state.regData.statusAction} open={this.setPopUpActionsOpen.bind(this)}></TourneyButtons> */}
                        <div className="row">
                            <div className="fd" >
                                <div className="col-8 f_right p_25 gridborded bg_new_1">
                                    <div className="fd">
                                        <span className="df_jc">
                                            {this.state.details.field1}
                                        </span>

                                    </div>
                                    <div className="fd">
                                        <span className="df_jc clr_5 txt_clr_4">
                                            {this.state.details.field4}
                                        </span>
                                    </div>
                                    <div className="fd">
                                        <span className="df_jc clr_5 txt_clr_4">
                                            {this.state.details.field5}
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-8 bg_new_1">
                                <div className="row">
                                    <div className="col-8 p_5" >
                                        <div className="gridborded" style={{ padding: "0px 0px 10px 0px", height: '100%' }}>
                                            <div className="fd">
                                                <span className="df_jc clr_5 tourneyLobbySectionHeader">
                                                    {this.state.details.field4}
                                                </span>
                                            </div>
                                            {this.state.info.Registerend !== "" &&
                                                <div className="df_jc_ac p_5">
                                                    <span className="col-6 d_flex jcs">Registration will end:</span><span className="col-6 d_flex jce">{!this.state.shiowRegistredbtn ? this.state.details.status : this.state.info.Registerend}</span>
                                                </div>
                                            }

                                            {/* <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Tournament will start :</span><span className="col-6 d_flex jce">{this.state.info.tournmentstart != "" ? this.state.info.tournmentstart : "When 2 Players registered"}</span>
                                            </div> */}
                                            {this.state.shiowRegistredbtn &&
                                                <div className="df_jc_ac p_5">
                                                    <span className="col-6 d_flex jcs">Tournament will start :
                                                    </span>
                                                    <span className="col-6 d_flex jce">
                                                        {this.state.info.tournmentstart !== "" ? (
                                                            <>
                                                                {this.state.info.tournmentstart}
                                                                {this.state.tournmentstartLeftTime.enable && (
                                                                    <CountdownTimer1
                                                                        initialMinutes={Number(this.state.tournmentstartLeftTime.min)}
                                                                        initialSeconds={Number(this.state.tournmentstartLeftTime.sec)}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            "When 2 Players registered"
                                                        )}
                                                    </span>
                                                    {/* {/ <span className="col-6 d_flex jce">{this.state.info.tournmentstart != "" ? this.state.info.tournmentstart   : "When 2 Players registered"}</span > /}
                                                {/ {this.state.tournmentstartLeftTime.enable&&<CountdownTimer1 initialMinutes={Number(this.state.tournmentstartLeftTime.min)} initialSeconds={(Number(this.state.tournmentstartLeftTime.sec))}></CountdownTimer1 >} /} */}
                                                </div>
                                            }
                                            <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Minimum Players:</span><span className="col-6 d_flex jce">{this.state.info.min}</span>
                                            </div>
                                            <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Maximum Players :</span><span className="col-6 d_flex jce">{this.state.info.max}</span>
                                            </div>
                                            {this.state.reEntryTable.isTourneyRetry &&
                                                <>
                                                    <div className="df_jc_ac p_5">
                                                        <span className="col-6 d_flex jcs">Maximum Entry Count :</span><span className="col-6 d_flex jce">{this.state.reEntryTable.maxRetryCount}</span>
                                                    </div>
                                                    <div className="df_jc_ac p_5">
                                                        <span className="col-6 d_flex jcs">No.Of Retries :</span><span className="col-6 d_flex jce">{this.state.reEntryTable.noOfRetries}</span>
                                                    </div>
                                                </>
                                            }
                                            <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Starting Chips :</span><span className="col-6 d_flex jce">{UM.numberWithCommas(this.state.infoData.data.startingChips)}</span>
                                            </div>
                                            <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Rebuy :</span><span className="col-6 d_flex jce">{this.state.prizeInfo.totalRebuys}</span>
                                            </div>
                                            <div className="df_jc_ac p_5">
                                                <span className="col-6 d_flex jcs">Addon :</span><span className="col-6 d_flex jce">{this.state.prizeInfo.totalAddons}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-4 p_5" >
                                        <PrizeInfo data={this.state.prizeInfo} reEntryTable={this.state.reEntryTable}></PrizeInfo>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8" >
                                        <div className="fd">
                                            {(this.TourneylobbyNetworkReady) &&
                                                <OpenTables1 watchorjoin={this.state.watchorjoin} reEntryTable={this.state.reEntryTable} isRanked={this.state.isRanked} gameTableid={this.props.idTables} showCashTableTabs={this.props.showCashTableTabs} toggleCashTourneyTables={this.props.toggleCashTourneyTables} network={this._tourneyNetwork} status={this.state.details.status} data={this.state.openTables} joinTableId={this.state.regData} callMethod={this.openTablesRequest.bind(this)} open={this.setPopUpActionsOpen.bind(this)} name={this.state.regData.statusAction}></OpenTables1>
                                            }

                                        </div>

                                    </div>
                                    <div className="col-4">
                                        <TableData data={this.state.tableData}></TableData>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 bg_new_1 gridborded m_t_5">
                                <Participants data={this.state.participants} network={this._tourneyNetwork} featear={false}></Participants>
                            </div>
                            <div className="col-12 bg_new_1">
                                <div className="fd p_t_10" >
                                    <div className="row">
                                        {(this.state.gameTableid.indexOf(this.state.opentabledetails.id) == -1 && !this.state.showOpenTableData) ?
                                            <div className="col-4">
                                                {(this.state.showwatchorjoin && this.state.opentabledetails.id != "") && <button className="btn_5"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        // if (this.props.idTables.indexOf(this.state.opentabledetails.id) != -1) {
                                                        if (this.state.watchorjoin == "Watch Table" && this.state.opentabledetails.id != "") {
                                                            this._tourneyNetwork.send(`<OpenTable id='${this.state.opentabledetails.id}' type='${this.state.opentabledetails.type}'/>`)
                                                            this.setState({ tableLoded: false })
                                                        } else if (this.state.watchorjoin == "Join Table") {
                                                            this._tourneyNetwork.send(`<OpenTable id='${this.state.opentabledetails.id}' type='${this.state.opentabledetails.type}'/>`)
                                                            console.log(`<OpenTable id='${this.state.opentabledetails.id}' type='${this.state.opentabledetails.type}'/>`)
                                                            this.setState({ tableLoded: false })
                                                        }
                                                        // }else{
                                                        // this.props.toggleCashTourneyTables()
                                                        // }
                                                        this.setState({ gameTableid: this.props.idTables })
                                                    }}
                                                >{this.state.watchorjoin}</button>}
                                            </div> :

                                            <div className="col-4">
                                                <button id="c-tables-tab" className="btn_5" data-name={this.props.showCashTableTabs ? "t-tables" : "c-tables"}
                                                    onClick={(e) => {
                                                        console.log(`<OpenTable id='${this.state.opentabledetails.id}' type='${this.state.opentabledetails.type}'/>`)
                                                        let tab = document.getElementById("c-tables-tab");
                                                        tab.disabled = true;
                                                        setTimeout(() => {
                                                            tab.disabled = false;
                                                        }, 5000);
                                                        this._tourneyNetwork.send(`<OpenTable id='${this.state.opentabledetails.id}' type='${this.state.opentabledetails.type}'/>`)
                                                        // this.props.toggleCashTourneyTables(e, "c-tables", this.state.opentabledetails.id)
                                                    }
                                                    }>
                                                    {this.props.showCashTableTabs ? "T.Tables" : this.state.watchorjoin}
                                                </button>
                                            </div>
                                        }
                                        <div className="col-4" >

                                            <button className="btn_5"
                                                onClick={(e) => {
                                                    e.preventDefault()

                                                    this.setPopUpActionsOpen("INFO")
                                                }}
                                            >Info</button>
                                            {/* <button className="btn_5">Invite</button> */}


                                        </div>
                                        <div className="col-4" >
                                            {(this.state.shiowRegistredbtn) && <button className="btn_5" onClick={() => {
                                                if (this.state.shiowRegistredtext === "Register") {
                                                    this._tourneyNetwork.send("<GetPlayerInfo/>");
                                                    this.setPopUpActionsOpen("REG");
                                                } else if (this.state.shiowRegistredtext === "Unregister") {
                                                    this._tourneyNetwork.send("<UnRegisterTournamentPlayer type='BALANCE' tournamentId='22-2f7c7'/>")
                                                    this.setPopUpActionsOpen("UNREG");
                                                }
                                            }}>{this.state.shiowRegistredtext}</button>}

                                            <button className="btn_5" onClick={(e) => {
                                                e.preventDefault()
                                                this.setPopUpActionsOpen("closeTourneyLobby")
                                            }}
                                            >Exit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="fd" >
                            {this.state.showRegistration &&
                                <TourneyRegistration data={this.state.regData} error={this.state.regAlert} close={this.setPopUpActionsClose.bind(this)} network={this._tourneyNetwork}></TourneyRegistration>
                            }
                            {this.state.showInfo && <TournamentInfo info={false} data={this.state.infoData} close={this.setPopUpActionsClose.bind(this)}></TournamentInfo>}
                        </div>
                    </div>}
                {!this.state.tableLoded && <Spinner></Spinner>}
            </div >
        );
    }
}
