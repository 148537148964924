
import React from "react";

import "../../../../css/ui/popUps/buyChips.css";
import { useMemo, useEffect, useState } from "react";
import close_1 from './../../../../assets/images/table/close_1.svg';
import dollar from './../../../../assets/images/table/dollar.svg';
import slider_thumb from '../../../../assets/images/table/slider_thumb.png';
import { withTranslation } from 'react-i18next';
import fileName from "../../../../jsconfig";
import Screen from "../../../utils/screen";
import Config from '../../../../config';
import UM from "../../../utils/utilityMethods";
// import useMemo from "react";
import { useTable } from "react-table";
function BuyChips(props) {
  // console.log(props.data)
  // console.log(props.onMoneyExchangeInfo)
  // console.log(props.usdTable)
  var config = new Config();
  // console.log("props from Buy chips window are");
  // console.log(props);

  // const columns = useMemo(() => {
  //   if (props.onMoneyExchangeInfo && props.onMoneyExchangeInfo.hasOwnProperty("attr")) {
  //     return [
  //       {
  //         Header: 'Wallet',
  //         accessor: props.Balance.attr.wallet,
  //       },
  //       {
  //         Header: "Available",
  //         accessor: props.Balance.attr.cash,
  //       },
  //       {
  //         Header: "Rate",
  //         accessor: "",
  //       },
  //       {
  //         Header: "Amount to Receive in USD",
  //         accessor: "",
  //       }
  //     ];
  //   } else {
  //     return [];
  //   }
  // }, [props.onMoneyExchangeInfo]);

  // const data = useMemo(() => props.onMoneyExchangeInfo, [props.onMoneyExchangeInfo]);
  // const tableInstance = useTable({
  //   columns,
  //   data,
  // });
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;


  const [min, setMin] = useState(props.data.min);
  const [max, setMax] = useState(props.data.max);
  const [available, setAvailable] = useState(props.data.available);
  const [newAvlBal, setNewAvlBal] = useState(props.data.newAvlBal);
  const [newMinBal, setNewMinBal] = useState(props.data.newMinBal);
  const [newMaxBal, setNewMaxBal] = useState(props.data.newMaxBal);
  const [time, setTime] = useState(props.data.time);
  const [showTimer, setShowTimer] = useState(props.data.runTimer);
  const [btnState, setbtnState] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsgText, setErrorMsgText] = useState(false);
  const [amount, setAmount] = useState(min);
  const [inputValues, setInputValues] = useState({
    cash: 0,
    bonus: 0,
  });

  useEffect(() => {
    let isSubscribed = true;
    setMin(props.data.min);
    setAvailable(props.data.available);
    setNewAvlBal(props.data.newAvlBal)
    setNewMinBal(props.data.newMinBal)
    setNewMaxBal(props.data.newMaxBal)
    setMax(props.data.max);
    setAmount(props.data.min);
    setTime(props.data.time);
    setShowTimer(props.data.runTimer);
    return () => (isSubscribed = false)
  }, [props.data]);



  // useEffect(() => {
  //   let isSubscribed = true;
  //   // console.log(props.data);
  //   if (Number(props.data.available) < Number(props.data.min)) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Your minimum balance shouldn't be greater then maximum balance..!")
  //   } else if (Number(props.data.min) == 0 && Number(props.data.max) == 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("You can't bring more chips to this table..!")

  //   } else if (Number(props.data.available) === 0) {
  //     setbtnState(true)
  //     setErrorMsg(true)
  //     setErrorMsgText("Please check  your balance..!")
  //   }
  //   else {
  //     setErrorMsgText("")
  //     setErrorMsg(false)
  //     setbtnState(false)
  //   }
  //   // if (props.data.runTimer) {
  //   // console.log(props.data.runTimer)
  //   let t = time;
  //   const buyChipsTimer = setInterval(() => {
  //     if (props.data.runTimer) {
  //       if (t > 0) {
  //         t = t - 1;
  //         setTime(t);
  //       } else {
  //         clearInterval(buyChipsTimer);
  //         props.setAction("hideBuyChips1");
  //       }
  //     }
  //   }, 1000);
  //   // }
  //   return () => {
  //     isSubscribed = false
  //     clearInterval(buyChipsTimer);
  //   }
  // }, [props, time, showTimer]);

  useEffect(() => {
    let isSubscribed = true;
    if (Number(newAvlBal) < Number(props.data.min)) {
      setbtnState(true)
      setErrorMsg(true)
      // setErrorMsgText("Your minimum balance shouldn't be greater then maximum balance..!")
      // if (props.usdTable) {
      if (header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) {
        setErrorMsgText(`You don't have enough money in ${props.usdTable ? "USD" : "CHP"} wallet. You can exchange money from other wallet.`);
      }
      if (props.onMoneyExchangeInfo.hasOwnProperty("attr")) {
        setNewAvlBal(props.onMoneyExchangeInfo.attr.cash);
      }
      // } else {
      //   setErrorMsgText("Your current balance is less than Minimum buy-in");
      // }
    } else {
      setErrorMsgText("");
      setErrorMsg(false);
      setbtnState(false);
    }
    var buyChipsTimer;
    if (props.data.runTimer) {
      let t = time;
      buyChipsTimer = setInterval(() => {
        if (t > 0) {
          t = t - 1;
          setTime(t);
        } else {
          clearInterval(buyChipsTimer);
          props.setAction("hideBuyChips");
        }
      }, 1000);
    } else {

    }
    return () => {
      isSubscribed = false
      clearInterval(buyChipsTimer);
    }
  }, [props, time, showTimer]);




  const onClickBuy = () => {
    // console.log(props.data.max)
    // console.log(props.data.min)
    // console.log(amount)
    if (Number(props.data.max) < Number(props.data.min)) {
      // if (Number(props.data.min) > Number(amount)) {
      setErrorMsg(true);
      setErrorMsgText("balance is low")
    } else {
      setErrorMsg(false);
      setErrorMsgText("");
      if (amount === 0) {
        setErrorMsg(true)
        setErrorMsgText("Minimum buy 1 chip")
      }
      else if (Number(props.data.min) > Number(amount)) {
        setErrorMsg(true);
        setErrorMsgText("please check minimun buyin" + `${Number(props.data.min)}`);
      } else {
        setErrorMsg(false)
        setErrorMsgText("")
        if (Number(props.data.min) <= Number(amount)) {
          props.network.send(`<BuyChips amount="${amount}"/>`);
          props.setAction("hideBuyChips");
        }

      }

    }
  };
  const [header, setHeader] = useState(`Add Chips - ${props.usdTable ? "USD" : "CHP"}`);

  const onClickExchange = () => {
    setErrorMsgText("");
    if (header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) {
      setHeader("Currency Exchange");
      props.network.send(`<GetMoneyExchangeInfo wallet=${props.usdTable ? "'USD'" : "'CHP'"}  />`);
      setTimeout(() => {
        props.network.send(`<CancelBuyChips />`);
      }, 100);
      // props.network.send(`<GetMoneyExchangeInfo wallet="CHP"  />`);
    } else {
      // onClickCancel()
      if (Number(inputValues['cash']) > 0) {
        props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} cash="${inputValues['cash']}" /></Sources></ExchangeMoney>`);
      }
      if (Number(inputValues['bonus']) > 0) {
        setTimeout(() => {
          props.network.send(`<ExchangeMoney wallet=${props.usdTable ? "'USD'" : "'CHP'"}><Sources><ExchangeSource wallet=${!props.usdTable ? "'USD'" : "'CHP'"} bonus="${inputValues['bonus']}" /></Sources></ExchangeMoney>`);
        }, 100);
      }
      setNewAvlBal((Number(props.onMoneyExchangeInfo.attr.cash) + (Number(inputValues['cash']) + (Number(inputValues['bonus'])))))
      // props.network.send(`<CancelBuyChips />`);
      setTimeout(() => {
        console.log(props.onMoneyExchangeInfo.attr.cash)
        if (Number(props.data.min) <= (Number(props.onMoneyExchangeInfo.attr.cash) + (Number(inputValues['cash']) + (Number(inputValues['bonus']))))) {
          props.onGetTakeSeat("", props.data.time > 0 ? "take_seat" : "rebuy_chips");
        }
      }, 1000);
      setHeader(`Add Chips - ${props.usdTable ? "USD" : "CHP"}`);
    }
  }



  function changeAmtlabl(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
          : Math.abs(Number(labelValue));
  };


  const onClickCancel = () => {
    props.network.send(`<CancelBuyChips />`)
    props.setAction("hideBuyChips1");
  };

  const onChangeSlider = (e) => {
    let sv = document.getElementById("sliderBar");
    // console.log(sv.value)
    setAmount(sv.value);

    const sliderWrapper = document.querySelector('.slider-wrapper');
    const sliderInput = document.querySelector('.slider-input');
    const sliderThumb = document.querySelector('.slider-thumb');


    const maxValue = +sliderInput.max || 0;

    const colorStops = [
      { r: 98, g: 173, b: 29 },
      { r: 251, g: 189, b: 30 },
      { r: 251, g: 110, b: 30 },
      { r: 231, g: 26, b: 26 },
    ];

    const updateSlider = () => {
      // var x = 100 / e.target.max
      const progress = 100 * (e.target.value - e.target.min) / (e.target.max - e.target.min)
      // const progress = ((e.target.value) * x);
      sliderWrapper.style.setProperty('--slider-progress', progress);
    }

    sliderInput.addEventListener('input', updateSlider);

    updateSlider();

    let pointerHoldingRightTimer;
    let isTimerRunning = false;

    const detectPointerHoldingRight = (event) => {
      const isSliderAtMax = +sliderInput.value === maxValue;

      const sliderRect = sliderWrapper.getBoundingClientRect();
      const pointerX = event.clientX;
      const offset = 40;
      const isPointerOnSliderRight = pointerX >= (sliderRect.right + offset);

      if (isSliderAtMax && isPointerOnSliderRight) {
        if (!isTimerRunning) {
          isTimerRunning = true;
          pointerHoldingRightTimer = setTimeout(() => {
            sliderInput.removeEventListener('pointerdown', detectSecret);
            colorStops.push({ r: 34, g: 0, b: 0 });
            sliderWrapper.classList.add('unlocked');
            sliderThumb.addEventListener('animationend', () => {
              sliderThumb.classList.remove('play-explosion');
            });
            sliderThumb.classList.add('play-explosion');
          }, 1000);
        }
      } else {
        clearTimeout(pointerHoldingRightTimer);
        isTimerRunning = false;
      }
    };

    const detectSecret = () => {
      window.addEventListener('pointermove', detectPointerHoldingRight);
      sliderInput.addEventListener('pointerup', () => {
        window.removeEventListener('pointermove', detectPointerHoldingRight);
        clearTimeout(pointerHoldingRightTimer);
      });
    }
    sliderInput.addEventListener('pointerdown', detectSecret);
  };
  const onChangeInputNumber = () => {
    let tv = document.getElementById("textInput");
    // console.log(tv.value)
    setAmount(tv.value);

    const sliderWrapper = document.querySelector('.slider-wrapper');
    if (Number(max) <= Number(tv.value)) {
      var x = 100 / max
      const progress = ((max) * x);
      sliderWrapper.style.setProperty('--slider-progress', progress);
      setAmount(max);
    } else if (Number(tv.value) === 0) {
      setAmount(0);
    } else {
      var x = 100 / max
      const progress = ((tv.value) * x);
      sliderWrapper.style.setProperty('--slider-progress', progress);
      setAmount(tv.value);
    }
    setTimeout(() => {
      if (Number(min) >= Number(tv.value)) {
        var x = 100 / max
        const progress = ((min) * x);
        sliderWrapper.style.setProperty('--slider-progress', progress);
        setAmount(min);
      }
    }, 9000);

  };


  const onTestSlider = () => {
    var slider = document.getElementById("myRange");
    setAmount(slider.value)
    slider.oninput = function () {
      var value = (this.value - this.min) / (this.max - this.min) * 100;
      this.style.background = 'linear-gradient(to right, #4CAF50 0%, #4CAF50 ' + value + '%, #d3d3d3 ' + value + '%, #d3d3d3 100%)';
    }
  }


  const COLOR_CODES = {
    info: {
      color: "green"
    },
    warning: {
      color: "orange",
      threshold: 10
    },
    alert: {
      color: "red",
      threshold: 5
    }
  };
  const COLOR_CODES_lB = {
    info: {
      color: "red"
    },
    warning: {
      color: "red",
      threshold: 10
    },
    alert: {
      color: "red",
      threshold: 5
    }
  };
  const TIME_LIMIT = time;
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;

  useEffect(() => {
    let timerInterval;
    if (fileName.name === "Leader_bet") {
      startTimer();

      function onTimesUp() {
        clearInterval(timerInterval);
      }

      function startTimer() {
        timerInterval = setInterval(() => {
          timePassed += 1;
          timeLeft = TIME_LIMIT - timePassed;
          if (Screen.getDeviceType().name !== "Mobile") {
            document.getElementById("base-timer-label").innerHTML = formatTime(timeLeft);
          }
          setCircleDasharray();
          setRemainingPathColor(timeLeft);
          if (timeLeft === 0) {
            onTimesUp();
          }
        }, 1000);
      }

      function formatTime(time) {
        const minutes = Math.floor(time / 60);
        let seconds = time % 60;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${minutes}:${seconds}`;
      }
      var remainingPathColor = (Screen.getDeviceType().name !== "Mobile" ? COLOR_CODES.info.color : COLOR_CODES_lB.info.color);
      // var remainingPathColor = COLOR_CODES.info.color;
      const pathElement = document.getElementById("base-timer-path-remaining");
      pathElement.classList.add(remainingPathColor);
      function setRemainingPathColor(timeLeft) {
        const { alert, warning, info } = COLOR_CODES;
        if (timeLeft <= alert.threshold) {
          pathElement.classList.remove(warning.color);
          pathElement.classList.add(alert.color);
        } else if (timeLeft <= warning.threshold) {
          pathElement.classList.remove(info.color);
          pathElement.classList.add(warning.color);
        }
      }

      function calculateTimeFraction() {
        const rawTimeFraction = timeLeft / TIME_LIMIT;
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
      }
      let num = (Screen.getDeviceType().name !== "Mobile" ? 283 : 100);
      function setCircleDasharray() {
        // const circleDasharray = `${(calculateTimeFraction() * 283).toFixed(0)} 283`;
        const circleDasharray = `${(calculateTimeFraction() * num).toFixed(0)} 283`;
        pathElement.setAttribute("stroke-dasharray", circleDasharray);
      }

      return () => clearInterval(timerInterval);
    }
  }, [props.data.time]);



  const setMaxAmount = () => {
    var slider = document.getElementById("myRange");
    setAmount(slider.max)
    slider.style.background = '#3EB553';
  }

  const calculateChips = (e) => {
    var slider = document.getElementById("myRange");
    var presentAmount = parseFloat(slider.value);
    var twentyPercent = presentAmount * 0.20;
    if (e.target.value == '+') {
      if (Number(presentAmount) + Number(twentyPercent) > Number(slider.max)) {
        var newAmount = Number(slider.max);
        setAmount(slider.max)
      } else {
        var newAmount = presentAmount + twentyPercent;
        setAmount(newAmount)
      }
    } else {
      if (Number(presentAmount) - Number(twentyPercent) < Number(slider.min)) {
        var newAmount = 0;
        setAmount(slider.min)
      } else {
        var newAmount = presentAmount - twentyPercent;
        setAmount(newAmount)
      }
    }

    var value = (newAmount - min) / (max - min) * 100;
    slider.style.background = 'linear-gradient(to right, #4CAF50 0%, #4CAF50 ' + value + '%, #d3d3d3 ' + value + '%, #d3d3d3 100%)';
  }

  // const handleInputChange = (e, attrKey) => {
  //   const newValue = e.target.value;
  //   console.log(props.onMoneyExchangeInfo.Balance.attr.cash);
  //   console.log(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate);
  //   console.log(newValue);
  //   const totalAmountCash = Number(props.onMoneyExchangeInfo.Balance.attr.cash) * Number(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate);
  //   const totalAmountBonus = Number(props.onMoneyExchangeInfo.Balance.attr.bonus) * Number(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate);
  //   console.log(totalAmountCash);
  //   console.log(totalAmountBonus);
  //   if (attrKey === "cash") {
  //     if (props.usdTable) {
  //       if (Number(newValue) < Number(totalAmountCash)) {
  //         setErrorMsgText("")
  //         setInputValues(prevState => ({
  //           ...prevState,
  //           [attrKey]: newValue
  //         }));
  //       } else {
  //         setErrorMsgText(`You can convert maximum ${(totalAmountCash).toFixed(2)} ${props.usdTable ? "USD" : "CHP"} only, with your ${!props.usdTable ? "USD" : "CHP"} ${attrKey}`)
  //       }
  //     } else {

  //     }
  //   } else if (attrKey === "bonus") {
  //     if (Number(newValue) < Number(totalAmountBonus)) {
  //       setErrorMsgText("")
  //       setInputValues(prevState => ({
  //         ...prevState,
  //         [attrKey]: newValue
  //       }));
  //     } else {
  //       setErrorMsgText(`You can convert maximum ${(totalAmountBonus).toFixed(2)} ${props.usdTable ? "USD" : "CHP"} only, with your ${!props.usdTable ? "USD" : "CHP"} ${attrKey}`)
  //     }
  //   }
  // };

  const [inputFocus, setInputFocus] = useState({ cash: false, bonus: false });

  const handleInputChange = (e, attrKey) => {
    const newValue = Number(e.target.value);
    const { usdTable, onMoneyExchangeInfo } = props;
    const { cash, bonus } = onMoneyExchangeInfo.Balance.attr;
    const chpToUsdRate = onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate;
    const usdToChpRate = 1 / chpToUsdRate;

    const totalAmountCash = usdTable ? cash * chpToUsdRate : cash * usdToChpRate;
    const totalAmountBonus = usdTable ? bonus * chpToUsdRate : bonus * usdToChpRate;

    const updateState = (amount, totalAmount, currency) => {
      if (newValue <= totalAmount) {
        setErrorMsgText("");
        setInputValues(prevState => ({
          ...prevState,
          [attrKey]: e.target.value
        }));
      } else {
        setErrorMsgText(`You can convert a maximum of ${UM.numberWithCommas(totalAmount)} ${currency} only.`);
      }
    };

    if (attrKey === "cash") {
      updateState(newValue, totalAmountCash, usdTable ? "USD" : "CHP");
    } else if (attrKey === "bonus") {
      updateState(newValue, totalAmountBonus, usdTable ? "USD" : "CHP");
    }
  };

  const handleInputFocus = (attrKey) => {
    setInputFocus(prevState => ({
      ...prevState,
      [attrKey]: true
    }));
  };

  const handleInputBlur = (attrKey) => {
    setInputFocus(prevState => ({
      ...prevState,
      [attrKey]: false
    }));

    if (inputValues[attrKey] === "" || inputValues[attrKey] === undefined) {
      setInputValues(prevState => ({
        ...prevState,
        [attrKey]: 0
      }));
    }
  };


  // const handleInputChange = (e, attrKey) => {
  //   const newValue = e.target.value;
  //   console.log(props.onMoneyExchangeInfo.Balance.attr.cash);
  //   console.log(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate);
  //   console.log(newValue);

  //   // Exchange rate: 1 CHP = 0.0000169 USD
  //   const chpToUsdRate = 0.0000169;
  //   const usdToChpRate = 1 / chpToUsdRate;

  //   let totalAmountCash, totalAmountBonus;

  //   if (props.usdTable) {
  //     // Conversion from CHP to USD
  //     totalAmountCash = Number(props.onMoneyExchangeInfo.Balance.attr.cash) * chpToUsdRate;
  //     totalAmountBonus = Number(props.onMoneyExchangeInfo.Balance.attr.bonus) * chpToUsdRate;
  //   } else {
  //     // Conversion from USD to CHP
  //     totalAmountCash = Number(props.onMoneyExchangeInfo.Balance.attr.cash) * usdToChpRate;
  //     totalAmountBonus = Number(props.onMoneyExchangeInfo.Balance.attr.bonus) * usdToChpRate;
  //   }

  //   console.log(totalAmountCash);
  //   console.log(totalAmountBonus);

  //   // Define minimum balance constraints
  //   const minBalance = 1; // You can adjust this value as needed

  //   if (attrKey === "cash") {
  //     if (props.usdTable) {
  //       if (Number(newValue) >= minBalance && Number(newValue) <= Number(totalAmountCash)) {
  //         setErrorMsgText("")
  //         setInputValues(prevState => ({
  //           ...prevState,
  //           [attrKey]: newValue
  //         }));
  //       } else if (Number(newValue) < minBalance) {
  //         setErrorMsgText(`You must convert at least ${minBalance.toFixed(2)} USD`)
  //       } else {
  //         setErrorMsgText(`You can convert a maximum of ${(totalAmountCash).toFixed(2)} USD only, with your CHP ${attrKey}`)
  //       }
  //     } else {
  //       if (Number(newValue) >= minBalance && Number(newValue) <= Number(totalAmountCash)) {
  //         setErrorMsgText("")
  //         setInputValues(prevState => ({
  //           ...prevState,
  //           [attrKey]: newValue
  //         }));
  //       } else if (Number(newValue) < minBalance) {
  //         setErrorMsgText(`You must convert at least ${minBalance.toFixed(2)} CHP`)
  //       } else {
  //         setErrorMsgText(`You can convert a maximum of ${(totalAmountCash).toFixed(2)} CHP only, with your USD ${attrKey}`)
  //       }
  //     }
  //   } else if (attrKey === "bonus") {
  //     if (props.usdTable) {
  //       if (Number(newValue) >= minBalance && Number(newValue) <= Number(totalAmountBonus)) {
  //         setErrorMsgText("")
  //         setInputValues(prevState => ({
  //           ...prevState,
  //           [attrKey]: newValue
  //         }));
  //       } else if (Number(newValue) < minBalance) {
  //         setErrorMsgText(`You must convert at least ${minBalance.toFixed(2)} USD`)
  //       } else {
  //         setErrorMsgText(`You can convert a maximum of ${(totalAmountBonus).toFixed(2)} USD only, with your CHP ${attrKey}`)
  //       }
  //     } else {
  //       if (Number(newValue) >= minBalance && Number(newValue) <= Number(totalAmountBonus)) {
  //         setErrorMsgText("")
  //         setInputValues(prevState => ({
  //           ...prevState,
  //           [attrKey]: newValue
  //         }));
  //       } else if (Number(newValue) < minBalance) {
  //         setErrorMsgText(`You must convert at least ${minBalance.toFixed(2)} CHP`)
  //       } else {
  //         setErrorMsgText(`You can convert a maximum of ${(totalAmountBonus).toFixed(2)} CHP only, with your USD ${attrKey}`)
  //       }
  //     }
  //   }
  // };



  return (
    <React.Fragment>
      <div className="popCover_1" onClick={onClickCancel}>  </div>
      {/* {(Number(min) > Number(newAvlBal) && props.usdTable && fileName.name === "Riverpoker") ? */}
      {(Number(min) > Number(newAvlBal) && fileName.name === "Riverpoker") ?

        <div className="popup_1" >
          <div className="popup_1_in">
            <div className="head">
              <span className="settingsSpan">
                <img src={dollar} alt="" style={{ display: fileName.name == "Leader_bet" ? 'none' : 'block' }} /> {header}</span>
              <button className="close_1" onClick={onClickCancel}> <img src={close_1} alt="" />  </button>
            </div>
            {errorMsg && (
              <div className="errorMsgDiv">
                <span>{errorMsgText}</span>
              </div>
            )}
            {header !== `Add Chips - ${props.usdTable ? "USD" : "CHP"}` ?
              <div div className="fd p_10 exChangeDiv">
                <p>Exchange funds to {props.usdTable ? "USD" : "CHP"} </p>
                {/* <table className="table_1" {...getTableProps()} id="table" >
                  <thead key={1}>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.render("Header")}>{column.render("Header")}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr data-id={row.original.id} data-type={row.original.type} {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table> */}

                {(props.onMoneyExchangeInfo && props.onMoneyExchangeInfo.hasOwnProperty("attr")) &&
                  <>
                    <table>
                      <thead>
                        <tr>
                          <td>Wallet</td>
                          <td>Available</td>
                          <td>Rate</td>
                          <td>Amount to Receive in {props.usdTable ? "USD" : "CHP"}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                          <td>{props.usdTable ? "CHP" : "USD"}  Cash</td>
                          <td>{UM.numberWithCommas(Number(props.onMoneyExchangeInfo.Balance.attr.cash).toFixed(2))}</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
                          <td>
                            <span>
                              <input type="number" value={inputValues['cash']} onChange={(e) => handleInputChange(e, 'cash')} />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} ( {props.usdTable ? UM.numberWithCommas((inputValues['cash'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2)) :
                                UM.numberWithCommas((inputValues['cash'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                              })
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td>{props.usdTable ? "CHP" : "USD"} Bonus</td>
                          <td>{UM.numberWithCommas(Number(props.onMoneyExchangeInfo.Balance.attr.bonus).toFixed(2))}</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
                          <td>
                            <span>
                              <input type="number" value={inputValues['bonus']} onChange={(e) => handleInputChange(e, 'bonus')} />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} ({props.usdTable ? UM.numberWithCommas((inputValues['bonus'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2)) :
                                UM.numberWithCommas((inputValues['bonus'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate).toFixed(2))
                              })
                            </span>
                          </td>
                        </tr> */}


                        <tr>
                          <td>{props.usdTable ? "CHP" : "USD"} Cash</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.cash)}</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
                          <td>
                            <span>
                              <input
                                type="number"
                                value={
                                  inputFocus.cash && inputValues['cash'] === 0 ? "" : inputValues['cash']
                                }
                                onChange={(e) => handleInputChange(e, 'cash')}
                                onFocus={() => handleInputFocus('cash')}
                                onBlur={() => handleInputBlur('cash')}
                              />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} (
                              {props.usdTable
                                ? UM.numberWithCommas(inputValues['cash'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)
                                : UM.numberWithCommas(inputValues['cash'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)
                              })
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td>{props.usdTable ? "CHP" : "USD"} Bonus</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.Balance.attr.bonus)}</td>
                          <td>{UM.numberWithCommas(props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)}</td>
                          <td>
                            <span>
                              <input
                                type="number"
                                value={
                                  inputFocus.bonus && inputValues['bonus'] === 0 ? "" : inputValues['bonus']
                                }
                                onChange={(e) => handleInputChange(e, 'bonus')}
                                onFocus={() => handleInputFocus('bonus')}
                                onBlur={() => handleInputBlur('bonus')}
                              />
                            </span>
                            <span>
                              {!props.usdTable ? "CHP" : "USD"} (
                              {props.usdTable
                                ? UM.numberWithCommas(inputValues['bonus'] / props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)
                                : UM.numberWithCommas(inputValues['bonus'] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate)
                              })
                            </span>
                          </td>
                        </tr>  G

                        {/* Dynamically render rows for other attributes */}
                        {/* {Object.keys(props.onMoneyExchangeInfo.Balance.attr).map(attrKey => (
                      <tr key={attrKey}>
                        <td>{attrKey}</td>
                        <td>{props.onMoneyExchangeInfo.Balance.attr[attrKey]}</td>
                        <td>{props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate}</td>
                        <td>
                          <span>
                            <input type="number" value={inputValues[attrKey]} onChange={(e) => handleInputChange(e, attrKey)} />
                          </span>
                          <span>
                            USD ({inputValues[attrKey] * props.onMoneyExchangeInfo.CurrencyRates.CurrencyRate.attr.rate})
                          </span>
                        </td>
                      </tr>
                    ))} */}
                      </tbody>
                    </table>
                    <div className="df">
                      <h4>Summary:</h4>
                      <div className="m_5">
                        <p>
                          <span>Cash :</span>
                          <span> {props.usdTable ? "$" : ""}  {UM.numberWithCommas(inputValues['cash'])}</span>
                        </p>
                        <p>
                          <span>Bonus :</span>
                          <span> {props.usdTable ? "$" : ""} {UM.numberWithCommas(inputValues['bonus'])}</span>
                        </p>
                      </div>
                    </div>

                  </>
                }
              </div>
              : <div className="fd buyNew" style={{ width: '100%', padding: '15px' }}>
                <div className="fd buyBalance" style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <span style={{ margin: '5px' }}>{props.t("Your current balance")}: </span>
                  <span style={{ margin: '1px' }} className="clr_ff font_20 bold"> {changeAmtlabl(newAvlBal)} </span>
                </div>
                <div className="fd">
                  <div className="minMaxBalance" style={{ flexDirection: 'row', justifyContent: 'center', margin: '5px' }}>
                    <span > {props.t("Minimum buy-in")} : </span>
                    <span className="clr_4 font_15"> {newMinBal} </span>
                  </div>
                </div>
              </div>
            }
            <div className="exchangeBtns">
              <button className="btn_2" onClick={onClickCancel}> Cancel  </button>
              {(showTimer && header === `Add Chips - ${props.usdTable ? "USD" : "CHP"}`) && (
                <div  >
                  {props.t("Time Left")}: <span style={{ color: "red" }} className="font_18">{time}</span>
                </div>
              )}
              <button className="btn_2 fd" onClick={onClickExchange} disabled={(Number(inputValues['cash']) <= 0 && Number(inputValues['bonus']) <= 0) && header !== `Add Chips - ${props.usdTable ? "USD" : "CHP"}`}>Exchange</button>
            </div>
          </div>
        </div>

        :

        <div className={fileName.name === "Leader_bet" ? "popup_1_lb" : "popup_1"} style={{ maxWidth: (Screen.getDeviceType().name === "Mobile" && fileName.name === "Leader_bet") ? "307px" : fileName.name === "Leader_bet" ? "545px" : "" }}>
          <div className={fileName.name === "Leader_bet" ? "popup_1_in_lb" : "popup_1_in"}>
            {/* <div className="popup_1">
        <div className="popup_1_in"> */}
            <div className="head">
              <span className="settingsSpan">
                <img src={dollar} alt="" style={{ display: fileName.name == "Leader_bet" ? 'none' : 'block' }} /> {props.t("Buy Chips")} - {!props.usdTable ? "CHP" : "USD"} </span>
              <button className="close_1" onClick={onClickCancel}> <img src={close_1} alt="" />  </button>
            </div>
            {errorMsg && (
              <div className="errorMsgDiv">
                <span>{errorMsgText}</span>
              </div>
            )}
            {fileName.name !== "Leader_bet" ?
              <>
                <div className="fd buyNew">
                  <div className="fd buyBalance">
                    <span style={{ margin: '5px 0px 3px 0px' }}>{props.t("Your current balance")}: </span>
                    <span style={{ margin: '0px 0px 3px 0px' }} className="clr_ff font_20 bold"> {changeAmtlabl(newAvlBal)} </span>
                  </div>
                  <div className="fd">
                    <table className="fd">
                      <tbody>
                        <tr className="minMaxBalance">
                          <td>
                            <div className="minMaxBalance">
                              <span > {props.t("Minimum buy-in")} : </span>
                              <span className="clr_4 font_15"> {changeAmtlabl(newMinBal)} </span>
                            </div>
                          </td>
                          <td>
                            <div className="minMaxBalance">
                              <span > {props.t("Maximum buy-in")} : </span>
                              <span className="clr_4 font_15"> {changeAmtlabl(newMaxBal)} </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="slider_buyIn fd p_lr_15">
                  <input id="sliderBar" className="backRange" type="range" min={min} max={max} value={amount} onChange={onChangeSlider}></input>
                  <div className="slider-wrapper">
                    <div className="slider-track">
                      <input id="inputSlider" className="slider-input" type="range" min={min || 0} max={max || 0} value={amount || 0} readOnly />
                      <div className="slider-thumb">
                        <img src={slider_thumb} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fd p_tb_15 p_lr_15" style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                  {/* <input id="textInput" className="inputValue" style={{ width: '30%' }} type="text" value={Intl.NumberFormat('en').format(amount)} disabled={btnState} onChange={onChangeInputNumber} /> */}
                  <input id="textInput" className="inputValue" style={{ width: '30%' }} type="text" value={UM.numberWithCommas(amount)} disabled={btnState} readOnly />
                  {showTimer && (
                    <div  >
                      {props.t("Time Left")}: <span style={{ color: "red" }} className="font_18">{parseInt(time)}</span>
                    </div>
                  )}
                  <button className="btn_2 fd" onClick={onClickBuy} disabled={btnState} >{props.t("BUY")} </button>
                </div>
                {/* {fileName.name === "Riverpoker" &&
                  <div className="fd df p_10">
                    <button className="m_auto" onClick={() => {
                      window.open(config.URL_Environment.proxy.baseUrl + config.URL_Environment.url.gotoExchangeCurrency)
                    }} >Exchange</button>
                  </div>
                } */}
              </>
              :
              <div className="DetailsDiv">
                <ul>
                  <li>{props.t("Your current balance")}:</li>
                  <li>{UM.numberWithCommas(newAvlBal)} ₾</li>
                </ul>
                <ul>
                  <li>{props.t("Minimum buy-in")} :</li>
                  <li>{UM.numberWithCommas(newMinBal)} ₾</li>
                </ul>
                <ul>
                  <li> {props.t("Maximum buy-in")} : </li>
                  <li>{UM.numberWithCommas(newMaxBal)} ₾</li>
                </ul>
                {Screen.getDeviceType().name !== "Mobile" ?
                  <>
                    <ul className="scroller">
                      <input className="minus" type="button" value="−" onClick={calculateChips} />
                      <div className="slider-container">
                        <input
                          type="range"
                          min={min}
                          max={max}
                          value={amount}
                          className="custom-slider"
                          id="myRange"
                          onChange={onTestSlider}
                          style={{ appearance: 'none', width: '100%', height: '10px', background: '#d3d3d3', outline: 'none', transition: 'opacity 0.2s', border: 'none', marginTop: '-5px', borderRadius: '20px', }}
                        />
                      </div>
                      <input className="plus" type="button" value="+" onClick={calculateChips} />
                      <span className="maxButton">
                        <button onClick={setMaxAmount} disabled={amount === max} style={{ position: 'relative', width: '100%', height: '100%', background: 'none', color: '#fff' }} >Max</button>
                      </span>
                      <span className="amountIndicator">{amount}</span>
                    </ul>
                    <div className="df" style={{ alignItems: 'center', justifyContent: 'space-between', margin: '10px 15px', maxHeight: '65px', minHeight: '50px', padding: '15px 0px' }}>
                      <div id="timerCircle">
                        <div className="base-timer">
                          <svg className="base-timer__svg" viewBox="0 0 100 100" >
                            <g className="base-timer__circle">
                              <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                              <path
                                id="base-timer-path-remaining"
                                strokeDasharray="283"
                                className="base-timer__path-remaining"
                                d=" M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0 "
                              ></path>
                            </g>
                          </svg>
                          <span id="base-timer-label" className="base-timer__label">0:00</span>
                        </div>
                      </div>
                      <div className="df">
                        <button className="cancelBtn" onClick={onClickCancel}>{props.t("Cancel")}  </button>
                        <button className="buyBtn" onClick={onClickBuy} disabled={btnState} >{props.t("BUY")} </button>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="df">
                      <div className="mobileOption">
                        <input className="minus" type="button" value="−" onClick={calculateChips} />
                        <span>{amount}/{max}</span>
                        <input className="plus" type="button" value="+" onClick={calculateChips} />
                      </div>
                      <span className="maxButton" style={{ margin: '30px 5px 10px' }}>
                        <button onClick={setMaxAmount} disabled={amount === max} style={{ position: 'relative', width: '100%', height: '100%', background: 'none', color: '#fff' }} >Max</button>
                      </span>
                    </div>
                    <div className="slider-container">
                      <input
                        type="range"
                        min={min}
                        max={max}
                        value={amount}
                        className="custom-slider"
                        id="myRange"
                        onChange={onTestSlider}
                        style={{ appearance: 'none', width: '90%', height: '10px', background: '#d3d3d3', outline: 'none', transition: 'opacity 0.2s', border: 'none', marginTop: '-5px', borderRadius: '20px', margin: '0px 15px' }}
                      />
                    </div>
                    <div className="df" style={{ margin: '15px 15px 20px 15px', justifyContent: 'space-between', alignContent: 'center' }}>
                      <button className="cancelBtn" onClick={onClickCancel}>{props.t("Cancel")}  </button>
                      <button className="buyBtn" onClick={onClickBuy} disabled={btnState} >{props.t("BUY")} </button>
                      <div id="timerLine" style={{ position: 'absolute', bottom: '-17px', left: '0', right: '0' }}>
                        <div style={{ width: '100%' }}>
                          <svg className="base-timer__svg" viewBox="0 0 100 10" style={{ position: 'relative' }}>
                            <g className="base-timer__line">
                              <line className="base-timer__path-elapsed" x1="0" y1="5" x2="100" y2="5" style={{ strokeWidth: '1px' }} />
                              <line id="base-timer-path-remaining" strokeDasharray="100" className="base-timer__path-remaining" x1="0" y1="5" x2="100" y2="5" style={{ transform: 'rotate(0deg)', strokeWidth: '1.3px', strokeLinecap: 'unset', transformOrigin: 'left' }} />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            }
          </div>
        </div>
      }
    </React.Fragment >
  );
}
export default withTranslation()(BuyChips);
