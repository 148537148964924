import React, { forwardRef, useImperativeHandle } from "react";
import CheckBoxAlert from "../../popUps/checkBoxAlert";
import "../../../../../css/ui/table/checkBox.css";
import close_1 from './../../../../../assets/images/table/close_1.svg';
import settingsIcon from '../../../../../assets/images/table/settings1.svg'

const GameCheckBox = forwardRef((props, ref) => {

  useImperativeHandle(
    ref,
    () => ({
      setSitOutCheck() {
        try {
          document.getElementById("CheckBoxSO").checked = true
        } catch (e) {

        }
      },
      setSitOutCheckTrue() {
        try {
          document.getElementById("CheckBoxSO").checked = false;
        } catch (e) {

        }
      },
      enableDefaults() {
        document.getElementById("CheckBoxAM").checked = true;
        document.getElementById("CheckBoxAP").checked = true;
        props.actions("AutoMuck", true);
        props.actions("AutoPost", true);
        props.actions("closeCkeckAlert", "");
      }
    }),
  )
  const setCheckStates = (action) => {
    switch (action) {
      case "Yes":
        document.getElementById("CheckBoxAM").checked = true;
        document.getElementById("CheckBoxAP").checked = true;
        props.actions("AutoMuck", true);
        props.actions("AutoPost", true);
        props.actions("closeCkeckAlert", "");
        break;
      case "No":
        props.actions("closeCkeckAlert", false)
        break;
      default:
        break;
    }
  }

  const onClickActions = (action, state) => {

    console.log("checkbox >>  ", action + "..." + state);
    switch (action) {
      case "AutoMuck":
        if (state) {
          props.actions("AutoMuck", state)
        } else {
          props.actions("AutoMuck", state)
        }
        break;
      case "AutoPost":
        if (state) {
          props.actions("AutoPost", state)
        } else {
          props.actions("AutoPost", state)
        }
        break;
      case "SitOut":
        if (state) {
          props.network.send("<SitOut/>");
        } else {
          props.network.send("<SitIn/>");
          props.actions("SitOut", state);
        }
        break;
      case "WaitForBB":
        if (state) {
          console.log("its true");
          props.network.send("<WaitForBigBlind/>");
        } else {
          console.log("its false");
        }
        break;

      // case "ritOne":
      //   if (state) {
      //     props.actions("ritOne", state)
      //     document.getElementById("CheckBoxWR2").checked = false;
      //   }
      //   else {
      //     document.getElementById("CheckBoxWR1").checked = false;
      //     props.actions("ritOne", state)
      //   }
      //   break;
      // case "ritTwo":
      //   if (state) {
      //     document.getElementById("CheckBoxWR1").checked = false;
      //     props.actions("ritTwo", state)
      //   }
      //   else {
      //     document.getElementById("CheckBoxWR2").checked = false;
      //     props.actions("ritTwo", state)
      //   }
      //   break;
      case "volume":
        if (state) {
          props.actions("volume", state)
        } else {
          props.actions("volume", state)
        }
        break;
      case "Addon":
        props.actions("Addon", state)
        break;
      case "Rebuy":
        // props.actions("Rebuy", state)
        props.actions(action, state)
        document.getElementById("CheckBoxRebuyTwo").checked = false;
        break;
      case "ReBuy2X":
        document.getElementById("CheckBoxRebuy").checked = false;
        props.actions("ReBuy2X", state)
        break;
      default:
        break;
    }
  }
  function closeCheckbox() {
    props.actions("hideCheckBox", "null");
  }
  function Rebuy(e) {
    e.preventDefault();
    props.network.send("<ReBuy/>")
  }

  return (
    <div>
      {props.showAlert &&
        <CheckBoxAlert actions={setCheckStates}></CheckBoxAlert>
      }
      <div className="popCover_1" onClick={() => { closeCheckbox() }} style={{ display: `${props.showCheckBox}` }}>
      </div>

      <div style={{ display: `${props.showCheckBox}` }} className="gameCheckBoxContainer">
        <div className="popup_1">
          <div className="popup_1_in">
            <div className="head df_al_jsb"> <span className="settingsSpan"> <img src={settingsIcon} alt="" /> Settings</span>
              <button className="close_1" onClick={() => { closeCheckbox() }}> <img src={close_1} alt="" /> </button>
            </div>
            <div id="gameCheckBox" className="gameCheckBox">
              <div className="field fd">
                <label htmlFor="AutoMuck" style={{ color: "#ffffff" }}>
                  Auto Muck
                </label>
                <input id="CheckBoxAM" className="checkBox" type="checkbox" name="AutoMuck" value="AutoMuck"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd">
                <label htmlFor="AutoPost" style={{ color: "#ffffff" }}>
                  Auto Post
                </label>
                <input id="CheckBoxAP" className="checkBox" type="checkbox" name="AutoPost" value="AutoPost"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd">
                <label htmlFor="SitOut" style={{ color: "#ffffff" }}>
                  Sit Out For Next Hand
                </label>
                <input id="CheckBoxSO" className="checkBox" type="checkbox" name="SitOut" value="SitOut"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd">
                <label htmlFor="WaitForBB" style={{ color: "#ffffff" }}>
                  Wait For BB
                </label>
                <input id="CheckBoxWBB" className="checkBox" type="checkbox" name="WaitForBB" value="WaitForBB"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>


              {/* <div className="field fd">
                <label htmlFor="ritOne" style={{ color: "#ffffff" }}>
                  Run it Always 2 time
                </label>
                <input id="CheckBoxWR2" className="checkBox" type="checkbox" name="RUNIT" value="ritTwo"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />

              </div>
              <div className="field fd">
                <label htmlFor="ritTwo" style={{ color: "#ffffff" }}>
                  Run it Always 1 time
                </label>
                <input id="CheckBoxWR1" className="checkBox" type="checkbox" name="RUNIT" value="ritOne"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />

              </div> */}
              <div className="field fd">
                <label htmlFor="WaitForBB" style={{ color: "#ffffff" }}>
                  Volume Mute
                </label>
                <input id="CheckBoxWBB" className="checkBox" type="checkbox" name="volume" value="volume"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd" style={{ display: `${props.settingsAddonNrebuy ? 'none' : 'block'}` }}>
                <label htmlFor="WaitForBB" style={{ color: "#ffffff" }}>
                  Add On
                </label>
                <input id="CheckBoxWBB" className="checkBox" type="checkbox" name="Addon" value="Addon"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd" style={{ display: `${props.settingsAddonNrebuy ? 'none' : 'block'}` }}>
                <label htmlFor="WaitForBB" style={{ color: "#ffffff" }}>
                  Auto Rebuy
                </label>
                <input id="CheckBoxRebuy" className="checkBox" type="checkbox" name="Rebuy" value="Rebuy"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
              <div className="field fd" style={{ display: `${props.settingsAddonNrebuy ? 'none' : 'block'}` }}>
                <label htmlFor="WaitForBB" style={{ color: "#ffffff" }}>
                  Auto Rebuy 2X
                </label>
                <input id="CheckBoxRebuyTwo" className="checkBox" type="checkbox" name="ReBuy2X" value="ReBuy2X"
                  onClick={(event) => { onClickActions(event.target.value, event.target.checked) }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default GameCheckBox;
