import React, { useEffect, useState } from "react";
import "../../../../../css/ui/lobby/filterTabs.css";
import FilterCheckBox from "./filterCheckBoxOne";
import FilterCheckBoxTwo from "./filterCheckBoxTwo";
import DateUtils from "../../../../utils/dateUtils";
import reload_icon from "../../../../../assets/images/table/reload_icon.svg";
import cashgames from "../../../../../assets/images/lobby/game.png";
import torno from "../../../../../assets/images/lobby/torno.png";

import Lb_cashgamesIcon from "../../../../../assets/images/lobby/cashgamesIcon.png";
import Lb_tournamentsIcon from "../../../../../assets/images/lobby/tournamentsIcon.png";
import Lb_sitandgosIcon from "../../../../../assets/images/lobby/sitandgosIcon.png";

// import casino from "../../../../../assets/images/lobby/casino.png";
import Sit_Go from "../../../../../assets/images/lobby/Sit_Go.png";
import cashier from "../../../../../assets/images/lobby/cashier.png";
import filterIcon from "../../../../../assets/images/lobby/filter.svg";
import all from "../../../../../assets/images/lobby/filterimages/all.png";
import holdem from "../../../../../assets/images/lobby/filterimages/Holdem_Main.png";
import omaha from "../../../../../assets/images/lobby/filterimages/Omaha_Main.png";
import omaha5 from "../../../../../assets/images/lobby/filterimages/Omaha5_Main.png";
import omaha6 from "../../../../../assets/images/lobby/filterimages/Omaha6_Main.png";
import tall from "../../../../../assets/images/lobby/filterimages/Tourney_All.png";
import freerolls from "../../../../../assets/images/lobby/filterimages/Tourney_Free-Roll.png";
import regular from "../../../../../assets/images/lobby/filterimages/Tourney_Regular.png";
import guaranteed from "../../../../../assets/images/lobby/filterimages/Tourney_Guaranteed.png";
import SingleTable from "../../../../../assets/images/lobby/filterimages/Sit-Go_Single-Table.png";
import MultiTable from "../../../../../assets/images/lobby/filterimages/Sit-Go_Multi-Table.png";
import HandsUp from "../../../../../assets/images/lobby/filterimages/Sit-Go_Hands-Up.png";
import sitngoall from "../../../../../assets/images/lobby/filterimages/Sit-Go_All.png";
import cashicon from '../../../../../assets/images/lobby/lobbyHeader/cashicon.png'
import tournoicon from '../../../../../assets/images/lobby/lobbyHeader/tournoicon.png'
import sitandgoicon from '../../../../../assets/images/lobby/lobbyHeader/sit&goicon.png'
// import rightArrow from "../../../../../assets/images/lobby/right arrow.svg";
import Screen from "../../../../utils/screen";
import fileName from '../../../../../jsconfig'
import Config from '../../../../../config'
import right_mp4 from '../../../../../assets/images/lobby/VideoMP4/lobby_lateral.mp4'
import rightbanner_png from '../../../../../assets/images/lobby/VideoMP4/rightbanner.png'
import logo from '../../../../../assets/images/lobby/Untitled-4.png'

import { withTranslation } from 'react-i18next'
import UserLabel from "../userLabel";
import { Emitter } from "../../../../utils/eventEmitter";

const Filters = (props) => {
    var config = new Config();

    const initialStakes = [
        // { name: "ALL", icon: all },
        { name: "Holdem", icon: holdem },
        { name: "Omaha", icon: omaha },
        { name: "Omaha-5", icon: omaha5 },
        { name: "Omaha-6", icon: omaha6 },
    ];


    const [stakes, setStakes] = useState(initialStakes);
    const footBtns = [
        { name: props.gametext, icon: (fileName.name === "Leader_bet" ? Lb_cashgamesIcon : cashgames) },
        { name: props.texttorno, icon: (fileName.name === "Leader_bet" ? Lb_tournamentsIcon : torno) },
        { name: props.sit_go, icon: (fileName.name === "Leader_bet" ? Lb_sitandgosIcon : Sit_Go) },
        { name: props.cashier, icon: cashier },
    ];
    const thirdhedaderdesktop = [
        { name: props.gametext, icon: cashicon },
        { name: props.texttorno, icon: tournoicon },
        { name: props.sit_go, icon: sitandgoicon },
        { name: props.cashier, icon: cashier },
    ];
    const fourheader_sub = [
        { name: "All" },
        { name: "Limit" },
        { name: "Pot Limit" },
        { name: "No Limit" },
    ];
    const fiveheader_sub = [
        { name: "All" },
        { name: "High" },
        { name: "Medium" },
        { name: "Low" },
        { name: "Micro" },
    ];
    const [fiveheader, SetFiveheader] = useState(fiveheader_sub);
    if (fileName.name !== "Riverpoker") {
        delete footBtns[3]
        delete thirdhedaderdesktop[3]
    }
    const [tablename, setTablename] = useState(props.gametext);

    const [stakesValue, setStakesValue] = useState(["ALL", "Holdem", "Omaha", "Omaha-5", "Omaha-6", "Omaha-HL"]);
    const [showFilters, setshowFilters] = useState(true);
    const [toggleStateModes, setToggleStateModes] = useState(1);
    const [showCheckOne, setShowCheckOne] = useState(true);
    const [showCheckTwo, setShowCheckTwo] = useState(false);
    const [showBottomDiv, setShowBottomDiv] = useState(true);

    // setTimeout(() => {
    //     setShowBottomDiv(true);
    // }, 4000);

    const options = [
        { value: 1, label: "All" },
        { value: 8, label: "HOLDEM" },
        { value: 2, label: "OMAHA" },
        { value: 5, label: "OMAHA-5" },
        { value: 6, label: "OMAHA-6" },
        { value: 7, label: "OMAHA-HL" },
    ];
    const toggleTabModes = (index, e) => {
        setToggleStateModes(index);
        props.action("Modes", e.target.name);
    };
    const [toggleStateGames, setToggleStateGames] = useState(2);
    const [toggleStateGames1, setToggleStateGames1] = useState(2);
    const [toggleStateGames2, setToggleStateGames2] = useState(2);
    const [namegame, setNamegame] = useState("ALL");
    const toggleTabGamesTwo = (index) => {
        if (namegame === index) {
            setStakes(initialStakes);
            // props.action(props.gametext, "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW");
            props.action("TABS", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW");
            setToggleStateStakes(-1);
            setNamegame("");
            SetFiveheader(fiveheader_sub)
            setShowBottomDiv(true)
        } else {
            setNamegame(index);
            if (index === "Omaha") {
                props.action("GAMES", "OMAHA");
                // props.action(props.gametext, "OMAHA");
            } else if (index === "Omaha-5") {
                props.action("GAMES", "OMAHA_FIVE_CARDS");
                // props.action(props.gametext, "OMAHA_FIVE_CARDS");
            } else if (index === "Omaha-6") {
                props.action("GAMES", "OMAHA_SIX_CARDS");
                // props.action(props.gametext, "OMAHA_SIX_CARDS");
            } else if (index === "Omaha-HL") {
                props.action("GAMES", "OMAHA_HIGH_LOW");
                // props.action(props.gametext, "OMAHA_HIGH_LOW");
            } else if (index === "Holdem") {
                props.action("GAMES", "TEXAS_HOLDEM");
                // props.action(props.gametext, "TEXAS_HOLDEM");
            }
        }
        setShowCheckOne(true);
        props.handleshowCheckOne(true)
        setShowCheckTwo(false);
        props.check("Check2");
    };

    useEffect(() => {
        const handleHideEvent = () => {
            toggleTabGames(2, 'TABLES');
        };

        Emitter.on('ShowCashTable', handleHideEvent);
        return () => {
            Emitter.off('ShowCashTable', handleHideEvent);
        };
    }, []);


    const toggleTabGames = (index, tbName) => {
        // let tab = document.getElementsByClassName("tableGaneBox")[index - 2];
        // tab.style.background = 'linear-gradient(#FFD12E, #EAA800)'        
        setTablename(tbName);
        setshowFilters(true);
        setToggleStateGames(index);
        setToggleStateStakes(1);
        props.action("TABS", tbName)
        if (tbName === props.texttorno) {
            setStakes([
                { name: "ALL", icon: tall },
                { name: "REGULAR", icon: regular },
                { name: "GUARANTEED", icon: guaranteed },
                { name: "FREEROLL", icon: freerolls },
            ]);
            setCallFiveHeader(toggleStateGames2 - 2, [
                { name: "All", icon: tall },
                { name: "REGULAR", icon: regular },
                { name: "GUARANTEED", icon: guaranteed },
                { name: "FREEROLL", icon: freerolls },
            ], tbName)
            setShowBottomDiv(false)
            setStakesValue(["ALL", "REGULAR", "GUARANTEED", "FREEROLL"]);
            setShowCheckOne(false);
            props.handleshowCheckOne(false)
            setShowCheckTwo(true);
            props.check("Check1");
        } else if (tbName === props.sit_go) {
            setStakes([
                { name: "ALL", icon: sitngoall },
                { name: "SINGLE TABLE", icon: SingleTable },
                { name: "MULTI TABLE", icon: MultiTable },
                { name: "HEADS UP", icon: HandsUp },
            ]);
            setCallFiveHeader(toggleStateGames2 - 2, [
                { name: "All", icon: sitngoall },
                { name: "SINGLE TABLE", icon: SingleTable },
                { name: "MULTI TABLE", icon: MultiTable },
                { name: "HEADS UP", icon: HandsUp },
            ], tbName)
            setStakesValue(["ALL", "SINGLE", "MULTI", "HEADSUP"]);
            setShowCheckOne(false);
            props.handleshowCheckOne(false)
            setShowCheckTwo(true);
            setShowBottomDiv(false)
            props.check("Check1");
        } else if (tbName === props.cashier) {
            // if (sessionStorage.getItem(`${window.location.hostname}'_wSid'`) !== undefined) {
            //     var ws = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid;
            //     var sid = (ws).split(".")[1]
            // } else {
            //     var sid = JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_sid'`)).sid;
            // }
            // window.open(`${config.URL_Environment.proxy.baseUrl}/client-redirect?LANG=en&sid=${sid}&to=deposit`)

            // window.open(`https://demo.rapoker.club/deposit?wsession=${ws}`)
            // window.open(`https://${window.location.host}/deposit?wsession=${ws}`);
            // window.open(config.URL_Environment.proxy.baseUrl + config.URL_Environment.url.gotoDeposit)
            props.setAction("showCashierPopUp")
        } else {
            setCallFiveHeader(toggleStateGames2 - 2, fiveheader_sub, tbName)
            setStakes(initialStakes);
            setStakesValue(["ALL", "Holdem", "Omaha", "Omaha-5", "Omaha-6", "Omaha-HL"]);
            setShowCheckOne(true);
            props.handleshowCheckOne(true)
            setShowCheckTwo(false);
            props.check("Check2");
            // setShowBottomDiv(true)
            if (tbName === props.gametext) {
                toggleTabGamesTwo("ALL");
            }
        }
    };

    const setCallFiveHeader = (index, data, tName) => {
        SetFiveheader(data)
        switch (tName) {
            case "TABLES":
                switch (index) {
                    case 1:
                        props.action("Filter", tName, "HIGH");
                        break;
                    case 2:
                        props.action("Filter", tName, "MEDIUM");
                        break;
                    case 3:
                        props.action("Filter", tName, "LOW");
                        break;
                    case 4:
                        props.action("Filter", tName, "MICRO");
                        break;
                    case 0:
                        // props.action("GAMES", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                        break;
                    default: break;
                }
                break;
            case "TOURNEYS":
                // console.log(index, data, tName)
                switch (index) {
                    case 1:
                        props.action("Filter", tName, "REGULAR");
                        break;
                    case 2:
                        props.action("Filter", tName, "GUARANTEED");
                        break;
                    case 3:
                        props.action("Filter", tName, "FREEROLL");
                        break;
                    default:
                        setToggleStateGames2(2)
                        // props.action("GAMES", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                        break;
                }
                break;
            case "SIT & GO":
                switch (index) {
                    case 1:
                        props.action("Filter", tName, "SINGLE");
                        break;
                    case 2:
                        props.action("Filter", tName, "MULTI");
                        break;
                    case 3:
                        props.action("Filter", tName, "HEADSUP");
                        break;
                    default:
                        setToggleStateGames2(2)
                        // props.action("GAMES", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                        break;
                }
                break;
            default: break;
        }
    }

    const [toggleStateStakes, setToggleStateStakes] = useState(-1);

    const toggleTabStakes = (index, tName) => {
        setToggleStateStakes(index);
        if (tablename === props.gametext) {
            if (tName === "Holdem" || tName === "Omaha" || tName === "Omaha-5" || tName === "Omaha-6" || tName === "Omaha-HL") {
                console.log(0 <= toggleStateStakes)
                if (0 <= toggleStateStakes) {
                    setNamegame("ALL");
                    toggleTabGamesTwo(tName);
                } else {
                    setNamegame(tName);
                    toggleTabGamesTwo(tName);
                }
            }
        } else if (tablename === props.texttorno) {
            switch (tName) {
                case "REGULAR":
                    props.action("Filter", tName, "REGULAR");
                    break;
                case "GUARANTEED":
                    props.action("Filter", tName, "GUARANTEED");
                    break;
                case "FREEROLL":
                    props.action("Filter", tName, "FREEROLL");
                    break;
                // case "All":
                case "ALL":
                    props.action(tablename, "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                    break;
            }
        } else if (tablename === props.sit_go) {
            // props.action("Stakes", tName);
            switch (tName) {
                case "SINGLE TABLE":
                    props.action("Filter", tName, "SINGLE");
                    break;
                case "MULTI TABLE":
                    props.action("Filter", tName, "MULTI");
                    break;
                case "HEADS UP":
                    props.action("Filter", tName, "HEADSUP");
                    break;
                // case "All":
                case "ALL":
                    props.action(tablename, "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                    break;
            }
        }

    };
    const toggleTabStakes1 = (index, tName) => {
        if (index == "ALL Games") {
            props.action(props.gametext, "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", undefined);
        } else {
            toggleTabGamesTwo(tName)
            setNamegame(tName);
        }

    }
    const toggleTabGamesFour = (index, tName) => {
        // if (tablename === props.gametext) {
        setToggleStateGames1(index)

        switch (tName) {
            case "All":
                props.action("ALL_Limit", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                break;
            case "Limit":
                props.action("Limits", tName, "LIMIT");
                break;
            case "Pot Limit":
                props.action("Limits", tName, "POT_LIMIT");
                break;
            case "No Limit":
                props.action("Limits", tName, "NO_LIMIT");
                break;

        }
        // }
    }
    const toggleTabGamesFive = (index, tName) => {
        // if (tablename === props.gametext) {
        setToggleStateGames2(index)
        switch (tName) {
            case "All":
                props.action("ALL_Type", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "All");
                break;
            case "High":
                props.action("Filter", tName, "HIGH");
                break;
            case "Medium":
                props.action("Filter", tName, "MEDIUM");
                break;
            case "Low":
                props.action("Filter", tName, "LOW");
                break;
            case "Micro":
                props.action("Filter", tName, "MICRO");
                break;
            case "REGULAR":
                props.action("Filter", tName, "REGULAR");
                break;
            case "GUARANTEED":
                props.action("Filter", tName, "GUARANTEED");
                break;
            case "FREEROLL":
                props.action("Filter", tName, "FREEROLL");
                break;
            case "SINGLE TABLE":
                props.action("Filter", tName, "SINGLE");
                break;
            case "MULTI TABLE":
                props.action("Filter", tName, "MULTI");
                break;
            case "HEADS UP":
                props.action("Filter", tName, "HEADSUP");
                break;
            default:
                break;
        }
        // }
    }
    const [ativeFilter, setActiveFilter] = useState(false);
    const FilterCheck = () => {
        props.action("Check1", "EMPTY", !ativeFilter);
        setActiveFilter(!ativeFilter);
    }
    return (
        <div className="filterTabss fd">
            <div className="row">
                {(showFilters && Screen.getDeviceType().name === "Mobile" && fileName.name !== "Leader_bet") && (
                    <div className="fd imgDivs">
                        {stakes.map((item, i) => (
                            <button key={i} className={toggleStateStakes === i + 1 ? "tabs activeTabs" : "tabs"} name={item.name} onClick={(e) => toggleTabStakes(i + 1, item.name)}>
                                <img className="lobbytopImage" src={item.icon} alt="Not Found" />
                            </button>
                        ))}
                    </div>
                )}

                {(showFilters && Screen.getDeviceType().name === "Mobile" && fileName.name === "Leader_bet") &&
                    <>
                        <div className="df fd bg_3">
                            {footBtns.map((item, i) => {
                                return (
                                    <div className="row" key={i} style={{ height: '50px', padding: '0px 10px', background: 'rgb(32, 33, 38)' }}>
                                        <button key={i} className="tablesFilter" name={item.name} onClick={(e) => toggleTabGames(i + 2, item.name)}>
                                            <div className={toggleStateGames === i + 2 ? "tableGaneBox active" : "tableGaneBox"}
                                                style={{ padding: '0px 5px', background: toggleStateGames === i + 2 ? (toggleStateGames == 2 ? 'linear-gradient(#3C5DC2, #2348B9)' : toggleStateGames === 3 ? 'linear-gradient(#C9273D, #A61325)' : toggleStateGames === 4 ? 'linear-gradient(#0E845C, #02633F)' : "") : "" }}
                                                key={i}>
                                                <img src={item.icon} alt="Not Found" />
                                                <span key={item.name} >
                                                    {item.name}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="fd imgDivs">
                            {stakes.map((item, i) => (
                                <button button key={i} style={{ width: toggleStateStakes !== 1 ? '20%' : '25%' }} className={toggleStateStakes === i + 1 ? "tabs activeTabs" : "tabs"} name={item.name} onClick={(e) => toggleTabStakes(i + 1, item.name)}>
                                    <span>{item.name}</span>
                                </button>
                            ))}
                            {toggleStateStakes !== 1 &&
                                <button type="button" className="fliterBtn" name="Empty" id="Empty" value="EMPTY" onClick={(e) => FilterCheck("")} style={{ border: ativeFilter ? '2px solid #FFD500' : "2px solid #232730" }} >
                                    <img src={filterIcon} about="" />
                                </button>
                            }
                        </div>
                    </>
                }


                {
                    Screen.getDeviceType().name === "Desktop" &&
                    (fileName.name == "Leader_bet" || fileName.name == "Riverpoker" ? <> <div className="child gameFiltersdesktop col-md-9 col-lg-6 col-lg-6 z_99 bg_3 p_t_10 p_b_10">
                        <button htmlFor="casino col-4" className='spanimage' value="casino" onClick={() => {

                        }}>
                            <img id='casino' src={logo} alt="casino"></img>
                        </button>
                        {thirdhedaderdesktop.map((item, i) => {
                            return (
                                <div className="row bdrgold1 m_5" style={{ border: toggleStateGames === i + 2 ? "" : "1px solid #383838" }} key={i}>
                                    <button key={i} className={toggleStateGames === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name} onClick={(e) => toggleTabGames(i + 2, item.name)}
                                        style={{ background: toggleStateGames === i + 2 ? toggleStateGames === 2 ? "linear-gradient(180deg, rgba(226,196,87,1) 35%, rgba(255,184,0,1) 100%)" : toggleStateGames === 3 ? "linear-gradient(180deg, rgba(201,55,68,1) 35%, rgba(156,41,48,1) 100%)" : "linear-gradient(180deg, rgba(60,93,194,1) 35%, rgba(35,72,185,1) 100%)" : "" }}
                                    >
                                        <img src={item.icon} alt="Not Found" />
                                        <span key={item.name} className={toggleStateGames === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames === i + 2 ? "#FFF" : "#FFFFFF" }}>
                                            {item.name}
                                        </span>
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                        {/* <div className="col-md-6 z_99 bg_3 p_t_10 p_b_10" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} > */}
                        <div className="col-sm-5 col-md-3 col-lg-6 z_99 bg_3 p_t_10 p_b_10" style={{ display: 'flex', alignItems: 'center' }}>
                            <UserLabel setAction={props.setAction} openMenu={props.openMenu} Exit={props.logOutHandler} avtar={props.avtar} user={props.user} balance={props.balance} datas={props.datas} stars={props.stars} />
                        </div>
                    </>
                        : <div className="child gameFiltersdesktop  col-md-8 z_99 bg_3" style={{ padding: "0px" }}>

                            {thirdhedaderdesktop.map((item, i) => {
                                return (
                                    <div className="row bdrgold" key={i}>
                                        <button key={i} className={toggleStateGames === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name} onClick={(e) => toggleTabGames(i + 2, item.name)}>
                                            <img src={item.icon} alt="Not Found" />
                                            <span key={item.name} className={toggleStateGames === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames === i + 2 ? "#FFF" : "#D1B462" }}>
                                                {item.name}
                                            </span>
                                        </button>
                                    </div>
                                );
                            })}
                        </div>)
                }


                {
                    showFilters && Screen.getDeviceType().name === "Desktop" && (
                        fileName.name == "Leader_bet" || fileName.name == "Riverpoker" ? (
                            <div className="gameFiltersdesktop col-md-12 col-lg-8 z_99  bdgt">

                                <select className="bdrgold1 box_shadow bdgt txt_clr_1 m_5 p_t_0 p_l_10 p_r_10" style={{ display: tablename === "TABLES" ? 'block' : 'none' }} onChange={(e) => toggleTabStakes1(e.target.value.split(",")[0], e.target.value.split(",")[1])}>
                                    {/* <option defaultValue={[0, "ALL"]}>ALL</option> */}
                                    {tablename == props.gametext && (<option defaultValue={[0, "ALL"]}>ALL Games</option>)}
                                    {stakes.map((item, i) => (
                                        <option key={item.name}
                                            id={i}
                                            value={[i, item.name]}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="row"><button className="bottomTabsthirdheader txt_clr_1"><span className="activeTabs">{props.gametypetext} : </span></button></div>

                                {fourheader_sub.map((item, i) => {
                                    return (
                                        <div className="row bdrgold1 m_5" key={i} style={{ border: toggleStateGames1 === i + 2 ? "" : "1px solid #383838" }}>
                                            <button key={i} className={toggleStateGames1 === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name}
                                                onClick={(e) => toggleTabGamesFour(i + 2, item.name)}
                                            >
                                                {/* <img src={item.icon} alt="Not Found" /> */}
                                                <span key={item.name} className={toggleStateGames1 === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames1 === i + 2 ? "#FFF" : "#FFF" }}>
                                                    {item.name}
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>)

                            :

                            (<div className="gameFiltersdesktop col-md-8 z_99 bg_3 bdrgoldtop m_t_2">

                                <select className="bdrgold bg_new_1 txt_clr_1" onChange={(e) => toggleTabStakes1(e.target.value.split(",")[0], e.target.value.split(",")[1])}>
                                    {/* <option defaultValue={[0, "ALL"]}>ALL</option> */}
                                    {tablename == props.gametext && (<option defaultValue={[0, "ALL"]}>ALL</option>)}
                                    {stakes.map((item, i) => (
                                        <option key={item.name}
                                            id={i}
                                            value={[i, item.name]}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="row"><button className="bottomTabsthirdheader txt_clr_1"><span className="activeTabs">{props.gametypetext} : </span></button></div>

                                {fourheader_sub.map((item, i) => {
                                    return (
                                        <div className="row" key={i}>
                                            <button key={i} className={toggleStateGames1 === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name}
                                                onClick={(e) => toggleTabGamesFour(i + 2, item.name)}
                                            >
                                                {/* <img src={item.icon} alt="Not Found" /> */}
                                                <span key={item.name} className={toggleStateGames1 === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames1 === i + 2 ? "#FFF" : "#D1B462" }}>
                                                    {item.name}
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>)




                    )
                }
                {/* {Screen.getDeviceType().name === "Desktop" && (
                    fileName.name==="Lapoker"?(<video autoPlay loop className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0",display:"none"}}>
                        <source src={right_mp4} type="video/mp4" />
                    </video>):fileName.name==="Leader_bet"?<img className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0"}} src={rightbanner_png}/> :(<video autoPlay loop className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0",display:"none",padding:"0 !important"}}>
                        <source src={right_mp4} type="video/mp4" />
                    </video>)
                    
            )} */}







                {
                    Screen.getDeviceType().name === "Desktop" && (fileName.name == "Leader_bet" || fileName.name == "Riverpoker" ?
                        (<div className="col-md-12 col-lg-8 clr_ff bdgb" >
                            <div className="row">
                                <div className="col-12">
                                    <div className="ptt">
                                        {/* {showBottomDiv && (<div className="row bdrgold"><button className="bottomTabsthirdheader txt_clr_1">Filter</button></div>)} */}
                                        {showBottomDiv && (<div className="row"><div className="bottomTabsthirdheader txt_clr_1"><span className="activeTabs">{props.Limittext} :</span></div></div>)}

                                        {fiveheader.map((item, i) => {
                                            return (
                                                <div className="row bdrgold1 m_5" key={i} style={{ border: toggleStateGames2 === i + 2 ? "" : "1px solid #383838" }}>
                                                    <button key={i} className={toggleStateGames2 === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name}
                                                        onClick={(e) => toggleTabGamesFive(i + 2, item.name)}
                                                    >
                                                        {/* <img src={item.icon} alt="Not Found" /> */}
                                                        <span key={item.name} className={toggleStateGames2 === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames2 === i + 2 ? "#FFF" : "#FFF" }}>
                                                            {item.name}
                                                        </span>
                                                    </button>
                                                </div>
                                            );
                                        })}
                                        <div className="df_al">{showCheckOne ? <FilterCheckBox action={props.action}></FilterCheckBox> : <FilterCheckBoxTwo action={props.action}></FilterCheckBoxTwo>}</div>

                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (<div className="col-md-8 clr_ff bg_3 bdrgoldbottom" >
                            <div className="row">
                                <div className="col-12">
                                    <div className="ptt">
                                        {/* {showBottomDiv && (<div className="row bdrgold"><button className="bottomTabsthirdheader txt_clr_1">Filter</button></div>)} */}
                                        {showBottomDiv && (<div className="row"><div className="bottomTabsthirdheader txt_clr_1"><span className="activeTabs">{props.Limittext} :</span></div></div>)}

                                        {fiveheader.map((item, i) => {
                                            return (
                                                <div className="row" key={i}>
                                                    <button key={i} className={toggleStateGames2 === i + 2 ? "bottomTabsthirdheader activeTabsthirdheader" : "bottomTabsthirdheader mouseover"} name={item.name}
                                                        onClick={(e) => toggleTabGamesFive(i + 2, item.name)}
                                                    >
                                                        {/* <img src={item.icon} alt="Not Found" /> */}
                                                        <span key={item.name} className={toggleStateGames2 === i + 2 ? "activeTabs" : ""} style={{ color: toggleStateGames2 === i + 2 ? "#FFF" : "#D1B462" }}>
                                                            {item.name}
                                                        </span>
                                                    </button>
                                                </div>
                                            );
                                        })}
                                        <div className="df_al">{showCheckOne ? <FilterCheckBox action={props.action}></FilterCheckBox> : <FilterCheckBoxTwo action={props.action}></FilterCheckBoxTwo>}</div>

                                    </div>
                                </div>
                            </div>
                        </div>)

                    )
                }





                {(Screen.getDeviceType().name === "Mobile" && fileName.name !== "Leader_bet") && (<div className="fd clr_ff bg_3" style={{ borderRadius: "20px", margin: "0px 3px 0px 3px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="ptt">
                                <div className="df_al">
                                    {props.playerText} :
                                    <span className="clr_4" style={{ textIndent: "2px", color: "#ffff" }}>
                                        {props.datas.players}
                                    </span>
                                </div>
                                <div className="df_al">
                                    {props.tablestext} :
                                    <span className="clr_4" style={{ textIndent: "2px", color: "#ffff" }}>
                                        {props.datas.tables}
                                    </span>
                                </div>
                                <div className="df_al">{showCheckOne ? <FilterCheckBox action={props.action}></FilterCheckBox> : <FilterCheckBoxTwo action={props.action}></FilterCheckBoxTwo>}</div>
                                {/* <div className="df_al">{showCheckTwo && <FilterCheckBoxTwo action={props.action}></FilterCheckBoxTwo>}</div> */}
                            </div>
                        </div>
                    </div>
                </div>)
                }

                {
                    Screen.getDeviceType().name === "Mobile" && fileName.name !== "Leader_bet" && <div className="gameFilters fd z_99 bg_3">
                        {footBtns.map((item, i) => {
                            return (
                                <div className="row" key={i}>
                                    <button key={i} className={toggleStateGames === i + 2 ? "bottomTabs activeTabs" : "bottomTabs"} name={item.name} onClick={(e) => toggleTabGames(i + 2, item.name)}>
                                        <img src={item.icon} alt="Not Found" />
                                        <span key={item.name} className={toggleStateGames === i + 2 ? "activeTabs fd" : ""}>
                                            {item.name}
                                        </span>
                                        <span key={item.id} className={toggleStateGames === i + 2 ? "activeTabs underLine fd" : ""}></span>
                                    </button>
                                    <div key={i + 3} className={i + 3 == 3 ? "" : "vertical"}></div>
                                </div>
                            );
                        })}
                    </div>
                }


            </div >
            {/* {Screen.getDeviceType().name === "Desktop" && (
                    fileName.name==="Lapoker"?(<video autoPlay loop className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0",display:"none"}}>
                        <source src={right_mp4} type="video/mp4" />
                    </video>):fileName.name==="Leader_bet"?<img className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0"}} src={rightbanner_png}/> :(<video autoPlay loop className="col-md-4 bdrgold" style={{position:"absolute",right:"0",bottom:"0",display:"none",padding:"0 !important"}}>
                        <source src={right_mp4} type="video/mp4" />
                    </video>)
                    
            )} */}
        </div >
    );
};

class FilterTabs extends React.Component {
    constructor(props) {
        super(props);
        // this.filter = ["CASH", "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW", "ALL", false, false, false, false, false, "ALL"];
        this.filter = ["CASH", "TEXAS_HOLDEM|HOLDEM_SHORT_DECK|OMAHA|OMAHA_HIGH_LOW|HOLDEM_BLACKJACK|REVERSE_HOLDEM|OMAHA_FIVE_CARDS|OMAHA_SIX_CARDS", "ALL", false, false, false, false, false, "ALL", "2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10", "ALL"];
        this._dateUtils = new DateUtils();
    }
    setCheckState(group) {
        switch (group) {
            case "Check1":
                this.filter[3] = false;
                this.filter[4] = false;
                break;
            case "Check2":
                this.filter[5] = false;
                break;
            default:
                break;
        }
    }
    // FilterCheck = (data) => {
    //     this.filter[4] = state;
    //     alert("h")
    // }
    // setFilters(group, action, state) {
    //     switch (group) {
    //         case "Modes":
    //             this.filter[0] = action;
    //             break;
    //         case this.props.t('GAMES'):
    //             this.props.action(action);
    //             this.filter[1] = action;
    //             this.filter[2] = "ALL";
    //             this.filter[6] = false;
    //             this.filter[7] = false;
    //             break;
    //         case "Stakes":
    //             this.filter[2] = action;
    //             break;
    //         case "Check1":
    //             if (action === "FULL") {
    //                 this.filter[3] = state;
    //             }
    //             if (action === "EMPTY") {
    //                 this.filter[4] = state;
    //             }
    //             break;
    //         case "Check2":
    //             if (action === "UpComingOnly") {
    //                 this.filter[5] = state;
    //             }
    //             break

    //         case "Limits":
    //             this.filter[2] = state;
    //             this.filter[6] = true;
    //             break;
    //         case "Filter":
    //             this.filter[8] = state;
    //             this.filter[7] = true;
    //             break;

    //         default:
    //             break;
    //     }
    //     this.getTablesRequestHandler(this.filter);
    // }

    setFilters(group, action, state) {
        // console.log(group, action, state);
        switch (group) {
            case "Modes":
                this.filter[0] = action;
                break;
            case 'GAMES':
                // this.props.action(action);
                this.filter[1] = action;
                this.filter[2] = "ALL";
                if (Screen.getDeviceType().name === "Mobile") {
                    /*================ set these in TABS not in GAMES================================*/
                    this.filter[6] = false;
                    this.filter[7] = false;
                    /*================ set these in TABS not in GAMES================================*/
                }
                break;
            case "Stakes":
                this.filter[2] = action;
                break;
            case "Check1":
                if (action === "FULL") {
                    this.filter[3] = state;
                }
                if (action === "EMPTY") {
                    this.filter[4] = state;
                }
                break;
            case "Check2":
                if (action === "UpComingOnly") {
                    this.filter[5] = state;
                }
                break

            case "Limits":
                this.filter[2] = state;
                this.filter[6] = true;
                break;
            case "Filter":
                this.filter[8] = state;
                this.filter[7] = true;
                break;
            case "TABS":
                this.props.action(action);
                const stateMap = {
                    "Texas Hold'em": "TEXAS_HOLDEM",
                    "Hold'em Short Deck": "HOLDEM_SHORT_DECK",
                    "Omaha": "OMAHA",
                    "Five Card Omaha": "OMAHA_FIVE_CARDS",
                    "Six Card Omaha": "OMAHA_SIX_CARDS"
                };
                // this.filter[1] = stateMap[state] || "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW";
                this.filter[1] = stateMap[state] || "TEXAS_HOLDEM|HOLDEM_SHORT_DECK|OMAHA|OMAHA_HIGH_LOW|HOLDEM_BLACKJACK|REVERSE_HOLDEM|OMAHA_FIVE_CARDS|OMAHA_SIX_CARDS";
                // if (state !== undefined) {
                //     this.filter[2] = 'ALL';
                // }
                // this.filter[1] = "TEXAS_HOLDEM | OMAHA | OMAHA_FIVE_CARDS | OMAHA_SIX_CARDS | OMAHA_HIGH_LOW"
                this.filter[9] = "2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10"
                if (action !== "TOURNEYS" && action !== 'SIT & GO')
                    this.filter[10] = "ALL"
                else
                    this.filter[10] = action;
                if (Screen.getDeviceType().name === "Mobile") {
                    /*================== set false here not in GAMES =============================*/
                    this.filter[6] = false;
                    this.filter[7] = false;
                    /*================== set false here not in GAMES =============================*/
                }
                break;
            case "Seats":
                this.filter[9] = action;
                break;
            case "ALL_Limit":
                this.filter[2] = "ALL";
                this.filter[6] = false;
                break;
            case "ALL_Type":
                this.filter[8] = "ALL";
                this.filter[7] = false;
                break;
            default:
                if (state === "All") {
                    this.filter[7] = false;
                    this.filter[6] = false;
                    this.filter[8] = "ALL";
                    this.filter[2] = "ALL";
                }
                break;
        }
        this.getTablesRequestHandler(this.filter);
    }

    getTablesRequestHandler(value) {
        // console.log(value)
        var tablerequest;
        if (value[10] !== 'TOURNEYS' && value[10] !== 'SIT & GO') {
            /*============================ Table Request for Single Table Start ============================================*/

            // if (value[3] && value[4]) {
            //     if (value[2] === "ALL") {
            //         tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" hide="FULL|EMPTY"/>';
            //     } else {
            //         tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" stakes="' + value[2] + '" hide="FULL|EMPTY"/>';
            //     }
            // } else {
            //     if (value[3] || value[4]) {
            //         if (value[3]) {
            //             if (value[2] === "ALL") {
            //                 tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" hide="FULL"/>';
            //             } else {
            //                 tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" stakes="' + value[2] + '" hide="EMPTY"/>';
            //             }
            //         }
            //         if (value[4]) {
            //             if (value[2] === "ALL") {
            //                 tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" hide="EMPTY"/>';
            //             } else {
            //                 tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '" stakes="' + value[2] + '" hide="FULL"/>';
            //             }
            //         }
            //     } else {
            //         if (value[2] === "ALL") {
            //             tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" seats="' + value[9] + '"/>';
            //         } else {
            //             tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" stakes="' + value[2] + '" seats="' + value[9] + '"/>';
            //         }
            //     }
            //     if (value[6] && value[7] && value[1] !== 'TOURNEYS' && value[1] !== 'SIT & GO') {
            //         tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" seats="' + value[9] + '" stakes="' + value[8] + '"/>';
            //     } else {
            //         if (value[6] && value[1] !== 'TOURNEYS' && value[1] !== 'SIT & GO') {
            //             tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" seats="' + value[9] + '"/>';
            //         }
            //         if (value[7] && value[1] !== 'TOURNEYS' && value[1] !== 'SIT & GO') {
            //             tablerequest = '<GetSingleTables id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" stakes="' + value[8] + '" seats="' + value[9] + '"/>';
            //         }
            //     }
            // }

            /*============================ Table Request for Single Table End ============================================*/

            tablerequest = `<GetSingleTables id="${this._dateUtils.getUniqueIdentifier()}" modes="${value[0]}" games="${value[1]}"${value[6] ? ` limits="${value[2]}"` : ``}${value[3] & value[4] ? ` hide="FULL|EMPTY"` : value[3] ? ` hide="FULL"` : value[4] ? ` hide="EMPTY"` : ``}${value[7] ? ` stakes="${value[8]}"` : ``} seats="${value[9]}" />`

        } else {
            if (value[10] === 'TOURNEYS') {

                /*============================ Table Request for Tournaments Start ============================================*/

                // if (value[5]) {
                //     // tablerequest = `<GetTournaments modes="CASH" games="TEXAS_HOLDEM|OMAHA|OMAHA_HIGH_LOW|REVERSE_HOLDEM|OMAHA_FIVE_CARDS|OMAHA_SIX_CARDS" status="ANNOUNCED|REGISTERING|SEATING|LATE_REG" tournament="SCHEDULED|LIVE"  />`;
                //     tablerequest = `<GetTournaments modes="CASH" games="TEXAS_HOLDEM|HOLDEM_SHORT_DECK|OMAHA|OMAHA_HIGH_LOW|HOLDEM_BLACKJACK|REVERSE_HOLDEM|OMAHA_FIVE_CARDS|OMAHA_SIX_CARDS" status="ANNOUNCED|REGISTERING|SEATING|LATE_REG" tournament="SCHEDULED|LIVE"  />`;
                // } else {
                //     if (value[2] === "ALL") {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" tournament="' + "SCHEDULED" + '" showTimeBased="' + value[5] + '"/>';
                //     }
                //     else {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" tournament="' + "SCHEDULED" + '" type="' + value[2] + '" showTimeBased="' + value[5] + '"/>';
                //     }
                // }
                // if (value[6] && value[7]) {
                //     tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" tournament="SCHEDULED|LIVE"' + ' type="' + value[8] + '" />';
                // } else {
                //     if (value[6]) {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" tournament="SCHEDULED|LIVE" />';
                //     }
                //     if (value[7]) {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" type="' + value[8] + '" tournament="SCHEDULED|LIVE" />';
                //     }
                // }
                tablerequest = `<GetTournaments id="${this._dateUtils.getUniqueIdentifier()}" modes="${value[0]}" games="${value[1]}"${value[5] ? ` status="ANNOUNCED|REGISTERING|SEATING|LATE_REG" ` : ``}${value[6] ? ` limits="${value[2]}" ` : ``}${value[7] ? ` type="${value[8]}" ` : ``} tournament="SCHEDULED|LIVE" />`
                /*============================ Table Request for Tournaments End  ============================================*/
            }
            if (value[10] === 'SIT & GO') {
                /*============================ Table Request for SIT and Go Start  ============================================*/

                // if (value[5]) {
                //     tablerequest = `<GetTournaments modes="CASH" games="TEXAS_HOLDEM|OMAHA|OMAHA_HIGH_LOW|REVERSE_HOLDEM|OMAHA_FIVE_CARDS|OMAHA_SIX_CARDS" status="ANNOUNCED|REGISTERING|SEATING|LATE_REG" tournament="SITANDGO" />`;
                // } else {
                //     if (value[2] === "ALL") {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" tournament="' + "SITANDGO" + '" showTimeBased="' + value[5] + '"/>';
                //     } else {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" tournament="' + "SITANDGO" + '" tables="' + value[2] + '" showTimeBased="' + value[5] + '"/>';
                //     }
                //     // }
                // }
                // if (value[6] && value[7]) {
                //     tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" tournament="SITANDGO"' + ' tables="' + value[8] + '" />'
                // } else {
                //     if (value[6]) {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" limits="' + value[2] + '" tournament="SITANDGO" />';
                //     }
                //     if (value[7]) {
                //         tablerequest = '<GetTournaments id="' + this._dateUtils.getUniqueIdentifier() + '" modes="' + value[0] + '" games="' + value[1] + '" tables="' + value[8] + '" tournament="SITANDGO" />';
                //     }
                // }

                /*============================ Table Request for SIT and Go End  ============================================*/
                tablerequest = `<GetTournaments id="${this._dateUtils.getUniqueIdentifier()}" modes="${value[0]}" games="${value[1]}"${value[5] ? ` status="ANNOUNCED|REGISTERING|SEATING|LATE_REG" ` : ``}${value[6] ? ` limits="${value[2]}" ` : ``}${value[7] ? ` tables="${value[8]}" ` : ``} tournament="SITANDGO" />`
            }
        }
        // console.log(tablerequest);

        if (this.props.network !== undefined) {
            this.props.network.send(tablerequest);
            this.props.updateRequest(tablerequest);
        }
    }

    render() {
        return <Filters action={this.setFilters.bind(this)} check={this.setCheckState.bind(this)} userdata={this.props} texttorno={this.props.t('TOURNEYS')} Limittext={this.props.t("Limit")} gametypetext={this.props.t("Game Type")} gametext={this.props.t('GAMES')} cashier={this.props.t('CASHIER')} sit_go={this.props.t('SIT & GO')} playerText={this.props.t('Players')} tablestext={this.props.t('Tables')} datas={this.props.datas}
            setAction={this.props.setAction} handleshowCheckOne={this.props.handleshowCheckOne} openMenu={this.props.openMenu} logOutHandler={this.props.logOutHandler} avtar={this.props.avtar} user={this.props.user} balance={this.props.balance} stars={this.props.stars}
        ></Filters>;
    }
}
export default withTranslation()(FilterTabs)