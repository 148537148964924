
import background1 from "../../../../assets/images/lobby/headerimage.png";
import UserLabel from '../lobby/userLabel';
import Screen from "../../../utils/screen";
import carpet from '../../../../assets/images/lobby/bg_topmenu.png'
import fileName from "../../../../jsconfig";

const LobbyHeader = (props) => {


    return (
        <div className="fd bg_new_1">
            {(Screen.getDeviceType().name === "Mobile" && fileName.name !== "Leader_bet") && <img className="headLogo" src={background1} alt="logo missing" style={{ width: '100%', maxHeight: '85px' }}></img>}
            {/* {(Screen.getDeviceType().name === "Mobile" && fileName.name === "Leader_bet") && <div className="" style={{ width: '100%', minHeight: '65px', background: "#202126" }}></div>} */}
            {(Screen.getDeviceType().name === "Mobile" && fileName.name === "Leader_bet") && <div className="" style={{ width: '100%', minHeight: '129px', background: "#000" }}></div>}
            {Screen.getDeviceType().name === "Desktop" && <img className="headLogo" src={carpet} alt="logo missing" style={{ width: '100%', maxHeight: '85px' }}></img>}
            {/* <div className="headLogo" style={{height:"75px"}}></div> */}
            <div className="row headerContent">
                {/* <div className="col-5 text_center df_ac">
                    <img className="headLogo" src={logo} alt="logo missing"></img>
                </div> */}
                <div className={fileName.name !== "Leader_bet" || fileName.name !== "Riverpoker" ? 'col - 12' : ""}>
                    <UserLabel activeTables={props.activeTables} network={props.network} ProfilePop={props.ProfilePop} setAction={props.setAction} openMenu={props.openMenu} Exit={props.logOutHandler} avtar={props.avtar} user={props.user} balance={props.balance} datas={props.datas} stars={props.stars} />
                </div>

            </div>
        </div>
    )
}

export default LobbyHeader;