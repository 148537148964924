import close_1 from './../../../../assets/images/table/close_1.svg';
import "../../../../css/ui/popUps/tourneyPlayerRanking.css";
import React, { useEffect, useState } from "react";

export default function TourneyPlayerRanking(props) {



    useEffect(() => {
        const updateScale = () => {
            const element = document.querySelector('.popup_1_in');
            if (element) {
                // Get the screen height
                const screenHeight = window.innerHeight;

                // Define minimum and maximum heights for scaling
                const minHeight = 375; // Minimum screen height for scaling
                const maxHeight = 812; // Maximum screen height for scaling

                // Define minimum and maximum scale factors
                const minScale = 0.75; // Minimum scale factor
                const maxScale = 1; // Maximum scale factor

                // Calculate scale factor based on screen height
                let scale = (screenHeight - minHeight) / (maxHeight - minHeight) * (maxScale - minScale) + minScale;

                // Clamp the scale to be within the minScale and maxScale bounds
                scale = Math.max(minScale, Math.min(maxScale, scale));

                // Apply the scale transformation
                element.style.transform = `scale(${scale})`;
                // element.style.transformOrigin = 'top left';
                // Adjust origin if needed
            }
        };

        // Initial call to set scale on mount
        updateScale();

        // Set up event listener for screen size changes
        window.addEventListener('resize', updateScale);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', updateScale);
    }, []);



    return (
        <>
            <div className="popCover_1" onClick={(e) => {
                e.preventDefault();
                props.setAction("hideTplayerAlert");
            }}> </div>
            <div className="popup_1">
                <div className="popup_1_in">
                    <div className="head"> Tourney Player Ranking
                        <button className="close_1" onClick={(e) => {
                            e.preventDefault();
                            props.setAction("hideTplayerAlert");
                        }}> <img src={close_1} alt='' /> </button>
                    </div>
                    <div className="fd p_10">
                        <table className="table_1 text_center">
                            <tbody>
                                <tr>
                                    <td> {props.data.lineOne} </td>
                                </tr>
                                <tr>
                                    <td> {props.data.lineTwo} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}