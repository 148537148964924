import "../../../../css/ui/popUps/detailedStatistics.css";
import closeIcon from '../../../../assets/images/lobby/close_icon.svg';
import { withTranslation } from 'react-i18next'
 function DetailedStatistics(props){
    return(
        <div className="alert_cover" onClick = {(e)=>{e.preventDefault();props.action()}}>
            <div className="detailedStatistics popup_500"> 
                <div className="header_1 fd"> Detailed Statistics</div>
                <div className="datePanel fd">Session Started at:  <span style={{color:'#ffe555'}}>{props.data.time}</span></div>
                <div className="statsPanel fd">
                    <div className="leftPan">
                        <table className="table_3">
                            <tbody>
                            <tr>
                                <td>{props.t("Hands Played")}</td> <td>{props.data.played} </td>
                            </tr>
                            <tr>
                                <td>{props.t("Hands Won")}: </td><td>{props.data.won}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Buy-In")}: </td> <td>{props.data.buyIn}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Wins")}: </td> <td>{props.data.wins}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Bets")}: </td> <td> {props.data.bets}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Win-Bets")}: </td> <td>{props.data.winBets}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="rightPan">
                        <table className="table_3">
                        <tbody>
                            <tr>
                                <td>{props.t("Won/Saw")}: </td><td>{props.data.wonSaw}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Pre Flop")}: </td><td>{props.data.preFlop}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Flop")}Flop: </td><td>{props.data.flop}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Turn")}: </td><td>{props.data.turn}</td>
                            </tr>
                            <tr>
                                <td>{props.t("River")}: </td><td>{props.data.river}</td>
                            </tr>
                            <tr>
                                <td>{props.t("Show Down")}: </td><td>{props.data.showDown}</td>
                            </tr>
                            <tr>
                                <td>{props.t("All In")}: </td><td>{props.data.allIn}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
            </div>
        </div>
    )
}
export default withTranslation()(DetailedStatistics)