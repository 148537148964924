import React from "react";
import Loader from "../utils/loader";
import LobbyMain from "./lobby/lobbyMain";
// import Table from "../table/table";
import TableMain from "./table/tableMain";
import ReplayHistory from "./table/replay";
import TesingReplay from "./table/HandhistoryReplay";
import TourneyLobbyMain from "./torneyLobby/tourneyLobbyMain";
import Alert from "./ui/popUps/alert";
import "../../css/game.css";
import "../../css/ui/table/tableBg.css";
import TableWaterMark from "../../assets/images/lobby/tableIcons/tableBg/riverWatermark.png";
import moreOptions from "../../assets/images/lobby/leaber_bet_icons/moreOptions.png";
import upArrows from "../../assets/images/lobby/leaber_bet_icons/upArrows.png";

import close_1 from '../../assets/images/table/close_1.svg';
import MOBILE_POPUP_EN from '../../assets/images/login/MOBILE_POPUP_EN.mp4';
import fileName from "../../jsconfig";
import Config from "../../config";
import { Emitter } from "../utils/eventEmitter";

export default class Game extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tablehidden: { table1: "block", table2: "block", table3: "block", table4: "block", table5: "block", table6: "block" },
            showPro: true,
            showLoader: true,
            showLobby: true,
            cashTable: -1,
            activeTable: -1,
            tourneyLobby: -1,
            activeTLobby: -1,
            showCashTableTabs: true,
            recallTourneyLobby: false,
            showTlobbyButton: false,
            hideMiniTableTab: false,
            showMiniTableTab: true,
            showStaticTable: false,
            showWonTable: false,
            tableOpned: false,
            showToggle: false,
            alert: {
                show: false,
                lineOne: "",
                lineTwo: "",
            },
            themes: {
                carpet: "carpet1",
                table: "table1",
            },
            openTables: {},
            openTLobbies: {},
            bottomInfoImage: moreOptions,
            tableArrays: [],
            openTLobby: [],
            avatarsList: [],
            cardAnimation: false
        };
        this.config = new Config();
        this.opentable = 0;
        this.tableArrays = [false, false, false, false, false, false];
        this.tourneyLobbyArray = [false, false]
        this.TableOpenOrNot = ["close", "close", "close", "close", "close", "close"];
        this.tableidArray = [];
        this.cTableOne = React.createRef();
        this.cTableTwo = React.createRef();
        this.cTableThree = React.createRef();
        this.cTableFour = React.createRef();
        this.cTableFive = React.createRef();
        this.staticTable = React.createRef();

        this.tLobbyOne = React.createRef();
        this.tLobbyTwo = React.createRef();


        window.name = "SooperPokerMainLobby";

        window.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        });
        console.log("the main props: ", props);
    }
    componentDidMount() {
        let promo = sessionStorage.getItem("promo")
        if (promo) {
            this.setState({ showPro: false })
        }

        setTimeout(() => {
            this.setState({ showLoader: false });
            function smoothScrollToTop() {
                const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                if (currentScroll > 0) {
                    window.scrollTo(0, currentScroll - Math.ceil(currentScroll / 8));
                    requestAnimationFrame(smoothScrollToTop);
                }
            }
            smoothScrollToTop();
        }, 5000);
    }
    async LobbyHandler(action, data) {
        switch (action) {
            case "openCashTable":
                console.log("==== open cash table: ", data);
                this.setState({ showMiniTableTab: true, tableOpned: true });
                this.toggleCashTourneyTables("", 'c-tables');
                let availability = await this.findVacantTable(this.state.openTables, 5, data?.OpenTable?.attr?.id ?? null);
                console.log("the table availability: ", availability);
                if (availability.existing) {
                    this.showCashTables(availability.index);
                    return;
                }
                if (!availability.available) {
                    this.setState({ alert: { show: true, lineOne: "you have already opened five live games, please close one", lineTwo: "" }, cashTable: -1 });
                    return;
                }
                this.openTheTable(data?.OpenTable?.attr?.id ?? null, null, data.isSeatMe, availability);
                break;
            case "openTournamentLobby":
                console.log("open the t lobby: ", data);
                this.setState({ showMiniTableTab: false, tableOpned: false });
                // this.toggleCashTourneyTables("", 't-tables');
                if (this.tourneyLobbyArray.indexOf(false) !== -1) {
                    this.toggleCashTourneyTables("", 't-tables');
                }
                let Tavailability = await this.findVacantTable(this.state.openTLobbies, 2, data?.OpenTournamentLobby?.attr?.id ?? null);
                console.log("the table availability: ", Tavailability);
                if (Tavailability.existing) {
                    this.showTourneyLobby(Tavailability.index);
                    return;
                }
                if (!Tavailability.available) {
                    this.setState({ alert: { show: true, lineOne: "you have already opened two tournament lobbies, please close one", lineTwo: "" } });
                    return;
                }
                this.openTourneyLobby(data?.OpenTournamentLobby?.attr?.id, data?.OpenTournamentLobby?.attr?.tableId === undefined ? null : data?.OpenTournamentLobby?.attr?.tableId, Tavailability);
                break;
            // case "showFooterToggle":
            //     let callOnce = true;
            //     if ((Object.values(this.state.openTables).length) + Object.values(this.state.openTLobbies).length === 0 && callOnce) {
            //         console.log("there is open table");
            //         callOnce = false;
            //         if (data.length !== 0) {
            //             console.log(data)
            //             if (data[0].hasOwnProperty("status")) {
            //                 // this.state.activeTLobby.push(data[0])
            //                 this.state.openTLobby.push(data[0])
            //                 this.setState({ showCashTableTabs: false, activeTLobby: data.length - 1 });
            //                 console.log("check if")
            //             } else {
            //                 // let ot = { ...this.state.openTables };
            //                 // console.log(data[0])
            //                 // console.log(this.state.openTables)
            //                 this.setState({ openTables: data })
            //                 console.log("check else")
            //                 this.setState({ showCashTableTabs: true, activeTable: data.length - 1 });
            //             }
            //             this.setState({ showToggle: true })
            //         }
            //     } else {
            //         console.log("there is no open table");
            //         this.setState({ showToggle: false })
            //     }
            //     break;
            case "getAvatarsList":
                this.setState({ avatarsList: data });
                break
            default:
                break;
        }
    }
    SaveIdmainlobby(action, id) {
        switch (action) {
            case "add":
                if (this.tableidArray.indexOf(id) === -1) {
                    this.tableidArray.push(String(id));
                }
                break;
            case "remove":
                this.tableidArray = this.tableidArray.filter(function (letter) {
                    return letter !== id;
                });
                break;
            default:
                console.log(action, id);
                break;
        }
        if (this.tourneyLobbyArray[0]) {

            this.tLobbyOne.current.UpadateTableId()
        }
        if (this.tourneyLobbyArray[1]) {

            this.tLobbyTwo.current.UpadateTableId()
        }
    }
    TableHandler(action, data, Tableid) {
        switch (action) {
            case "closeCashTable":
                this.closeTheTable(data);
                this.setState({ tableOpned: false });
                // unload the table, clear the socket, reset state??
                break;
            case "SINGLE_TABLE":
                this.setState({ tableOpned: true });
                this.closeTheTable_S(data, Tableid);
                break;
            case "TOURNAMENT_TABLE":
                this.setState({ tableOpned: true });
                this.closeTheTable_T(data, Tableid);
                break;
            default:
                break;
        }
    }
    async TourneyHandler(action, data) {
        switch (action) {
            case "closeTourneyLobby":
                // unload the tourney Lobby, clear the socket, reset state??
                console.log("======>  close the tourney lobby: ", data);
                this.closeTheTLobby(data);
                this.setState({ showMiniTableTab: false, tableOpned: true });
                // this.setState({ showLobby: true, cashTable: -1, tourneyLobby: -1 });
                break;
            case "openTourneyTable":
                console.log("==== open cash table: ", data);
                this.setState({ showMiniTableTab: true, tourneyLobby: -1, tableOpned: true, showCashTableTabs: true });
                // this.setState({ showMiniTableTab: true, tourneyLobby: -1 });
                let availability = await this.findVacantTable(this.state.openTables, 5, data.tourney_table_id ?? null);
                console.log("the table availability: ", availability);
                if (availability.existing) {
                    this.showCashTables(availability.index);
                    return;
                }
                if (!availability.available) {
                    this.setState({ alert: { show: true, lineOne: "you have already opened five live games, please close one", lineTwo: "", showLobby: false } });
                    return;
                }
                this.openTheTable(data.tourney_table_id, data.id, false, availability);
                break;
            case "hideMiniTableTab":
                this.setState({ hideMiniTableTab: data })
                break;
            default:
                break;
        }
    }
    setThemes(data) {
        this.setState({ themes: data });
    }
    setThemesL(group, data) {
        this.cTableOne.current.setThemesL(group, data)
        this.cTableTwo.current.setThemesL(group, data)
        this.cTableThree.current.setThemesL(group, data)
        this.cTableFour.current.setThemesL(group, data)
        this.cTableFive.current.setThemesL(group, data)
        this.setState({ themes: data });

        console.log(data)

    }
    closeTheTLobby(data) {
        // console.log("==== close Tourney Lobby: ", data);
        let otl = { ...this.state.openTLobbies };
        for (var index in otl) {
            if (otl[index].id == data.id) {
                this.tourneyLobbyArray[index] = false;
                delete otl[index];
            }
        }

        let at = Object.values(otl)[0]?.index ?? -1;
        // console.log("===open t lobbies: ", otl);
        // console.log({ showLobby: !(at >= 0), showCashTableTabs: !(at >= 0), cashTable: -1, tourneyLobby: at, openTLobbies: otl, activeTLobby: at });
        this.setState({ showLobby: !(at >= 0), showCashTableTabs: !(at >= 0), cashTable: -1, tourneyLobby: at, openTLobbies: otl, activeTLobby: at });
    }
    AnimationChange() {
        this.setState({ cardAnimation: !this.state.cardAnimation })
    }
    closeTheTable(data) {
        let ot = { ...this.state.openTables };
        for (var index in ot) {
            if (ot[index].id == data.id) {
                delete ot[index];
            }
        }

        let at = Object.values(ot)[0]?.index ?? -1;
        this.setState({ showLobby: !(at >= 0), showCashTableTabs: at >= 0, cashTable: at, tourneyLobby: -1, openTables: ot, activeTable: at });
    }
    closeTheTable_S(data, Tableid) {
        console.log(data)
        // console.log("==== close cash table: ", data);

        let ot = { ...this.state.openTables };
        for (var index in ot) {
            if (ot[index].id == data.id) {
                this.tableArrays[index] = false;
                delete ot[index];
            }
        }
        // console.log("====table list: ", ot);
        console.log(ot)
        this.opentable = ot.length
        let at = Object.values(ot)[0]?.index ?? -1;
        this.setState({ showLobby: !(at >= 0), showCashTableTabs: at >= 0, cashTable: at, tourneyLobby: -1, openTables: ot, activeTable: at });
        if (Object.values(this.state.openTLobbies).length !== 0) {
            this.showCashTables(null);
        }
        if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            this.InactiveTavleHAndle(Tableid);
        }
        if (this.tableArrays.indexOf(true) == -1) {
            this.setState({ showMiniTableTab: false })
        }
    }
    closeTheTable_T(data) {
        console.log(data)

        // console.log("==== close cash table: ", data);
        let ot = { ...this.state.openTables };
        for (var index in ot) {
            if (ot[index].id == data.id) {
                this.tableArrays[index] = false;
                delete ot[index];
            }
        }
        let at = Object.values(ot)[0]?.index ?? -1;
        this.setState({ showLobby: !(at >= 0), showCashTableTabs: at >= 0, cashTable: at, tourneyLobby: -1, openTables: ot, activeTable: at });
        this.showCashTables(null);
        if (this.state.tourneyLobby == -1 && this.state.activeTable == -1) {
            this.setState({ showLobby: true })
        } else if (this.state.tourneyLobby == 0) {
            this.tLobbyOne.current.openTablesRequest(data);
        } else {
            this.tLobbyTwo.current.openTablesRequest(data);
        }
        if (this.tableArrays.indexOf(true) == -1) {
            this.setState({ showMiniTableTab: false })
        }
    }
    openTheTable(table_id, tourneyId, isSeatMe, availability) {
        console.log(table_id, tourneyId, isSeatMe, availability)
        let d = {
            tableId: table_id,
            sid: this.props.sid,
            tourneyId: tourneyId,
            isSeatMe: isSeatMe,
            showLoader: true,
            themes: {
                carpet: "carpet1",
                table: "table1",
            },
        };

        try {
            let ot = { ...this.state.openTables };
            ot[availability.index] = { id: table_id, index: availability.index, tourneyId: tourneyId };
            this.opentable = availability.index;
            this.tableArrays[availability.index] = true;
            if (tourneyId !== null && ot[availability.index]) {
                this.setState({ showTlobbyButton: true })
            } else {
                this.setState({ showTlobbyButton: false })
            }
            let tablehidden = { ...this.state.tablehidden }
            this.setState({ showLobby: false, cashTable: availability.index, tourneyLobby: -1, openTables: ot, activeTable: availability.index });


            switch (availability.index) {
                case 0:
                    this.cTableOne.current.initNetwork(d);
                    if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                        tablehidden.table1 = "block";
                    }
                    break;
                case 1:
                    this.cTableTwo.current.initNetwork(d);
                    if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                        tablehidden.table2 = "block"
                    }
                    break;
                case 2:
                    this.cTableThree.current.initNetwork(d);
                    if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                        tablehidden.table3 = "block"
                    }
                    break;
                case 3:
                    this.cTableFour.current.initNetwork(d);
                    if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                        tablehidden.table4 = "block"
                    }
                    break;
                case 4:
                    this.cTableFive.current.initNetwork(d);
                    if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                        tablehidden.table5 = "block"
                    }
                    break;
                default:
                    console.log("====== table index not matching");
                    break;
            }
            this.TableOrLobbbyHandle();
            this.setState({ tablehidden: tablehidden })
            if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                this.RearrageHandle();
            }
        } catch (error) {
            console.log(error);
        }
    }
    async findVacantTable(list, cnt, id) {
        if (id) {
            for (var index in list) {
                if (list[index].id == id) {
                    return { available: false, existing: true, index: index, msg: "" };
                }
            }
        }
        for (let i = 0; i < cnt; i++) {
            if (!list[i]?.id) {
                return { available: true, existing: false, index: i, msg: "" };
            }
        }

        return { available: false, existing: false, index: null, msg: "No Vacant Tables Available" };
    }
    openTourneyLobby(tourney_id, tableId, availability) {
        console.log(tourney_id, tableId, availability)
        let openTLobby = this.state.openTLobby;
        let data = {
            tourneyId: tourney_id,
            sid: this.props.sid,
            tableId: tableId,
        };

        try {
            // let otl = [...this.state.openTLobbies, { id: tourney_id, index: availability.index }];
            let otl = { ...this.state.openTLobbies };
            openTLobby.push(otl)
            otl[availability.index] = { id: tourney_id, index: availability.index };
            this.tourneyLobbyArray[availability.index] = true;

            // console.log("====t lobby list: ", otl);

            this.setState({ showCashTableTabs: false, showLobby: false, cashTable: -1, tourneyLobby: availability.index, openTLobbies: otl, activeTLobby: availability.index });
            switch (availability.index) {
                case 0:
                    this.tLobbyOne.current.initNetwork(data);
                    break;
                case 1:
                    this.tLobbyTwo.current.initNetwork(data);
                    break;
                default:
                    console.log("====== table index not matching");
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }
    showLobby(target) {
        // console.log("==== show the lobby: ", target);
        if (target == "lobby") {
            let at = this.state.activeTable;
            this.setState({ showLobby: true, cashTable: -1, tourneyLobby: -1 });
            this.setState({ bottomInfoImage: moreOptions });
            this.TableOrLobbbyHandle();
            if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                this.RearrageHandle()
                this.BlockAndNoneHandle()
            }
        }

    }
    showCashTables(index, table_id) {
        console.log(this.state.showCashTableTabs)
        console.log(index)
        // console.log("========= show the cash table: ", index);
        if (this.state.showCashTableTabs) {
            let at = this.state.activeTable;
            // if (this.state.showToggle) {
            //     console.log("cash table ifff")
            //     console.log(this.state.openTables)
            //     console.log(Object.values(this.state.openTables).length)
            //     console.log(at)

            //     let openTableCnt = Object.values(this.state.openTables).length;
            //     this.tableArrays[openTableCnt - 1] = true;

            //     this.setState({ showLobby: false, cashTable: index ?? at, activeTable: index ?? at, tourneyLobby: -1 });

            //     let d = {
            //         tableId: this.state.openTables[0].id,
            //         sid: this.props.sid,
            //         tourneyId: null,
            //         isSeatMe: false,
            //         showLoader: true,
            //         themes: {
            //             carpet: "carpet1",
            //             table: "table1",
            //         },
            //     };
            //     console.log(d)
            //     let tablehidden = { ...this.state.tablehidden }

            //     setTimeout(() => {
            //         switch (openTableCnt - 1) {
            //             case 0:
            //                 this.cTableOne.current.initNetwork(d);
            //                 if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            //                     tablehidden.table1 = "block"
            //                 }
            //                 break;
            //             case 1:
            //                 this.cTableTwo.current.initNetwork(d);
            //                 if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            //                     tablehidden.table2 = "block"
            //                 }
            //                 break;
            //             case 2:
            //                 this.cTableThree.current.initNetwork(d);
            //                 if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            //                     tablehidden.table3 = "block"
            //                 }
            //                 break;
            //             case 3:
            //                 this.cTableFour.current.initNetwork(d);
            //                 if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            //                     tablehidden.table4 = "block"
            //                 }
            //                 break;
            //             case 4:
            //                 this.cTableFive.current.initNetwork(d);
            //                 if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            //                     tablehidden.table5 = "block"
            //                 }
            //                 break;
            //             default:
            //                 console.log("====== table index not matching");
            //                 break;
            //         }
            //         this.TableOrLobbbyHandle();
            //         this.setState({ tablehidden: tablehidden })
            //     }, 1000);
            // } else {
            console.log("cash table else")
            this.setState({ showLobby: false, cashTable: index ?? at, activeTable: index ?? at, tourneyLobby: -1 });

            console.log(at)

            if (this.tableArrays[0]) {
                this.cTableOne.current.TagName();
            }
            if (this.tableArrays[1]) {
                this.cTableTwo.current.TagName();
            }
            if (this.tableArrays[2]) {
                this.cTableThree.current.TagName();
            }
            if (this.tableArrays[3]) {
                this.cTableFour.current.TagName();
            }
            if (this.tableArrays[4]) {
                this.cTableFive.current.TagName();
            }

            this.TableOrLobbbyHandle();
            const getIndex = function (r) {
                for (const key in r) {
                    if (r[key] && r[key].id === table_id) {
                        return key;
                    }
                }
                return -1;
            };
            let arr = this.state.openTables;
            console.log(getIndex(arr));

            if (this.state.openTables[getIndex(arr)]?.tourneyId !== null) {
                this.setState({ showTlobbyButton: true });
            } else {
                this.setState({ showTlobbyButton: false });
            }
            // this.state.openTables.forEach((tables) => {
            //     if (tables.tourneyId && tables.tableId === table_id) {
            //         this.setState({ showTlobbyButton: true })
            //     } else {
            //         this.setState({ showTlobbyButton: false })
            //     }
            // })
            // }
        } else {
            // if (this.state.showToggle) {
            //     let openTLobby = this.state.openTLobby[this.state.openTLobby.length - 1]
            //     console.log(openTLobby)
            //     // console.log(this.state.openTLobbies)
            //     let data = {
            //         tourneyId: openTLobby.id,
            //         sid: this.props.sid,
            //         // tableId: openTLobby.id,
            //         tableId: null,
            //     };
            //     console.log(data)
            //     switch (this.state.openTLobby.length - 1) {
            //         case 0:
            //             this.tLobbyOne.current.initNetwork(data);
            //             break;
            //         case 1:
            //             this.tLobbyTwo.current.initNetwork(data);
            //             break;
            //         default:
            //             console.log(data)
            //             console.log("-------------------Error-------------------")
            //             break;
            //     }
            // }
            let at = this.state.activeTLobby;
            this.setState({ showLobby: false, tourneyLobby: index ?? at, activeTLobby: index ?? at, cashTable: -1 });
        }
        if (this.config.URL_Environment.TableFeatures.selected == "F2") {
            this.RearrageHandle()
        }

    }

    // showCashTables(e) {
    //   console.log("========= show the cash table: ", e);
    //     e.preventDefault();
    //     if(this.state.showCashTableTabs){
    //       let at = this.state.activeTable;
    //       this.setState({ showLobby: false, cashTable: e.target.dataset.index ?? at, activeTable: e.target.dataset.index ?? at, tourneyLobby:-1 });
    //     }else{
    //       let at = this.state.activeTLobby;
    //       this.setState({ showLobby: false, tourneyLobby: e.target.dataset.index ?? at, activeTLobby: e.target.dataset.index ?? at, cashTable:-1 });

    //     }
    // }
    showTourneyLobby(index) {
        let at = this.state.activeTLobby;
        this.setState({ showLobby: false, tourneyLobby: index ?? at, activeTLobby: index ?? at });
        // if (this.config.URL_Environment.TableFeatures.selected == "F2") {
        //     this.RearrageHandle()
        // }
    }

    toggleCashTourneyTables(e, name) {
        if (e !== "") {
            e.preventDefault();
            console.log("This the watch or join table", e.target.dataset.name, name)
        }
        // console.log("toggle between c/t: ", e.target);
        // console.log("toggle between c/t name: ", e.target.dataset.name);
        var tableType = (e !== "" ? e.target.dataset.name : name)

        // if (e.target.dataset.name == "c-tables") {
        if (tableType == "c-tables") {
            this.setState({ showMiniTableTab: true, tableOpned: true })
            if (this.config.URL_Environment.TableFeatures.selected == "F1") {
                this.setState({ hideMiniTableTab: false })
            }
            if (this.config.URL_Environment.TableFeatures.selected == "F2") {

                this.setState({ hideMiniTableTab: true })
            }
            let at = this.state.activeTable;

            this.TableOrLobbbyHandle()
            // show the C-tables tabs, with activeTable as active
            // console.log("====== show the cash tables ");
            // e.target.innerText = "T.Tables";
            // e.target.dataset.name = "t-tables";
            let cTab = document.getElementById(`c-tables-tab`);
            // console.log("====>> Cash tables tab: ", cTab, cTab.dataset.isactive);
            if (cTab) {
                cTab.style.animation = "";
                cTab.style.background = "";
                cTab.style.color = "";
            }
            this.setState({ showCashTableTabs: true, cashTable: this.state.activeTable, tourneyLobby: -1 });
            if (this.config.URL_Environment.TableFeatures.selected == "F2") {
                this.RearrageHandle()
            }
        }
        // if (e.target.dataset.name == "t-tables") {
        if (tableType == "t-tables") {
            this.setState({ showMiniTableTab: false });
            this.setState({ hideMiniTableTab: false, tableOpned: false });
            let at = this.state.activeTable;
            // this.cTableOne.current.TableOrLobbby(false);
            this.TableOrLobbbyHandle();

            Emitter.emit("updateTLobbyDetails");
            // show the Tourny Lobby tabs, with activeT-lobby as active
            // console.log("====== show the TTTT lobbies ");

            this.setState({ showCashTableTabs: false, cashTable: -1, tourneyLobby: this.state.activeTLobby });
            // e.target.innerText = "C.Tables";
            // e.target.dataset.name = "c-tables";
        }
    }
    setPopUpActions(action) {
        switch (action) {
            case "hideAlert":
                this.setState({ alert: { show: false, lineOne: "", lineTwo: "" } });
                break;
            default:
                break;
        }
    }
    handleClick = (e) => {
        sessionStorage.setItem("promo", true)
        this.setState({ showPro: false }); //raju
    };
    JoinTable = (e) => {
        console.log(`<GetTableDetails id="41a-32" type="SINGLE_TABLE"/>`);
        console.log(`<OpenTable id="41a-32" type="SINGLE_TABLE"/>`)
        // let table_id = '41a-32';
        // let isSeatMe = false;
        // let tourneyId = undefined;
        // let d = {
        //     tableId: table_id,
        //     sid: this.props.sid,
        //     tourneyId: tourneyId,
        //     isSeatMe: isSeatMe,
        //     showLoader: true,
        //     themes: {
        //         carpet: "carpet1",
        //         table: "table1",
        //     },
        // };
        // console.log(d)
        // this.staticTable.current.initNetwork(d);
        this.setState({ showStaticTable: true, showLobby: false })
    }

    JoinTableWon = (e) => {
        this.setState({ showWonTable: true, showLobby: false })
    }
    closeReplayTable = (e) => {
        this.setState({ showWonTable: false, showLobby: true })
    }
    ActiveTable(e) {
        switch (e) {
            case "gamebox1":
                this.TableOpenOrNot[0] = "open";
                this.activeTable = 1;
                this.setState({ activeTable: 1, tablehidden: { table1: "block", table2: "none", table3: "none", table4: "none", table5: "none", table6: "none" } })
                break;
            case "gamebox2":
                this.TableOpenOrNot[1] = "open";
                this.activeTable = 2;
                this.setState({ activeTable: 2, tablehidden: { table1: "none", table2: "block", table3: "none", table4: "none", table5: "none", table6: "none" } })
                break;
            case "gamebox3":
                this.TableOpenOrNot[2] = "open";
                this.activeTable = 3;
                this.setState({ activeTable: 3, tablehidden: { table1: "none", table2: "none", table3: "block", table4: "none", table5: "none", table6: "none" } })
                break;
            case "gamebox4":
                this.TableOpenOrNot[3] = "open"
                this.activeTable = 4;
                this.setState({ activeTable: 4, tablehidden: { table1: "none", table2: "none", table3: "none", table4: "block", table5: "none", table6: "none" } })
                break;
            case "gamebox5":
                this.TableOpenOrNot[4] = "open";
                this.activeTable = 5;
                this.setState({ activeTable: 5, tablehidden: { table1: "none", table2: "none", table3: "none", table4: "none", table5: "block", table6: "none" } })

                break;
            case "gamebox6":
                this.TableOpenOrNot[5] = "open";
                this.activeTable = 6;
                this.setState({ activeTable: 6, tablehidden: { table1: "none", table2: "none", table3: "none", table4: "none", table5: "none", table6: "block" } })
                break;
            default:
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "block", table3: "block", table4: "block", table5: "block", table6: "block" } })
                this.TableOpenOrNot = ["close", "close", "close", "close", "close", "close"]

                this.RearrageHandle()
                break;
        }

    }
    InactiveTavleHAndle(e) {
        // let tablehidden = { ...this.state.tablehidden }
        switch (e) {
            case "gamebox1":
                // tablehidden.table1 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "none", table2: "block", table3: "block", table4: "block", table5: "block", table6: "block" } })

                break;
            case "gamebox2":
                // tablehidden.table2 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "none", table3: "block", table4: "block", table5: "block", table6: "block" } })

                break;
            case "gamebox3":
                // tablehidden.table3 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "block", table3: "none", table4: "block", table5: "block", table6: "block" } })


                break;
            case "gamebox4":
                // tablehidden.table4 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "block", table3: "block", table4: "none", table5: "block", table6: "block" } })

                break;
            case "gamebox5":
                // tablehidden.table5 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "block", table3: "block", table4: "block", table5: "none", table6: "block" } })

                break;
            case "gamebox6":
                // tablehidden.table6 = "none";
                this.setState({ activeTable: 0, tablehidden: { table1: "block", table2: "block", table3: "block", table4: "block", table5: "block", table6: "none" } })
                break;
            default:

                break;
        }
        this.RearrageHandle()


    }
    BlockAndNoneHandle() {
        let tablehidden = { ...this.state.tablehidden }
        if (this.tableArrays[0]) {
            tablehidden.table1 = "block"
        }
        if (this.tableArrays[1]) {
            tablehidden.table2 = "block"
        }
        if (this.tableArrays[2]) {
            tablehidden.table3 = "block"
        }
        if (this.tableArrays[3]) {
            tablehidden.table4 = "block"
        }
        if (this.tableArrays[4]) {
            tablehidden.table5 = "block"
        }
        if (this.tableArrays[5]) {
            tablehidden.table6 = "block"
        }
        this.setState({ tablehidden: tablehidden })
    }
    RearrageHandle() {
        setTimeout(() => {
            if (this.tableArrays[0]) {
                this.cTableOne.current.CloseTableRearrageGrid(this.state.showToggle ? 1 : (Object.values(this.state.openTables).length))
            }
            if (this.tableArrays[1]) {
                this.cTableTwo.current.CloseTableRearrageGrid(this.state.showToggle ? 2 : (Object.values(this.state.openTables).length))
            }
            if (this.tableArrays[2]) {
                this.cTableThree.current.CloseTableRearrageGrid(this.state.showToggle ? 3 : (Object.values(this.state.openTables).length))
            }
            if (this.tableArrays[3]) {
                this.cTableFour.current.CloseTableRearrageGrid(this.state.showToggle ? 4 : (Object.values(this.state.openTables).length))
            }
            if (this.tableArrays[4]) {
                this.cTableFive.current.CloseTableRearrageGrid(this.state.showToggle ? 5 : (Object.values(this.state.openTables).length))
            }
        }, this.state.showToggle ? 1000 : 0);

    }
    TableOrLobbbyHandle() {
        if (this.tableArrays[0]) {
            this.cTableOne.current.TableOrLobbby(true);
        }
        if (this.tableArrays[1]) {
            this.cTableTwo.current.TableOrLobbby(true);
        }
        if (this.tableArrays[2]) {
            this.cTableThree.current.TableOrLobbby(true);
        }
        if (this.tableArrays[3]) {
            this.cTableFour.current.TableOrLobbby(true);
        }
        if (this.tableArrays[4]) {
            this.cTableFive.current.TableOrLobbby(true);
        }
        if (this.tableArrays[5]) {

        }
    }

    actionButton(e) {
        // alert("test")
        let p = document.getElementsByClassName("poker-nav_lb")[0];
        p.style.transition = 'all .5s';
        if (this.state.bottomInfoImage !== moreOptions) {
            p.style.bottom = '-65px';
        } else {
            p.style.bottom = '65px';
        }
        this.setState({ bottomInfoImage: this.state.bottomInfoImage !== moreOptions ? moreOptions : upArrows });
    }
    render() {
        console.log("Animation Change is Called", this.state.cardAnimation);
        return (
            <div className="fd main-game">
                {/* <div className="fd">
                    <button type="button" onClick={(e) => this.JoinTable(e)}>Test Table</button>
                </div>
                <div className="fd">
                    <button type="button" onClick={(e) => this.JoinTableWon(e)}>Own Table</button>
                </div> */}

                {this.state.showLoader && <Loader></Loader>}
                <div className={this.state.showLobby ? "main_lobby" : "hide_main_lobby"}>
                    <LobbyMain data={this.props} animation={this.AnimationChange.bind(this)} LobbyHandler={this.LobbyHandler.bind(this)} setThemesL={this.setThemesL.bind(this)}></LobbyMain>


                    {/* {this.state.showPro && (
                        <div className="promoMain" onClick={(e) => { this.handleClick() }}>
                            <div className="popup_1 inpElm">
                                <div className="popup_1_in">
                                    <div className="head" style={{ color: '#161a1d', display: "block" }}>
                                        <button className="close_1" onClick={(e) => { this.handleClick() }} > <img src={close_1} alt="" />  </button>
                                    </div>
                                    <div className="fd">
                                        <div className="fd">
                                            <div className="fd">
                                                <div className="fd ">
                                                    <video autoPlay loop style={{ width: '-webkit-fill-available', borderBottomLeftRadius: (fileName.name === "Riverpoker" ? '' : "25px"), borderBottomRightRadius: (fileName.name === "Riverpoker" ? '' : "25px"), marginBottom: "-3px" }} >
                                                        <source src={MOBILE_POPUP_EN} type="video/mp4" />
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                    <div className="fd bg_new_1" style={{ height: "100px", marginTop: "-1px" }}>

                    </div>
                </div>

                {/* <div className={this.state.cashTable >= 0 ? "cash_table" : "hide_cash_table"}>
                    {fileName.name === "Riverpoker" && (
                        <div className="gameBg">
                            <div className="carpetBg" >
                                <div className="DivTableWaterMark">
                                    <img className="TableWaterMark" src={TableWaterMark} alt="" />
                                    <p style={{ color: 'rgb(255 255 255 / 19%)', marginTop: '0px', zIndex: '1', fontSize: '10px', letterSpacing: '0.3em' }}>RIVERPOKER.COM</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div> */}

                {/* {this.state.showStaticTable &&
                    <div className={this.state.showStaticTable ? "cash_table" : "hide_cash_table"}>
                        <ReplayHistory ref={this.staticTable} id={"gameBox1"} ></ReplayHistory>
                        <div className='cover'></div>
                    </div>
                } */}

                {/* {this.state.showWonTable &&
                    <div className={this.state.showWonTable ? "cash_table" : "hide_cash_table"}>
                        <TesingReplay ref={this.staticTable} id={"gameBox1"} closeReplayTable={this.closeReplayTable.bind(this)} />
                    </div>
                } */}

                {(this.config.URL_Environment.TableFeatures.selected == "F1") && <div className={this.state.cashTable == 0 ? "cash_table" : "hide_cash_table"}>
                    <TableMain ref={this.cTableOne} animation={this.state.cardAnimation} id={"gameBox1"} avatarsList={this.state.avatarsList} tableid={this.state.cashTable} activeTable={this.state.cashTable == 0} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)} ></TableMain>
                </div>}
                {(this.config.URL_Environment.TableFeatures.selected == "F1") && <div className={this.state.cashTable == 1 ? "cash_table" : "hide_cash_table"}>
                    <TableMain ref={this.cTableTwo} animation={this.state.cardAnimation} id={"gameBox2"} avatarsList={this.state.avatarsList} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}></TableMain>
                </div>}
                {(this.config.URL_Environment.TableFeatures.selected == "F1") && <div className={this.state.cashTable == 2 ? "cash_table" : "hide_cash_table"}>
                    <TableMain ref={this.cTableThree} animation={this.state.cardAnimation} id={"gameBox3"} avatarsList={this.state.avatarsList} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}></TableMain>
                </div>}
                {(this.config.URL_Environment.TableFeatures.selected == "F1") && <div className={this.state.cashTable == 3 ? "cash_table" : "hide_cash_table"}>
                    <TableMain ref={this.cTableFour} animation={this.state.cardAnimation} id={"gameBox4"} avatarsList={this.state.avatarsList} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}></TableMain>
                </div>}
                {(this.config.URL_Environment.TableFeatures.selected == "F1") && <div className={this.state.cashTable == 4 ? "cash_table" : "hide_cash_table"}>
                    <TableMain ref={this.cTableFive} animation={this.state.cardAnimation} id={"gameBox5"} avatarsList={this.state.avatarsList} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)} ></TableMain>
                </div>}




                {/* <------------------------------------------------------------------mutlipe table display start-----------------------------------------------------------> */}
                {(this.config.URL_Environment.TableFeatures.selected == "F2") && <div className={this.state.cashTable >= 0 ? "cash_table" : "hide_cash_table"} >
                    <div className="tablespage">
                        {this.tableArrays[0] && <TableMain id={"gamebox1"}
                            // width={this.state.showToggle ? (window.innerWidth - 5 * 1) : (window.innerWidth - 5 * ((Object.values(this.state.openTables).length))) / (((Object.values(this.state.openTables).length)) == 1 ? 1 : ((Object.values(this.state.openTables).length)) == 2 ? 2 : ((Object.values(this.state.openTables).length)) == 3 ? 3 : ((Object.values(this.state.openTables).length)) == 4 ? 2 : ((Object.values(this.state.openTables).length)) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            width={(window.innerWidth - 5 * ((Object.values(this.state.openTables).length))) / (((Object.values(this.state.openTables).length)) == 1 ? 1 : ((Object.values(this.state.openTables).length)) == 2 ? 2 : ((Object.values(this.state.openTables).length)) == 3 ? 3 : ((Object.values(this.state.openTables).length)) == 4 ? 2 : ((Object.values(this.state.openTables).length)) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table1}
                            value={1} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            ref={this.cTableOne} tableid={this.state.cashTable} activeTable={this.state.cashTable == 0} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}
                            TourneyHandler={this.TourneyHandler.bind(this)}
                        ></TableMain>}


                        {this.tableArrays[1] && <TableMain id={"gamebox2"}
                            width={(window.innerWidth - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 2 : (Object.values(this.state.openTables).length) == 3 ? 3 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table2}
                            value={2} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            ref={this.cTableTwo} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}
                            TourneyHandler={this.TourneyHandler.bind(this)}
                        ></TableMain>}
                        {this.tableArrays[2] && <TableMain id={"gamebox3"}
                            width={(window.innerWidth - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 2 : (Object.values(this.state.openTables).length) == 3 ? 3 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table3}
                            value={3} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            ref={this.cTableThree} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}
                            TourneyHandler={this.TourneyHandler.bind(this)}

                        ></TableMain>
                        }
                        {this.tableArrays[3] && <TableMain id={"gamebox4"}
                            width={(window.innerWidth - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 2 : (Object.values(this.state.openTables).length) == 3 ? 3 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table4}
                            value={4} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            ref={this.cTableFour} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}
                            TourneyHandler={this.TourneyHandler.bind(this)}

                        ></TableMain>}
                        {this.tableArrays[4] && <TableMain id={"gamebox5"}
                            width={(window.innerWidth - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 2 : (Object.values(this.state.openTables).length) == 3 ? 3 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table5}
                            value={5} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            ref={this.cTableFive} closeTTable={this.closeTheTable.bind(this)} setThemes={this.setThemes.bind(this)} TableHandler={this.TableHandler.bind(this)} SaveIdmainlobby={this.SaveIdmainlobby.bind(this)}
                            TourneyHandler={this.TourneyHandler.bind(this)}

                        ></TableMain>}
                        {this.tableArrays[5] && <TableMain id={"gamebox6"}
                            width={(window.innerWidth - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 2 : (Object.values(this.state.openTables).length) == 3 ? 3 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 3 : (Object.values(this.state.openTables).length) == 6 ? 3 : 3)}
                            height={(window.innerHeight - 5 * (Object.values(this.state.openTables).length)) / ((Object.values(this.state.openTables).length) == 1 ? 1 : (Object.values(this.state.openTables).length) == 2 ? 1 : (Object.values(this.state.openTables).length) == 3 ? 2 : (Object.values(this.state.openTables).length) == 4 ? 2 : (Object.values(this.state.openTables).length) == 5 ? 2 : (Object.values(this.state.openTables).length) == 6 ? 2 : 2)}
                            ActiveTable={this.ActiveTable.bind(this)}
                            tenery={this.state.activeTable} avatarsList={this.state.avatarsList}
                            tenery1={this.state.tablehidden.table6}
                            value={6} animation={this.state.cardAnimation}
                            opentables={Object.values(this.state.openTables).length}
                            TableOpenOrNot={this.TableOpenOrNot}
                            TourneyHandler={this.TourneyHandler.bind(this)}
                        ></TableMain>}

                    </div>
                </div>}

                {/* <------------------------------------------------------------------mutlipe table display   end  -----------------------------------------------------------> */}


                <div className={this.state.tourneyLobby == 0 ? "tourney_lobby" : "hide_tourney_lobby"}>
                    <TourneyLobbyMain idTables={this.tableidArray} ref={this.tLobbyOne} TourneyHandler={this.TourneyHandler.bind(this)} toggleCashTourneyTables={this.toggleCashTourneyTables.bind(this)} showCashTableTabs={this.state.showCashTableTabs}></TourneyLobbyMain>
                </div>

                <div className={this.state.tourneyLobby == 1 ? "tourney_lobby" : "hide_tourney_lobby"}>
                    <TourneyLobbyMain idTables={this.tableidArray} ref={this.tLobbyTwo} TourneyHandler={this.TourneyHandler.bind(this)} toggleCashTourneyTables={this.toggleCashTourneyTables.bind(this)} showCashTableTabs={this.state.showCashTableTabs}></TourneyLobbyMain>
                </div>
                {
                    (!this.state.showLobby && this.config.URL_Environment.TableFeatures.selected == "F1") && (
                        <div className={fileName.name !== "Leader_bet" ? "poker-nav" : !this.state.tableOpned ? "poker-nav" : "poker-nav_lb"}>
                            <div className={!this.state.showLobby ? "left1" : "left"}>
                                <button
                                    className="lb-btn"
                                    data-name="lobby"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.showLobby(e.target.dataset.name);
                                    }}
                                >
                                    Lobby
                                </button>
                                {/* {Object.values(this.state.openTLobbies).length && Object.values(this.state.openTables).length && this.state.showTlobbyButton ? (
                                <button id="c-tables-tab" className="lb-btn" data-name={this.state.showCashTableTabs ? "t-tables" : "c-tables"} onClick={this.toggleCashTourneyTables.bind(this)}>
                                    {this.state.showCashTableTabs ? "T.Lobby" : "T.Tables"}
                                </button>
                            ) : null} */}
                                {Object.values(this.state.openTLobbies).length && Object.values(this.state.openTables).length && this.state.showTlobbyButton ? (
                                    <button id="c-tables-tab" className="lb-btn" data-name={this.state.showCashTableTabs ? "t-tables" : "c-tables"} onClick={this.toggleCashTourneyTables.bind(this)}>
                                        {this.state.showCashTableTabs ? "T.Lobby" : this.state.showTlobbyButton ? "T.Tables" : "C.Tables"}
                                    </button>
                                ) : null}
                            </div>
                            <div className={fileName.name === "Leader_bet" ? "right_lb" : "right"} style={{ visibility: this.state.showMiniTableTab ? 'visible' : 'hidden' }}>
                                <div className="icons_lb" onClick={(e) => { this.actionButton(e) }} style={{ display: fileName.name !== "Leader_bet" ? 'none' : 'flex' }}>
                                    <img src={this.state.bottomInfoImage} alt="" />
                                </div>
                                <div className="next-div">
                                    {this.state.showCashTableTabs && !this.state.showLobby
                                        ? Object.values(this.state.openTables).map((table, i) => {
                                            return (
                                                <button
                                                    key={i}
                                                    className={table.index == this.state.activeTable ? "table-tab tt-active" : "table-tab"}
                                                    id={table.id}
                                                    data-index={table.index}
                                                    data-isactive={table.index == this.state.activeTable ? true : false}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // alert(table.id)
                                                        //   let el = document.getElementById(table.id);
                                                        //   if (el) {
                                                        //       el.innerHTML = "";
                                                        //       el.style.animation = "";
                                                        //       e.target.style.background = "";
                                                        //   }
                                                        this.showCashTables(table.index, table.id);
                                                    }}
                                                ></button>
                                            );
                                        })
                                        : null}
                                </div>
                                {!this.state.showCashTableTabs && !this.state.showLobby
                                    ? Object.values(this.state.openTLobbies).map((tLobby, i) => {
                                        return (
                                            <button
                                                key={i}
                                                className={tLobby.index == this.state.activeTLobby ? "table-tab tt-active" : "table-tab"}
                                                id={tLobby.id}
                                                data-index={tLobby.index}
                                                data-isactive={tLobby.index == this.state.activeTable ? true : false}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.showTourneyLobby(tLobby.index);
                                                }}
                                            ></button>
                                        );
                                    })
                                    : null}
                            </div>
                        </div>
                    )
                }
                {
                    (!this.state.showLobby && this.config.URL_Environment.TableFeatures.selected == "F2") && (
                        <div className="poker-nav1">
                            <div className={!this.state.showLobby ? "left1" : "left"}>
                                <button
                                    className="lb-btn"
                                    data-name="lobby"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.showLobby(e.target.dataset.name);
                                    }}
                                >
                                    Lobby
                                </button>
                                {Object.values(this.state.openTLobbies).length && Object.values(this.state.openTables).length
                                    // && this.state.showTlobbyButton 
                                    ? (
                                        <button id="c-tables-tab" className="lb-btn" data-name={this.state.showCashTableTabs ? "t-tables" : "c-tables"} onClick={this.toggleCashTourneyTables.bind(this)}>
                                            {this.state.showCashTableTabs ? "T.Lobby" : "T/C.Tables"}
                                        </button>
                                    ) : null}
                            </div>
                            {(Object.values(this.state.openTLobbies).length != 0) && <div className="right" style={{ visibility: this.state.hideMiniTableTab === false ? 'visible' : 'hidden' }}>
                                {/* {this.state.showCashTableTabs && !this.state.showLobby
                                ? Object.values(this.state.openTables).map((table, i) => {
                                    return (
                                        <button
                                            key={i}
                                            className={table.index == this.state.activeTable ? "table-tab tt-active" : "table-tab"}
                                            id={table.id}
                                            data-index={table.index}
                                            data-isactive={table.index == this.state.activeTable ? true : false}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // alert(table.id)
                                                //   let el = document.getElementById(table.id);
                                                //   if (el) {
                                                //       el.innerHTML = "";
                                                //       el.style.animation = "";
                                                //       e.target.style.background = "";
                                                //   }
                                                this.showCashTables(table.index);
                                            }}
                                        ></button>
                                    );
                                })
                                : null} */}
                                {!this.state.showCashTableTabs && !this.state.showLobby
                                    ? Object.values(this.state.openTLobbies).map((tLobby, i) => {
                                        return (
                                            <button
                                                key={i}
                                                className={tLobby.index == this.state.activeTLobby ? "table-tab tt-active" : "table-tab"}
                                                id={tLobby.id}
                                                data-index={tLobby.index}
                                                data-isactive={tLobby.index == this.state.activeTable ? true : false}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.showTourneyLobby(tLobby.index);
                                                }}
                                            ></button>
                                        );
                                    })
                                    : null}
                            </div>}
                        </div>
                    )
                }

                {/* {(Object.values(this.state.openTables).length || Object.values(this.state.openTLobbies).length) && this.state.showLobby && ( */}
                {
                    // ((Object.values(this.state.openTables).length) || Object.values(this.state.openTLobbies).length || this.state.showToggle) && this.state.showLobby && (
                    ((Object.values(this.state.openTables).length) || Object.values(this.state.openTLobbies).length) && this.state.showLobby && (
                        <div
                            className="footNav"
                            onClick={(e) => {
                                e.preventDefault();
                                this.showCashTables(null);
                            }}
                            style={{ bottom: fileName.name !== "Leader_bet" ? '100px' : '50px' }}
                        >
                            C.T
                        </div>
                    )
                }
                {this.state.alert.show && <Alert data={this.state.alert} setAction={this.setPopUpActions.bind(this)}></Alert>}
            </div >
        );
    }
}
