import React from "react";
import "../../../../css/ui/popUps/alert.css";
import close_1 from '../../../../assets/images/table/close_1.svg';
import { withTranslation } from 'react-i18next'
import fileName from "../../../../jsconfig";
import recoonect from '../../../../assets/images/popUp/reconnect.svg'
function ReconnectionAlert(props) {
  return (
    <React.Fragment>
      <div className="popCover_1"
      //   onClick={(e) => { e.preventDefault(); props.setAction("hideAlert"); }}
      > </div>
      {fileName.name !== "Leader_bet" && <div className="popup_1">
        <div className="popup_1_in">
          <div className="head">{props.t("Alert")}
            {/* <button className="close_1" onClick={(e) => { e.preventDefault(); props.setAction("hideAlert"); }} > <img src={close_1} alt=""/> </button> */}
          </div>
          <div className="fd clr_ff p_15 text_center">
            <div className="fd">{props.data.lineOne}</div>
            <div className="fd">{props.data.lineTwo}</div>
          </div>
        </div>
      </div>}
      {fileName.name == "Leader_bet" &&
        <div className="popup_1">
          <div className="popup_1_in rds_4  bg_7" style={{ borderRadius: "5px" }}>
            <div className="v_align m_10 rds_4" style={{ background: "#2A2B36" }}>

              <img className="p_5 m_t_5" style={{ height: "50px" }} src={recoonect} />
              <div className="df_jc_ac p_5">
                <span className="col-12 d_flex jcc m_t_5  txt_clr_5 font_25">{props.data.lineOne}</span>

              </div>
              <div className="df_jc_ac p_5">
                <span className="col-12 d_flex jcc clr_ff">{props.data.lineTwo}</span>

              </div>
            </div>

          </div>

        </div>

      }
    </React.Fragment>
  );
}
export default withTranslation()(ReconnectionAlert)