import { useMemo, useEffect, useState } from "react";
import { useTable } from "react-table";
import "../../../../css/ui/tLobby/participants.css";
import { withTranslation } from 'react-i18next';
function Participants(props) {
  const columns = useMemo(() => {
    return [
      {
        Header: props.t("Name"),
        accessor: "nickName",
      },
      {
        Header: props.t("Chips"),
        accessor: "chips",
      },
      {
        Header: props.t("Place"),
        accessor: "place",
      },
      {
        Header: props.t("KOB"),
        accessor: "knockoutFee",
      }
    ]
  }, []);
  const data = useMemo(() => props.data, [props.data]);
  // useEffect(() => {
  //  setData(props.data)
  // }, [props]);

  const table = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;
  const [spin, setSpin] = useState(false);
  const rotate = () => {
    setSpin(true)
    setTimeout(() => {
      setSpin(false)
    }, 3000);
  }
  return (
    <div className="fd">
      <div className="header_5">Participants
        <span style={{ marginLeft: '20px' }} onClick={() => { props.network.send("<GetPlayers/>") }}>
          <i onClick={() => rotate()} className={!spin ? "fa fa-refresh" : 'fa fa-refresh fa-spin'} ></i>
        </span> </div>
      <div className="fd">
        {/* <button className="btn_3" onClick={() => { props.network.send("<GetPlayers/>") }}>
          Click Here To See The List Of Participants</button> */}

        {/* <div className="eyeToggleDiv" onClick={() => { props.network.send("<GetPlayers/>") }}>
          <i className={this.state.spinStart ? 'fa fa-refresh fa-spin' : 'fa fa-refresh'} style={{ fontSize: "6.2vw", margin: '8px 0px 0px 0px' }}  ></i>
          <i className={'fa fa-refresh fa-spin'} style={{ fontSize: "6.2vw", margin: '8px 0px 0px 0px' }}  ></i>
        </div> */}
      </div>
      <div className="fd" style={{ overflow: "auto", maxHeight:props.featear? "86px":"100%" }}>

        <table id="participants_table" className="table_1" cellPadding={0} cellSpacing={0} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>
    </div>
  );
}
export default withTranslation()(Participants);