import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { useTable, usePagination } from "react-table";
import "../../../../css/ui/popUps/pokerhandHistory.css";
import Pokerhandphase from "./PokerHandphase.js";
import PokerHistoryPhaseTwo from './HistoryPhaseTwo.js'
import dIcon from "../../../../assets/images/lobby/arrow_up.png";
import Ac from "../../../../assets/images/popUp/chatCards/Ac.png";
import closeIcon from '../../../../assets/images/lobby/close_icon.svg';
import close_1 from './../../../../assets/images/table/close_1.svg';
import Config from "../../../../config.js";

import { withTranslation } from 'react-i18next'
var config = new Config();
function PokerhandHistory(props) {
  const columns = useMemo(() => {
    // return [
    //   {
    //     Header: props.t("Start Date"),
    //     accessor: "startDate",
    //   },
    //   {
    //     Header: props.t("End Date"),
    //     accessor: "finishDate",
    //   },
    //   {
    //     Header: props.t("Table Name"),
    //     accessor: "tableName",
    //   },
    //   {
    //     Header: props.t("Hands"),
    //     accessor: "roundsCount",
    //   },
    //   {
    //     Header: props.t("Payouts"),
    //     accessor: "payouts",
    //   },
    //   {
    //     Header: props.t("Compoints"),
    //     accessor: "compPoints",
    //   },
    //   {
    //     Header: props.t("Bonus"),
    //     accessor: "bonusBuyIn",
    //   },
    //   {
    //     Header: props.t("Bets"),
    //     accessor: "bets",
    //   },
    //   {
    //     Header: props.t("Buy-In"),
    //     accessor: "buyIn",
    //   },
    // ];
    return [
      {
        Header: "Date/Time",
        accessor: "time",
      },
      {
        Header: "Table Name",
        accessor: "tableName",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Hands",
        accessor: "hands",
      },
      {
        Header: "Result",
        accessor: "result",
      },
    ];
  }, []);
  const data = useMemo(() => props.data, [props.data]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, state, rows, prepareRow } = tableInstance;

  const { pageIndex } = state;

  const [startDate, setStartdate] = useState(null);
  const [endDate, setEnddate] = useState(null);
  const [showBtn, setShowbtn] = useState(true);
  const [historyDataNext, setHDnext] = useState([]);
  const [newhanddata, setnewhanddata] = useState([]);
  // const [secondHide,setSecondhide]= useState(true);
  const [thirdData, setThird] = useState([]);
  const [showSubpokerHistory, setShowSubpokerHistory] = useState(false);
  const [userName, setUsername] = useState(props.user);
  const [errorMsg, setErrorMsg] = useState(props.errorMsg);
  const [cardNames, setcardNames] = useState([]);
  const [cardId, setCardId] = useState([]);
  const [phaseTwoData, setPhaseTwoData] = useState([]);
  const [openTableData, setOpenTableData] = useState("");

  function handlestartDate() {
    setStartdate(moment(document.getElementById("startDate").value).format("DD-MM-YYYY"));
    setShowbtn(false);
  }


  function handleendDate() {
    setEnddate(moment(document.getElementById("endDate").value).format("DD-MM-YYYY"));
    setShowbtn(false);
  }

  function getSubHandHistory(rowId) {

    // let r = document.getElementsByTagName("tr");
    // for (var i = 0; i < r.length; i++) {
    //   // let row = r[i];
    //   let row = r[rowId];
    //   console.log(row)
    //   setTimeout(() => {
    //     row.style.boxShadow = '#007c32';
    //   }, 500);
    // }
    setOpenTableData(props.data[rowId])
    // let body = {
    //   "startDate": props.data[rowId].startDate,
    //   "endDate": props.data[rowId].finishDate,
    //   "pSessionId": props.data[rowId].playSessionId
    // }
    // console.log(body)

    // var phaseTwo = config.URL_Environment.proxy.baseUrl + config.URL_Environment.apiPath.handDataNew_Api;
    // var xhr = new XMLHttpRequest();
    // xhr.open("POST", phaseTwo, true);
    // xhr.setRequestHeader("Content-Type", "application/json");
    // xhr.setRequestHeader("siteid", config.URL_Environment.sitIds.sitid);
    // xhr.setRequestHeader("wsession", JSON.parse(sessionStorage.getItem(`${window.location.hostname}'_wSid'`)).wSid);
    // xhr.addEventListener("load", (e) => {
    //   eachTableData(e);
    // });

    // if (body) {
    //   xhr.send(JSON.stringify(body));
    // } else {
    //   xhr.send();
    // }




    let shData = props.secondLevel;
    let subData = historyDataNext;
    let subTables = shData.values[rowId].roundInfo;

    let i = 0,
      cnt = subTables.length;

    for (i; i < cnt; i++) {
      subData.push({
        Time: subTables[i].gameRoundInfo.startDate,
        HandNumber: subTables[i].gameRoundInfo.num,
        Result: subTables[i].gameRoundInfo.pot,
        win: subTables[i].playerRoundInfo.payout,
        bet: subTables[i].playerRoundInfo.bet,
        rowId: rowId,
      });
    }

    subData = [];

    setHDnext(subData);
    setnewhanddata(historyDataNext);
    setUsername(props.user);
    // CreateTableFromJSON()
  }

  function eachTableData(data) {
    let phaseTwoRes = JSON.parse(data.target.response);
    if (phaseTwoRes.success) {
      setPhaseTwoData(phaseTwoRes.roundData);
    } else {

    }
  }

  function thirdlevel(hnumber) {
    // console.log(hnumber);
    let forThird = props.secondLevel;
    console.log(forThird);
    // var cell =hnumber;
    var id = hnumber;
    // console.log(id);
    // console.log(newhanddata);

    let j = 0,
      cntOne = newhanddata.length,
      players = [];

    for (j = 0; j < cntOne; j++) {
      if (newhanddata[j].HandNumber === id) {
        players = forThird.values[newhanddata[j].rowId].roundInfo;
        console.log(players)
      }
    }

    // console.log(players);

    let i = 0,
      cnt = players.length,
      handData = [];

    for (i; i < cnt; i++) {
      if (players[i].gameRoundInfo.num === id) {
        if (players[i].gameRoundDataInfos.length !== 0) {
          try {
            handData = JSON.parse(players[i].gameRoundDataInfos[0].gameDa.replace(/\\/g, ""));
          } catch (e) { console.log(e) }
        } else {
          // alert("Sorry no data found");
          console.log("Sorry no data found");
          return;
        }
      }
    }

    console.log(handData);
    let playerNick = [];
    let playerInfo = handData.data.playersInfo;
    if (handData !== undefined) {
      if (handData.data.flop !== undefined && handData.data.turn !== undefined && handData.data.river !== undefined) {
        playerNick.push({
          nickName: "Community cards",
          Flop: (
            <div>
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(0, 2)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(3, 5)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(6, 8)}.png`).default} width={20} alt="Player" />
            </div>
          ),
          Turn: <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.turn}.png`).default} width={20} alt="Player" />,
          River: <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.river}.png`).default} width={20} alt="Player" />,
        });
      } else if (handData.data.flop !== undefined && handData.data.turn !== undefined && handData.data.river === undefined) {
        playerNick.push({
          nickName: "Community cards",
          Flop: (
            <div>
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(0, 2)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(3, 5)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(6, 8)}.png`).default} width={20} alt="Player" />
            </div>
          ),
          Turn: <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.turn}.png`).default} width={20} alt="Player" />,
          River: handData.data.river,
        });
      } else if (handData.data.flop !== undefined && handData.data.turn === undefined && handData.data.river === undefined) {
        playerNick.push({
          nickName: "Community cards",
          Flop: (
            <div>
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(0, 2)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(3, 5)}.png`).default} width={20} alt="Player" />
              <img src={require(`../../../../assets/images/popUp/chatCards/${handData.data.flop.slice(6, 8)}.png`).default} width={20} alt="Player" />
            </div>
          ),
          Turn: handData.data.turn,
          River: handData.data.river,
        });
      } else {
        playerNick.push({
          nickName: "Community cards",
          Flop: handData.data.flop,
          Turn: handData.data.turn,
          River: handData.data.river,
        });
      }
      console.log(handData.data.flop + " " + handData.data.turn + " " + handData.data.river);
    } else {
      console.log("players folded before preflop");
    }
    let playersCnt = Object.keys(playerInfo).length;
    // console.log(handData.data.flop.slice(0,2));
    // handData.data.flop,
    // handData.data.turn,
    // :handData.data.river

    for (var k = 0; k < playersCnt; k++) {
      var firstKey = Object.keys(playerInfo)[k];
      // console.log(`${playerInfo[firstKey].dealer}`);
      if (playerInfo[firstKey].cards !== undefined) {
        if (playerInfo[firstKey].dealer === true) {
          playerNick.push({
            nickName: `${playerInfo[firstKey].nickName}  🅳 `,
            Cards: (
              <div>
                <img src={require(`../../../../assets/images/popUp/chatCards/${playerInfo[firstKey].cards[0].slice(0, 2)}.png`).default} width={20} alt="Player" />
                <img src={require(`../../../../assets/images/popUp/chatCards/${playerInfo[firstKey].cards[0].slice(3, 5)}.png`).default} width={20} alt="Player" />
              </div>
            ),
            Blind: playerInfo[firstKey].commonData[0],
            PreFlop: playerInfo[firstKey].commonData[1],
            Flop: playerInfo[firstKey].commonData[2],
            Turn: playerInfo[firstKey].commonData[3],
            River: playerInfo[firstKey].commonData[4],
          });
          console.log(playerInfo[firstKey].cards);
        } else {
          playerNick.push({
            nickName: playerInfo[firstKey].nickName,
            Cards: (
              <div>
                <img src={require(`../../../../assets/images/popUp/chatCards/${playerInfo[firstKey].cards[0].slice(0, 2)}.png`).default} width={20} alt="Player" />
                <img src={require(`../../../../assets/images/popUp/chatCards/${playerInfo[firstKey].cards[0].slice(3, 5)}.png`).default} width={20} alt="Player" />
              </div>
            ),
            Blind: playerInfo[firstKey].commonData[0],
            PreFlop: playerInfo[firstKey].commonData[1],
            Flop: playerInfo[firstKey].commonData[2],
            Turn: playerInfo[firstKey].commonData[3],
            River: playerInfo[firstKey].commonData[4],
          });
          console.log(playerInfo[firstKey].cards);
        }
      } else {
        console.log("no record of opponent");
        return;
      }
    }

    let cntTwo = Object.keys(handData.data.potByPhase).length;
    // console.log(cntTwo);

    if (cntTwo !== 0) {
      if (cntTwo === 1) {
        playerNick.push({
          nickName: "Pot",
          PreFlop: handData.data.potByPhase[0].potsSum._value,
        });
      } else if (cntTwo === 2) {
        playerNick.push({
          nickName: "Pot",
          PreFlop: handData.data.potByPhase[0].potsSum._value,
          Flop: handData.data.potByPhase[1].potsSum._value,
        });
      } else if (cntTwo === 3) {
        playerNick.push({
          nickName: "Pot",
          PreFlop: handData.data.potByPhase[0].potsSum._value,
          Flop: handData.data.potByPhase[1].potsSum._value,
          Turn: handData.data.potByPhase[2].potsSum._value,
        });
      } else {
        playerNick.push({
          nickName: "Pot",
          PreFlop: handData.data.potByPhase[0].potsSum._value,
          Flop: handData.data.potByPhase[1].potsSum._value,
          Turn: handData.data.potByPhase[2].potsSum._value,
          River: handData.data.potByPhase[3].potsSum._value,
        });
      }
    } else {
      return;
    }

    console.log(playerNick);
    setThird(playerNick);

    setPopUpActionsOpen("opensubHistory");
  }

  // function CreateTableFromJSON() {

  //   var userHistory = historyDataNext;

  //   // EXTRACT VALUE FOR HTML HEADER.
  //   var col = [];
  //   for (var i = 0; i < userHistory.length; i++) {
  //       for (var key in userHistory[i]) {
  //           if (col.indexOf(key) === -1) {
  //               col.push(key);
  //           }
  //       }
  //   }

  //   // CREATE DYNAMIC TABLE.
  //   var table = document.createElement("table");

  //   // CREATE HTML TABLE HEADER ROW USING THE EXTRACTED HEADERS ABOVE.

  //   var tr = table.insertRow(-1);                   // TABLE ROW.

  //   for (var i = 0; i < col.length-1; i++) {
  //       var th = document.createElement("th");      // TABLE HEADER.
  //       th.innerHTML = col[i];
  //       tr.appendChild(th);
  //   }

  //   // ADD JSON DATA TO THE TABLE AS ROWS.
  //   for (var i = 0; i < userHistory.length; i++) {

  //       tr = table.insertRow(-1);

  //       tr.addEventListener("click",function() {thirdlevel(this.getElementsByTagName("td")[1]);});

  //       // console.log(tr);

  //       for (var j = 0; j < col.length-1; j++) {
  //           var tabCell = tr.insertCell(-1);
  //           tabCell.innerHTML = userHistory[i][col[j]];

  //       }
  //   }

  //   // FINALLY ADD THE NEWLY CREATED TABLE WITH JSON DATA TO A CONTAINER.
  //   var divContainer = document.getElementById("secondLevel");
  //   divContainer.innerHTML = "";
  //   divContainer.appendChild(table);

  // }

  function setPopUpActionsOpen(action) {
    switch (action) {
      case "opensubHistory":
        setShowSubpokerHistory(true);
        break;
      default:
        break;
    }
  }

  function setPopUpActionsClose(action) {
    switch (action) {
      case "hidesubHistory":
        setShowSubpokerHistory(false);
        break;
      case "hidePokerHistoryPhaseTwo":
        setPhaseTwoData([]);
        break;

      default:
        break;
    }
  }
  const getCurrentDateInput1 = () => {
    setTimeout(() => {
      setShowbtn(false);
      if (document.getElementById("startDate") !== null) {
        try {
          setStartdate(moment(document.getElementById("startDate").value).format("DD-MM-YYYY"));
        } catch (e) { console.log(e) }
      }
    }, 3000);
    const dateObj = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + (dateObj.getDate())).slice(-2);
    const year = dateObj.getFullYear();

    const startDate = `${year}-${month}-${day}`;

    return startDate;
  };
  const getCurrentDateInput = () => {
    setTimeout(() => {
      setShowbtn(false);
      if (document.getElementById("endDate") !== null) {
        setEnddate(moment(document.getElementById("endDate").value).format("DD-MM-YYYY"));
      }
    }, 3000);

    const dateObj = new Date();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const endDate = `${year}-${month}-${day}`;

    return endDate;
  };
  useEffect(() => {
    return () => {
      setShowbtn(true);
      setStartdate('');
      setStartdate('');
      console.log("unsubscribe all function ");
    }
  }, [])
  return (
    <React.Fragment>
      <div className="popCover_1" > </div>
      <div className="popup_1 inpElm">
        <div className="popup_1_in">
          <div className="head">  {props.t('Game History')}
            <button className="close_1" onClick={(e) => {
              e.preventDefault();
              props.setAction("hideHistory");
            }}> <img src={close_1} alt="" />  </button>
          </div>
          <div className="fd p_10 extra1">
            <div className="fd extra">
              <div className="row">
                <div className="fd  ">
                  <label htmlFor="starDate">{props.t('Start Date')}:</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    defaultValue={getCurrentDateInput1()}
                    onChange={() => {
                      handlestartDate();
                    }}
                  />
                </div>
                <div className="fd m_t_15">
                  <label htmlFor="endDate">{props.t('End Date')}:</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    max={getCurrentDateInput()}
                    defaultValue={getCurrentDateInput()}
                    onChange={() => {
                      handleendDate();
                    }}
                  />
                </div>
                <div className="fd m_t_15">
                  <label >{props.t('Page Count')}  </label>
                  <input type="number" name="index" value="10" readOnly />
                </div>

                <div className="fd">
                  <p style={{ color: 'red' }}>{props.errorMsg}</p>
                </div>
                <div className="fd" style={{ display: props.data.length ? 'none' : 'block' }}>
                  {/* <label > &nbsp; </label> */}
                  <button
                    className="btn_2 fd float_right"
                    onClick={(e) => {
                      props.gethandHistory(startDate, endDate);
                      setShowbtn(true);
                    }}
                    disabled={showBtn}
                  >
                    {props.t('Show')}
                    {showBtn && <span className="m_l_5 loader_3"></span>}
                  </button>
                </div>
              </div>
            </div>
            <div className="fd" style={{ display: !props.data.length ? 'none' : 'block' }}>
              <div className="fd m_t_15" style={{ overflow: 'auto', border: '1px solid gray', maxHeight: '250px' }}>
                <table className="table_1" cellPadding={0} cellSpacing={0} {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.render("Header")}>{column.render("Header")}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);

                      return (
                        <tr
                          key={row.id}
                          onClick={(e) => {
                            getSubHandHistory(row.id);
                            setPopUpActionsOpen("opensubHistory");
                          }}
                        // style={{ background: row.id ? 'red' : '' }}
                        >
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* <div className="secondlevel" id="secondLevel">
     
        </div> */}
              </div>
              <div className="fd m_t_15" >
                <div className="fd pageNatoin">
                  <span>
                    <button className="back" onClick={() => previousPage()} disabled={!canPreviousPage}>‹</button>
                  </span>
                  <span style={{ padding: '3px 10px' }}>
                    {props.t('Page')}{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </span>
                  <span>
                    <button className="next" onClick={() => nextPage()} disabled={!canNextPage}>›</button>
                  </span>
                </div>
              </div>
            </div>
            <div>{showSubpokerHistory && <Pokerhandphase data={thirdData} userName={userName} dataTwo={newhanddata} setAction={setPopUpActionsClose.bind(this)} setHandnumber={thirdlevel.bind(this)}></Pokerhandphase>}</div>
            {/* <div>{phaseTwoData.length !== 0 && <PokerHistoryPhaseTwo data={phaseTwoData} table={openTableData} setAction={setPopUpActionsClose.bind(this)}></PokerHistoryPhaseTwo>}</div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default withTranslation()(PokerhandHistory)