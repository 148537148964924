import logo from "../../assets/images/lobby/RiverLogo.png";
import small_logo from "../../assets/images/lobby/small_logo.png";
import { withTranslation } from 'react-i18next'
import "../../css/login/loader.css";
import { t } from "i18next";
 function Loader() {
    return (
        <div className="logoBoxBg">
            <div className="logoBoxLoader">
                <img src={logo} className="pixLoader" alt="RiverPoker"></img>
            </div>
            <div className="fd logoBoxLoader loadingPageDiv">
                {/* <div className="loaderlogo">
                    <img src={small_logo} className="" alt="RiverPoker"></img>
                </div> */}
                <div className="loading">
                    <div className="progress">
                        <div className="color"><div className="spark"></div></div>
                    </div>
                    <p>{t("Loading")}...</p>
                </div>
                
            </div>
            
        </div>
    );
};
export default (Loader)
