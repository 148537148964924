
import dollar from "../../../../assets/images/table/dollar.svg";
import buychips_lb from "../../../../assets/images/table/Leader-bet-icons/buychips_lb.png";
import fileName from "../../../../jsconfig";
import Screen from "../../../utils/screen";

export default function Rebuy(props) {
    const style_lb = {
        position: "fixed",
        right: "46px",
        top: '0px',
        width: '45px',
        height: '45px',
        margin: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: '1px solid red'
    }

    return (
        <div>
            {fileName.name !== "Leader_bet" ?
                <div className={props.csschange ? "buyChips" : "buyChips1"} style={{ right: (fileName.name === "Riverpoker" && Screen.getDeviceType().name != "Mobile") ? '55px' : '20%', top: fileName.name === "Riverpoker" ? '0px' : '5px' }}
                    onClick={(e) => {
                        e.preventDefault();
                        props.network.send("<ReBuy/>")
                    }}> <img src={dollar} style={{ width: fileName.name === "Riverpoker" ? '25px' : '' }} alt="Image not found" />
                </div>
                :
                <div style={style_lb}>
                    <img style={{ width: '45px', height: '45px', marginTop: '2px' }} src={buychips_lb} alt="" />
                </div>
            }
        </div>
    )
}